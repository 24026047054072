import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { FormModel, InitFormModel, InitFormStatusHistory } from './form.model'

export interface FormTWOModel {
  baseForm: FormModel

  tempWorkOrderNo?: string // <- system generated
  workOrderNo?: string

  // a
  // contractId
  descriptionOfWorks?: string
  justificationOnCoveringWorkOrder?: string
  verbalAgreementFromEctoDate?: Date
  verbalAgreementToContractorDate?: Date
  issuingCoveringWorkOrderDueDate?: Date
  notCoveringReason?: string
  coveringWorkOrder?: boolean
  ectoRemarks?: string
  typeOfMmWorks?: number
  linkedWoId?: number

  // d - signature
  signatureRequired: boolean
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string
}

export const InitTWOForm: FormTWOModel = {
  signatureRequired: true,
  baseForm: {
    ...InitFormModel,
    formStatus: 'FORM_TWO_DRAFT',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface TwoListResponse extends BaseListResponse {
  list: FormTwoListModel[]
  twoExtraInfo:  {
    ApprovedList: String[]
    DistrictIdList: number[]
    FormIdList: KeyValPair[]
    ParentFormIdList: KeyValPair[]
    SubmittedByList: String[]
    TeamIdList: number[]
    WorkOrderIdList: number[]
  }
}

export interface FormTwoListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string

  reportNo: string
  descriptionOfWorks: string

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export interface TwoGeneralOptions {
  contractNo: {
    id: number
    contractNo: string
    client: string
    contractor: string
    clientOrganizationId: number
    contractorOrganizationId: number
    contractTitle: string
    workOrderList: {
      id: number
      workOrderNo: string
      isLinkWithTempWorkOrder: boolean
    }[]
  }[]
  typeOfMmWorks: {
    id: number
    name: string
  }[]
}

import GeneralOptionsService from '@services/general.service'
import { FormStatus } from '@services/model/form/form.model'

const GetFormStatusId = (formStatusList: FormStatus[], targetFormStatusName: string[]): number[] | undefined => {
    if (formStatusList ){
        if(targetFormStatusName.length > 0){
            return (
                formStatusList?.filter((x) => targetFormStatusName.some(ac => ac.includes(x.actionCode)))?.map((x) => x.id)
            )
        }else{
            return []
        }
    }
}

export { GetFormStatusId }

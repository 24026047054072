import { cloneElement, Fragment, ReactElement } from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

/**
 * form controller extending controller from `react-hook-form` and wrapped with `FormField`
 * @param props
 * ```
 * {
 *  controllerProps: {
 *      name: "name of the field",
 *      control: "control of the form from useForm"
 *      rules: { required: true },
 *      ...
 *  },
 *  children: "react element",
 * }
 * ```
 * @example make sure to provide the correct `{name, control}` for `controllerProps`
 * ```
 *      <FormController
 *          controllerProps={{
 *          name: "weather",
 *          control: control,
 *          rules: { required: true },
 *      }}>
 *              <TextField fullWidth />
 *      </FormController>
 * ```
 */
function FormController<T extends FieldValues>(props: {
  controllerProps: UseControllerProps<T>
  children: ReactElement<any>
}) {
  const {
    field: { onChange, value, name, onBlur },
    fieldState: { error },
  } = useController(props.controllerProps)


  return (
    <Fragment>
      {cloneElement(props.children, {
        onChange: onChange,
        onBlur: onBlur,
        value: value,
        name: name,
        error: error,
      })}
    </Fragment>
  )
}

export default FormController

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { Typography } from '@mui/material'
import { FormCdrModel, InitCDRForm } from '@services/model/form/form.CDR.model'
import moment from 'moment'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormSignatureHistories from '@components/form/signatureHistories'
import { BaseFormPermission } from '@services/model/form/form.model'
import { GlobalStateProps } from '@providers/globalStore'

export default ({
    control,
    getValues,
    globalState,
    formPermission,
    handleOnComplete
}:{
    control: Control<FormCdrModel>
    getValues: any
    globalState: GlobalStateProps
    formPermission: BaseFormPermission
    handleOnComplete:any
}) => {
    const UUID = uuid()
    let index = 0

    return (
    <FormContainer fkey={`${UUID}-cdr-c`}>
        <FormSignatureHistories
          formStatusList={globalState.formStatusList}
          histories={getValues('baseForm.formStatusHistories')}
          control={control}
          handleOnComplete={() => handleOnComplete()}
          endOfFlow={formPermission?.endOfFlow}
          userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
          disabled={!formPermission?.canUpdate}
        />
    </FormContainer>
    )
  }
import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { ContractNo } from '../contract.model'
import { FileModel } from '../file.model'
import {
  BaseFormStatusDtoForList,
  FormModel,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
} from './form.model'

export interface FormNeaModel {
  baseForm: FormModel

  // a
  // contract no
  typeOfMmWorks?: number
  typeOfMmWorksValue?: string
  witnessedBy?: string
  excludedDays?: Array<number>
  monthYearOfPerformanceStandard?: Date
  neaExcels?: FileModel[]

  // b
  // mmWorksId
  noOfItemForSelection?: number
  noOfSelectedItem?: number
  dateOfSelection?: Date
  selectionResult?: string

  // c
  neaNotiticationNo?: string
  dateOfAudit?: Date
  auditingOfficer?: string
  checkingOfficer?: string

  meetupLocationId?: number

  // d - signature
  signatureRequired: boolean
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string
}

export const InitNEAForm: FormNeaModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.FORM_NEA_DRAFT,
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  signatureRequired: true,
}

export interface NEAExcelFormatingRule {
  header: string
  dataType: string
  allowEmpty: boolean
}

export interface NEAMmWorkDefectOption {
  id: number
  status: boolean
  defectCategoryId: number
  defectCode: string
  rmiCode?: string
  description: string
  createdAt: Date
  createdBy: string
}

export interface NeaListResponse extends BaseListResponse {
  list: FormNeaListModel[]
}

export interface NeaFilterOptions extends BaseFilterOptions {
  workOrderIdList: number[]
}

export interface NEAMmWorkOptionGroup {
  id: number
  status: boolean
  name: string
  editable: boolean
  createdAt: Date
  createdBy: string
}

export interface NEADistrictGroupType {
  id: number
  name: string
}

export interface NeaDistrictMeetupLocation {
  id: number
  districtId: number
  meetupLocation: string
}

export interface NeaDistrictWorkOrder {
  id: number
  workOrderNo: string
}

export interface NEADistrictOfficer {
  id: number
  name: string
}

export interface FormNeaListModel extends BaseFormStatusDtoForList {
  formId: number
  color: string

  reportNo: string
  teamId: number[]
  districtId: number[]
  dateOfSelection: Date
  dateOfAudit: Date

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export type NeaGeneralOptions = {
  typeOfMmWorks: {
    id: number
    name: string
    excelValidationRules: NEAExcelFormatingRule[]
  }[]
  meetUpLocations?: {
    id: number
    districtId: number
    meetUpLocation: string
  }[]
  auditingOfficer?: {
    id: string
    name: string
  }[]
  checkingOfficer?: {
    id: string
    name: string
  }[]
}

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormRadio from '@components/form/radio'
import { FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import FormFreeText from '@components/form/freeText'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { TextField } from '@mui/material'
import FormTypography from '@components/form/typography'
import FormSelect from '@components/form/select'

export default ({
    control,
    readOnly,
    useWatch,
    setValue
}:{
    control: Control<FormSDModel>
    readOnly: boolean
    useWatch: any
    setValue: any
}) => {
    const UUID = uuid()
    let index = 0
    const { getOptionsByKey } = useGeneralOptions()
    const { t } = useTranslation()

    
  const WorkInProgressFields = () => {
    const workStatus = useWatch({
      name: 'worksStatusId',
      control,
    })

    const isInProgress =
      getOptionsByKey('Form SD - Works Status')?.find((o) => o.value === 'Works in progress')
        ?.key === workStatus

    if (isInProgress) {
      return (
        <>
          <FormField fieldName="Category">
            <FormController
              controllerProps={{
                name: 'workInProgressTypeId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={getOptionsByKey('Form SD/SCR - Works In Progress Type')}
                uneditable={readOnly}
                additionalChangeAction={(event, newValue) => {
                  console.log('additional change action')
                  setValue('wipTypeSubOptionId', undefined)
                  setValue('wipTypeSubOptionOthers', '')
                }}
              />
            </FormController>
          </FormField>
        </>
      )
    } else {
      return <></>
    }
  }

  const WorkInProgressSuboptionsField = () => {
    const [workStatus, progressTypeId] = useWatch({
      name: ['worksStatusId', 'workInProgressTypeId'],
      control,
    })

    const isInProgress = getOptionsByKey('Form SD - Works Status')?.find((o) => o.value === 'Works in progress')?.key === workStatus

    if (isInProgress) {
      return (
        <>
          <FormField fieldName="Actual Activities">
            <FormController
              controllerProps={{
                name: 'wipTypeSubOptionId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={getOptionsByKey('Form SD/SCR - WIP Type Sub Option').filter(
                  (x) => x.parentId === progressTypeId,
                )}
                uneditable={readOnly}
                additionalChangeAction={(event, newValue) => {
                  console.log('additional change action')
                  setValue('wipTypeSubOptionOthers', '')
                }}
              />
            </FormController>
          </FormField>
        </>
      )
    }else{
      return <></>
    }
  }

  const OthersFields = () => {
    const [workStatus, wipTypeSubOptionId] = useWatch({
      name: ['worksStatusId', 'wipTypeSubOptionId'],
      control,
    })

    const isInProgress = getOptionsByKey('Form SD - Works Status')?.find((o) => o.value === 'Works in progress')?.key === workStatus
    const isOthers = getOptionsByKey('Form SD/SCR - WIP Type Sub Option')?.find((o) => o.value === 'Others')?.key === wipTypeSubOptionId

    if (isInProgress && isOthers) {
      return (
        <>
          <FormField fieldName="Others">
            <FormController
              controllerProps={{
                name: 'wipTypeSubOptionOthers',
                control: control,
              }}>
              {!readOnly ? (
                <TextField
                  fullWidth
                  multiline
                  rows={7}
                  placeholder={t('Please fill in information')}
                />
              ) : (
                <FormTypography />
              )}
            </FormController>
          </FormField>
        </>
      )
    } else {
      return <></>
    }
  }

  const OldActualActivitiesFormFields = () => {
    const actualActivities = useWatch({
      name: 'actualActivities',
      control,
    })

    if(actualActivities){
      return (
      <FormField fieldName="Actual Activities(Old)">
        <FormController
          controllerProps={{
            name: 'actualActivities',
            control: control,
          }}>
          {!readOnly ? (
            <TextField
              fullWidth
              multiline
              rows={7}
              placeholder={t('Please fill in information')}
            />
          ) : (
            <FormTypography />
          )}
        </FormController>
      </FormField>
      )
    }else{
      return <></>
    }
  }

    return (
      <FormContainer fkey={`$sd-c`}>
        <FormField fieldName="Works Status">
          <FormController
            controllerProps={{
              name: 'worksStatusId',
              control: control,
            }}>
            <FormRadio
              labels={getOptionsByKey('Form SD - Works Status')}
              uneditable={readOnly}
              additionalChangeAction={(value: number) => {
                if (
                  value !== getOptionsByKey('Form SD - Works Status')?.find( (o) => o.value === 'Works in progress' )?.key
                ) {
                  setValue('workInProgressTypeId', undefined)
                  setValue('wipTypeSubOptionId', undefined)
                  setValue('wipTypeSubOptionOthers', '')
                }
              }}
            />
          </FormController>
        </FormField>
        <WorkInProgressFields />
        <WorkInProgressSuboptionsField />
        <OthersFields />
        <OldActualActivitiesFormFields />
      </FormContainer>
    )
  }
import {
  Typography,
  Collapse,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import { FormStatus, FormStatusHistory } from '@services/model/form/form.model'
import { Fragment, useState } from 'react'
import FormDate from './date'
import FormField from './field'
import { useTranslation } from 'react-i18next'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Base64StringToImageBase64 } from '@utils/image'
import FormController from './controller'
import FormSignatureHolder from './signatureHolder'
import { KeyValPair } from '@models/common'

type formSignatureHistoriesProps = {
  histories: FormStatusHistory[]
  handleOnComplete?: () => void
  control?: any
  endOfFlow?: boolean
  formStatusList?: FormStatus[]
  userMetaList?: KeyValPair[]
  roleMetaList?: KeyValPair[]

  disabled?: boolean
}

/**
 * form signature histories showcasing signatures and related fields from action histories
 * @param props formSignatureHistoriesProps
 * @example
 * ```
 * <FormSignatureHistories histories={form.formStatusHistories} />
 * ```
 */
const FormSignatureHistories = (props: formSignatureHistoriesProps) => {
  const [open, setOpen] = useState<boolean[]>(
    props.histories?.map((_: FormStatusHistory, index: number) =>
      index === props.histories.length - 1 ? true : false,
    ) || [],
  )
  const handleOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }

  console.log("props.histories", props.histories)
  console.log("props.formStatusList",  props.formStatusList)

  return (
    <Grid xs={12}>
      <List>
        {props.histories?.map((history: FormStatusHistory, index: number) => (
          <List key={`form-history-${index}-${history.id}`}>
            {index === props.histories.length - 1 && props.endOfFlow ? (
              <Box
                sx={{
                  color: 'inherit',
                  display: 'flex',
                  width: '100%',
                  padding: '8px 16px',
                  border: '1px solid #c9c9c9',
                  alignItems: 'center',
                }}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    history.actionName
                      ? history.actionName
                      : props.formStatusList?.find((x) => x.id === history.formStatusId)?.actionName
                  }
                />
              </Box>
            ) : (
              <Fragment>
                <ListItemButton
                  onClick={(_: any) => {
                    handleOpen(index)
                  }}>
                  <ListItemIcon>
                    <BookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      history.actionName
                        ? history.actionName
                        : props.formStatusList?.find((x) => x.id === history.formStatusId)
                            ?.actionName
                    }
                  />
                  {open[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open[index]}>
                  {index === props.histories.length - 1 ? (
                    props.endOfFlow ? (
                      <></>
                    ) : (
                      <Fragment>
                        <FormField fieldName="Submit by">
                          <Typography>
                            {props.userMetaList?.find((x) => x.key === history.actionBy)?.value}
                          </Typography>
                        </FormField>

                        <FormField fieldName="Signature">
                          <FormController
                            controllerProps={{
                              name: 'signatureBase64',
                              control: props.control,
                            }}>
                            <FormSignatureHolder
                              onClick={() => {
                                if (props.handleOnComplete && !props.disabled) {
                                  props.handleOnComplete()
                                }
                              }}
                              disabled={props.disabled}
                            />
                          </FormController>
                        </FormField>

                        <FormField fieldName="Date Sign-off">
                          <FormDate value={history.actionAt} visable={false} />
                        </FormField>
                      </Fragment>
                    )
                  ) : (
                    <Fragment>
                      <FormField fieldName="Submit by">
                        <Typography>
                          {history?.actionByUserName && history.actionByUserRole
                            ? `${history.actionByUserName} (${history?.actionByUserRole
                                ?.map(
                                  (role) =>
                                    props.roleMetaList?.find((x) => x.key === role)?.value ?? '',
                                )
                                ?.join(', ') ?? ''})`
                            : props.userMetaList?.find((x) => x.key === history.actionBy)?.value}
                        </Typography>
                      </FormField>
                      {history.actionSignatureBase64 && (
                        <FormField fieldName="Signature">
                          <Box
                            sx={{
                              width: 300,
                              height: 150,
                            }}>
                            <Box
                              component="img"
                              alt={`action-history-${index}-signature`}
                              src={Base64StringToImageBase64(history.actionSignatureBase64, 'jpeg')}
                            />
                          </Box>
                        </FormField>
                      )}

                      <FormField fieldName="Date Sign-off">
                        <FormDate value={history.actionAt} visable />
                      </FormField>
                    </Fragment>
                  )}
                </Collapse>
              </Fragment>
            )}
          </List>
        ))}
      </List>
    </Grid>
  )
}

export default FormSignatureHistories

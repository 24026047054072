import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import {
  FormNeaModel,
} from '@services/model/form/form.NEA.model'
import { Fragment, useContext } from 'react'
import { GlobalContext } from '@providers/globalStore'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormTypography from '@components/form/typography'
import FormDate from '@components/form/date'
import FormContentBlock from '@components/form/contentBlock'

export default ({
  control,
  formStatus,
  getValues
}: {
  control: Control<FormNeaModel>
  formStatus: string
  getValues: any
}) => {
  const UUID = uuid()

  const readonly = true
  const { state: globalState } = useContext(GlobalContext)

  return (
    <FormContainer fkey={`${UUID}-nea-b`}>
          {formStatus === 'Draft' ? (
            <FormContentBlock />
          ) : (
            <Fragment>
              <FormField fieldName="Type of M&M Works">
                <FormTypography value={getValues("typeOfMmWorksValue")} />
              </FormField>
              <FormField fieldName="Team">
                <FormController
                  controllerProps={{
                    name: 'baseForm.teamId',
                    control,
                  }}>
                  <FormMultipleSelect options={globalState.teamList || []}  uneditable={readonly}/>
                </FormController>
              </FormField>
              <FormField fieldName="District">
                <FormController
                  controllerProps={{
                    name: 'baseForm.districtId',
                    control,
                  }}>
                  <FormMultipleSelect
                    options={globalState.districtList || []}
                    uneditable={readonly}
                  />
                </FormController>
              </FormField>
              <FormField fieldName="Sample Pool Uploaded by">
                <FormController
                  controllerProps={{
                    name: 'baseForm.createdBy',
                    control,
                  }}>
                  <FormTypography type="select" options={globalState.userMetaList ?? []} />
                </FormController>
              </FormField>
              <FormField fieldName="Sample Pool Witnessed by">
                <FormController
                  controllerProps={{
                    name: 'witnessedBy',
                    control,
                  }}>
                  <FormTypography />
                </FormController>
              </FormField>
              <FormField fieldName="No. of Item for Selection">
                <FormController
                  controllerProps={{
                    name: 'noOfItemForSelection',
                    control,
                  }}>
                  <FormTypography />
                </FormController>
              </FormField>
              <FormField fieldName="No. of Item Selected">
                <FormController
                  controllerProps={{
                    name: 'noOfSelectedItem',
                    control,
                  }}>
                  <FormTypography />
                </FormController>
              </FormField>
              <FormField fieldName="Date & Time of Sample Selection">
                <FormController
                  controllerProps={{
                    name: 'dateOfSelection',
                    control,
                  }}>
                  <FormDate />
                </FormController>
              </FormField>
              <FormField fieldName="Results">
                <FormController
                  controllerProps={{
                    name: 'selectionResult',
                    control,
                  }}>
                  <FormTypography />
                </FormController>
              </FormField>
            </Fragment>
          )}
        </FormContainer>
  )
}

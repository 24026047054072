import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

const UnauthourizedPage = () => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: '5px',
      }}>
      <Box
        sx={{
          mt: 1,
          backgroundColor: 'white',
          padding: '25px',
          borderRadius: '5px',
        }}
        minWidth={matchUpMd ? '850px' : '455px'}
        maxWidth={matchUpMd ? '850px' : '455px'}>
        <Typography variant="h5" component="div" gutterBottom sx={{ textAlign: 'center' }}>
          Unauthorized Access
        </Typography>

        <Typography variant="subtitle1" gutterBottom component="div" sx={{ textAlign: 'center' }}>
          The page you are going to accesse is not authorized, please contact your supervisor if you
          have further questions.
        </Typography>
      </Box>
    </Grid>
  )
}

export default UnauthourizedPage

import {
  Typography,
  useMediaQuery,
  Box,
  Grid,
} from '@mui/material'
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CSSProperties, Fragment, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from '@styles/reactDatePicker.module.scss'
import DialogButton from '@components/button/DialogButton'

const dateTimePickerTheme = createTheme({
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 30,
        },
      },
    },
  },
})

interface formDateTimePickerProps {
  value?: Date
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  minDate?: Date
  maxDate?: Date
  minTime?: Date
  maxTime?: Date
  filterDate?: (date) => boolean
  type?: 'month' | 'date' | 'datetime'
  additionalChangeAction?: (value: Date | null) => void
  sx?: CSSProperties

  disabled?: boolean
  uneditable?: boolean
  currentTimeBtn?: boolean
}

const FormDateTimePicker = (props: formDateTimePickerProps) => {
  const { t } = useTranslation()
  const [date, setDate] = useState<Date | null>(props.value ? new Date(props.value) : null)
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  let picker: JSX.Element = <></>
  switch (props.type) {
    case 'month':
      picker = (
        <Grid container spacing={2}>
          <Grid item xs={props.currentTimeBtn ? 9 : 12}>
            <DatePicker
              portalId="root-portal"
              className={styles.datePicker}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              calendarClassName={styles.datePickerCalendar}
              selected={date}
              placeholderText={t('Please pick a month')}
              onChange={(value) => {
                setDate(value)
                const _date = value ? new Date(value.getFullYear(), value.getMonth(), 1) : undefined
                props.onChange!(_date)
                if (props.additionalChangeAction) {
                  props.additionalChangeAction(value)
                }
              }}
              showPopperArrow={false}
              showMonthYearPicker
              showFullMonthYearPicker
              dateFormat="MMMM yyyy"
              minDate={props.minDate}
              maxDate={props.maxDate}
              withPortal={downMd}
              preventOpenOnFocus={true}
            />
          </Grid>
          {props.currentTimeBtn && (
            <Grid item xs={3}>
              <DialogButton
                sx={{ backgroundColor: '#ffffff' }}
                onClick={() => {
                  const target: Date = new Date()
                  setDate(target)
                  props.onChange!(target ?? undefined)
                  if (props.additionalChangeAction) {
                    props.additionalChangeAction(target)
                  }
                }}>
                <Typography sx={{whiteSpace: 'nowrap'}}>Current Time</Typography>
              </DialogButton>
            </Grid>
          )}
        </Grid>
      )
      break
    case 'date':
      picker = (
        <Grid container spacing={2}>
          <Grid item xs={props.currentTimeBtn ? 9 : 12}>
            <DatePicker
              portalId="root-portal"
              formatWeekDay={(day: string) => day.substr(0, 1)}
              className={styles.datePicker}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              calendarClassName={styles.datePickerCalendar}
              selected={date}
              placeholderText={t('Please pick a datetime')}
              onChange={(value) => {
                setDate(value)
                props.onChange!(value ?? undefined)
                if (props.additionalChangeAction) {
                  props.additionalChangeAction(value)
                }
              }}
              showPopperArrow={false}
              dateFormat="d MMMM yyyy"
              minDate={props.minDate}
              maxDate={props.maxDate}
              withPortal={downMd}
              preventOpenOnFocus={true}
              filterDate={props.filterDate}
            />
          </Grid>
          {props.currentTimeBtn && (
            <Grid item xs={3}>
              <DialogButton
                sx={{ backgroundColor: '#ffffff' }}
                onClick={() => {
                  const target: Date = new Date()
                  setDate(target)
                  props.onChange!(target ?? undefined)
                  if (props.additionalChangeAction) {
                    props.additionalChangeAction(target)
                  }
                }}>
                <Typography sx={{whiteSpace: 'nowrap'}}>Current Time</Typography>
              </DialogButton>
            </Grid>
          )}
        </Grid>
      )
      break
    case 'datetime':
    default:
      picker = (
        <Grid container spacing={2}>
          <Grid item xs={props.currentTimeBtn ? 9 : 12}>
            <DatePicker
              portalId="root-portal"
              formatWeekDay={(day: string) => day.substr(0, 1)}
              className={styles.datePicker}
              wrapperClassName={styles.datePickerWrapper}
              popperClassName={styles.datePickerPopper}
              calendarClassName={styles.datePickerCalendar}
              timeClassName={() => {
                return styles.datePickerTime
              }}
              selected={date}
              placeholderText={t('Please pick a datetime')}
              onChange={(value) => {
                setDate(value)
                props.onChange!(value ?? undefined)
                if (props.additionalChangeAction) {
                  props.additionalChangeAction(value)
                }
              }}
              showPopperArrow={false}
              // showTimeSelect
              timeIntervals={1}
              timeFormat="HH:mm"
              dateFormat="d MMMM yyyy, HH:mm"
              minDate={props.minDate}
              maxDate={props.maxDate}
              minTime={props.minTime}
              maxTime={props.maxTime}
              withPortal={downMd}
              preventOpenOnFocus={true}
              showTimeInput
            />
          </Grid>
          {props.currentTimeBtn && (
            <Grid item xs={3}>
              <DialogButton
                sx={{ backgroundColor: '#ffffff' }}
                onClick={() => {
                  const target: Date = new Date()
                  setDate(target)
                  props.onChange!(target ?? undefined)
                  if (props.additionalChangeAction) {
                    props.additionalChangeAction(target)
                  }
                }}>
                <Typography sx={{whiteSpace: 'nowrap'}}>Current Time</Typography>
              </DialogButton>
            </Grid>
          )}
        </Grid>
      )
      break
  }
  const getTypographyDisplay = () => {
    if (!props.value) {
      return ''
    }

    switch (props.type) {
      case 'month': {
        return moment(props.value).format('MMMM YYYY')
      }
      case 'date': {
        return moment(props.value).format('D MMMM YYYY')
      }
      case 'datetime':
      default: {
        return moment(props.value).format(moment.defaultFormat)
      }
    }
  }

  return (
    <ThemeProvider theme={dateTimePickerTheme}>
      {props.uneditable ? (
        <Typography sx={{ display: 'flex', alignItems: 'flex-start', ...props.sx }}>
          {getTypographyDisplay()}
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', ...props.sx }}>
          {picker}
        </Box>
      )}
    </ThemeProvider>
  )
}
export default FormDateTimePicker

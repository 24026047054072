import { Avatar, Box, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { formateDatetime } from '@utils/timeConvertor'
import FormIcon, { formIconProps } from '../icon'
import { styled } from '@mui/material/styles'
import CustomIcon from '@components/icon/icon'
import Styles from '@styles/activityLogRecordRow.module.scss'

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    borderRadius: '5px',
    padding: theme.spacing(1),
}))

//list -> activityLog
interface formActivityLogRecordRowProps {
    id: number
    type: string
    fromUser: number
    formIconProps?: formIconProps
    title: string
    msgContent?: string | null
    logDatetime: Date
}

const FormActivityLogRecordRow = (props: formActivityLogRecordRowProps) => {
    //TODO: get user icon by user id
    let userIconImg = "url('../img/dummyAvatar.png')"
    let thirdIconImg = "url('../img/icon1.svg')"

    //get second icon by log type
    let secIcon: any
    switch (props.type) {
        case 'form':
            if (props.formIconProps !== undefined) {
                secIcon = <FormIcon {...props.formIconProps}></FormIcon>
            }
            break
        default:
            secIcon = <>Unmatched activity log type</>
            break
    }

    //TODO: click activity func
    const onClickHanlder = () => {
        console.log('click activity log: ', props.id)
    }

    return (
        <StyledListItemButton key={props.id} onClick={onClickHanlder} className={Styles.log}>
            <Grid
                item
                md={9}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    className={Styles.icon}
                    sx={{
                        marginRight: '10px',
                    }}
                >
                    <CustomIcon className={Styles.image} image={userIconImg}></CustomIcon>
                </Avatar>
                <Box
                    component={'div'}
                    sx={{
                        marginLeft: '5px',
                        marginRight: '-10px',
                    }}
                >
                    {secIcon}
                </Box>
                <Avatar
                    className={Styles.icon}
                    sx={{
                        marginRight: '10px',
                    }}
                >
                    <CustomIcon className={Styles.image} image={thirdIconImg}></CustomIcon>
                </Avatar>
                <ListItemText
                    primary={props.title}
                    secondary={props.msgContent ? props.msgContent : null}
                    sx={{
                        marginLeft: '10px',
                    }}
                />
            </Grid>
            <Grid item md={3}>
                <ListItemText primary={formateDatetime(props.logDatetime)} />
            </Grid>
        </StyledListItemButton>
    )
}
export default FormActivityLogRecordRow

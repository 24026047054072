import apiGateway from '@utils/axiosInterceptor'
import { FormScrListModel, FormScrModel, ScrGeneralOptions, CdrOptions, ScrListResponse, ScrFilterOptions, ScrDashboardReq } from '../model/form/form.SCR.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'
import { DashboardChart } from '@services/model/base.model'

const GetGeneralOptions = async () => {
  return await apiGateway.get<ScrGeneralOptions>(`/api/v1/forms/SCR/options`).then((rep) => {
    return rep.data
  })
}

const GetCdrOptions = async (req: {
  contractNoId: number
  formDate: Date
  WorkOrderId: number
}) => {
  return await apiGateway.post<CdrOptions>('api/v1/forms/SCR/cdr-options', req).then((rep) => {
    return rep.data
  })
}

const GetScrForm = async (formId: string) => {
  return await apiGateway
    .get<FormScrModel>(`api/v1/forms/SCR/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetScrList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<ScrListResponse>(
      `api/v1/forms/SCR/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetExistingScr = async (formId: string, reportDate: string) => {
  return await apiGateway
    .get<FormScrModel>(`/api/v1/forms/clone?category=${'SCR'}&formId=${formId}&reportDate=${reportDate}`)
    .then((rep) => {
      return rep.data
    })
}

const GetScrFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/SCR/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<ScrFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveScrForm = async (req: FormScrModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/SCR', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/SCR/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveScrForm = async (req: FormScrModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/SCR/${req.baseForm.formId ? req.baseForm.formId : '_'}/approve`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const RejectScrForm = async (req: FormScrModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/SCR/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteScrForm = async (req: FormScrModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/SCR/${req.baseForm.formId}`,
  )
}

const GetScrDashboardContent = async(req: ScrDashboardReq) => {
  return await apiGateway.post<{mmWorksInScr: DashboardChart}>(`api/v1/forms/SCR/dashboards`, req).then((rep) => {
    return rep.data
  })
}


const FormScrService = {
  GetGeneralOptions,
  GetScrForm,
  GetScrList,
  GetExistingScr,
  GetScrFilterOption,
  SaveScrForm,
  ApproveScrForm,
  RejectScrForm,
  DeleteScrForm,
  GetCdrOptions,
  GetScrDashboardContent
}

export default FormScrService

import FormContainer from '@components/form/container'
import { FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { GlobalStateProps } from '@providers/globalStore'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn } from 'react-hook-form'
import uuid from 'react-uuid'
import FormSignatureHistories from '@components/form/signatureHistories'
import { BaseFormPermission } from '@services/model/form/form.model'
export default ({
    control,
    getValues,
    handleOnComplete,
    globalState,
    formPermission
}:{
    control: Control<FormSDModel>
    getValues: any,
    handleOnComplete: any,
    globalState: GlobalStateProps,
    formPermission: BaseFormPermission
}) => {
    const UUID = uuid()

    return (
        <FormContainer fkey={`${UUID}-sd-i`}>
           <FormSignatureHistories
              formStatusList={globalState.formStatusList}
              histories={getValues('baseForm.formStatusHistories')}
              control={control}
              handleOnComplete={() => handleOnComplete()}
              endOfFlow={formPermission?.endOfFlow}
              userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
              disabled={!formPermission?.canUpdate}
            />
        </FormContainer>
    )
  }
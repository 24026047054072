import FormBinarySelect from '@components/form/binarySelect'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormSelect from '@components/form/select'
import FormTypography from '@components/form/typography'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { FormPartComponents } from '@models/common'
import { Typography, TextField } from '@mui/material'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormScrModel, ScrGeneralOptions } from '@services/model/form/form.SCR.model'
import { Fragment } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default({
  control,
  useWatch,
  setValue,
  getValues,
  scrGeneralOptions,
  formStatus,
  globalState
}: {
  control: Control<FormScrModel>
  useWatch: any
  setValue: UseFormSetValue<FormScrModel>
  getValues: any
  scrGeneralOptions?: ScrGeneralOptions
  formStatus: string
  globalState: any
}) => {
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

  const [cdrRef] = useWatch({
    name: ['cdrRef'],
    control: control,
  })

  const editPartB = cdrRef !== true && editable

  const WorkOrderFields = () => {
    const [contractNoId, workOrderId] = useWatch({
      name: ['baseForm.contractNoId', 'baseForm.workOrderId'],
      control: control,
    })

    const selectedWorkOrder = globalState.worksOrderList?.find((x) => x.id === workOrderId)

    return (
      <Fragment>
        <FormField fieldName="Type of Works">
          <FormTypography value={selectedWorkOrder?.typeOfWorks ? 'M&M Works' : 'Non-M&M works'} />
        </FormField>

        <FormField fieldName="Work Order No.">
          <FormTypography value={selectedWorkOrder?.workOrderNo} />
        </FormField>
      </Fragment>
    )
  }

  const NonMmWorkFields = () => {
    const [contractNoId, workOrderId] = useWatch({
      name: ['baseForm.contractNoId', 'baseForm.workOrderId'],
      control: control,
    })

    const selectedWorkOrder = globalState.worksOrderList?.find((x) => x.id === workOrderId)

    const isMmWorks = selectedWorkOrder?.typeOfWorks === true
    if (isMmWorks) return <></>

    return (
      <Fragment>
        <FormField fieldName="Essential Operation">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.essentialOperation',
              control: control,
            }}>
            {editPartB ? <FormBinarySelect fullWidth /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>
        <FormField fieldName="Hidden Works other than LSG">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.hiddenWorkOtherThanLsg',
              control: control,
            }}>
            {editPartB ? <FormBinarySelect fullWidth /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>
        <FormField fieldName="In-situ Test">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.inSituTest',
              control: control,
            }}>
            {editPartB ? <FormBinarySelect fullWidth /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>
      </Fragment>
    )
  }

  return(
      <FormContainer fkey={`scr-b`}>
        <FormField fieldName="Contractor's Daily Report Ref.">
          <Typography>{editPartB ? t('No') : t('Yes')}</Typography>
        </FormField>

        <WorkOrderFields />

        <FormField fieldName="Road Name & Location Details">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.locationDetails',
              control: control,
            }}>
            {editPartB ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={t('Please fill in information')}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
        <FormField fieldName="Proposed Activities">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.proposedActivities',
              control: control,
            }}>
            {editPartB ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={t('Please fill in information')}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
        <FormField fieldName="No. of Workers">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.noOfWorker',
              control: control,
            }}>
            {editPartB ? (
              <FormFreeText
                textfieldProps={{
                  fullWidth: true,
                  rows: 10,
                  type: 'number',
                  inputProps: {
                    min: '0',
                  },
                }}
                sx={{
                  width: '90%',
                }}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>

        <FormField fieldName="LSG on site">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.lsgOnSite',
              control: control,
            }}>
            {editPartB ? <FormBinarySelect fullWidth /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>

        <NonMmWorkFields />

        <FormField fieldName="Day/Night Works">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.dayNightWorks',
              control: control,
            }}>
            {editPartB ? (
              <FormSelect fullWidth options={getOptionsByKey('Form SD - Day Night Works')} />
            ) : (
              <FormTypography type='select' options={getOptionsByKey('Form SD - Day Night Works')}  />
            )}
          </FormController>
        </FormField>
        <FormField fieldName="IOW/WS Remarks">
          <FormController
            controllerProps={{
              name: 'fromScrCrw.iosWsRemarks',
              control: control,
            }}>
            {editPartB ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={t('Please fill in information')}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
      </FormContainer>
     )
  }
    
    
import FormContainer from '@components/form/container'
import { Control } from 'react-hook-form'
import { FormWouModel, WouGeneralOptions } from '@services/model/form/form.WOU.model'
import { useTranslation } from 'react-i18next'
import FormTable from '@components/form/table'

export default ({
  control,
  uneditable
}: {
  control: Control<FormWouModel>
  uneditable: boolean
}) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-e`}>
      <FormTable
        columns={[
          {
            field: 'dateOfContractorParticularsSubmission',
            displayName: 'Date of Contractor Particulars Submission',
            type: 'date',
          },
          {
            field: 'dateOfCompletionOfEngineerValuation',
            displayName: 'Date of Completion of Engineer Valuation',
            type: 'date',
          },
          {
            field: 'attachment',
            displayName: 'Attachment',
            type: 'fileUpload',
            acceptFiles: ['application/pdf']
          },
        ]}
        fieldArrayProps={{
          name: 'siteInstructionValuations',
          control,
        }}
        height={100}
        uneditable={uneditable}
      />
    </FormContainer>
  )
}

import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { FileModel } from '../file.model'
import {
  FormModel,
  InitFormModel,
  BaseFormStatusDtoForList,
  FormStatusEnum,
  InitFormStatusHistory,
} from './form.model'
import { FormSdpModel } from './form.SDP.model'
export interface FormSDModel {
  baseForm: FormModel
  sdNo?: string
  cdrid?: number
  notifyUserList?: string[]
  formDate?: Date

  //a
  form_date?: Date
  cdrRef?: boolean
  cdrId?: number
  itemNo?: number

  //b
  formSdCrw?: FormSDCrw

  //c
  worksStatusId?: number
  actualActivities?: string
  workInProgressTypeId?: number
  wipTypeSubOptionId?: number
  wipTypeSubOptionOthers?: string

  //d
  siteProgressForLabour?: FormSDLabour[]
  siteProgressForPlant?: FormSDPlant[]

  //e
  sdPhotos?: FormSdpModel[]

  //f
  workCompletionId?: number
  essentialOperation?: number[]
  essentialOperationOther?: string
  inSituTest?: number[]
  inSituTestOther?: string
  inSituSample?: number[]
  inSituSampleOther?: string

  //g
  remarks?: string
  instructionToContractor?: string
  contactorRepesentativeComment?: string

  //h
  // hiddenWorkRef?: FormSDHiddenWork[]
  // hiddenWorkCheck?: boolean
  hiddenWorkRefs?: FileModel[]

  // cdr
  siteIdling?: boolean
  worksCommenced?: boolean
  worksCompleted?: boolean
  xpSswEoNo?: string
  category?: string
  location?: string

  signatureRequired: boolean
  signatureBase64?: string
  submissionComment?: string

  //from backend
  cdrNo?: string
  itemNoValue?: string
  hiddenWorkCheck: boolean

  summaryMapBase64?: string
}

// export interface FormSDHiddenWork {
//   id: number
//   status: boolean
//   recordNo?: string
//   contractNo?: string
//   category?: string
//   woNo?: string
// }

export interface SdListResponse extends BaseListResponse {
  list: FormSdListModel[]
}

export interface SdFilterOptions extends BaseFilterOptions {
  workOrderIdList: number[]
}

export interface FormSDLabour {
  id: number
  status: boolean
  tradeId: number
  workingHourFrom?: string
  workingHourTo?: string
  company?: string
  noOfWorker?: number
  remarks?: string
  resourceTypeId?: number
}

export interface FormSDPlant {
  id: number
  status: boolean
  plantType?: string
  plantId?: string
  company?: string
  workingHourFrom?: string
  workingHourTo?: string
  noOfPlant?: number
  noOfIdlePlant?: number
  idleCodeId?: number
  remarks?: string
  resourceTypeId?: number
  resourceTypeOthers?: string
}

export interface FormSDCrw {
  locationDetails?: string
  proposedActivities?: string
  noOfWorker?: number
  lsgOnSite?: Boolean
  essentialOperation?: Boolean
  hiddenWorkOtherThanLsg?: Boolean
  inSituTest?: Boolean
  dayNightWorks?: number
  iosWsRemarks?: string
}

export interface FormSdListModel extends BaseFormStatusDtoForList {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string

  reportNo: string
  contractNo: string
  workOrderNo: string
  parentReportNo: string
  auditSequence: number
  location: string
  inspectionDate: Date
  formDate: Date

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export const InitSDForm: FormSDModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.FORM_SD_DRAFT,
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  formSdCrw: {},
  signatureRequired: false,
  hiddenWorkCheck: false,
}

export interface SDGeneralOptions {
  tradeList: {
    id: number
    code: string
    trade: string
    tradeDivision: string
  }[]
  dayNightWorks: {
    id: number
    name: string
  }[]
  workStatusList: {
    id: number
    name: string
  }[]
  idleCodeList: {
    id: number
    name: string
  }[]
  photoCategoryList: {
    id: number
    name: string
  }[]
}

export type CdrOptions = {
  workOrderList: {
    id: number
    workOrderNo: string
    districtId?: number
    typeOfWorks?: boolean
  }[]
  cdrList: {
    id: number
    cdrNo: string
    relatedWorkOrder: {
      workOrderId: number
      items: {
        id: number
        name: string
        locationDetails: string
        proposedActivities: string
        noOfWorker: number
        lsgOnSite: boolean
        essentialOperation: boolean
        hiddenWorkOtherThanLsg: boolean
        inSituTest: boolean
        dayNightWorks: number
        iosWsRemarks?: string
      }[]
    }[]
  }[]
}

import { Grid, ListItemIcon, SvgIcon } from '@mui/material'
import styles from '@styles/sidebar.module.scss'
import { ReactComponent as FormSvg } from '@images/presentation-alt.svg'

type FormIconProps = {
  acronym?: string
  shortNameBgColor?: string
}

const FormIcon = (props: FormIconProps) => {
  return (
    <Grid>
      <Grid>
        <ListItemIcon
          sx={{
            // p: 1,
            paddingTop: 1,
            paddingBottom: 1,
            // backgroundColor: props.shortNameBgColor,
            width: 'fit-content',
            height: 'fit-content',
            '--data-code-bg-color-var': props.shortNameBgColor as React.CSSProperties,
          }}
          className={styles.afa2}
          data-code={props.acronym}>
          <SvgIcon sx={{ fontSize: '45px' }}>
            <FormSvg />
          </SvgIcon>
        </ListItemIcon>
      </Grid>
    </Grid>
  )
}
export default FormIcon

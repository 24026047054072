import { KeyValPair } from '@models/common'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  useMediaQuery,
  Paper,
  CircularProgress,
  Grid,
  Tooltip,
  ClickAwayListener,
  Autocomplete,
  FilterOptionsState,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { SelectChangeEvent } from '@mui/material/Select'
import { styled, useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Dispatch, Fragment, SetStateAction, useState, useRef, useEffect } from 'react'
import {
  ArrayPath,
  Controller,
  ControllerRenderProps,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  Path,
  useFieldArray,
  UseFieldArrayProps,
} from 'react-hook-form'
import moment from 'moment'
import GetValueWithKey from '@utils/getValueWithKey'
import { ImageModel } from '@services/model/image.model'
import { ImageModelToUrl, Base64StringToImageBase64 } from '@utils/image'
import FormTypography from './typography'
import FormDateTimePicker from './dateTimePicker'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { FileToFileModel, GetFileFromUrl } from '@utils/file'
import { FileModel } from '@services/model/file.model'
import FileIcon from '@components/icon/file'
import DownloadIcon from '@mui/icons-material/Download'
import ClearIcon from '@mui/icons-material/Clear'
import useImageModal from '@hooks/useImageModal'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FormSelect from './select'
import { useSnackbar } from 'notistack'
import FormMultipleSelect from './multipleSelect'
import { filter, get, map } from 'lodash'

const FormTableBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '16px 30px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '16px 0px',
  },
  // overflowX: 'auto',
}))

const FormTableFooterButton = styled(Button)(() => ({
  height: 60,
  width: '100%',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  backgroundColor: '#ffffff',
  ':hover': {
    backgroundColor: '#00ffff',
    '#form-table-add-icon': {
      color: '#ffffff',
    },
  },
  ':disabled': {
    backgroundColor: '#d9d9d9',
    '#form-table-add-icon': {
      color: '#ffffff',
    },
  },
}))

const TableImageHolder = styled(Box)(({ theme }) => ({
  margin: 'auto',
  border: '0.1px solid rgb(0 0 0 / 20%)',
  objectFit: 'contain',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 70,
  },
  [theme.breakpoints.up('md')]: {
    width: 300,
    height: 200,
  },
}))

const FormTableAddIcon = styled(AddIcon)(() => ({
  color: '#000000',
}))

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 'bold',
  padding: theme.spacing(1),
}))

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  padding: theme.spacing(1),
}))

const TableFileUpload = (props: {
  value: FileModel[] | undefined
  onChange: (...event: any[]) => void
  disabled?: boolean
  uneditable?: boolean
  acceptFiles?: string[]
}) => {
  const [files, setFiles] = useState<FileModel[]>([])
  const uploadRef = useRef<HTMLInputElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  // mounting
  const mounted = useRef<boolean>(false)
  useEffect(() => {
    if (!mounted.current && props.value) {
      setFiles(props.value)
      setUploaded(true)
      mounted.current = true
    }
  }, [props.value])

  const handleUploadFileChange = async (event: any) => {
    setIsLoading(true)

    if (event.target.files) {
      const _files: File[] = [...event.target.files]
      const _file = await FileToFileModel(_files[0])
      if (props.acceptFiles !== undefined && props.acceptFiles.length > 0) {
        var mimeDb = require('mime-db')
        var data = props.acceptFiles.map((x) => mimeDb[x].extensions).flat()
        if (!data.includes(_file?.ext)) {
          enqueueSnackbar(t('Invalid format. Please upload in PDF format'), {
            variant: 'error',
            autoHideDuration: null,
          })
          setIsLoading(false)
          return
        }
      }
      if (_file) {
        setFiles([...files, _file])
        props.onChange!([...files, _file])
      }
      event.target.value = ''
      setUploaded(true)
    }

    setIsLoading(false)
  }

  const handleAddFileClick = () => {
    if (uploadRef && uploadRef.current && !uploaded) {
      uploadRef.current.click()
    }
  }

  const handleDownloadFile = async () => {
    setIsLoading(true)

    const file = files.find((f) => f.status)
    if (file) {
      const fileBytes = file.bytes
      if (fileBytes) {
        const download = document.createElement('a')
        download.href = window.URL.createObjectURL(new Blob([fileBytes]))
        download.setAttribute('download', `${file.fileName}.${file.ext}`)
        document.body.appendChild(download)
        download.click()
        document.body.removeChild(download)
      } else if (file.fileUrl && file.fileUrl !== '') {
        const blob = await GetFileFromUrl(file)
        if (blob) {
          const download = document.createElement('a')
          download.href = URL.createObjectURL(blob)
          console.log(URL.createObjectURL(blob))
          download.setAttribute('download', `${file.fileName}.${file.ext}`)
          document.body.appendChild(download)
          download.click()
          document.body.removeChild(download)
        }
      }
    }

    setIsLoading(false)
  }

  const handleRemoveFile = () => {
    setIsLoading(true)

    const _files = [...files]
    const foundIndex = _files.findIndex((f) => f.status)
    if (foundIndex !== -1) {
      // fileId => status = false
      // otherize => remove record
      if (_files[foundIndex].fileId) {
        _files[foundIndex].status = false
      } else {
        _files.splice(foundIndex, 1)
      }
      setFiles(_files)
      props.onChange!(_files)
      setUploaded(false)
    }

    setIsLoading(false)
  }

  const CurrentFile = () => {
    const file = files.find((f) => f.status)
    if (file) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}>
          <FileIcon text={file ? file.ext : ''} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 12,
                whiteSpace: 'nowrap',
              }}>
              {file && `${file.fileName}.${file.ext}`}
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: 9,
                whiteSpace: 'nowrap',
              }}>
              {Math.floor(file.size / 1024) + t('Kb')}
            </Typography>
          </Box>
          <IconButton onClick={handleDownloadFile}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={handleRemoveFile}>
            <ClearIcon />
          </IconButton>
        </Box>
      )
    } else {
      return <></>
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
      <input
        type="file"
        ref={uploadRef}
        style={{ display: 'none' }}
        onChange={handleUploadFileChange}
      />
      <Paper
        sx={{
          width: '95%',
          minWidth: 150,
          height: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(!uploaded && {
            cursor: 'pointer',
          }),
        }}
        {...(!uploaded && {
          onClick: handleAddFileClick,
        })}>
        {isLoading ? <CircularProgress /> : uploaded ? <CurrentFile /> : <UploadFileIcon />}
      </Paper>
    </Box>
  )
}

function FormTable<F extends FieldValues>(props: {
  columns: {
    field: string
    subfield?: string
    displayName: string
    type?:
      | 'select'
      | 'string'
      | 'number'
      | 'times'
      | 'checkbox'
      | 'images'
      | 'date'
      | 'fileUpload'
      | 'binarySelect'
      | 'displayDate'
      | 'displaySelected'
      | 'displayImages'
      | 'controlledCustom'
      | 'custom'
      | 'selectWithSearch'
      | 'multipleSelect'
    dateFormat?: string
    options?: KeyValPair[]
    optionsFilterBy?: any
    binaryOptions?: { yes: string; no: string; na?: string }
    handleSelectChange?: (
      event: any,
      newValue: KeyValPair | null,
      row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
      rowIndex: number,
    ) => void
    render?: (
      row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
      rowIndex: number,
      setWarningText: Dispatch<SetStateAction<string | undefined>>,
    ) => JSX.Element
    controlledRender?: (
      row: FieldArrayWithId<F, ArrayPath<F>, '_id'>,
      field: ControllerRenderProps<F, Path<F>>,
      rowIndex: number,
      setWarningText: Dispatch<SetStateAction<string | undefined>>,
    ) => JSX.Element
    hidden?: boolean
    disable?: boolean
    uneditable?:
      | boolean
      | ((row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex: number) => boolean)
    mobileHidden?: boolean
    disableFieldCallback?: (name: string, datumIndex: number) => any
    tooltip?: string
    width?: number | string
    minHeight?: number | string
    minNumber?: number
    acceptFiles?: string[]
    nonDuplicates?: boolean
    renderKey?: boolean
    onChange?: (event: SelectChangeEvent) => void
  }[]
  fieldArrayProps: UseFieldArrayProps<F>
  useWatch?: any

  header?: string
  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  removeAddButton?: boolean
  removeDeleteButton?: boolean
  disableAddButton?: boolean
  extraButtons?: (rowIndex: number) => JSX.Element
  additionalAppendAction?: (event: any) => void
  additionalRemoveAction?: (event: any, index: number) => void
  additionalRow?: (rows: FieldArrayWithId<F, ArrayPath<F>, '_id'>[]) => JSX.Element
  rowFilter?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex?: number) => boolean
  statusMapper?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>) => boolean

  disabled?: boolean
  uneditable?: boolean
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const { fields, append, remove, update } = useFieldArray({
    keyName: '_id',
    ...props.fieldArrayProps,
  })
  const [warningText, setWarningText] = useState<string | undefined>()
  let itemNo = 0

  const appendField = (event: any) => {
    append({ status: true } as FieldArray<F, ArrayPath<F>>)
    if (props.additionalAppendAction) {
      props.additionalAppendAction(event)
    }
  }

  const removeField = (event: any, index: number) => {
    // uuid / formId / id / fileId -> set status = false
    //           otherize -> remove record
    if (fields[index]['uuid'] || fields[index]['id'] || fields[index]['fileId']) {
      const _field = fields[index]
      _field['status'] = false
      update(index, _field)
    } else if (fields[index]['baseForm'] && fields[index]['baseForm']['formId']) {
      const _field = fields[index]
      _field['baseForm']['status'] = false
      update(index, _field)
    } else {
      remove(index)
    }
    if (props.additionalRemoveAction) {
      props.additionalRemoveAction(event, index)
    }
  }

  const [modalImage, setModalImage] = useState<string | undefined>()
  const [ImageModal, openImageModal] = useImageModal()
  const handleImageModalOpen = (base64Url: string) => {
    setModalImage(base64Url)
    openImageModal()
  }

  return (
    <Grid xs={12}>
      <FormTableBox>
        {props.header && <Typography>{props.header} : </Typography>}
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#c5c5c5',
              }}>
              <HeaderTableCell
                align={props.align ?? 'center'}
                style={{
                  width: 5,
                  display: props.hiddenItemNo ? 'none' : 'table-cell',
                }}>
                {downMd ? '#' : t('Item No.')}
              </HeaderTableCell>
              {props.columns.map((column, index: number) => (
                <Tooltip
                  title={column.tooltip ? t(column.tooltip) : ''}
                  key={`form-table-header-tooltip-${index}`}
                  placement="top">
                  <HeaderTableCell
                    style={{
                      display:
                        column.hidden || (downMd && column.mobileHidden) ? 'none' : 'table-cell',
                      // flex: 1,
                      minWidth: column.width ?? '100px',
                    }}
                    key={`form-table-header-${index}`}
                    align={props.align ?? 'center'}>
                    {t(column.displayName)}
                  </HeaderTableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((datum: FieldArrayWithId<F, ArrayPath<F>, '_id'>, datumIndex: number) => {
              if (
                props.rowFilter &&
                !props.rowFilter(datum as FieldArrayWithId<F, ArrayPath<F>, '_id'>, datumIndex)
              ) {
                return <Fragment key={`form-table-row-${datumIndex}-${datum['_id']}`} />
              } else if (
                !props.rowFilter &&
                !(datum['status'] || (datum['baseForm'] && datum['baseForm']['status']))
              ) {
                return <Fragment key={`form-table-row-${datumIndex}-${datum['_id']}`} />
              }

              return (
                <TableRow
                  key={`form-table-row-${datumIndex}-${datum['_id']}`}
                  sx={{
                    height: props.height ?? 200,
                    ':hover': {
                      '#table-row-actions': {
                        display: 'inherit',
                      },
                    },
                    backgroundColor: '#edeef0',
                  }}>
                  <BodyTableCell
                    style={{
                      flex: 1,
                      position: 'relative',
                      display: props.hiddenItemNo ? 'none' : 'table-cell',
                    }}
                    key={`form-table-cell-${datumIndex}-${datum['_id']}-item-no`}>
                    {props.removeDeleteButton || props.uneditable || props.disabled ? (
                      <></>
                    ) : (
                      <Box
                        id="table-row-actions"
                        sx={{
                          position: 'absolute',
                          transition: 'transform',
                          transform: 'translateX(-100%)',
                          display: 'none',
                          ':hover': {
                            display: 'inherit',
                          },
                        }}>
                        <IconButton
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                              transition: 'transform 1000ms ease-in-out',
                              transform: 'rotate(360deg)',
                            },
                          }}
                          onClick={(event: any) => {
                            removeField(event, datumIndex)
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                    {!props.extraButtons ? <></> : props.extraButtons!(datumIndex)}

                    <Typography textAlign={props.align ?? 'center'} fontSize={12}>
                      {++itemNo}
                    </Typography>
                  </BodyTableCell>
                  {props.columns.map((column, columnIndex: number) => {
                    let children: JSX.Element = <Fragment></Fragment>
                    if (column.hidden || (downMd && column.mobileHidden)) {
                      return children
                    }

                    if (column.type) {
                      switch (column.type) {
                        case 'select': {
                          if (column.options) {
                            children = (
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                        <Fragment>
                                          {
                                            props.uneditable ||
                                            (typeof column.uneditable === 'function'
                                             ? column.uneditable(datum, datumIndex)
                                             : column.uneditable) ? (
                                                    <FormTypography type='select'
                                                                    options={ column.options }
                                                                    value={ field.value }
                                                                    sx={ { fontSize: 12 } } />
                                            ) : (
                                                    <Select fullWidth
                                                            sx={ {
                                                              fontSize       : 12,
                                                              backgroundColor: '#ffffff',
                                                              height         : column.minHeight
                                                            } }
                                                            error={ !!error }
                                                            { ...field }
                                                            { ...(column.onChange ? { onChange: column.onChange } : {}) }>
                                                      {
                                                        column.options!.map((option: KeyValPair) =>
                                                                                    <MenuItem sx={ { fontSize: 12 } }
                                                                                              value={ option.key }
                                                                                              key={ `form-table-select-option-${option.key}` }>
                                                                                      { t(option.value) }
                                                                                    </MenuItem>)
                                                      }
                                                    </Select>
                                            )
                                          }
                                        </Fragment>
                                )}
                                name={ `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F> }
                                control={ props.fieldArrayProps.control }
                                defaultValue={ datum[column.field] } />
                            )
                          }
                          break
                        }
                        case 'selectWithSearch': {
                          if (column.options) {
                            const selected: number[] = map(fields, column.field)
                            children = (
                              <Controller
                                render={({ field, fieldState: { error } }) => (
                                  <FormSelect
                                    fullWidth
                                    options={column.options || []}
                                    uneditable={props.uneditable}
                                    value={field.value}
                                    onChange={(value: any) => {
                                      if (value) {
                                        field.onChange(value)
                                      }
                                      if (column.renderKey) {
                                        const rowValue = get(fields, datumIndex)
                                        rowValue[column.field] = value
                                        console.log('onChange rowValue: ', rowValue)
                                        update(datumIndex, rowValue)
                                      }
                                    }}
                                    disabledOption={column.nonDuplicates ? selected : []}
                                  />
                                )}
                                name={
                                  `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                                }
                                control={props.fieldArrayProps.control}
                                defaultValue={datum[column.field]}
                              />
                            )
                          }
                          break
                        }
                        case 'binarySelect': {
                          children = (
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <Fragment>
                                  {props.uneditable ||
                                  (typeof column.uneditable === 'function'
                                    ? column.uneditable(datum, datumIndex)
                                    : column.uneditable) ? (
                                    <FormTypography
                                      type="binary"
                                      value={field.value}
                                      sx={{ fontSize: 12 }}
                                    />
                                  ) : (
                                    <Select
                                      fullWidth
                                      sx={{
                                        fontSize: 12,
                                        backgroundColor: '#ffffff',
                                      }}
                                      disabled={column.disable || props.disabled}
                                      error={!!error}
                                      value={
                                        typeof field.value !== 'boolean'
                                          ? 'NA'
                                          : field.value
                                          ? 'Yes'
                                          : 'No'
                                      }
                                      onChange={(event: any) => {
                                        switch (event.target.value) {
                                          case 'Yes': {
                                            field.onChange(true)
                                            return
                                          }
                                          case 'No': {
                                            field.onChange(false)
                                            return
                                          }
                                          default: {
                                            field.onChange(undefined)
                                            return
                                          }
                                        }
                                      }}>
                                      <MenuItem
                                        sx={{
                                          fontSize: 12,
                                        }}
                                        value="Yes"
                                        key={`form-table-select-option-true`}>
                                        {column.binaryOptions
                                          ? t(column.binaryOptions.yes)
                                          : t('Yes')}
                                      </MenuItem>
                                      <MenuItem
                                        sx={{
                                          fontSize: 12,
                                        }}
                                        value="No"
                                        key={`form-table-select-option-false`}>
                                        {column.binaryOptions
                                          ? t(column.binaryOptions.no)
                                          : t('No')}
                                      </MenuItem>
                                      <MenuItem
                                        value="NA"
                                        key={`form-table-select-option-empty`}
                                        style={{
                                          display:
                                            column.binaryOptions && column.binaryOptions.na
                                              ? 'inherit'
                                              : 'none',
                                          fontSize: 12,
                                        }}>
                                        {column.binaryOptions &&
                                          column.binaryOptions.na &&
                                          t(column.binaryOptions.na)}
                                      </MenuItem>
                                    </Select>
                                  )}
                                </Fragment>
                              )}
                              name={
                                `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                              }
                              control={props.fieldArrayProps.control}
                            />
                          )
                          break
                        }
                        case 'multipleSelect': {
                          if (column.options) {
                            const rowValue = get(fields, datumIndex)
                            let options = column.options
                            if (column.optionsFilterBy) {
                              const predicate = [
                                column.optionsFilterBy,
                                get(rowValue, column.optionsFilterBy),
                              ]
                              options = filter(column.options, predicate) as any as KeyValPair[]
                            }
                            console.log('rowValue', rowValue)
                            console.log('options', options)
                            console.log('column.options', column.options)
                            children = (
                              <Box>
                                <Controller
                                  render={({ field, fieldState: { error } }) => (
                                    <FormMultipleSelect
                                      options={options || column.options || []}
                                      uneditable={props.uneditable}
                                      value={field.value}
                                      onChange={(value: any) => {
                                        if (value) {
                                          field.onChange(value)
                                        }
                                      }}
                                    />
                                  )}
                                  name={
                                    `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                                  }
                                  control={props.fieldArrayProps.control}
                                  defaultValue={datum[column.field]}
                                />
                              </Box>
                            )
                          }
                          break
                        }
                        case 'string': {
                          let disableByCallBack = false
                          if (column.disableFieldCallback) {
                            disableByCallBack = column.disableFieldCallback(
                              props.fieldArrayProps.name,
                              datumIndex,
                            )
                          }
                          children = (
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <Fragment>
                                  {props.uneditable ||
                                  (typeof column.uneditable === 'function'
                                    ? column.uneditable(datum, datumIndex)
                                    : column.uneditable) ? (
                                    <FormTypography
                                      type="string"
                                      value={field.value}
                                      sx={{ fontSize: 12 }}
                                    />
                                  ) : (
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={5}
                                      value={field.value ?? ''}
                                      onChange={(event: any) => field.onChange(event.target.value)}
                                      error={!!error}
                                      placeholder={t(column.displayName)}
                                      InputProps={{
                                        style: {
                                          fontSize: 12,
                                          backgroundColor: '#ffffff',
                                        },
                                      }}
                                      disabled={
                                        column.disable || props.disabled || disableByCallBack
                                      }
                                    />
                                  )}
                                </Fragment>
                              )}
                              name={
                                `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                              }
                              control={props.fieldArrayProps.control}
                              defaultValue={datum[column.field]}
                            />
                          )
                          break
                        }
                        case 'number': {
                          children = (
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <Fragment>
                                  {props.uneditable ||
                                  (typeof column.uneditable === 'function'
                                    ? column.uneditable(datum, datumIndex)
                                    : column.uneditable) ? (
                                    <FormTypography
                                      type="string"
                                      value={field.value}
                                      sx={{ fontSize: 12 }}
                                    />
                                  ) : (
                                    <TextField
                                      fullWidth
                                      type="number"
                                      error={!!error}
                                      placeholder={t(column.displayName)}
                                      InputProps={{
                                        inputProps: { min: column.minNumber },
                                        style: {
                                          fontSize: 12,
                                          backgroundColor: '#ffffff',
                                          height: column.minHeight,
                                        },
                                      }}
                                      value={field.value ?? ''}
                                      onChange={(event: any) =>
                                        field.onChange(parseInt(event.target.value))
                                      }
                                      disabled={column.disable || props.disabled}
                                    />
                                  )}
                                </Fragment>
                              )}
                              name={
                                `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                              }
                              control={props.fieldArrayProps.control}
                              defaultValue={datum[column.field]}
                            />
                          )
                          break
                        }
                        case 'times': {
                          if (column.subfield) {
                            children = (
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center">
                                {t('From')}
                                <Controller
                                  render={({ field, fieldState: { error } }) => (
                                    <Fragment>
                                      {props.uneditable ||
                                      (typeof column.uneditable === 'function'
                                        ? column.uneditable(datum, datumIndex)
                                        : column.uneditable) ? (
                                        <FormTypography
                                          type="string"
                                          value={field.value}
                                          sx={{ fontSize: 12 }}
                                        />
                                      ) : (
                                        <TextField
                                          type="time"
                                          InputProps={{
                                            style: {
                                              fontSize: 12,
                                              backgroundColor: '#ffffff',
                                            },
                                          }}
                                          // {...field}
                                          error={!!error}
                                          disabled={column.disable || props.disabled}
                                          onChange={(event: any) =>
                                            field.onChange(event.target.value)
                                          }
                                          value={field.value}
                                        />
                                      )}
                                    </Fragment>
                                  )}
                                  name={
                                    `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                                  }
                                  control={props.fieldArrayProps.control}
                                  defaultValue={datum[column.field] ?? ''}
                                />
                                {t('to')}
                                <Controller
                                  render={({ field, fieldState: { error } }) => (
                                    <Fragment>
                                      {props.uneditable ||
                                      (typeof column.uneditable === 'function'
                                        ? column.uneditable(datum, datumIndex)
                                        : column.uneditable) ? (
                                        <FormTypography
                                          type="string"
                                          value={field.value}
                                          sx={{ fontSize: 12 }}
                                        />
                                      ) : (
                                        <TextField
                                          type="time"
                                          InputProps={{
                                            style: {
                                              fontSize: 12,
                                              backgroundColor: '#ffffff',
                                            },
                                          }}
                                          // {...field}
                                          error={!!error}
                                          disabled={column.disable || props.disabled}
                                          onChange={(event: any) => {
                                            field.onChange(event.target.value)
                                            console.log('event time', event)
                                          }}
                                          value={field.value}
                                        />
                                      )}
                                    </Fragment>
                                  )}
                                  name={
                                    `${props.fieldArrayProps.name}.${datumIndex}.${column.subfield}` as Path<F>
                                  }
                                  control={props.fieldArrayProps.control}
                                  defaultValue={datum[column.field] ?? ''}
                                />
                              </Box>
                            )
                          }
                          break
                        }
                        case 'checkbox': {
                          children = (
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <Fragment>
                                  {props.uneditable ||
                                  (typeof column.uneditable === 'function'
                                    ? column.uneditable(datum, datumIndex)
                                    : column.uneditable) ? (
                                    <FormTypography
                                      type="binary"
                                      value={field.value}
                                      sx={{ fontSize: 12 }}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={!!field.value}
                                      onChange={(event: any) => {
                                        field.onChange(event.target.checked)
                                      }}
                                      sx={{ color: error !== undefined ? 'red' : 'black' }}
                                    />
                                  )}
                                </Fragment>
                              )}
                              name={
                                `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                              }
                              control={props.fieldArrayProps.control}
                              defaultValue={datum[column.field]}
                            />
                          )
                          break
                        }
                        case 'images': {
                          // display first image of the image model array with status = true
                          const renderedDatum = (
                            datum[column.field] as ImageModel[] | undefined
                          )?.find((image) => image.status)
                          children = (
                            <Fragment>
                              {renderedDatum ? (
                                <TableImageHolder
                                  onClick={() => {
                                    handleImageModalOpen(
                                      Base64StringToImageBase64(
                                        ImageModelToUrl(renderedDatum, 'original'),
                                        renderedDatum.ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                                      ),
                                    )
                                  }}>
                                  <img
                                    style={{
                                      margin: 'auto',
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'contain',
                                    }}
                                    src={ImageModelToUrl(renderedDatum, 'regular')}
                                    alt={`table-img-${datumIndex + 1}`}
                                  />
                                </TableImageHolder>
                              ) : (
                                <Typography fontSize={12}>{t('No image')}</Typography>
                              )}
                            </Fragment>
                          )
                          break
                        }
                        case 'date': {
                          children = (
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                  }}>
                                  {props.uneditable ||
                                  (typeof column.uneditable === 'function'
                                    ? column.uneditable(datum, datumIndex)
                                    : column.uneditable) ? (
                                    <FormTypography
                                      type="date"
                                      dateFormat="D/MM/YYYY"
                                      value={field.value}
                                      sx={{ fontSize: 12 }}
                                    />
                                  ) : (
                                    <FormDateTimePicker
                                      type="date"
                                      onChange={field.onChange}
                                      value={field.value}
                                      sx={{ fontSize: 12, justifyContent: 'center' }}
                                      error={!!error}
                                    />
                                  )}
                                </Box>
                              )}
                              name={
                                `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                              }
                              control={props.fieldArrayProps.control}
                              defaultValue={datum[column.field]}
                            />
                          )
                          break
                        }
                        case 'fileUpload': {
                          children = (
                            <Controller
                              render={({ field, fieldState: { error } }) => (
                                <TableFileUpload
                                  value={field.value}
                                  onChange={field.onChange}
                                  disabled={props.disabled}
                                  uneditable={
                                    props.uneditable ||
                                    (typeof column.uneditable === 'function'
                                      ? column.uneditable(datum, datumIndex)
                                      : column.uneditable)
                                  }
                                  acceptFiles={column.acceptFiles}
                                />
                              )}
                              name={
                                `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                              }
                              control={props.fieldArrayProps.control}
                              defaultValue={datum[column.field]}
                            />
                          )
                          break
                        }
                        case 'displayDate': {
                          children =
                              <Typography textAlign="center" fontSize={ 12 }>
                                {
                                  datum[column.field]
                                  ? moment(datum[column.field]).format(column?.dateFormat ?? 'DD/MM/YYYY')
                                  : ''
                                }
                              </Typography>
                          break
                        }
                        case 'displaySelected': {
                          if (column.options) {
                            children = (
                              <Typography textAlign="center" fontSize={12}>
                                {t(GetValueWithKey(datum[column.field], column.options) ?? '')}
                              </Typography>
                            )
                          }
                          break
                        }
                        case 'displayImages': {
                          // display first image of the image model array with status = true
                          const renderedDatum = (
                            datum[column.field] as ImageModel[] | undefined
                          )?.find((image) => image.status)
                          children = (
                            <Fragment>
                              {renderedDatum ? (
                                <TableImageHolder
                                  onClick={() => {
                                    handleImageModalOpen(
                                      Base64StringToImageBase64(
                                        ImageModelToUrl(renderedDatum, 'original'),
                                        renderedDatum.ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                                      ),
                                    )
                                  }}>
                                  <img
                                    style={{
                                      margin: 'auto',
                                      border: '0.1px solid rgb(0 0 0 / 20%)',
                                      width: 300,
                                      height: 200,
                                      objectFit: 'contain',
                                    }}
                                    src={ImageModelToUrl(renderedDatum, 'regular')}
                                    alt={`table-img-${datumIndex + 1}`}
                                  />
                                </TableImageHolder>
                              ) : (
                                <Typography fontSize={12}>{t('No image')}</Typography>
                              )}
                            </Fragment>
                          )
                          break
                        }
                        case 'controlledCustom': {
                          if (column.controlledRender) {
                            children = (
                              <Controller
                                render={({ field, fieldState: { error } }) =>
                                  column.controlledRender!(datum, field, datumIndex, setWarningText)
                                }
                                name={
                                  `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                                }
                                control={props.fieldArrayProps.control}
                                defaultValue={datum[column.field]}
                              />
                            )
                          }
                          break
                        }
                        case 'custom': {
                          if (column.render) {
                            children = (
                              <Fragment>
                                {column.render(datum, datumIndex, setWarningText)}
                              </Fragment>
                            )
                          }
                          break
                        }
                      }
                    } else {
                      children = (
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontSize: 12,
                            whiteSpace: 'initial',
                            wordBreak: 'break-word',
                          }}>
                          {datum[column.field] ?? ''}
                        </Typography>
                      )
                    }

                    return (
                      <BodyTableCell
                        style={{
                          minWidth: column.width ?? '100px',
                          position: 'relative',
                          textAlign: 'center',
                        }}
                        key={`form-table-cell-${columnIndex}-${datumIndex}-${datum['_id']}`}>
                        {children}
                      </BodyTableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {props.additionalRow && props.additionalRow(fields)}
          </TableBody>
          <tfoot
            style={{
              // boxShadow:
              //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              border: '1px solid rgba(224, 224, 224, 1)',
              ...((props.removeAddButton || props.uneditable || props.disabled) && {
                display: 'none',
              }),
            }}>
            <tr>
              <td colSpan={1000}>
                {/* <Box
                  sx={{
                    boxShadow: 2,
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    display: props.removeAddButton || props.uneditable ? 'none' : 'inherit',
                  }}> */}
                <FormTableFooterButton
                  disabled={props.disableAddButton}
                  startIcon={
                    <FormTableAddIcon
                      id="form-table-add-icon"
                      sx={{
                        height: 40,
                        width: 40,
                      }}
                    />
                  }
                  onClick={(event: any) => {
                    appendField(event)
                  }}
                />
                {/* </Box> */}
              </td>
            </tr>
          </tfoot>
        </Table>

        {warningText && (
          <Typography
            sx={{
              color: 'red',
            }}>
            {t(warningText)}
          </Typography>
        )}
      </FormTableBox>
      <ImageModal image={modalImage} />
    </Grid>
  )
}

export default FormTable

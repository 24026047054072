import { BaseModel } from '../base.model'

export interface FormModel extends BaseModel {
  formId: string
  categoryCode: string
  name?: string
  contractNoId: number
  contractTitle?: string
  districtId: number[]
  teamId: number[]
  workOrderId?: number
  workOrderNo?: string
  parentFormId?: string // todo: not in used, please fix
  parentFormUuid?: string
  editable?: boolean

  formStatus: string
  formStatusName: string
  formStatusShortName: string
  formStatusHistories: FormStatusHistory[]

  formPermission: BaseFormPermission
}

export interface FormStatusHistory {
  id: number
  formStatusId: number
  actionName: string
  actionBy?: string
  actionAt?: Date
  actionSignatureBase64?: string
  actionByUserName?: string
  actionByUserRole?: number[]
}

export const InitFormStatusHistory: FormStatusHistory = {
  id: 0,
  formStatusId: 0,
  actionName: '',
}

export interface BaseFormPermission {
  canUpdate: boolean
  canDelete: boolean
  workflowRequired: boolean
  endOfFlow: boolean
}

export const InitBaseFormPermission: BaseFormPermission = {
  canUpdate: true,
  canDelete: false,
  workflowRequired: false,
  endOfFlow: false,
}

export const InitFormModel: FormModel = {
  formId: '',
  categoryCode: '',
  contractNoId: 0,
  districtId: [],
  teamId: [],
  formStatus: 'Draft',
  formStatusHistories: [],
  status: true,
  createdBy: '',
  createdAt: new Date(),
  formPermission: InitBaseFormPermission,
  formStatusShortName: '',
  formStatusName: 'Draft',
}

export interface FormStatus {
  id: number
  actionName: string
  actionCode: string
  signatureRequired: boolean
}

export interface DashboardFormStatus {
  date: string
  formStatus: string
  total: number
}

export interface BaseFormResponse {
  formId: string
  formStatus: string
}
export interface NotifyList {
  roles: number[]
  users: string[]
}

export interface Comment {
  id: number
  formId: string
  status: boolean
  comment: string
  formStatusHistoryId: number
  formStatusId: number
  commentType: string
  formStatus: BaseFormStatusDtoForList

  createdBy: number
  createdAt: Date
}

export interface FormHistory {
  id: number
  status: boolean
  createdBy: string
  createdAt: Date
  statusName: string
}

export interface BaseFormStatusDtoForList {
  formStatusId: number
  formStatus: string
  formStatusShortName: string
  formStatusName: string
  formStatusColor?: string
}

export enum RightsCategory {
  FORM_DUTY = 'FORM_DUTY',
  FORM_USER = 'FORM_USER',
  FORM_EIA = 'FORM_EIA',
  FORM_EI = 'FORM_EI',
  FORM_NF = 'FORM_NF',
  FORM_DN = 'FORM_DN',
  FORM_NEA = 'FORM_NEA',
  FORM_EA = 'FORM_EA',
  FORM_DEA = 'FORM_DEA',
  FORM_QS = 'FORM_QS',
  FORM_CDR = 'FORM_CDR',
  FORM_SD = 'FORM_SD',
  FORM_SCR = 'FORM_SCR',
  FORM_HWR = 'FORM_HWR',
  FORM_TWO = 'FORM_TWO',
  FORM_WOI = 'FORM_WOI',
  FORM_WOU = 'FORM_WOU',
  FORM_CR = 'FORM_CR',
  FORM_DNA = 'FORM_DNA',
  FORM_TEAM = 'FORM_TEAM'
}

export enum RightsCode {
  CREATE = 'C',
  READ = 'R',
  UPDATE = 'U',
  DELETE = 'D',
}

export interface FormCountByUser {
  formCode: string
  totalCount: number
}
;[]

export enum FormStatusEnum {
  //admin
  FORM_USER_CREATE = 'FORM_USER_CREATE',

  //M1

  // M1

  FORM_EIA_DRAFT = 'FORM_EIA_DRAFT',
  FORM_EIA_ASSIGNED = 'FORM_EIA_ASSIGNED',
  FORM_EIA_COMPLETED = 'FORM_EIA_COMPLETED',

  FORM_EI_DRAFT = 'FORM_EI_DRAFT',
  FORM_EI_REVIEW = 'FORM_EI_REVIEW',
  FORM_EI_REJECTED = 'FORM_EI_REJECTED',
  FORM_EI_APPROVED = 'FORM_EI_APPROVED',

  FORM_NF_DRAFT = 'FORM_NF_DRAFT',
  FORM_NF_REVIEW = 'FORM_NF_REVIEW',
  FORM_NF_ISSUE_REJECTED = 'FORM_NF_ISSUE_REJECTED',
  FORM_NF_RECTIFICATION = 'FORM_NF_RECTIFICATION',
  FORM_NF_REVIEW_RECTIFICATION = 'FORM_NF_REVIEW_RECTIFICATION',
  FORM_NF_RECTIFICATION_REJECTED = 'FORM_NF_RECTIFICATION_REJECTED',
  FORM_NF_APPROVED = 'FORM_NF_APPROVED',

  FORM_NF_NOT_UNDER_HYD_PURVIEW = 'FORM_NF_NOT_UNDER_HYD_PURVIEW',
  FORM_NF_NOT_UNDER_HYD_PURVIEW_FOLLOWED_UP = 'FORM_NF_NOT_UNDER_HYD_PURVIEW_FOLLOWED_UP',

  FORM_NF_OUTSIDE_M_M = 'FORM_NF_OUTSIDE_M_M',
  FORM_NF_OUTSIDE_M_M_FOLLOWED_UP = 'FORM_NF_OUTSIDE_M_M_FOLLOWED_UP',

  FORM_DN_DRAFT = 'FORM_DN_DRAFT',
  FORM_DN_REVIEW = 'FORM_DN_REVIEW',
  FORM_DN_ISSUE_REJECTED = 'FORM_DN_ISSUE_REJECTED',
  FORM_DN_ISSUED = 'FORM_DN_ISSUED',
  FORM_DN_APPEALED = 'FORM_DN_APPEALED',
  FORM_DN_ENDORSEMENT_REJECTED = 'FORM_DN_ENDORSEMENT_REJECTED',
  FORM_DN_APPEAL_ENDORSED = 'FORM_DN_APPEAL_ENDORSED',
  FORM_DN_APPEAL_APPROVAL_REJECTED = 'FORM_DN_APPEAL_APPROVAL_REJECTED',
  FORM_DN_APPEAL_SUCCESS = 'FORM_DN_APPEAL_SUCCESS',
  FORM_DN_CANCEL = 'FORM_DN_CANCEL',

  //M2

  FORM_EA_DRAFT = 'FORM_EA_DRAFT',
  FORM_EA_AUDIT_UNAVAILABLE = 'FORM_EA_AUDIT_UNAVAILABLE',
  FORM_EA_AGREE_PRELIM_RESULT = 'FORM_EA_AGREE_PRELIM_RESULT',
  FORM_EA_REVIEW_PRELIM_RESULT = 'FORM_EA_REVIEW_PRELIM_RESULT',
  FORM_EA_ENDORSE_PRELIM_RESULT = 'FORM_EA_ENDORSE_PRELIM_RESULT',
  FORM_EA_PRELIM_RESULT_REJECTED = 'FORM_EA_PRELIM_RESULT_REJECTED',
  FORM_EA_AUDIT_RESULT_ISSUED = 'FORM_EA_AUDIT_RESULT_ISSUED',
  FORM_EA_AUDIT_RESULT_UPDATED = 'FORM_EA_AUDIT_RESULT_UPDATED',
  FORM_EA_REVIEW_AUDIT_APPEAL = 'FORM_EA_REVIEW_AUDIT_APPEAL',
  FORM_EA_ENDORSE_AUDIT_APPEAL = 'FORM_EA_ENDORSE_AUDIT_APPEAL',
  FORM_EA_AUDIT_APPEAL_NOT_ENDORSED = 'FORM_EA_AUDIT_APPEAL_NOT_ENDORSED',
  FORM_EA_AUDIT_RESULT_CONFIRMED = 'FORM_EA_AUDIT_RESULT_CONFIRMED',

  FORM_NEA_DRAFT = 'FORM_NEA_DRAFT',
  FORM_NEA_SAMPLE_SELECTED = 'FORM_NEA_SAMPLE_SELECTED',
  FORM_NEA_SAMPLE_SELECTED_RESULT_REJECTED = 'FORM_NEA_SAMPLE_SELECTED_RESULT_REJECTED',
  FORM_NEA_REVIEW = 'FORM_NEA_REVIEW',
  FORM_NEA_ENDORSEMENT_REJECTED = 'FORM_NEA_ENDORSEMENT_REJECTED',
  FORM_NEA_ENDORSED = 'FORM_NEA_ENDORSED',
  FORM_NEA_COMPLETED = 'FORM_NEA_COMPLETED',

  FORM_DEA_DRAFT = 'FORM_DEA_DRAFT',
  FORM_DEA_ISSUED = 'FORM_DEA_ISSUED',
  FORM_DEA_APPEAL_SUCCESS = 'FORM_DEA_APPEAL_SUCCESS',
  FORM_DEA_APPEAL_REJECTED = 'FORM_DEA_APPEAL_REJECTED',

  FORM_CDR_DRAFT = 'FORM_CDR_DRAFT',
  FORM_CDR_SUBMITTED = 'FORM_CDR_SUBMITTED',
  FORM_CDR_ACKNOWLEDGED = 'FORM_CDR_ACKNOWLEDGED',

  FORM_SCR_DRAFT = 'FORM_SCR_DRAFT',
  FORM_SCR_REVIEW = 'FORM_SCR_REVIEW',
  FORM_SCR_REJECTED = 'FORM_SCR_REJECTED',
  FORM_SCR_APPROVED = 'FORM_SCR_APPROVED',
  FORM_SCR_COUNTERSIGNED = 'FORM_SCR_COUNTERSIGNED',

  FORM_SD_DRAFT = 'FORM_SD_DRAFT',
  FORM_SD_REVIEW = 'FORM_SD_REVIEW',
  FORM_SD_REJECTED = 'FORM_SD_REJECTED',
  FORM_SD_CONTRACTOR_REVIEW = 'FORM_SD_CONTRACTOR_REVIEW',
  FORM_SD_CONTRACTOR_REJECTED = 'FORM_SD_CONTRACTOR_REJECTED',
  FORM_SD_APPROVED = 'FORM_SD_APPROVED',
  FORM_SD_COUNTERSIGNED = 'FORM_SD_COUNTERSIGNED',

  FORM_WORK_ORDER_APPROVED = 'FORM_WORK_ORDER_APPROVED',

  FORM_QS_DRAFT = 'FORM_QS_DRAFT',
  FORM_QS_REJECTED = 'FORM_QS_REJECTED',
  FORM_QS_APPROVED = 'FORM_QS_APPROVED',

  //M4
  // M4
  FORM_HWR_DRAFT = 'FORM_HWR_DRAFT',
  FORM_HWR_REVIEW = 'FORM_HWR_REVIEW',
  FORM_HWR_APPROVED = 'FORM_HWR_APPROVED',
  FORM_HWR_REJECTED = 'FORM_HWR_REJECTED',
  FORM_HWR_ENDORSEMENT = 'FORM_HWR_ENDORSEMENT',
  FORM_HWR_NOT_ENDORSED = 'FORM_HWR_NOT_ENDORSED',
  FORM_HWR_COUNTERSIGNED = 'FORM_HWR_COUNTERSIGNED',

  // M5
  FORM_WOI_DRAFT = 'FORM_WOI_DRAFT',
  FORM_WOI_CLOSED = 'FORM_WOI_CLOSED',

  FORM_TWO_DRAFT = 'FORM_TWO_DRAFT',
  FORM_TWO_REVIEW = 'FORM_TWO_REVIEW',
  FORM_TWO_ISSUE_CONFIRMED = 'FORM_TWO_ISSUE_CONFIRMED',
  FORM_TWO_REJECTED = 'FORM_TWO_REJECTED',
  FORM_TWO_COMPLETED = 'FORM_TWO_COMPLETED',

  FORM_WOU_DRAFT = 'FORM_WOU_DRAFT',
  FORM_WOU_REVIEW = 'FORM_WOU_REVIEW',
  FORM_WOU_REJECTED = 'FORM_WOU_REJECTED',
  FORM_WOU_APPROVED = 'FORM_WOU_APPROVED',

  // M6
  FORM_CR_DRAFT = 'FORM_CR_DRAFT',
  FORM_CR_WORKS_COMPLETION_REVIEW = 'FORM_CR_WORKS_COMPLETION_REVIEW',
  FORM_CR_WORKS_COMPLETION_ENDORSEMENT = 'FORM_CR_WORKS_COMPLETION_ENDORSEMENT',
  FORM_CR_WORKS_COMPLETION_ENDORSEMENT_REJECTED = 'FORM_CR_WORKS_COMPLETION_ENDORSEMENT_REJECTED',
  FORM_CR_WORKS_COMPLETION_CERTIFIED = 'FORM_CR_WORKS_COMPLETION_CERTIFIED',
  FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS = 'FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS',
  FORM_CR_WORKS_INCOMPLETE = 'FORM_CR_WORKS_INCOMPLETE',
  FORM_CR_OUTSTANDING_WORKS_REVIEW = 'FORM_CR_OUTSTANDING_WORKS_REVIEW',
  FORM_CR_OUTSTANDING_WORKS_INCOMPLETE = 'FORM_CR_OUTSTANDING_WORKS_INCOMPLETE',
  FORM_CR_OUTSTANDING_WORKS_COMPLETED = 'FORM_CR_OUTSTANDING_WORKS_COMPLETED',

  //M7
  FORM_DNA_DRAFT = 'FORM_DNA_DRAFT',
  FORM_DNA_PENDING = 'FORM_DNA_PENDING',
  FORM_DNA_IOW_REVIEW = 'FORM_DNA_IOW_REVIEW',
  FORM_DNA_ECTO_REVIEW = 'FORM_DNA_ECTO_REVIEW',
  FORM_DNA_REVIEW_REJECTED = 'FORM_DNA_REVIEW_REJECTED',
  FORM_DNA_ISSUED = 'FORM_DNA_ISSUED',
  FORM_DNA_IOW_2ND_REVIEW = 'FORM_DNA_IOW_2ND_REVIEW',
  FORM_DNA_ECTO_2ND_REVIEW = 'FORM_DNA_ECTO_2ND_REVIEW',
  FORM_DNA_COMPLETED = 'FORM_DNA_COMPLETED',
}

import { ForwardedRef, forwardRef, MutableRefObject } from 'react'
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import uuid                                           from 'react-uuid'
import FormContainer                                  from '@components/form/container'
import FormController                                 from '@components/form/controller'
import FormDateTimePicker                             from '@components/form/dateTimePicker'
import FormField                                      from '@components/form/field'
import FormFreeText                                   from '@components/form/freeText'
import FormImageUpload                                from '@components/form/imageUpload'
import FormMediumMap                                  from '@components/form/mediumMap'
import FormSelect                                     from '@components/form/select'
import FormTypography                                 from '@components/form/typography'
import { KeyValPair }                                 from '@models/common'
import { GlobalStateProps }                           from '@providers/globalStore'
import { ContractNo }                                 from '@services/model/contract.model'
import { FormDeaModel }                               from '@services/model/form/form.DEA.model'
import { FormEaModel }                                from '@services/model/form/form.EA.model'
import { FormStatusEnum }                             from '@services/model/form/form.model'
import { ImageModel }                                 from '@services/model/image.model'

const UUID = uuid()

function PartAInner({
                        control,
                        setValue,
                        getValues,
                        formStatus,
                        globalState,
                        parentForm,
                        getOptionsByKey,
                        showInspectionSection,
                        isMounted,
                        uneditable,
                        contractInfo
                    }: {
    control: Control<FormDeaModel>
    setValue: UseFormSetValue<FormDeaModel>
    getValues: UseFormGetValues<FormDeaModel>
    formStatus: string
    globalState: GlobalStateProps
    parentForm?: FormEaModel
    getOptionsByKey: (key: string) => KeyValPair[]
    showInspectionSection: boolean
    isMounted: MutableRefObject<boolean>
    uneditable: boolean
    contractInfo?: ContractNo
}, ref: ForwardedRef<HTMLUListElement>) {
    const contractNoList =
              globalState.contractList?.map(x => {
                  return {
                      key  : x.id,
                      value: x.contractNo
                  }
              }) || []

  return  (
    <>
   <FormContainer fkey={`${UUID}-dea-a`}>
          <FormField fieldName="Contract No.">
            <FormTypography
              type="select"
              options={contractNoList}
              value={parentForm?.baseForm.contractNoId}
            />
          </FormField>
          <FormField fieldName="Type of M&M Works">
            <FormTypography
              type="select"
              options={getOptionsByKey('Form EA - Category')}
              value={parentForm?.formNea.typeOfMmWorks}
            />
          </FormField>
          <FormField fieldName="Defects Identified">
            <FormController
              controllerProps={{
                name: 'defectsIdentified',
                control,
              }}>
              <FormSelect
                options={
                  globalState.generalOptionGroupList
                    ?.find((x) => x.name === 'Form EA - Category')
                    ?.generalOptions?.find((x) => x.id === parentForm?.formNea.typeOfMmWorks)
                    ?.defectOptions?.map((x) => {
                      return {
                        key: x.id,
                        value: `${x.defectCode} - ${x.description}`,
                      }
                    }) || []
                }
                uneditable={uneditable}
              />
            </FormController>
          </FormField>
          <FormField fieldName="EA No.">
            <FormTypography value={parentForm?.reportNo} />
          </FormField>
          <FormField fieldName="EA Defect No.">
            <FormTypography value={getValues('reportNo')} />
          </FormField>
          <FormField fieldName="Images">
            <FormController
              controllerProps={{
                name: 'images',
                control,
              }}>
              <FormImageUpload
                uneditable={uneditable}
                multiple
                additionalChangeAction={(value: ImageModel[]) => {
                  if (value.length > 0 && value[value.length - 1].location) {
                    setValue('location', value[value.length - 1].location)
                    setValue('road', value[value.length - 1].location?.locationAddress)
                  }
                }}
              />
            </FormController>
          </FormField>

          <FormField fieldName="Location">
            <FormController
              controllerProps={{
                name: 'location',
                control,
              }}>
              <FormMediumMap
                additionalChangeAction={async (address) => {
                  setValue('road', address)
                }}
                isMounted={isMounted.current}
                uneditable={uneditable}
                preventBrowserLocation={parentForm?.baseForm.formStatus === FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED}
                ref={ref}
              />
            </FormController>
          </FormField>
          <FormField fieldName="Road">
            <FormController
              controllerProps={{
                name: 'road',
                control,
              }}>
              <FormTypography />
            </FormController>
          </FormField>

          {
              showInspectionSection &&
              <FormField fieldName="Inspection Section">
                  <FormController controllerProps={ {
                          name: 'inspectionSection',
                          control
                      } }>
                      <FormFreeText textfieldProps={{ type: 'number', inputProps: { min: 0 } }} />
                  </FormController>
              </FormField>
          }

          <FormField fieldName="Date & Time of Checking">
            <FormController
              controllerProps={{
                name: 'checkingDate',
                control,
              }}>
              <FormDateTimePicker uneditable={uneditable} />
            </FormController>
          </FormField>
          <FormField fieldName="Location Remarks">
            <FormController
              controllerProps={{
                name: 'locationRemarks',
                control,
              }}>
              <FormFreeText
                textfieldProps={{
                  fullWidth: false,
                  multiline: true,
                  rows: 10,
                }}
                sx={{
                  width: '70%',
                }}
                uneditable={uneditable}
              />
            </FormController>
          </FormField>
        </FormContainer>
    </>
  )
}

export const PartA = forwardRef( PartAInner )
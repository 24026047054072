import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control, useWatch } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import GetValueWithKey from '@utils/getValueWithKey'
import FormTypography from '@components/form/typography'
import FormCompactField from '@components/form/compactField'
import { useTranslation } from 'react-i18next'
import FormFreeText from '@components/form/freeText'
import FormSelect from '@components/form/select'

export default ({
  control,
  getValues,
  options,
  globalState,
}: {
  control: Control<FormWoiModel>
  getValues: any
  options?: WoiGeneralOptions
  globalState?: any
}) => {
  const { t } = useTranslation()

  const typeOfWorks = useWatch({ name: 'typeOfWorks', control})

  const WOIPartAFundingSourceDetail = (source: string) => {
    switch (source) {
      case 'B-Vote': {
        return {
          fieldName: t('B-Vote Item'),
          component: (
            <FormController
              controllerProps={{
                name: 'bVoteItemNumber',
                control,
              }}>
              <FormTypography />
            </FormController>
          ),
          removeColon: false,
        }
      }
      case 'Allocation Warrant': {
        return {
          fieldName: t('Allocation Warrant'),
          component: (
            <FormController
              controllerProps={{
                name: 'allocationWarrantItemNumber',
                control,
              }}>
              <FormTypography />
            </FormController>
          ),
          removeColon: false,
        }
      }
      case 'Others': {
        return {
          fieldName: t('Others'),
          component: (
            <FormController
              controllerProps={{
                name: 'othersVoteItemNumber',
                control,
              }}>
              <FormTypography />
            </FormController>
          ),
          removeColon: false,
        }
      }
      default: {
        return {
          fieldName: '',
          component: <></>,
          removeColon: true,
        }
      }
    }
  }

  return (
    <FormContainer fkey={`woi-a`}>
      <FormCompactField
        childrens={[
          {
            fieldName: 'Works Order No.',
            component: (
              <FormController
                controllerProps={{
                  name: 'mainsWorkOrderNo',
                  control,
                }}>
                <FormTypography />
              </FormController>
            ),
          },
          {
            fieldName: 'Location',
            component: (
              <FormController
                controllerProps={{
                  name: 'workLocation',
                  control,
                }}>
                <FormTypography />
              </FormController>
            ),
          },
        ]}
      />
      <FormField fieldName="Description of Work">
        <FormController
          controllerProps={{
            name: 'workDesc',
            control,
          }}>
          <FormTypography
            sx={{
              maxHeight: '182px',
              // height: '182px',
              overflowY: 'auto',
            }}
          />
        </FormController>
      </FormField>
      <FormCompactField
        childrens={[
          {
            fieldName: 'Funding Source',
            component: (
              <FormController
                controllerProps={{
                  name: 'voteType',
                  control,
                }}>
                <FormTypography
                  type="select"
                  options={
                    options?.fundingSource?.map((x) => ({
                      key: x.id,
                      value: x.name,
                    })) ?? []
                  }
                />
              </FormController>
            ),
          },
          WOIPartAFundingSourceDetail(
            GetValueWithKey(
              getValues('voteType'),
              options?.fundingSource?.map((x) => ({
                key: x.id,
                value: x.name,
              })) ?? [],
            ) as string,
          ),
        ]}
      />
      <FormCompactField
        childrens={[
          {
            fieldName: 'Works Estimate',
            component: (
              <FormController
                controllerProps={{
                  name: 'origEstCost',
                  control,
                }}>
                <FormTypography />
              </FormController>
            ),
          },
          {
            fieldName: 'Latest Works Estimate',
            component: (
              <FormController
                controllerProps={{
                  name: 'revEstCost',
                  control,
                }}>
                <FormTypography />
              </FormController>
            ),
          },
        ]}
      />
      <FormCompactField
        childrens={[
          {
            fieldName: 'Issue Date',
            component: (
              <FormController
                controllerProps={{
                  name: 'issueDate',
                  control,
                }}>
                <FormTypography type="date" />
              </FormController>
            ),
          },
          {
            fieldName: 'Days for Completion',
            component: (
              <FormController
                controllerProps={{
                  name: 'daysForCompletion',
                  control,
                }}>
                <FormTypography />
              </FormController>
            ),
          },
        ]}
      />
      <FormCompactField
        childrens={[
          {
            fieldName: 'Original Completion Date',
            component: (
              <FormController
                controllerProps={{
                  name: 'originalCompletionDate',
                  control,
                }}>
                <FormTypography type="date" />
              </FormController>
            ),
          },
          {
            fieldName: 'Extended Completion Date',
            component: (
              <FormController
                controllerProps={{
                  name: 'extendedCompletionDate',
                  control,
                }}>
                <FormTypography type="date" />
              </FormController>
            ),
          },
        ]}
      />
      <FormCompactField
        childrens={[
          {
            fieldName: 'Responsible E/CTO',
            component: (
              <FormTypography
                value={
                  globalState?.userMetaEmailList?.find(
                    (x) => x.key === getValues('responsibileEctoEmail'),
                  )?.value ?? getValues('responsibileEctoEmail')
                }
              />
            ),
          },
          {
            fieldName: 'Responsible Inspectorate',
            component: (
              <FormTypography
                value={
                  globalState?.userMetaEmailList?.find(
                    (x) => x.key === getValues('responsibileInspectorateEmail'),
                  )?.value ?? getValues('responsibileInspectorateEmail')
                }
              />
            ),
          },
        ]}
      />
      <FormCompactField
        childrens={[
          {
            fieldName: 'Nature of Works',
            component: (
              <FormController
                controllerProps={{
                  name: 'typeOfWorks',
                  control,
                }}>
                <FormTypography type="binary" binaryOptions={{ yes: t('M&M'), no: t('Non-M&M') }} />
              </FormController>
            ),
          },
          {
            fieldName: 'Covering Works Order',
            component: (
              <FormController
                controllerProps={{
                  name: 'coveringWorkOrder',
                  control,
                }}>
                <FormTypography type="binary" />
              </FormController>
            ),
          },
        ]}
      />
      <FormCompactField
        childrens={[
          {
            fieldName: 'Urgency of the Works',
            component: (
              <FormController
                controllerProps={{
                  name: 'urgencyOfWorks',
                  control,
                }}>
                <FormTypography
                  type="select"
                  options={
                    options?.urgencyLevel?.map((x) => ({
                      key: x.id,
                      value: x.name,
                    })) ?? []
                  }
                />
              </FormController>
            ),
          },
          {
            fieldName: 'Justification on Urgency',
            component: (
              <FormController
                controllerProps={{
                  name: 'justificationOnUrgency',
                  control,
                }}>
                <FormTypography />
              </FormController>
            ),
          },
        ]}
      />
      <FormField fieldName="Emergency Serial/SSW/XP No.">
        <FormController
          controllerProps={{
            name: 'emergencySerialNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      {typeOfWorks && <FormField fieldName="Category of Works">
        <FormController
          controllerProps={{
            name: 'workDescRemark',
            control,
          }}>
          <FormSelect
            options={options?.typeOfMmWorks?.map((x) => ({ key: x.id, value: x.name })) ?? []}
            uneditable={true}
          />
        </FormController>
      </FormField>}
    </FormContainer>
  )
}

import { KeyValPair }                                                      from '@models/common'
import { BaseFilterOptions, BaseListResponse }                             from '../base.model'
import { ImageModel }                                                      from '../image.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'
import { LocationModel }                                                   from '../location.model'
import { FileModel }                                                       from '../file.model'
import { GeneralOptionGroup }                                              from '../general_option.model'
import { FormDNModel }                                                     from './form.DN.model'

export type FormDNAModel = {
	baseForm: FormModel

	// a
	formId?: number
	dnaNo?: number
	nonComplianceId?: number
	isNcPeriodConfirmed?: boolean

	dateOfCheck?: Date
	ncStartDate?: Date
	ncEndDate?: Date
	typeOfNcEndDateId?: number

	backDateReason?: string

	location?: LocationModel
	roadName?: string
	locationMapBase64?: string
	locationRemark?: string

	supportingPhotos?: Array<ImageModel>
	remarks?: string

	isReportResubmitted?: boolean
	dateOfResubmitCheck?: Date

	emergencySerialNo?: string

	formDn?: FormDNModel[]
	formDnView?: Array<FormDNModel> // READONLY, just to show the expected generated DNs
	invalidFormDn?: FormDNModel[]
	appeals?: DnaAppeal[]
	dnAppealResults?: DnaDnAppeal[]

	signatureRequired: boolean
	signatureBase64?: string
	notifyUserList?: string[]
	submissionComment?: string

}

export interface DnaAppeal {
	id: number
	dnaId: number
	appealDate?: Date
	reasonForAppeal?: string
	status: boolean
	images?: ImageModel[]
	documents?: FileModel[]
}

export interface DnaDnAppeal {
	id: number
	dnaId: number
	dnid: number
	dnaAppealId?: string
	dnaRectificationId?: number
	markAppeal?: boolean
	canAppeal?: boolean
	appealResult?: string
}

export interface DNAListResponse extends BaseListResponse {
	list: FormDNAListModel[]
}

export interface DNAFilterOptions extends BaseFilterOptions {
	workOrderIdList: number[]
	parentFormIdList: KeyValPair[]
}

export const InitDNAForm: FormDNAModel = {
	signatureRequired: false,
	baseForm         : {
		...InitFormModel,
		formStatus         : FormStatusEnum.FORM_DNA_DRAFT,
		formStatusName     : 'Draft',
		formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }]
	}
}

export interface FormDNAListModel {
	formId: number
	color: string
	formStatus: string
	formStatusShortName: string
	formStatusColor: string

	reportNo: string
	parentReportNo?: string
	contractNoId?: number
	teamId?: number[]
	districtId?: number[]
	nonComplianceId?: number
	inspectionDate?: Date
	dueDate?: Date

	submittedBy: string
	approvedBy: string
	updatedAt: Date
}

export interface DNAGeneralOptions {
	ncEndDateType?: GeneralOptionGroup[]
	typeOfNonCompliance?: {
		amountOfDeduction?: number
		dnRefNo?: string
		id: number
		refundDateType?: number
		reoccurenceInHour?: number
		refundable?: boolean
		refundAmount?: number
		refundTimelimitInHour?: number
		type?: string
		typeOfNonCompliance?: string
	}[]
}
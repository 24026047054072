import { FormScrpModel, InitScrpForm } from '@services/model/form/form.SCRP.model'
import { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import FormController from '@components/form/controller'
import FormSelect from '@components/form/select'
import uuid from 'react-uuid'
import { FormContext } from '@providers/formStateProvider'
import { FormScrModel } from '@services/model/form/form.SCR.model'
import { NavigateTo } from '@utils/navigate'
import FormMediumMap from '@components/form/mediumMap'
import FormImageUpload from '@components/form/imageUpload'
import useFormSwitcher from '@hooks/useFormSwitcher'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { useSnackbar } from 'notistack'
import { ImageModel } from '@services/model/image.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import { canvasExport } from '@utils/canvasExport'

const UUID = uuid()
const SCRPForm = () => {
  const { state, dispatch } = useContext(FormContext)
  const { getOptionsByKey } = useGeneralOptions()

  // extracting id from params
  const { id } = useParams<string>()

  // extracting index from query
  const query = useQuery()
  const num = QueryStepParser(query.get('num'))

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [parentForm, setParentForm] = useState<FormScrModel | undefined>()

  const mapRef = useRef<HTMLUListElement>(null)
  const saveTargetMap = async () => {
    let targetBase64 = await canvasExport(mapRef)
    console.log("setTargetMap")
    setValue('locationMapBase64', targetBase64)
  }

  const editable =
    (parentForm?.baseForm.formStatus || FormStatusEnum.FORM_SCR_DRAFT) ===
      FormStatusEnum.FORM_SCR_DRAFT ||
    (parentForm?.baseForm.formStatus || FormStatusEnum.FORM_SCR_REJECTED) ===
      FormStatusEnum.FORM_SCR_REJECTED

  const validationSchema = yup
    .object()
    .shape({
      photoCategoryId: yup
        .number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? undefined : value,
        )
        .required(t('Category is required')),
      location: yup.object().required(t('Location is required')),
    })
    .required()

  const { control, setValue, getValues, trigger, reset } = useForm<FormScrpModel>({
    defaultValues: { ...InitScrpForm },
    resolver: yupResolver(validationSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  useEffect(() => {
    if (state.form && state.formType === 'SCR') {
      if (num !== undefined) {
        // old form
        const parentForm = state.form as FormScrModel
        if (parentForm.scrPhotos && parentForm.scrPhotos[num]) {
          const thisForm = parentForm.scrPhotos[num]

          reset(thisForm)
        }
      } else {
        // new form
        reset({ ...InitScrpForm })
      }

      // set parent form into this state
      setParentForm(state.form)
    } else {
      // redirect to SCR form
      NavigateTo(navigate, '/site-check-record/:id', { id }, { step: 4 })
    }

    // pop off form stack
    return () => dispatch({ type: 'pop', targetFormType: 'SCR' })
  }, [id, num, dispatch])

  //#region form submission
  const onSubmit = async (data: FormScrpModel) => {
    if (parentForm) {
      let _parentForm: FormScrModel = { ...parentForm }
      if (num !== undefined) {
        // old form
        _parentForm = {
          ...parentForm,
          scrPhotos: parentForm.scrPhotos?.map((photo: FormScrpModel, index: number) =>
            index === num ? data : photo,
          ),
        }
      } else {
        // new form
        _parentForm = {
          ...parentForm,
          scrPhotos: [...(parentForm.scrPhotos ?? []), data],
        }
      }
      // insert this form to parent from
      dispatch({ type: 'push', form: _parentForm, formType: 'SCR' })
    } else {
      alert('something went wrong')
      dispatch({ type: 'clear' })
    }
    NavigateTo(navigate, '/site-check-record/:id', { id }, { step: 4 })
  }
  //#endregion

  //#region form parts components

  //#region part a
  const PartA = (): FormPartComponents => {
    return {
      partTitle: t('General Information'),
      component: (
        <FormContainer fkey={`${UUID}-sdp-a`}>
          <FormField fieldName="Site Check Record No.">
            <Typography>{getValues('scrNo')}</Typography>
          </FormField>

          <FormField fieldName="Site Check Record Photo No.">
            <Typography>{getValues('scrPhotoNo')}</Typography>
          </FormField>

          <FormField fieldName="Category">
            <FormController
              controllerProps={{
                name: 'photoCategoryId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={getOptionsByKey('Form SD - Site Photo Category')}
                uneditable={!editable}
              />
            </FormController>
          </FormField>

          <FormField fieldName="Image">
            <FormController
              controllerProps={{
                name: 'images',
                control: control,
              }}>
              <FormImageUpload
                additionalChangeAction={async (value: ImageModel[]) => {
                  if (value.length > 0 && value[value.length-1].location) {
                    setValue('location', value[value.length-1].location)
                    setValue('locationMapBase64', await canvasExport(mapRef))
                  }
                }}
                uneditable={!editable}
                multiple
              />
            </FormController>
          </FormField>

          <FormField fieldName="Location">
            <FormController
              controllerProps={{
                name: 'location',
                control: control,
              }}>
              <FormMediumMap 
                uneditable={!editable} 
                additionalChangeAction={async () => {
                  setValue('locationMapBase64', await canvasExport(mapRef))
                }}  
                ref={mapRef}
              />
            </FormController>
          </FormField>
        </FormContainer>
      ),
      onNext: () => saveTargetMap()
    }
  }
  //#endregion

  //#endregion

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
  ) => {
    if (!(await trigger())) return

    if (parentForm) {
      let _parentForm: FormScrModel = { ...parentForm }
      if (num !== undefined) {
        // old form
        _parentForm = {
          ...parentForm,
          scrPhotos: parentForm.scrPhotos?.map((photo: FormScrpModel, index: number) =>
            index === num ? getValues() : photo,
          ),
        }
      } else {
        // new form
        _parentForm = {
          ...parentForm,
          scrPhotos: [...(parentForm.scrPhotos ?? []), getValues()],
        }
      }
      // insert this form to parent from
      dispatch({ type: 'push', form: _parentForm, formType: 'SCR' })
    } else {
      alert('something went wrong')
      dispatch({ type: 'clear' })
    }
    NavigateTo(navigate, '/site-check-record/:id', { id }, { step: 4 })
  }

  const handleOnSave = async () => {
    // TODO: on save
  }

  const handleOnDelete = async () => {
    // TODO: on delete
  }


  const [FormSwitcher] = useFormSwitcher({
    title: t('SITE CHECK RECORD PHOTO'),
    components: [PartA()],
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    disableComment: true,
    navigateBack: () => {
      if (parentForm) {
        let _parentForm: FormScrModel = { ...parentForm }
        dispatch({ type: 'push', form: _parentForm, formType: 'SCR' })
        NavigateTo(navigate, '/site-check-record/:formId', { id }, { step: 4 })
      } else {
        dispatch({ type: 'clear' })
        NavigateTo(navigate, '/site-check-record/all-record')
        // NavigateTo(navigate, '/notification-form-of-defect/all-record')
      }
    },
    ...(parentForm && {
      onSubFormComplete: async () => {
        if (!(await trigger())) return
        await saveTargetMap()
        if (parentForm) {
          let _parentForm: FormScrModel = { ...parentForm }
          if (num !== undefined) {
            // old form
            _parentForm = {
              ...parentForm,
              scrPhotos: parentForm.scrPhotos?.map((photo: FormScrpModel, index: number) =>
                index === num ? getValues() : photo,
              ),
            }
          } else {
            // new form
            _parentForm = {
              ...parentForm,
              scrPhotos: [...(parentForm.scrPhotos ?? []), getValues()],
            }
          }
          // insert this form to parent from
          dispatch({ type: 'push', form: _parentForm, formType: 'SCR' })
        } else {
          alert('something went wrong')
          dispatch({ type: 'clear' })
        }

        NavigateTo(navigate, '/site-check-record/:formId', { id }, { step: 4 })
      },
    }),
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}

export default SCRPForm

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { Control, useWatch } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormDNAModel } from '@services/model/form/form.DNA.model'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import FormTypography from '@components/form/typography'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormBinaryRadio from '@components/form/binaryRadio'
import { FormStatusEnum } from '@services/model/form/form.model'
import moment from 'moment'

export default ({
  control,
  options,
  formStatus,
  getValues,
}: {
  control: Control<FormDNAModel>
  options: any
  formStatus: string
  getValues: any
}) => {
  const UUID = uuid()
  const uneditable = formStatus !== FormStatusEnum.FORM_DNA_IOW_2ND_REVIEW
  const nonComplianceId = useWatch({ control, name: 'nonComplianceId' })
  const selectedDnType = options?.typeOfNonCompliance?.find((x) => x.id === nonComplianceId)
  const isReportResubmitted = useWatch({ name: 'isReportResubmitted', control })
  const dateOfResubmitCheck = useWatch({ control, name: 'dateOfResubmitCheck' })
  const issuedAt = getValues('formDn')?.[0].issuedAt
  const ncStartDate = getValues('ncStartDate')

  const RefundAmountByType = (start, resubmit) => {
      if (resubmit && start) {
        const duration = moment(resubmit).diff(moment(start), 'hours')
        if (duration < selectedDnType?.refundTimelimitInHour) {
          console.info(`duration: ${duration}`)
          console.info(
            `Within refund time limit - ${duration} < ${
              selectedDnType?.refundTimelimitInHour
            }: Refund amount = ${selectedDnType?.refundable ? selectedDnType?.refundAmount : 0}`,
          )

          if (getValues('formDn')?.[0].actualRefundAmount) {
            return (
              <>
                <FormField fieldName="Actual Refund Amount">
                  <FormTypography
                    type="price"
                    value={getValues('formDn')?.[0].actualRefundAmount ?? 0}
                  />
                </FormField>
              </>
            )
          }

          return (
            <>
              <FormField fieldName="Expected Refund Amount">
                <FormTypography
                  type="price"
                  value={selectedDnType?.refundable ? selectedDnType?.refundAmount : 0}
                />
              </FormField>
              <FormField fieldName="Actual Refund Amount">
                <FormTypography
                  type="price"
                  value={getValues('formDn')?.[0].actualRefundAmount ?? 0}
                />
              </FormField>
            </>
          )
        }
      }
      return (
        <FormField fieldName="Refund Amount">
          <FormTypography type="price" value={0} />
        </FormField>
      )
  }

  const ReportResubmittedFollowing = () => {
    if (!isReportResubmitted) {
      return (
        <FormField fieldName="Refund Amount">
          <FormTypography type="price" value={0} />
        </FormField>
      )
    }

    switch(selectedDnType?.refundDateType){
      case 1:
        return RefundAmountByType(ncStartDate, dateOfResubmitCheck)
      case 2:
        return RefundAmountByType(issuedAt, dateOfResubmitCheck)
    }
  
    return <></>
  }

  return (
    <FormContainer fkey={`${UUID}-dna-b`}>
      <FormField fieldName="Type of Non-Compliance">
        <FormController
          controllerProps={{
            name: 'nonComplianceId',
            control,
            rules: { required: true },
          }}>
          <FormSelect
            options={
              options?.typeOfNonCompliance?.map(({ id, dnRefNo, typeOfNonCompliance }) => {
                return { key: id, value: dnRefNo + ' - ' + typeOfNonCompliance }
              }) ?? []
            }
            uneditable={true}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Refund Period">
        <FormTypography value={`${selectedDnType?.refundTimelimitInHour ?? '0'} Hours`} />
      </FormField>

      <FormField fieldName="Start of NC Period">
        <FormController
          controllerProps={{
            name: 'ncStartDate',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker type="datetime" uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Report Resubmitted">
        <FormController
          controllerProps={{
            name: 'isReportResubmitted',
            control,
            rules: { required: true },
          }}>
          <FormBinaryRadio uneditable={uneditable} />
        </FormController>
      </FormField>

      {isReportResubmitted && <FormField fieldName="Date/ Time of Resubmission">
        <FormController
          controllerProps={{
            name: 'dateOfResubmitCheck',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker
            type="datetime"
            uneditable={uneditable}
            minDate={moment(selectedDnType?.refundDateType === 2 ? issuedAt : ncStartDate).toDate()}
          />
        </FormController>
      </FormField>}

      <ReportResubmittedFollowing />
    </FormContainer>
  )
}

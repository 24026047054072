import { KeyValPair } from '@models/common'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import { ChangeEvent, Fragment } from 'react'

interface formRadioProps {
  value?: number
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  labels: KeyValPair[]

  disabled?: boolean
  uneditable?: boolean
  additionalChangeAction?: (value: number) => void
}

/**
 * form radio group with custom labels, which should be type `string[]`
 * @param props formRadioProps
 * @example
 * ```
 * <FormField fieldName="Type of Works" index={6}>
 *     <FormController
 *         controllerProps={{
 *             name: 'typeOfWorks',
 *             control: control,
 *         }}>
 *             <FormRadio labels={masterData.typeOfWorks}/>
 *     </FormController>
 * </FormField>
 * ```
 */
const FormRadio = (props: formRadioProps) => {
  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any) => {
    props.onChange!(parseInt(event.target.value))
  }

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{GetValueWithKey(props.value, props.labels)}</Typography>
      ) : (
        <RadioGroup
          value={props.value ?? -1}
          onChange={(event: ChangeEvent<HTMLInputElement>, value: string): void => {
            props.onChange!(parseInt(value) ?? undefined)
            if (props.additionalChangeAction) {
              props.additionalChangeAction(parseInt(value))
            }
          }}
          onBlur={props.onBlur}>
          <FormControlLabel key={`form-radio-undefined`} label="" value={-1} control={<></>} />
          {props.labels.map((label, index) => (
            <FormControlLabel
              label={label.value}
              value={label.key}
              disabled={(label.status !== undefined && !label.status) || props.disabled}
              key={`form-radio-${index}`}
              control={<Radio sx={{color: props.error !== undefined ? "red" : "black"}}  />}
            />
          ))}
        </RadioGroup>
      )}
    </Fragment>
  )
}

export default FormRadio

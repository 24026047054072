import { TextField, TextFieldProps, Typography } from '@mui/material'
import { CSSProperties, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface formFreeTextProps {
  value?: any
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  sx?: CSSProperties
  textfieldProps?: TextFieldProps

  disabled?: boolean
  uneditable?: boolean
}

export default function FormFreeText(props: formFreeTextProps) {
  const { t } = useTranslation()

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const textfieldProps: TextFieldProps = {
    fullWidth: true,
    placeholder: t('Please fill in information'),
    disabled: props.disabled,
    ...props.textfieldProps,
  }

  const handleChange = (event: any) => {
    switch (props.textfieldProps?.type) {
      case 'number': {
        props.onChange!(parseInt(event.target.value))
        break
      }

      default: {
        props.onChange!(event.target.value)
        break
      }
    }
  }

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography sx={{ ...props.sx }}>{props.value ?? ''}</Typography>
      ) : (
        <TextField
          defaultValue={props.value ?? ''}
          value={props.value ?? ''}
          onChange={handleChange}
          sx={{ backgroundColor: '#ffffff', ...props.sx }}
          error={!!props.error}
          {...textfieldProps}
        />
      )}
    </Fragment>
  )
}

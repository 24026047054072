import useLocalStorage from '@hooks/useLocalStorage'
import SetupPage from '@pages/setup'
import { GlobalContext } from '@providers/globalStore'
import { useKeycloak } from '@react-keycloak/web'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

type ProtectedRouteProps = {
  permision?: boolean
  children: JSX.Element
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { keycloak, initialized } = useKeycloak()
  const { state, dispatch } = useContext(GlobalContext)
  const { isInitiated, contractNoId } = state
  const { pathname } = useLocation()
  const [lsContractNoId, setContractNoId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  const [lsWorkGroupId, setWorkGroupId] = useLocalStorage<string | number | undefined>(
    'workgroup',
    undefined,
  )

  console.debug("[ProtectedRoute] isInitiated", isInitiated, contractNoId, pathname)
  if (initialized) {
    if (!keycloak.authenticated) {
      keycloak.logout()
      return <Navigate to="/" replace />
    }
  }

  if ((!contractNoId || contractNoId === 0) && ( !lsContractNoId && !lsWorkGroupId ) && pathname !== '/setup')
    return <Navigate to="/setup" />
  else if (props.permision === undefined || props.permision === true)
    return props.children
  else return <Navigate to="/401" />
}

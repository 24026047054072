import React, { ReactNode, useState }                           from 'react'
import { Typography, Box, Drawer, IconButton, Divider, Button } from '@mui/material'
import ArrowForwardIosIcon                                      from '@mui/icons-material/ArrowForwardIos'
import LoadingPopup                                             from './LoadingDialog'

type formDrawerProps = {
	buttonText: string
	title: string
	matchUpMd: boolean
	hidden: boolean
	disabled: boolean
	children: ReactNode
	onClickButton?: () => Promise<any>
	additionalIcons?: ReactNode
}

const FormDrawer = (props: formDrawerProps) => {
	const [isOpen, setIsOpen]       = useState<boolean | undefined>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleOnClick = async () => {
		if (!isLoading)
			setIsLoading(true)

		if (props.onClickButton) {
			await props.onClickButton().then(() => {
				setIsLoading(false)
				setIsOpen(true)
			})
		}
		setIsLoading(false)
	}

	if (props.hidden)
		return <></>
	else {
		return (
			<>
				<LoadingPopup open={ isLoading }></LoadingPopup>
				<Button disabled={ props.disabled }
				        onClick={ handleOnClick }
				        sx={ {
					        color          : 'black',
					        backgroundColor: 'white',
					        boxShadow      : '1',
					        maxHeight      : '100px'
				        } }>
					<Typography>{ props.buttonText ?? '' }</Typography>
				</Button>
				<Drawer anchor={ 'right' }
				        open={ isOpen }
				        onClose={ () => setIsOpen(!isOpen) }
				        variant={ props.matchUpMd ? 'persistent' : 'temporary' }
				        PaperProps={ {
					        style: {
						        boxShadow : 'none',
						        zIndex    : 99,
						        paddingTop: props.matchUpMd ? '125px' : '20px'
					        }
				        } }>
					<Box width={ props.matchUpMd ? '500px' : '100%' }>
						<Box sx={ {
							padding        : 2,
							display        : 'flex',
							alignItems     : 'center',
							position       : 'fixed',
							backgroundColor: 'white',
							width          : props.matchUpMd ? '500px' : '100%',
							justifyContent : 'space-between'
						} }>
							<Typography variant='h6'
							            sx={ { fontWeight: 'bold' } }>
								{ props.title ?? '' }
							</Typography>
							{ props.additionalIcons }
							<IconButton onClick={ () => setIsOpen(!isOpen) }>
								<ArrowForwardIosIcon />
							</IconButton>
						</Box>
						<Divider />
						<Box sx={ { overflow: 'auto', paddingTop: '62px' } }>
							{ props.children }
						</Box>
					</Box>
				</Drawer>
			</>
		)
	}
}

export default FormDrawer

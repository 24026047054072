import { Box, Typography, Grid } from '@mui/material'
import { LocationModel } from '@services/model/location.model'
import { Marker, Popup, Tooltip, useMap } from 'react-leaflet'
import FormMapCore from '@components/form/mapCore'
import { Fragment, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FormModel } from '@services/model/form/form.model'
import { featureGroup, Icon, LatLng, latLngBounds, LatLngBounds, LatLngExpression, LeafletEvent } from 'leaflet'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import commonStyles from '@styles/common.module.scss'
import * as htmlToImage from 'html-to-image'

declare type largeMapLocation = {
  location?: LocationModel
  status?: boolean
  baseForm?: FormModel
}

const useLargeMap = function <L extends largeMapLocation>(props: {
  value?: L[]
  onChange?: (...event: any[]) => void
  height?: number | string
  width?: number | string
  onMarkerClick?: (event: LeafletEvent, markerIndex: number) => void

  disabled?: boolean
  uneditable?: boolean
}): [LargeMap: () => JSX.Element, exportCanvas: () => Promise<string | undefined>] {

  const canvasRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const FitBoundsOfPin = (pinsValue: L[]) => {
    const map = useMap()
    useEffect(() => {
      if (Object.keys(pinsValue).length > 0) {
        let pinsGroup: LatLng[] = []
        for(var keyValue in pinsValue){
          if(pinsValue[keyValue].location !== undefined)
            pinsGroup.push(new LatLng(pinsValue[keyValue]?.location?.locationLatitude ?? 0 , pinsValue[keyValue]?.location?.locationLongitude ?? 0))
        }

        map.fitBounds(latLngBounds(pinsGroup).pad(0.1))
      }
    }, [pinsValue])
    return null
  }

  const exportCanvas = async () => {
    if(canvasRef !== null && canvasRef.current){
        let base64url = await htmlToImage.toJpeg(canvasRef.current, { quality: 0.95 , skipFonts: true })
        return base64url
    }
  }

  const LargeMap = () => {
    return (
      <Grid
        ref={canvasRef}
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: props.height ?? 350,
          width: props.width ?? '100%',
        }}
        >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            marginLeft: '16px',
            marginRight: '16px',
          }}
          className={commonStyles.fillBox}
          >
          <Typography
            sx={{
              position: 'absolute',
              textAlign: 'center',
              width: 'inherit',
              zIndex: 1,
              color: '#ffffff',
              ...(props.value &&
                props.value.filter((l: L) => l.status || (l.baseForm && l.baseForm.status)).length >
                  0 && {
                  display: 'none',
                }),
            }}>
            {t('Please add record in the table')}
          </Typography>
          <Box
            sx={{
              height: 'inherit',
              width: 'inherit',
              ...(props.value &&
                props.value.filter((l: L) => l.status || (l.baseForm && l.baseForm.status)).length ===
                  0 && {
                  filter: 'blur(2px)',
                  pointerEvents: 'none',
                }),
            }}>
            <FormMapCore
              zoom={15}
              center={
                props.value && props.value[0] && props.value[0].location
                  ? {
                      lat: props.value[0].location.locationLatitude,
                      lng: props.value[0].location.locationLongitude,
                    }
                  : undefined
              }
              sx={{
                border: 'solid 2px #c5c5c5',
                borderRadius: 5,
                zIndex: 1,
              }}>
              {props.value && props.value?.filter(x => x.location === undefined).length === 0 && <FitBoundsOfPin {...props.value} />}
              {props.value &&
                props.value.map((l: L, index: number) => {
                  if ((l.status || (l.baseForm && l.baseForm.status)) && l.location) {
                    return (
                      <Marker
                        key={`location-marker-${index}`}
                        icon={
                          new Icon.Default({
                            iconRetinaUrl: iconRetinaUrl,
                            iconUrl: '/img/location-on.svg',
                            shadowUrl: shadowUrl,
                          })
                        }
                        position={{
                          lat: l.location.locationLatitude,
                          lng: l.location.locationLongitude,
                        }}
                        eventHandlers={{
                          click: (e: LeafletEvent) => {
                            if (props.onMarkerClick) {
                              props.onMarkerClick(e, index)
                            }
                          },
                        }}>
                        {props.value && props.value?.length > 1 ? <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>{index+1}</Tooltip> : <></>}
                        <Popup>
                          <Typography
                            sx={{
                              whiteSpace: 'break-spaces',
                              fontSize: 10,
                            }}>{`${t('Location')}: ${l.location.locationAddress}`}</Typography>
                        </Popup>
                      </Marker>
                    )
                  } else {
                    return <Fragment key={`location-marker-${index}`} />
                  }
                })}
            </FormMapCore>
          </Box>
        </Box>
      </Grid>
    )
  }

  return [LargeMap, exportCanvas]
}

export default useLargeMap

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import FormTypography from '@components/form/typography'
import { useTranslation } from 'react-i18next'
import FormFreeText from '@components/form/freeText'
import FormTable from '@components/form/table'
import FormFileUpload from '@components/form/fileUpload'
import FormBinarySelect from '@components/form/binarySelect'

export default ({ control }: { control: Control<FormWoiModel> }) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-d`}>
      <FormField fieldName="Letter of Slow Progress Issued">
        <FormController
          controllerProps={{
            name: 'letterOfSlowProgressIssued',
            control,
          }}>
          <FormBinarySelect  uneditable={true}/>
        </FormController>
      </FormField>
      <FormField fieldName="Slow Progress Letter EDMS Ref. No">
        <FormController
          controllerProps={{
            name: 'slowProgressNumber',
            control,
          }}>
          <FormFreeText textfieldProps={{ type: 'string' }} uneditable={true} />
        </FormController>
      </FormField>
      {/*<FormField fieldName="Attachment for Letter of Slow Progress">*/}
      {/*  <FormController*/}
      {/*    controllerProps={{*/}
      {/*      name: 'attachmentForLetterOfSlowProgress',*/}
      {/*      control,*/}
      {/*    }}>*/}
      {/*    <FormFileUpload*/}
      {/*      acceptFiles={[*/}
      {/*        'application/pdf',*/}
      {/*        'application/msword',*/}
      {/*        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </FormController>*/}
      {/*</FormField>*/}
      <FormField fieldName="Letter of Overdue Progress Issued">
        <FormController
          controllerProps={{
            name: 'letterOfOverdueProgressIssued',
            control,
          }}>
          <FormBinarySelect uneditable={true} />
        </FormController>
      </FormField>
      <FormField fieldName="Attachment for Letter of Overdue Progress">
        <FormController
          controllerProps={{
            name: 'attachmentForLetterOfOverdueProgress',
            control,
          }}>
          <FormFileUpload
            height="125"
            acceptFiles={[
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            uneditable={true}
          />
        </FormController>
      </FormField>
      <FormTable
        columns={[
          {
            field: 'completionReportSubmissionDate',
            displayName: 'Completion Report Submission Date',
            type: 'date',
            // uneditable: true,
          },
          {
            field: 'proposedCompletionDate',
            displayName: 'Proposed Completion Date',
            type: 'date',
            // uneditable: true,
          },
          {
            field: 'submissionStatus',
            displayName: 'Submission Status',
          },
        ]}
        fieldArrayProps={{
          name: 'completionStatuses',
          control,
        }}
        uneditable
      />
      <FormField fieldName="Certified Completion Date">
        <FormController
          controllerProps={{
            name: 'certifiedCompletionDate',
            control,
          }}>
          <FormTypography type="date" />
        </FormController>
      </FormField>
      <FormField fieldName="Outstanding Works">
        <FormController
          controllerProps={{
            name: 'outstandingWorks',
            control,
          }}>
          <FormTypography type="binary" />
        </FormController>
      </FormField>
      {/* <FormField fieldName="Commencement Date of Outstanding Works">
            <FormController
              controllerProps={{
                name: 'commencementDateOfOutstandingWorks',
                control,
              }}>
              <FormDateTimePicker type="date" />
            </FormController>
          </FormField> */}
      <FormField fieldName="Completion Date of Outstanding Works">
        <FormController
          controllerProps={{
            name: 'completionDateOfOutstandingWorks',
            control,
          }}>
          {/* <FormDateTimePicker type="date" /> */}
          <FormTypography type="date" />
        </FormController>
      </FormField>
    </FormContainer>
  )
}

import BaseButton from '@components/button/BaseButton'
import { Grid, Box } from '@mui/material'
import { formIconProps } from './icon'
import FormIntroductionFields, { ListFormIntroductionFieldProps } from './introductionFields'
import styles from '@styles/baseButton.module.scss'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { FormContext } from '@providers/formStateProvider'
import FormIcon from '@components/FormIcon'

export interface formBriefInfo {
  id: string
  workflowId: string
  name: string
  description: string
  workflowInvolvement: string[]
  linkedLists: string[]
}

interface formIntroductionBoxProps {
  formIconProps: formIconProps
  formBriefInfo: formBriefInfo
  hasSideBtn: boolean
  disableSideBtn?: boolean
  onClickSideBtnHandler?: Function
}

const FormIntroductionBox = (props: formIntroductionBoxProps) => {
  const { t } = useTranslation('dashboard')
  const { dispatch } = useContext(FormContext)

  let listSx = {
    padding: 0,
  }

  let fieldSx = {
    fontSize: '12px',
  }

  let leftFormFields: ListFormIntroductionFieldProps = {
    sx: listSx,
    fields: [],
  }

  let rightFormFields: ListFormIntroductionFieldProps = {
    sx: listSx,
    fields: [
      {
        title: t('form-name'),
        titleTextDeco: 'underline',
        content: props.formBriefInfo.name,
        sx: fieldSx,
      },
      {
        title: t('form-description'),
        titleTextDeco: 'underline',
        content: props.formBriefInfo.description,
        sx: fieldSx,
      },
    ],
  }

  return (
    <Box>
      <Grid container>
        <Grid
          item
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          xs={12}
          md={12}
          lg={9}
          style={{
            display: 'flex',
          }}>
          <Grid item xs={12} md={12} lg={3} alignSelf="center">
            <FormIcon
              acronym={props.formIconProps.shortName}
              shortNameBgColor={props.formIconProps.shortNameBgColor}></FormIcon>
            <Box>
              <FormIntroductionFields {...leftFormFields}></FormIntroductionFields>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <FormIntroductionFields {...rightFormFields}></FormIntroductionFields>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={3} alignSelf="flex-end">
          <BaseButton
            className={styles.createFormButton}
            isShown={props.hasSideBtn}
            isDisabled={!!props.disableSideBtn}
            displayText={'Create'}
            onClickHanlder={(event: any) => {
              dispatch({ type: 'clear' })
              if (props.onClickSideBtnHandler) {
                props.onClickSideBtnHandler(event)
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default FormIntroductionBox

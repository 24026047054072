import { KeyValPair } from '@models/common'
import {
  Autocomplete,
  FilterOptionsState,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Box,
} from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

type additionalChangeActionPromiseType = (event: any, newValue: KeyValPair | null) => Promise<void>
type additionalChangeActionType = (event: any, newValue: KeyValPair | null) => void

interface formSelectProps {
  value?: number
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  options: KeyValPair[]
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  additionalChangeAction?: additionalChangeActionPromiseType | additionalChangeActionType
  multiple?: boolean
  disabledOption?: number[]

  disabled?: boolean
  uneditable?: boolean
}

export default function FormSelect(props: formSelectProps) {
  const { t } = useTranslation()
  const { disabledOption = [] } = props

  const [optionList, setOptionList] = useState<KeyValPair[]>([])
  useEffect(() => {
    setOptionList(props.options || [])
  }, [props.options])

  const [open, setOpen] = useState(false)

  // if (!props.onChange) {
  //   return <>missing onChange from props</>
  // }

  const handleChange = async (event: any, newValue: KeyValPair | null) => {
    props.onChange!(newValue ? newValue.key : null)
    if (props.additionalChangeAction) {
      if (props.additionalChangeAction.constructor.name === 'AsyncFunction') {
        await props.additionalChangeAction(event, newValue)
      } else {
        props.additionalChangeAction(event, newValue)
      }
    }
    setOpen(false)
  }

  const blurHandler = (e) => {
    if (open) {
      setOpen(false)
    }
  }

  const clickHandler = (e) => {
    if (!e.target.disabled) {
      setOpen(true)
    }
  }

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{t(GetValueWithKey(props.value, optionList) ?? '')}</Typography>
      ) : props.multiple ? null : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
          }}>
          <Box flex={1}>
            <ClickAwayListener onClickAway={() => undefined}>
              <Autocomplete
                // disablePortal
                open={open}
                value={{
                  key: props.value || null,
                  value: optionList.find((option) => option.key === props.value)?.value || '',
                }}
                getOptionLabel={(option: KeyValPair) => {
                  return option.value
                }}
                isOptionEqualToValue={(option: KeyValPair, value: KeyValPair) =>
                  option.key === value.key
                }
                options={[{ key: null, value: '' }, ...optionList]}
                onChange={handleChange}
                onBlur={props.onBlur}
                disabled={props.disabled ?? false}
                fullWidth={props.fullWidth ?? true}
                forcePopupIcon={false}
                getOptionDisabled={(option) => disabledOption.includes(option.key) }
                renderOption={(props , option) => {
                  return (
                    <li {...props} key={`${option.key}`}>
                      <Tooltip title={option.value}>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                          {option.value}
                        </Typography>
                      </Tooltip>
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    variant={props.variant ?? 'standard'}
                    error={!!props.error}
                    {...params}
                    onClick={(e) => {
                      clickHandler(e)
                    }}
                    onBlur={(e) => {
                      blurHandler(e)
                    }}
                    multiline
                  />
                )}
                filterOptions={(options: KeyValPair[], state: FilterOptionsState<KeyValPair>) =>
                  options.filter(
                    (opt: KeyValPair) => opt.value !== '' && opt.value.includes(state.inputValue),
                  )
                }
              />
            </ClickAwayListener>
          </Box>
          {!props.uneditable && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <IconButton
                disabled={props.disabled}
                {...(!props.disabled && {
                  onClick: () => {
                    setOpen(!open)
                  },
                })}>
                <ArrowDropDownIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </Fragment>
  )
}

import { BaseFilterOptions } from '@services/model/base.model'
import apiGateway from '@utils/axiosInterceptor'
import { FormCdrListModel, FormCdrModel, CDRGeneralOptions, CdrListResponse, WorkActivity } from '../model/form/form.CDR.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetGeneralOptions = async (team?: number, district?: number) => {
  if (team === undefined && district === undefined) {
    return await apiGateway.get<CDRGeneralOptions>(`/api/v1/forms/CDR/options`).then((rep) => {
      return rep.data
    })
  }else {
    return await apiGateway.get<CDRGeneralOptions>(`/api/v1/forms/CDR/options?district=${district}&team=${team}`).then((rep) => {
      return rep.data
    })
  }
}

const GetCdrForm = async (id: string) => {
  return await apiGateway.get<FormCdrModel>(`api/v1/forms/CDR/${id}`).then((rep) => {
    return rep.data
  })
}

const GetCdrList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<CdrListResponse>(`api/v1/forms/CDR/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetCdrFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/CDR/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<BaseFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveCdrForm = async (req: FormCdrModel) => {
  // TODO use json stringify replacer to filter out properties

  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/CDR', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/CDR/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveCdrForm = async (req: FormCdrModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/CDR/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteCdrForm = async (req: FormCdrModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/CDR/${req.baseForm.formId}`,
  )
}

const GetWorkOrderList = async (req: {
  contractNoId: number
  formDate: Date
  teamId: number
  districtId: number
  typeOfWorks?: boolean
  shiftType?: number
  skipEmptyValidation?: boolean
  skipUnmatchValidation?: boolean
  cdrExcelFile?: { bytesBase64: string }
}) => await apiGateway
    .post<{ workActivities: WorkActivity[] }>('/api/v1/forms/CDR/data', req)
    .then(rep => rep.data)

const FormCDRService = {
  GetCdrForm,
  GetCdrList,
  GetCdrFilterOption,
  SaveCdrForm,
  ApproveCdrForm,
  DeleteCdrForm,
  GetGeneralOptions,
  GetWorkOrderList
}

export default FormCDRService

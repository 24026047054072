import { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams }                    from 'react-router-dom'
import { useForm }                                   from 'react-hook-form'
import { GlobalContext }                             from '@providers/globalStore'
import { FormPartComponents }                        from '@models/common'
import { useTranslation }                            from 'react-i18next'
import useQuery                                      from '@hooks/useQuery'
import { QueryStepParser }                           from '@utils/queryStepParser'
import { FormContext }                               from '@providers/formStateProvider'
import { NavigateTo }                                from '@utils/navigate'
import useGeneralOptions                             from '@hooks/useGeneralOptions'
import { FormEaModel }                               from '@services/model/form/form.EA.model'
import { FormDeaModel, InitDeaForm }                 from '@services/model/form/form.DEA.model'
import useFormSwitcher                               from '@hooks/useFormSwitcher'
import FormService                                   from '@services/form.service'
import { canvasExport }                              from '@utils/canvasExport'
import { useRef }                                    from 'react'
import { yupResolver }                               from '@hookform/resolvers/yup'
import * as yup                                      from 'yup'
import { PartA }                                     from './part-a'
import ValidationToast                               from '@components/form/ValidationToast'
import { FormStatusEnum }                            from '@services/model/form/form.model'
import ContractService                               from '@services/contract.service'
import { ContractNo }                                from '@services/model/contract.model'
import { find }                                      from 'lodash'

export default function DEAForm() {
  const { state: globalState } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const { getOptionsByKey } = useGeneralOptions()
  const mapRef = useRef<HTMLUListElement>(null)
  const saveTargetMap = async () => {
    let targetBase64 = await canvasExport(mapRef)
    setValue('locationMapBase64', targetBase64)
  }

  // extracting id from params
  const { id } = useParams<string>()

  // extracting index from query
  const query = useQuery()
  const num = QueryStepParser(query.get('num'))

  const { t }                                             = useTranslation()
  const navigate                                          = useNavigate()
  const [contractInfo, setContractInfo]                   = useState<ContractNo>()
  const [parentForm, setParentForm]                       = useState<FormEaModel | undefined>()
  const [showInspectionSection, setShowInspectionSection] = useState(false)
  const isMounted                                         = useRef(false)

  const { control, setValue, getValues, trigger, reset } = useForm<FormDeaModel>({
                                                                                   defaultValues : { ...InitDeaForm },
                                                                                   resolver      : (data, context, options) => {
                                                                                     const inspectionSectionValidator = yup
                                                                                         .number()
                                                                                         .label(t('Inspection Section'))
                                                                                         .min(1)
                                                                                         .max(formState.form.totalInspectionSection) // formState is of type FormEA

                                                                                     const validationSchema = yup
                                                                                         .object()
                                                                                         .shape({
                                                                                                  inspectionSection: showInspectionSection ? inspectionSectionValidator.required() : inspectionSectionValidator,
                                                                                                  location         : yup.object().required(t('Location is required'))
                                                                                                })
                                                                                         .required()

                                                                                     return yupResolver(validationSchema)(data, context, options)
                                                                                   },
                                                                                   mode          : 'all',
                                                                                   reValidateMode: 'onChange',
                                                                                   criteriaMode  : 'all',
                                                                                 })

  const formStatus = getValues('baseForm.formStatus')
  const parentForm_formStatus = parentForm?.baseForm.formStatus

  useEffect(() => {
    const contractInfo = find(globalState.contractList, { id: parentForm?.baseForm.contractNoId })
    console.group(`Contract Info with parentForm_formStatus=${parentForm_formStatus}`)
    console.dir(contractInfo)
    if (contractInfo)
      setContractInfo(contractInfo)
    console.groupEnd()

    if (contractInfo?.highSpeedRoad &&
        (formState.form.formNea.typeOfMmWorksValue === 'Maintenance of Road Marking, Road Drainage System and Detailed Inspections' ||
         formState.form.formNea.typeOfMmWorksValue === 'Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions'))
      setShowInspectionSection(true)
  }, [parentForm_formStatus])

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'EA') {
        if (num !== undefined) {
          // old form
          const parentSDForm = formState.form as FormEaModel
          if (parentSDForm.formDeaList && parentSDForm.formDeaList[num]) {
            reset({ ...parentSDForm.formDeaList[num] })
          }
        } else {
          // new form
          reset({ ...InitDeaForm })
        }

        // set parent form into this state
        setParentForm({ ...formState.form })
      } else {
        // redirect to EA form
        NavigateTo(navigate, '/engineer-audit/:id', { id }, { step: 1 })
      }

      const contractInfo = find(globalState.contractList, { id: parentForm?.baseForm.contractNoId })
      if (contractInfo)
        setContractInfo(contractInfo)
      isMounted.current = true
    }

    // pop off form stack
    return () => formStateAction({ type: 'pop', targetFormType: 'EA' })
  }, [id, num, formStateAction, ContractService, getValues])

  let isLoading = false

  //#region form parts
  const DEAPartA = (): FormPartComponents => {
    return {
      partTitle: t('General information'),
      component: (
          <PartA control={ control }
                 setValue={ setValue }
                 getValues={ getValues }
                 formStatus={ formStatus }
                 globalState={ globalState }
                 parentForm={ parentForm }
                 getOptionsByKey={ getOptionsByKey }
                 showInspectionSection={ showInspectionSection }
                 isMounted={ isMounted }
                 uneditable={
                     parentForm_formStatus !== FormStatusEnum.FORM_EA_DRAFT &&
                     parentForm_formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
                 }
                 ref={ mapRef }
                 contractInfo={ contractInfo } />
      ),
      onNext   : () => saveTargetMap(),
    }
  }

  //#endregion

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const [FormSwitcher] = useFormSwitcher({
    title: t('DEFECT IDENTIFED IN ENGINEER AUDIT'),
    components: [DEAPartA()],
    isLoading: isLoading,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    disableComment: getValues('baseForm.formId') ? false : true,
    onSubFormComplete: async () => {
      if (parentForm) {
        if (!(await trigger())) return
        await saveTargetMap()
        let _parentForm: FormEaModel = { ...parentForm }
        if (num !== undefined) {
          // old form
          _parentForm = {
            ...parentForm,
            formDeaList: parentForm.formDeaList?.map((photo: FormDeaModel, index: number) =>
              index === num ? getValues() : photo,
            ),
          }
        } else {
          // new form
          _parentForm = {
            ...parentForm,
            formDeaList: [...(parentForm.formDeaList ?? []), getValues()],
          }
        }
        // insert this form to parent from
        formStateAction({ type: 'push', form: _parentForm, formType: 'EA' })
      } else {
        alert('something went wrong')
        formStateAction({ type: 'clear' })
      }
      NavigateTo(navigate, '/engineer-audit/:id', { id }, { step: 1 })
    },
    navigateBack: async () => {
      if (parentForm) {
        let _parentForm: FormEaModel = { ...parentForm }
        formStateAction({ type: 'push', form: _parentForm, formType: 'EA' })
      } else {
        alert('something went wrong')
        formStateAction({ type: 'clear' })
      }
      NavigateTo(navigate, '/engineer-audit/:id', { id }, { step: 1 })
    },
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}

import SearchPanel from '@components/searchPanel'
import { Grid, TableCell, useTheme } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import FormDEAService from '@services/formService/form.DEA.service'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useAPIFetch from '@hooks/useAPIFetch'
import useTableView from '@hooks/useTableView'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { FormDEAListModel } from '@services/model/form/form.DEA.model'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'

const MyRecord = () => {
  const refMounted = useRef(true)
  const { state: globalState } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [TableView, reload] = useTableView<FormDEAListModel>({
    headers: [
      {
        key: 'status',
        desc: t('General Status', { ns: common.i18nFormNS }),
      },
      {
        key: 'submissionStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
      },
      {
        key: '"DEA_no"',
        desc: t('Form ID', { ns: common.i18nFormNS }),
      },
      {
        key: 'action_by',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
      },
      {
        key: 'created_by',
        desc: t('Approved By', { ns: common.i18nFormNS }),
      },
      {
        key: 'updated_at',
        desc: t('Last Update', { ns: common.i18nFormNS }),
      },
    ],
    onRowGen: function (navigate: NavigateFunction, record: FormDEAListModel): JSX.Element {
      return (
        <Fragment>
          <TableCell>
            <FormStatusLabel label={record.formStatusShortName} color={record.color} />
          </TableCell>
          <TableCell>{record.submissionStatus}</TableCell>
          <TableCell>{record.formId}</TableCell>
          <TableCell>
            {GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}
          </TableCell>
          <TableCell>
            {GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}
          </TableCell>
          <TableCell>{moment(record.updatedAt).format(moment.defaultFormat)}</TableCell>
        </Fragment>
      )
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      return await FormDEAService.GetDEAList(
        {
          ...state.filterResult,
          sorting: sorting,
          pagination: pagination,
        },
        cancelToken,
        true,
      )
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'bookingDate',
              desc: t('bookingDate', { ns: common.i18nFormNS }),
              type: 'DateFilter',
              disableOperator: true,
              defaultFrom: moment().add(1, 'day').format('DD-MM-YYYY'),
              defaultTo: moment().add(1, 'day').format('DD-MM-YYYY'),
              defaultOperator: '=',
            },
            {
              key: 'formDate',
              desc: t('formDate'),
              type: 'DateFilter',
            },
            {
              key: 'toDate',
              desc: t('toDate'),
              type: 'DateFilter',
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default MyRecord

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { Typography } from '@mui/material'
import { FormHWRModel, InitHWRForm } from '@services/model/form/form.HWR.model'
import FormFileUpload from '@components/form/fileUpload'
import FormTable from '@components/form/table'
import { useTranslation } from 'react-i18next'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormFreeText from '@components/form/freeText'

export default ({
    control,
    readOnly,
}:{
    control: Control<FormHWRModel>
    readOnly: boolean

}) => {
    const UUID = uuid()
    let index = 0

    const { t } = useTranslation()

    return (
      <FormContainer fkey={`${UUID}-hwr-b`}>
        {/* <FormField fieldName="Reference of Hidden Works Record" index={index++}>
          <FormController
            controllerProps={{
              name: 'referenceOfHwr',
              control: control,
            }}>
            <FormFileUpload disabled />
          </FormController>
        </FormField> */}
        {/* <FormField fieldName="Type" index={index++}>
          <Typography>{t('Hidden Works Details')}</Typography>
        </FormField>
        <FormTable
          columns={[
            {
              field: 'description',
              displayName: 'Description',
              type: 'string',
            },
            {
              field: 'quantity',
              displayName: 'Quantity',
              type: 'string',
            },
          ]}
          fieldArrayProps={{
            name: 'hwrDetails',
            control: control,
          }}
        /> */}
        <FormField fieldName="Last Submission">
          <FormController
            controllerProps={{
              name: 'lastSubmission',
              control: control,
            }}>
          <FormBinaryRadio
              binaryOptionNames={{
                yes: 'Yes',
                no: 'No',
              }}
              uneditable={readOnly}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Attachment Upload" index={index++}>
          <FormController
            controllerProps={{
              name: 'hwrAttachments',
              control: control,
            }}>
            <FormFileUpload
                acceptFiles={[
                    'application/pdf',
                ]}
                multipleUpload={true}
                disabled={readOnly}
                requirePDFpageNumber={true}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Remarks">
          <FormController
            controllerProps={{
              name: `remarks`,
              control,
            }}>
            <FormFreeText
              textfieldProps={{
                multiline: true,
                rows: 4,
              }}
              disabled={readOnly}
            />
          </FormController>
        </FormField>
      </FormContainer>
    )
  }



import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface formContentBlockProps {
  customText?: string
}

export default function FormContentBlock(props: formContentBlockProps) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
      <Paper
        sx={{
          height: '40%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#ebebeb',
        }}>
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            fontWeight: 'normal',
          }}>
          {props.customText ? t(props.customText) : t('N/A at this status.')}
        </Typography>
      </Paper>
    </Box>
  )
}

import { Modal, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useCallback, useState } from 'react'

export default function useImageModal(): [
  ImageModal: ({ image }: { image: string | undefined }) => JSX.Element,
  openImageModal: () => void,
  closeImageModal: () => void,
  isImageModalOpen: boolean,
] {
  const [isImageModalOpen, setOpen] = useState<boolean>(false)

  const openImageModal = () => setOpen(true)
  const closeImageModal = () => setOpen(false)

  const ImageModal = useCallback(
    ({ image }: { image: string | undefined }) => {
      return (
        <Modal open={isImageModalOpen} onClose={closeImageModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '10px',
              boxShadow: 24,
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}>
            <Box sx={{ alignSelf: 'end' }}>
              <IconButton onClick={closeImageModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <img
              src={image}
              alt="original-image"
              style={{
                borderRadius: '10px',
                maxHeight: 'calc(100vh - 96px)',
                maxWidth: 'calc(100vw - 32px)',
              }}
            />
          </Box>
        </Modal>
      )
    },
    [isImageModalOpen],
  )

  return [ImageModal, openImageModal, closeImageModal, isImageModalOpen]
}

import { KeyValPair } from '@models/common'
import { GlobalContext } from '@providers/globalStore'
import { GeneralOptionGroup } from '@services/model/general_option.model'
import { useContext } from 'react'

/**
 * get master data general options (KeyValPair[])
 * @param masterDataList master data list
 * @param name name of general options
 * @returns key value pair list
 */
const GetOrganizationOptions = (): KeyValPair[] => {
    const { state } = useContext(GlobalContext)
    if(state.organizationMetaList ){
        return state.organizationMetaList?.filter((item) => !['System', 'Varadise'].includes(item.value))
    }
    return []
}

export { GetOrganizationOptions }

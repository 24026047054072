import FormContainer from '@components/form/container'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import { useTranslation } from 'react-i18next'
import FormTable from '@components/form/table'

export default ({ control }: { control: Control<FormWoiModel> }) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-g`}>
      <FormTable
        columns={[
          {
            field: 'dateOfContractorSubmission',
            displayName: "Date of the Contractor's Submission",
            type: 'date',
          },
          {
            field: 'dateOfEngineerReturn',
            displayName: "Date of the Engineer's Return",
            type: 'date',
          },
          {
            field: 'dateOfContractorResubmission',
            displayName: "Date of the Contractor's Resubmission",
            type: 'date',
          },
          {
            field: 'dateOfEngineerAgreement',
            displayName: "Date of the Engineer's Agreement",
            type: 'date',
          },
          {
            field: 'numberOfPage',
            displayName: 'Number of page',
          },
        ]}
        fieldArrayProps={{
          name: 'hiddenWorkRecords',
          control,
        }}
        rowFilter={() => true}
        removeDeleteButton
        uneditable
      />
    </FormContainer>
  )
}

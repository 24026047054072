import { KeyValPair } from '@models/common'
import { Box, Chip, Grid, Typography } from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import { get } from 'lodash'
import moment from 'moment'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface BinaryOptions {
  yes: string
  no: string
  na?: string
}
interface formTypographyProps {
  value?: any
  onChange?: (...event: any[]) => void
  name?: string
  type?:
    | 'string'
    | 'binary'
    | 'select'
    | 'numbers'
    | 'date'
    | 'time'
    | 'number'
    | 'price'
    | 'multipleString'
  dateFormat?: string
  timeFormat?: string
  numberDemical?: number
  options?: KeyValPair[]
  binaryOptions?: BinaryOptions
  sx?: CSSProperties
  displayKey?: string
}

export default function FormTypography(props: formTypographyProps) {
  const { t } = useTranslation()

  switch (props.type) {
    case 'binary': {
      let displayValue = ''
      switch (props.value) {
        case true: {
          displayValue = props.binaryOptions ? t(props.binaryOptions.yes) : t('Yes')
          break
        }
        case false: {
          displayValue = props.binaryOptions ? t(props.binaryOptions.no) : t('No')
          break
        }
        default: {
          displayValue =
            props.binaryOptions && props.binaryOptions.na ? t(props.binaryOptions.na) : t('N/A')
          break
        }
      }
      return <Typography sx={props.sx}>{displayValue}</Typography>
    }
    case 'select': {
      if (props.options) {
        return (
          <Typography sx={props.sx}>
            {t(GetValueWithKey(props.value, props.options) ?? '')}
          </Typography>
        )
      } else {
        console.warn(`${props.name} missing options for select type typography`)
        return <></>
      }
    }
    case 'numbers': {
      const str =
        props.value?.reduce((acc: string, cur: string) => (acc += cur + ', '), '')?.slice(0, -2) ??
        ''
      return <Typography sx={props.sx}>{str}</Typography>
    }
    case 'number': {
      const num = props.value as number
      if (props.numberDemical) {
        return <Typography sx={props.sx}>{num?.toFixed(props.numberDemical)}</Typography>
      } else {
        return <Typography sx={props.sx}>{num}</Typography>
      }
    }
    case 'date': {
      const dateStr = props.value
        ? moment(props.value).format(props.dateFormat ?? 'D MMMM YYYY')
        : ''
      return <Typography sx={props.sx}>{dateStr}</Typography>
    }
    case 'time': {
      const timeStr = props.value
        ? moment(props.value).format(props.timeFormat ?? moment.defaultFormat)
        : ''
      return <Typography sx={props.sx}>{timeStr}</Typography>
    }
    case 'price': {
      const num = props.value as number
      if (props.numberDemical) {
        return <Typography sx={props.sx}>{'$' + num?.toFixed(props.numberDemical)}</Typography>
      } else {
        return <Typography sx={props.sx}>{'$' + num?.toFixed(2)}</Typography>
      }
    }
    case 'multipleString':
      console.log('props.value', props.value)
      return (
        (
          <Grid container spacing={1}>
            {props.value?.map((x) => {
              return <Grid item ><Chip label={get(x, props.displayKey ?? '')} sx={{ margin: '4px, 2px'}}/></Grid>
            })}
          </Grid>
        ) ?? ''
      )
    case 'string':
    default: {
      return <Typography sx={props.sx}>{props.value ?? ''}</Typography>
    }
  }
}

import { ForwardedRef, forwardRef, MutableRefObject, useEffect }              from 'react'
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form'
import { useTranslation }                                                     from 'react-i18next'
import { Box }                                                                from '@mui/material'
import { format }                                                             from 'date-fns'
import { isString }                                                           from 'lodash'
import { GlobalStateProps }                                                   from '@providers/globalStore'
import { UserInfo }                                                           from '@services/model/user.model'
import { FormStatusEnum }                                                     from '@services/model/form/form.model'
import { FormDNModel }                                                        from '@services/model/form/form.DN.model'
import { DNAGeneralOptions, FormDNAModel }                                    from '@services/model/form/form.DNA.model'
import FormField                                                              from '@components/form/field'
import FormSelect                                                             from '@components/form/select'
import FormImageUpload                                                        from '@components/form/imageUpload'
import FormContainer                                                          from '@components/form/container'
import FormLinkTable                                                          from '@components/form/linkTable'
import FormController                                                         from '@components/form/controller'
import FormTypography                                                         from '@components/form/typography'
import FormFreeText                                                           from '@components/form/freeText'
import FormDateTimePicker                                                     from '@components/form/dateTimePicker'
import FormMultipleSelect                                                     from '@components/form/multipleSelect'
import FormMediumMap                                                          from '@components/form/mediumMap'
import FormBinaryRadio                                                        from '@components/form/binaryRadio'
import { GetTeamOptions }                                                     from '@utils/teamOptions'
import { GetDistrictOptions }                                                 from '@utils/districtOptions'
import { GetWorksOrderOptions }                                               from '@utils/worksOrderOptions'

declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

function PartAInner(
    {
        globalState,
        control,
        watch,
        userInfo,
        getValues,
        options,
        setValue,
        calculateExpectedGeneratedDn,
        isMounted
    }: {
        globalState: GlobalStateProps
        control: Control<FormDNAModel>
        watch: UseFormWatch<FormDNAModel>
        userInfo: UserInfo
        getValues: UseFormGetValues<FormDNAModel>
        options: DNAGeneralOptions
        setValue: UseFormSetValue<FormDNAModel>
        calculateExpectedGeneratedDn: (startDate: Date, endDate: Date, reoccurrenceInHour: number, nonComplianceId: number) => Array<FormDNModel>
        isMounted: MutableRefObject<boolean>
    },
    ref: ForwardedRef<HTMLUListElement>
) {
    const { t }                 = useTranslation()
    const formStatus            = getValues('baseForm.formStatus') as FormStatusEnum
    const uneditable            = ![FormStatusEnum.FORM_DNA_DRAFT].includes(formStatus)
    const uneditableForRejected = ![FormStatusEnum.FORM_DNA_DRAFT,
                                    FormStatusEnum.FORM_DNA_REVIEW_REJECTED].includes(formStatus)

    const contractNoList  =
              globalState.contractList?.map((x) => {
                  return {
                      key  : x.id,
                      value: x.contractNo
                  }
              }) || []
    const contractNoId    = watch('baseForm.contractNoId')
    const matchedContract = globalState.contractList?.find(contractNo => contractNo.id === contractNoId)

    const [nonComplianceId, ncEndDate, ncStartDate, isNcPeriodConfirmed, formDnView] = useWatch({
                                                                                        control,
                                                                                        name: ['nonComplianceId',
                                                                                               'ncEndDate',
                                                                                               'ncStartDate',
                                                                                               'isNcPeriodConfirmed',
                                                                                               'formDnView']
                                                                                    })

    const dnOptions      = globalState.formOptionsList?.find(({ key }) => key === 'DN')?.value ?? {}
    const selectedDnType = options?.typeOfNonCompliance?.find(x => x.id === nonComplianceId)

    useEffect(() => {
        if (!isNcPeriodConfirmed) {
            setValue('ncEndDate', undefined)
            setValue('formDnView', undefined)
        }
    }, [selectedDnType?.reoccurenceInHour, isNcPeriodConfirmed])

    const PartAWorksOrderNo = () => {
        const [teamId, districtId] = useWatch({
                                                  control,
                                                  name: ['baseForm.teamId', 'baseForm.districtId']
                                              })

        return (
            <FormField fieldName='Works Order No.'>
                {
                    uneditable
                    ? <FormTypography value={ getValues('baseForm.workOrderNo') } />
                    :
                    <FormController controllerProps={ {
                        name : 'baseForm.workOrderId',
                        control,
                        rules: { required: true }
                    } }>
                        <FormSelect fullWidth
                                    options={ GetWorksOrderOptions(globalState.worksOrderList || [], teamId, districtId) }
                                    uneditable={ uneditable } />
                    </FormController>
                }
            </FormField>
        )
    }

    useEffect(() => {
        if (selectedDnType?.reoccurenceInHour && ncStartDate && ncEndDate)
            setValue('formDnView',
                     calculateExpectedGeneratedDn(isString(ncStartDate) ? new Date(ncStartDate) : ncStartDate,
                                                  isString(ncEndDate) ? new Date(ncEndDate) : ncEndDate,
                                                  selectedDnType.reoccurenceInHour,
                                                  selectedDnType?.id))
    }, [selectedDnType?.reoccurenceInHour,
        (isString(ncStartDate) ? new Date(ncStartDate) : ncStartDate)?.getTime() ?? null,
        (isString(ncEndDate) ? new Date(ncEndDate) : ncEndDate)?.getTime() ?? null])

  return (
    <FormContainer fkey="dna-a">
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control,
            rules: { required: true },
          }}>
          <FormSelect
            options={contractNoList}
            uneditable={true}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect options={GetTeamOptions(userInfo.teams)} uneditable={uneditable} />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={GetDistrictOptions(userInfo.districts)}
            uneditable={uneditable}
          />
        </FormController>
      </FormField>

      <FormField fieldName="From">
        <FormTypography type="string" value={matchedContract?.client} />
      </FormField>

      <FormField fieldName="Contractor">
        <FormTypography type="string" value={matchedContract?.contractor} />
      </FormField>

      <PartAWorksOrderNo />

      <FormField fieldName="Type of Non-Compliance">
        <FormController
          controllerProps={{
            name: 'nonComplianceId',
            control,
            rules: { required: true },
          }}>
          <FormSelect
            options={
              options?.typeOfNonCompliance?.map(({ id, dnRefNo, typeOfNonCompliance }) => {
                return { key: id, value: dnRefNo + ' - ' + typeOfNonCompliance }
              }) ?? []
            }
            uneditable={formStatus !== FormStatusEnum.FORM_DNA_DRAFT}
          />
        </FormController>
      </FormField>

      <FormField fieldName="DNA No.">
        <FormController
          controllerProps={{
            name: 'dnaNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="DN No.">
        <FormTypography
          value={getValues('formDn')
            ?.map(({ dnNo }) => dnNo)
            .join(', ')}
        />
      </FormField>

      <FormField fieldName="Recurrance">
        <FormTypography value={selectedDnType?.reoccurenceInHour ? 'Yes' : 'No'} />
      </FormField>

      {selectedDnType?.reoccurenceInHour && (
        <FormField fieldName="Recurring Frequency">
          <FormTypography
            value={
              selectedDnType?.reoccurenceInHour === 168
                ? '7 Days'
                : selectedDnType?.reoccurenceInHour
                ? selectedDnType?.reoccurenceInHour + ' Hours'
                : ''
            }
          />
        </FormField>
      )}

      {selectedDnType?.reoccurenceInHour && (
        <FormField fieldName="NC Completed">
          <FormController
            controllerProps={{
              name: 'isNcPeriodConfirmed',
              control,
              rules: { required: true },
            }}>
            <FormBinaryRadio
              uneditable={
                formStatus !== FormStatusEnum.FORM_DNA_DRAFT &&
                formStatus !== FormStatusEnum.FORM_DNA_REVIEW_REJECTED &&
                formStatus !== FormStatusEnum.FORM_DNA_PENDING
              }
            />
          </FormController>
        </FormField>
      )}

        <FormField fieldName='Amount of Deduction'>
            <FormTypography value={ selectedDnType?.amountOfDeduction ?? 0 } type='price' />
        </FormField>

        <FormField fieldName='Location'>
            <FormController controllerProps={ { control, name: 'location' } }>
                <FormMediumMap ref={ ref }
                               additionalChangeAction={ async (address) => {
                                   setValue('roadName', address)
                               } }
                               uneditable={ uneditableForRejected }
                               isMounted={ isMounted.current } />
            </FormController>
        </FormField>

        <FormField fieldName='Road'>
            <FormController controllerProps={ { control, name: 'roadName' } }>
                <FormTypography />
            </FormController>
        </FormField>

        <FormField fieldName='Supporting Photo(s)'>
            <FormController controllerProps={ { control, name: 'supportingPhotos' } }>
                <FormImageUpload uneditable={ uneditableForRejected } multiple />
            </FormController>
        </FormField>

        <FormField fieldName='Remarks'>
            <FormController controllerProps={ { control, name: 'remarks' } }>
                <FormFreeText textfieldProps={ { fullWidth: true } } uneditable={ uneditableForRejected } />
            </FormController>
        </FormField>

      <FormField fieldName="Date & Time of Checking">
        <FormController
          controllerProps={{
            name: 'dateOfCheck',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker uneditable={uneditableForRejected} />
        </FormController>
      </FormField>

      <FormField fieldName="Start of NC Period">
        <FormController
          controllerProps={{
            name: 'ncStartDate',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker uneditable={uneditableForRejected} />
        </FormController>
      </FormField>

        {
            isNcPeriodConfirmed &&
            <FormField fieldName='End of NC Period'>
                <FormController controllerProps={ {
                    control,
                    name : 'ncEndDate',
                    rules: { required: true }
                } }>
                    <FormDateTimePicker uneditable={
                        formStatus !== FormStatusEnum.FORM_DNA_DRAFT &&
                        formStatus !== FormStatusEnum.FORM_DNA_REVIEW_REJECTED &&
                        formStatus !== FormStatusEnum.FORM_DNA_PENDING
                    } />
                </FormController>
            </FormField>
        }

        {
            selectedDnType?.reoccurenceInHour &&
            ncStartDate &&
            <FormField fieldName='Expected Generated DN No.'>
                <FormTypography value={
                    (() => {
                        if (isNcPeriodConfirmed) {
                            if (!ncEndDate)
                                return ''
                            return formDnView?.length ?? '—'
                        }
                        else
                            return 'Number of DNs generated will be confirmed after DNA issued'
                    })()
                } />
            </FormField>
        }

        {
            getValues('formDn') &&
            <FormField fieldName='Actual Generated DN No.'>
                <FormTypography value={ getValues('formDn')?.length ?? 0 } />
            </FormField>
        }

        {
            getValues('invalidFormDn') &&
            <FormField fieldName='Generated DN outside above criteria'>
                <FormTypography value={ getValues('invalidFormDn')?.length ?? 0 } />
            </FormField>
        }

        {
            selectedDnType?.reoccurenceInHour &&
            isNcPeriodConfirmed &&
            ncEndDate &&
            (formDnView?.length ?? 0) > 0 &&
            <FormLinkTable fieldArrayProps={ { control, name: 'formDnView' } }
                           title={ t('Expected Generated DNs') }
                           columns={ [
                               {
                                   field: 'nonComplianceId',
                                   name: 'DN Ref No.',
                                   type: 'selected',
                                   options: dnOptions
                                            ? dnOptions.typeOfNonComplianceList?.map(t => ({ key: t.id, value: t.dnRefNo }))
                                            : [],
                               },
                               {
                                   field: 'nonComplianceId',
                                   name: 'Type of Non-Compliance',
                                   type: 'selected',
                                   options: dnOptions
                                            ? dnOptions.typeOfNonComplianceList?.map(t => ({ key: t.id, value: t.typeOfNonCompliance }))
                                            : [],
                               },
                               {
                                   field: 'nonComplianceId',
                                   name: 'Amount of Deduction',
                                   type: 'selected',
                                   options: dnOptions
                                            ? dnOptions.typeOfNonComplianceList?.map(t => ({ key: t.id, value: t.amountOfDeduction }))
                                            : [],
                                   mobileHidden: true,
                               },
                               {
                                   field : '',
                                   name  : 'NC Period',
                                   type  : 'custom',
                                   render: (_row) => {
                                       const row = _row as any
                                       return (
                                           <Box sx={ { whiteSpace: 'nowrap' } }>
                                               <Box>{ format(row.startAt, 'dd MMM yyyy, HH:mm') }</Box>
                                               &mdash;
                                               <Box>{ format(row.endAt, 'dd MMM yyyy, HH:mm') }</Box>
                                           </Box>
                                       )
                                   }
                               }
                           ] }
                           uneditable={ true } />
        }
    </FormContainer>
  )
}

export const PartA = forwardRef(PartAInner)
import { Box, LinearProgress } from '@mui/material'
import '@styles/spinner.module.scss'

export default function CustomSpinner() {
    return (
        <Box>
            <LinearProgress />
        </Box>
    )
}

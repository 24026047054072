import AddIcon from '@mui/icons-material/Add'

import {
  Box,
  Typography,
  Grid,
  styled,
} from '@mui/material'
import SearchPanel from '@components/searchPanel'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { UserFilterOptions } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { ContractNo } from '@services/model/contract.model'
import ContractService from '@services/contract.service'
import UserService from '@services/user.service'
import useLocalStorage from '@hooks/useLocalStorage'
import { GroupByContract } from '@services/model/group.model'

const LinkTableAddButton = styled(Box)<{
    state: {
      disableAddButton?: boolean
      uneditable?: boolean
      color?: string
      hoverBackgroundColor?: string
      backgroundColor?: string
    }
  }>(({ state }) => ({
    width: 40,
    height: 40,
    display: state.uneditable ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    zIndex: 1000,
    backgroundColor: state.disableAddButton ? '#eeeeee' : state.color ?? '#23e9c0',
    ...(!state.disableAddButton && {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: state.hoverBackgroundColor ? state.hoverBackgroundColor : 'grey',
        '#add-button-hover-text': {
          opacity: '100% !important',
          width: '200px',
          transition: 'opacity 500ms !important',
        },
      },
    }),
  }))

const DistrictList = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [extraListInfo, setExtraListInfo] = useState<UserFilterOptions>({
    organizationIdList: [],
  })

  const [contractNoId] = useLocalStorage<string | undefined>('contract', undefined)

  const getExtraListInfo = async () => {
    let extraInfo = await AdminService.GetUserFilterOption()
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<GroupByContract>({
    headers: [
      {
        key: 'name',
        desc: t('Name', { ns: common.i18nFormNS }),
        renderCell: (record: GroupByContract) => <Fragment>{record.name}</Fragment>,
      },
      {
        key: 'shortTerm',
        desc: t('Short Term', { ns: common.i18nFormNS }),
        renderCell: (record: GroupByContract) => <Fragment>{record.shortTerm}</Fragment>,
      },
      {
        key: 'status',
        desc: t('Status', { ns: common.i18nFormNS }),
        renderCell: (record: GroupByContract) => {
          return <Fragment>{record.status? "Active" : "Inactive"}</Fragment>
        },
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: GroupByContract) {
      NavigateTo(navigate, '/settings/districts/:id', { id: record.id })//, { id: record.id }
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if(contractNoId){
        var list = await UserService.GetGroupsByContract('District', contractNoId)
        return {list: list.items, totalCount: list.items.length}
      }
    },
  })

  const handleAddDistrict = (event) => {
    NavigateTo(navigate, '/settings/districts')
  }
  
  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            width: '100%',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ padding: '5px 10px ' }}>
              <LinkTableAddButton
                state={{
                  disableAddButton: false, // add logic
                  uneditable: false, // add logic
                  color: '#cccccc',
                  hoverBackgroundColor: '#000000',
                }}
                onClick={handleAddDistrict}>
                <AddIcon
                  id="link-table-add-icon"
                  sx={{
                    height: '100%',
                    width: '100%',
                    color: '#ffffff',
                  }}
                />
                <Box
                  id="add-button-hover-text"
                  style={{
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    padding: 10,
                    paddingRight: 70,
                    opacity: 0,
                  }}>
                  <Typography>{t('Add a New District')}</Typography>
                </Box>
              </LinkTableAddButton>
            </Box>
            {/* <Box>
            <SearchPanel
                dispatch={dispatch}
                addUrl="/booking/new"
                onSearch={reload}
                onInitReload={reload}
                onToggleFilterDrawer={getExtraListInfo}
                mountedRef={refMounted}
                excludeStatus={true}
                criteria={[
                  {
                    key: '',
                    desc: 'Status',
                    type: 'BooleanSelectionFilter',
                    multiple: false,
                    valueList: [
                      {
                        key: true,
                        value: 'Enabled',
                      },
                      {
                        key: false,
                        value: 'Disabled',
                      },
                    ],
                    defaultValue: []
                  },
                  {
                    key: 'name',
                    desc: 'Name',
                    type: 'StringFilter',
                    defaultValue: '',
                  },
                  {
                    key: 'shortName',
                    desc: 'Short name',
                    type: 'StringFilter',
                    defaultValue: '',
                  }
                ]}
              />
            </Box> */}
          </Box>
        </Box>
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default DistrictList

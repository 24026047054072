import {
  List,
  ListItem,
  Paper,
  Typography,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DownloadIcon from '@mui/icons-material/Download'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { Fragment, useEffect, useRef, useState } from 'react'
import { FileModel } from '@services/model/file.model'
import { FileToFileModel, GetFileFromUrl } from '@utils/file'
import { useSnackbar } from 'notistack'
import FileIcon from '@components/icon/file'

const FormImageUplaodContainer = styled(Paper)<{
  state: {
    uploaded: boolean
    draggedOver: boolean
    height?: number | string
    width?: number | string
    disabled?: boolean
    uneditable?: boolean
    disableUpload?: boolean,
    hideUpload?: boolean,
    isLoading: boolean
  }
}>(({ theme, state }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  height: state.height ?? 300,
  [theme.breakpoints.up('md')]: {
    width: '90%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  width: state.width ?? '90%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  ...(state.draggedOver &&
    !state.disabled &&
    !state.uneditable &&
    !state.disableUpload &&
    !state.isLoading && {
      '#file-uploader-container': {
        scale: '0.8 1',
        visibility: 'visible',
        transition: 'visibility 0s, transform 200ms ease-in-out',
        transform: 'scale(1.25, 1)',
      },
      '#file-uploader-add-text': {
        visibility: 'visible',
        transition: 'transform 0ms, visibility 200ms ease-in-out',
        transitionDelay: '200ms',
        transform: 'translateY(25px)',
      },
      '#file-uploader-add-icon': {
        transition: 'transform 200ms ease-in-out',
        transform: 'translateY(-20px)',
      },
    }),
  ...(!state.disabled &&
    !state.uneditable &&
    !state.disableUpload &&
    !state.isLoading && {
      ':hover': {
        '#file-uploader-container': {
          scale: '0.8 1',
          visibility: 'visible',
          transition: 'visibility 0s, transform 200ms ease-in-out',
          transform: 'scale(1.25, 1)',
        },
        '#file-uploader-add-text': {
          visibility: 'visible',
          transition: 'transform 0ms, visibility 200ms ease-in-out',
          transitionDelay: '200ms',
          transform: 'translateY(25px)',
        },
        '#file-uploader-add-icon': {
          transition: 'transform 200ms ease-in-out',
          transform: 'translateY(-20px)',
        },
      },
    }),
  position: 'relative',
  ...(state.uploaded && {
    height: state.height ?? state.hideUpload ? 200 : 400,
  }),
}))

const UploaderBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
}))

const FileUploaderContainer = styled(Box)<{
  state: { uploaded: boolean; heightAfterUploaded?: number | string, multipleUpload?: boolean  }
}>(({ state }) => ({
  border: 'dashed 2px #c5c5c5',
  borderRadius: 5,
  height: '90%',
  width: '95%',
  visibility: 'hidden',
  ...((state.uploaded || state.heightAfterUploaded) && {
    height: state.heightAfterUploaded ?? '40%',
  }),
  ...((state.heightAfterUploaded && (state.uploaded && !state.multipleUpload)) && {
    height: state.heightAfterUploaded ?? "0",
  }),
}))

const UploadFileIconHolder = styled(Box)<{ state: { uploaded: boolean, multipleUpload?: boolean } }>(({ state }) => ({
  width: '100%',
  height: 40,
  position: 'absolute',
  textAlign: 'center',
  top: '40%',
  zIndex: 5,
  pointerEvents: 'none',
  ...(state.uploaded && {
    top: '20%',
  }),
  ...(state.uploaded && !state.multipleUpload && {
    top: '20%',
    height: 0
  })
}))

const UploadFileText = styled(Typography)<{ state: { uploaded: boolean; multipleUpload?: boolean } }>(({ state }) => ({
  fontSize: 9,
  textAlign: 'center',
  zIndex: 5,
  visibility: 'hidden',
  position: 'absolute',
  top: '40%',
  width: '100%',
  pointerEvents: 'none',
  ...(state.uploaded && {
    top: '20%',
  }),
  ...(state.uploaded && !state.multipleUpload && {
    height: 0
  }),
}))

const UploadedFilesHolder = styled(List)<{
  state: { uploaded: boolean; uploadedFilesHeight?: number | string, hideUpload?: boolean }
}>(({ state }) => ({
  display: 'none',
  width: '100%',
  height: 200,
  overflowY: 'auto',
  ...(state.uploaded && {
    display: 'block',
  }),
  ...(state.uploadedFilesHeight && {
    height: state.uploadedFilesHeight,
  }),
  ...(!state.hideUpload && {
    marginTop: 10
  }),
}))

const UploadedFile = styled(Paper)(() => ({
  width: '100%',
  height: 60,
  margin: 'auto',
  padding: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}))

export interface FormFileUploadValidationResult {
  validated: boolean
  message?: string
}

export default function FormFileUpload<C extends FormFileUploadValidationResult>(props: {
  value?: FileModel[]
  onChange?: (...event: any[]) => void
  height?: number | string
  width?: number | string
  heightAfterUploaded?: number | string
  uploadedFilesHeight?: number | string
  disableUpload?: boolean
  disableDownload?: boolean
  disableRemove?: boolean
  hideUpload?: boolean
  hideDownload?: boolean
  hideRemove?: boolean
  acceptFiles?: string[]
  isLoading?: boolean
  multipleUpload?: boolean
  uploadChecker?: (event: any, file: FileModel) => Promise<C>
  requirePDFpageNumber?: boolean

  disabled?: boolean
  uneditable?: boolean
}) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [uploaded, setUploaded] = useState<boolean>(
    props.value && props.value.find((file: FileModel) => file.status) ? true : false,
  )
  const [draggedOver, setDraggedOver] = useState<boolean>(false)
  const [leftZone, setLeftZone] = useState<boolean>(false)
  const [files, setFiles] = useState<FileModel[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const uploadRef = useRef<HTMLInputElement | null>(null)

  // mounting
  const mounted = useRef<boolean>(false)
  useEffect(() => {
    if (!mounted.current && props.value) {
      setFiles(props.value)
      mounted.current = true
    }
  }, [props.value])

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleDrap = async (event: any) => {
    event.preventDefault()
    setIsLoading(true)

    if (
      props.disabled ||
      props.disableUpload ||
      props.uneditable ||
      (!props.multipleUpload && files.filter((f) => f.status).length > 0)
    ) {
      setIsLoading(false)
      return
    }

    const _files = [...files]
    if (event.dataTransfer.items) {
      const _items: DataTransferItem[] = [...event.dataTransfer.items]
      for (let i = 0; i < _items.length; i++) {
        if (_items[i].kind === 'file') {
          const _file = _items[i].getAsFile()
          if (_file) {
            try {
              const file = await FileToFileModel(_file)
              if (file) {
                if (props.uploadChecker) {
                  const result = await props.uploadChecker(event, file)
                  if (!result.validated) {
                    enqueueSnackbar(t(result.message ?? 'Invalid excel format'), { variant: 'error', autoHideDuration: null })
                    setIsLoading(false)
                    return
                  }
                }
                _files.push(file)
              }
            } catch (error ) {
              enqueueSnackbar("File upload error. Make sure file size cannot be greater than 60MB.", { variant: 'error', autoHideDuration: null })
              setIsLoading(false)
              return 
            }
            
          }
        }
      }
    } else {
      const __files: File[] = [...event.dataTransfer.files]
      for (let i = 0; i < __files.length; i++) {
        try {
          const file = await FileToFileModel(__files[i])
          if (file) {
            if (props.uploadChecker) {
              const result = await props.uploadChecker(event, file)
              if (!result.validated) {
                enqueueSnackbar(t(result.message ?? 'Invalid excel format'), { variant: 'error', autoHideDuration: null })
                setIsLoading(false)
                return
              }
            }
            _files.push(file)
          }
        } catch (error) {
          enqueueSnackbar("File upload error. Make sure file size cannot be greater than 60MB.", { variant: 'error', autoHideDuration: null })
          setIsLoading(false)
          return 
        }
      }
    }
    setFiles(_files)
    setUploaded(true)
    props.onChange!(_files)

    setIsLoading(false)
  }

  const handleUploadFileClick = async (event: any, acceptFiles?: string[]) => {
    setIsLoading(true)

    if (event.target.files) {
      const _files = [...files]
      const __files: File[] = [...event.target.files]
      for (let i = 0; i < __files.length; i++) {
        try {
          const file = await FileToFileModel(__files[i])
          if (file) {
            if (props.uploadChecker) {
              const result = await props.uploadChecker(event, file)
              if (!result.validated) {
                enqueueSnackbar(t(result.message ?? 'Invalid excel format'), { variant: 'error', autoHideDuration: null })
                setIsLoading(false)
                return
              }
            }
            if(acceptFiles !== undefined && acceptFiles.length>0) {
              var mimeDb = require("mime-db")
              var data = acceptFiles.map(x=> mimeDb[x].extensions).flat()
              console.log("acceptFiles: file",data)
              if(!data.includes(file.ext)){
                enqueueSnackbar(t('Invalid format'), { variant: 'error', autoHideDuration: null })
                setIsLoading(false)
                return 
              }
            }
            _files.push(file)
          }
        } catch (error) {
          enqueueSnackbar("File upload error. Make sure file size cannot be greater than 60MB.", { variant: 'error', autoHideDuration: null })
          setIsLoading(false)
          return 
        }
      }
      event.target.value = ''

      setFiles(_files)
      setUploaded(true)
      props.onChange!(_files)
    }

    setIsLoading(false)
  }

  const handleAddFileClick = () => {
    if (uploadRef && uploadRef.current) {
      uploadRef.current.click()
    }
  }

  const handleDragOver = (event: any) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: any) => {
    event.preventDefault()
    setDraggedOver(true)
    setLeftZone(false)
  }

  const handleDragExit = (event: any) => {
    event.preventDefault()
    if (leftZone) {
      setDraggedOver(false)
    } else {
      setLeftZone(true)
    }
  }

  const handleDownloadFile = async (index: number) => {
    setIsLoading(true)
    
    const fileBytes = files[index].bytes
    try {
      if (fileBytes) {
        const download = document.createElement('a')
        download.href = window.URL.createObjectURL(new Blob([fileBytes]))
        download.setAttribute('download', `${files[index].fileName}.${files[index].ext}`)
        document.body.appendChild(download)
        download.click()
        document.body.removeChild(download)
      } else if (files[index].fileUrl && files[index].fileUrl !== '') {
        const blob = await GetFileFromUrl(files[index])
        if (blob) {
          const download = document.createElement('a')
          download.href = URL.createObjectURL(blob)
          console.log(URL.createObjectURL(blob))
          download.setAttribute('download', `${files[index].fileName}.${files[index].ext}`)
          document.body.appendChild(download)
          download.click()
          document.body.removeChild(download)
        }
      } 
    } catch (err) {
      enqueueSnackbar(t('Error getting file'), { variant: 'error', autoHideDuration: null })
    } finally {
      setIsLoading(false)
    }
  }

  const handleRemoveFile = (index: number) => {
    setIsLoading(true)

    const _files = [...files]
    //     fileId => status = false
    // otherize => remove record
    if (_files[index].fileId) {
      _files[index].status = false
    } else {
      _files.splice(index, 1)
    }
    setFiles(_files)
    props.onChange!(_files)
    const found = _files.find((file: FileModel) => file.status)
    if (!found) {
      setUploaded(false)
    }

    setIsLoading(false)
  }

  return (
    <FormImageUplaodContainer
      state={{
        uploaded,
        draggedOver,
        height: props.height,
        width: props.width,
        disabled: props.disabled,
        uneditable: props.uneditable,
        disableUpload:
          props.disableUpload ||
          (!props.multipleUpload && files.filter((f) => f.status).length > 0),
        hideUpload: props.hideUpload,
        isLoading: props.isLoading || isLoading,
      }}
      onDrop={handleDrap}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragExit}>
        {
          props.hideUpload? (
            <></>
          ) : (
            <>
              <UploadFileIconHolder state={{ uploaded: uploaded, multipleUpload: props.multipleUpload }}>
                <CircularProgress
                  sx={{
                    display: props.isLoading || isLoading ? 'inline-block' : 'none',
                  }}
                />
                <UploadFileIcon
                  id="file-uploader-add-icon"
                  sx={{
                    height: 40,
                    width: 40,
                    zIndex: 5,
                    color:
                      props.disabled ||
                      props.disableUpload ||
                      props.uneditable ||
                      (!props.multipleUpload && files.filter((f) => f.status).length > 0)
                        ? '#c9c9c9'
                        : '#000000',
                    display: props.isLoading || isLoading || (uploaded && !props.multipleUpload )? 'none' : 'inline-block',
                  }}
                />
              </UploadFileIconHolder>
              <UploadFileText id="file-uploader-add-text" state={{ uploaded: uploaded, multipleUpload: props.multipleUpload }}>
                {t('Drag & Drop or Browse your files')}
              </UploadFileText>
              <FileUploaderContainer
                id="file-uploader-container"
                state={{ uploaded, heightAfterUploaded: props.heightAfterUploaded }}
                onClick={handleAddFileClick}>
                <input
                  type="file"
                  multiple={props.multipleUpload}
                  ref={uploadRef}
                  style={{ display: 'none' }}
                  onChange={(e)=>handleUploadFileClick(e, props.acceptFiles)}
                  {...{
                    accept: props.acceptFiles
                      ?.reduce((acc: string, cur: string) => (acc += cur + ','), '')
                      ?.slice(0, -1),
                  }}
                />
                <UploaderBox />
              </FileUploaderContainer>
            </>
          )
        }
      <UploadedFilesHolder state={{ uploaded, uploadedFilesHeight: props.uploadedFilesHeight, hideUpload: props.hideUpload }}>
        {files.map((file: FileModel, index: number) => {
          if (file.status) {
            return (
              <ListItem key={`uploaded-file-${index}-${file.fileId}`}>
                <UploadedFile>
                  <ListItemIcon>
                    <FileIcon text={file.ext} />
                  </ListItemIcon>
                  <ListItemText sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      {`${file.fileName}.${file.ext}`}
                    </Typography>
                      <Typography
                        sx={{
                          fontSize: 13,
                          color: '#858383',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}>
                        {Math.floor(file.size / 1024) + t('Kb')} 
                        {props.requirePDFpageNumber && file.ext === 'pdf' && !Number.isNaN(file.pdfPageNum) && ` - ${file.pdfPageNum} pages`}
                      </Typography>
                  </ListItemText>
                  {
                    props.hideDownload ? (<></>) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <IconButton
                          sx={{
                            height: 40,
                            width: 40,
                          }}
                          onClick={() => {
                            handleDownloadFile(index)
                          }}
                          disabled={props.disableDownload || props.uneditable}>
                          <DownloadIcon />
                        </IconButton>
                      </Box>
                    )
                  }
                  {
                    props.hideRemove ? (<></>) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                        <IconButton
                          sx={{
                            height: 40,
                            width: 40,
                          }}
                          onClick={() => {
                            handleRemoveFile(index)
                          }}
                          disabled={props.disabled || props.disableRemove || props.uneditable}>
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )
                  }
                </UploadedFile>
              </ListItem>
            )
          } else {
            return <Fragment key={`uploaded-file-${index}`} />
          }
        })}
      </UploadedFilesHolder>
    </FormImageUplaodContainer>
  )
}

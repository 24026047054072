import ConvertorsService from '@services/convertors.service';
import createReport from 'docx-templates';
import moment, { defaultFormat } from 'moment';
import { GetFormStatusId } from './getFormStatusId';
import { chunk, filter, find, findLast, first, get, last, map, reduce, size, upperCase } from 'lodash';
import { FormStatusEnum } from '@services/model/form/form.model'
import { 
  templateMapping
} from '@utils/mmValue'
import { GetBase64FromUrl, GetImageDimensions } from './image';

const postTemplateData = (templatePath: string, data: any, data2: any, optionList?: any) => {
  const  { 
    globalState, dnGeneralOptions, nfGeneralOptions, neaGeneralOptions,
    /* For SD */
    sdGeneralOptions, resourceType = [], constructionPlant = [], wipType = [], workSCompletionList = [], essentialOperationList = [], inSituTestList = [], inSituSampleList = [], sitePhotoCategory = [],
    /* For NEA */
    eaCategoryList = [], roadType = []
    // getOptionsByKey('Form EA - Category')
  } = optionList
  switch(templatePath){
    case '/templates/NF_ver2.docx':
    case '/templates/NF_ver2_draft.docx':
      let formDn  = data['formDn']?.filter(dn => dn.baseForm.formStatusId === 64)?.map((x, index) => {
        return {
          itemNo: index + 1,
          dnNo: x.dnNo,
          typeOfNonCompliance: dnGeneralOptions?.typeOfNonComplianceList?.find(dn => dn.id === x.nonComplianceId)?.typeOfNonCompliance,
          amountOfDeduction: dnGeneralOptions?.typeOfNonComplianceList?.find(dn => dn.id === x.nonComplianceId)?.amountOfDeduction,
          ncPeriod: moment(x.startAt).format(moment.defaultFormat).toString() + " - " + moment(x.endAt).format(moment.defaultFormat).toString()
        }
      })

      let partC_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_DRAFT"])?.includes(x.formStatusId))
      let partC2_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_REVIEW"])?.includes(x.formStatusId))
      let partD_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_RECTIFICATION", "FORM_NF_RECTIFICATION_REJECTED"])?.includes(x.formStatusId))
      let partE_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_REVIEW_RECTIFICATION"])?.includes(x.formStatusId))

      return {
        contractor: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractor ?? "",
        contractTitle: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractTitle ?? "",
        NF_no: data['nfNo'] ?? "",
        contract_no: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractNo ?? "",
        EI_no: data['eiNo'] ?? "N/A",
        ei_date_time: data['dateOfCheck'] === undefined ? "" : moment(data['dateOfCheck']).format(moment.defaultFormat).toString() ?? "",   //*** */
        locationAddress: data['location']['locationAddress']  ?? "",
        locationRemarks: data['locationRemark'] ?? "",
        ei_emergency_no: data2?(data2['emergencySerialNo'] ?? ""):"N/A",
        ei_icc: data2?(data2['iccNo'] ?? ""):"N/A",
        works_order_no: data['baseForm']['workOrderNo'] ?? '' ?? "",

        ei_works_order_no: data2?(data2['baseForm']['workOrderNo'] ?? '' ?? ""):"",

        complaint_checkbox: data2?(data2['caseSourceId'] === 33 ? '☑': '☐'):'☐',
        works_order_checkbox: data2?(data2['caseSourceId'] === 34 ? '☑': '☐'):'☐',
        staff_checkbox: data2?(data2['caseSourceId'] === 35 ? '☑': '☐'):'☐',
        others_checkbox: data2?(data2['caseSourceId'] === 36 ? '☑': '☐'):'☐',

        defect_category: nfGeneralOptions?.defectCategory?.find((x) => x.id === data['defectCategoryId'])?.name ?? "",
        identified_defect: nfGeneralOptions?.defectCategory?.find((category) => data['defectCategoryId'] === category.id)?.defectOptions?.find((x) => x.id === data['defectOptionId'])?.description ?? '' ?? "",
        date_of_check: data['dateOfCheck'] === undefined ? "" : moment(data['dateOfCheck']).format(moment.defaultFormat).toString() ?? "",
        time_limit: nfGeneralOptions?.defectCategory?.find((category) => data['defectCategoryId'] === category.id)?.defectOptions?.find((option) => data['defectOptionId'] === option.id)?.timeLimits?.find((x) => x.id === data['timeLimitId'])?.timeLimit ?? '' ?? "",
        nf_partA_remarks: data['remarks'] ?? "",
        ei_otherInfo: data2?(data2['otherInfo'] ?? ""):"N/A",
        due_date: data['dueDate'] === undefined ? "" :  moment(data['dueDate']).format(moment.defaultFormat).toString() ?? "",
        
        partC_full_name: globalState.userMetaFullList?.find((x) => x.userId === partC_action?.actionBy)?.userName ?? "",
        partC_designation: globalState.userMetaFullList?.find((x) => x.userId === partC_action?.actionBy)?.position ?? "",
        partC_datetime: partC_action === undefined ? "" :  moment(partC_action?.actionAt).format(moment.defaultFormat).toString() ?? "",

        partC2_full_name: globalState.userMetaFullList?.find((x) => x.userId === partC2_action?.actionBy)?.userName ?? "",
        partC2_designation: globalState.userMetaFullList?.find((x) => x.userId === partC2_action?.actionBy)?.position ?? "",
        partC2_datetime: partC2_action === undefined ? "" :   moment(partC2_action?.actionAt).format(moment.defaultFormat).toString() ?? "",
        rectification_date: data['rectifications']?.at(-1)?.rectificationDate === undefined ? "" : moment(data['rectifications']?.at(-1)?.rectificationDate).format(moment.defaultFormat).toString() ?? "",
       
        partD_remarks: data['rectifications']?.at(-1)?.otherInfo ?? "",
        partD_full_name: globalState.userMetaFullList?.find((x) => x.userId === partD_action?.actionBy)?.userName ?? "",
        partD_designation: globalState.userMetaFullList?.findLast((x) => x.userId === partD_action?.actionBy)?.position ?? "",
        partD_datetime: partD_action === undefined ? "" : moment(partD_action?.actionAt).format(moment.defaultFormat).toString() ?? "",
        
        partE_full_name: globalState.userMetaFullList?.find((x) => x.userId === partE_action?.actionBy)?.userName ?? "",
        partE_designation: globalState.userMetaFullList?.findLast((x) => x.userId === partE_action?.actionBy)?.position ?? "",
        partE_datetime: partE_action === undefined ? "" :  moment(partE_action?.actionAt).format(moment.defaultFormat).toString() ?? "" ,
        defect_photo: data['images'] === null ? [] : data['images'] ?? [],
        rectification_photo : data['rectifications']?.at(-1)?.['rectificationImages'] ?? [],
        formDn: formDn ?? [],
        defect_map: data["locationMapBase64"] ?? "",
        site_condition_photo: data2?(data2['siteImages'] === null ? [] : data2['siteImages'] ?? []):[]
        //ei_map: data2?(data2["locationMapBase64"] === null ? "":data2["locationMapBase64"]):"",

      }
    case '/templates/DN_template.docx':
      let tempTotalProposedDeductionAmount = 0;
      const draftStage = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_DRAFT])?.includes(formStatusId) })
      const issuedStage = findLast(get(data, 'parentForm.baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DNA_IOW_REVIEW])?.includes(formStatusId) })
      const reviewStage = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_REVIEW])?.includes(formStatusId) })
      const reviewIssuedStage = findLast(get(data, 'parentForm.baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DNA_ECTO_REVIEW])?.includes(formStatusId) });
      // Draft might be empty if there is no Draft stage, added special handling below
      const draft = draftStage ? draftStage : issuedStage;
      const review = reviewStage ? reviewStage : reviewIssuedStage;
      // Extract data from issued if draft stage does not exist
      const draftBy_draft = get(find(globalState.userMetaFullList, { 'userId': get(draft, 'actionBy')}) , "userName",  "")
      const draftBy_issued = get(find(globalState.userMetaFullList, { 'userId': get(draft, 'createdBy')}) , "userName",  "")
      const draft_post_draft = get(find(globalState.userMetaFullList, { 'userId': get(draft, 'actionBy')}), "position", "")
      const draft_post_issued = get(find(globalState.userMetaFullList, { 'userId': get(draft, 'createdBy')}), "position", "")
      const draft_datetime_draft = get(draft, 'actionAt') ? moment(get(draft, 'actionAt')).format(moment.defaultFormat).toString() ?? "" : ""
      const draft_datetime_issued = get(draft, 'createdAt') ? moment(get(draft, 'createdAt')).format(moment.defaultFormat).toString() ?? "" : ""
      // Ends
      const appealed = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_APPEALED])?.includes(formStatusId) })
      const rectitication = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_NF_RECTIFICATION])?.includes(formStatusId) })       
      const res =  {
        checking_datetime: get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', '')) ? moment(get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', ''))).format(moment.defaultFormat).toString() ?? "" : "",
        contract_no: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractNo', ''),
        contract_title: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractTitle', ''),
        contractor: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractor', ''),
        deduction_amount: get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': get( data, 'nonComplianceId', '')}), 'amountOfDeduction'),
        draftBy: draftBy_draft ? draftBy_draft : draftBy_issued,
        draft_datetime: draft_datetime_draft ? draft_datetime_draft : draft_datetime_issued,
        draft_post: draft_post_draft ? draft_post_draft : draft_post_issued,
        lastDnDraftDatetime: get(draft, 'updatedAt') ? moment(get(draft, 'updatedAt')).format(moment.defaultFormat).toString() ?? "" : "",
        NF_no: get(data, 'parentNf.nfNo', ''),
        nc_startAt: get(data, 'startAt') ? moment(get(appealed, 'startAt')).format(moment.defaultFormat).toString() ?? "" : "",
        rectification_datetime: get(rectitication, 'actionAt') ? moment(get(rectitication, 'actionAt')).format(moment.defaultFormat).toString() ?? "" : "",
        reference: '',
        review_datetime: get(review, 'actionAt') ? moment(get(review, 'actionAt')).format(moment.defaultFormat).toString() ?? "" : "",
        review_name: get(review, 'actionByUserName', ''),
        review_post: get(find(globalState.userMetaFullList, { 'userId': get(review, 'actionBy')}), "position", ""),
        review_signature: get(review, 'actionSignatureBase64', ''),
        road: get(data, 'parentNf.roadName', get(data, 'parentDna.roadName', '')),
        sor_item: get(data, 'sorItem', ''),
        works_order_no: get(data, 'baseForm.workOrderNo', ''),
        formDn: filter(get(data, 'parentForm.formDn'), (dn) => { 
          return map(get(dn, 'baseForm.formStatusHistories'), 'formStatusId').includes(first(GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_ISSUED])))}).map(dn => {
            const amountOfDeduction = get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'amountOfDeduction') ? get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'amountOfDeduction') : 0;
            let status = get(dn, 'baseForm.formStatus')
            let appealStatusResult = "";
            // Checking for display contractor's appeal or not
            let appealDisplayStatus = true;
            if (status === FormStatusEnum.FORM_DN_DRAFT || status === FormStatusEnum.FORM_DN_REVIEW || status === FormStatusEnum.FORM_DN_ISSUED || status === FormStatusEnum.FORM_DN_ISSUE_REJECTED) {
              appealDisplayStatus = false;
            }
            // ends
            // Checking for display Results of appeal or not
            let approveDisplayStatus = false;
            if (status === FormStatusEnum.FORM_DN_APPEAL_SUCCESS || status === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED) {
              approveDisplayStatus = true;
            }
            // ends
            if (status === FormStatusEnum.FORM_DN_APPEAL_SUCCESS) {
              appealStatusResult =  "Success"
            } else if (status === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED) {
              appealStatusResult =  "Rejected"
            } else {
              appealStatusResult = "-"
            }
            const approved = findLast(get(dn, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_APPEAL_SUCCESS])?.includes(formStatusId)})
            const refundableAmount = get(dn, 'baseForm.formStatus') === FormStatusEnum.FORM_DN_APPEAL_SUCCESS ? get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'refundAmount') : 0;
            const typeOfNonCompliance = get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'typeOfNonCompliance')
            const proposedDeductionAmount = amountOfDeduction - refundableAmount
            tempTotalProposedDeductionAmount += proposedDeductionAmount;
            return {
              amountOfDeduction: amountOfDeduction ? amountOfDeduction : "-",
              appealedBy: get(dn, 'baseForm.createdByUserName') && appealDisplayStatus ? get(dn, 'baseForm.createdByUserName') : "-",
              appealed_datetime: get(dn, 'baseForm.createdAt') && appealDisplayStatus ? moment(get(dn, 'baseForm.createdAt')).format(moment.defaultFormat).toString() : "-",
              appealed_post: get(dn, 'baseForm.createdBy') && appealDisplayStatus ? get(find(globalState.userMetaFullList, { 'userId': get(dn, 'baseForm.createdBy')}), "position", "") : "-",
              appealStatus: appealStatusResult,
              approvedBy: get(approved, 'createdByUserName') && approveDisplayStatus ? get(approved, 'createdByUserName') : "-",
              approved_post: findLast(get(approved, 'createdByUserRole')) && approveDisplayStatus ? get(find(globalState.userMetaFullList, { 'userId': get(approved, 'createdBy')}), 'position', "") : "-",
              dnNo: get(dn, 'dnNo'),
              endAt: get(dn, 'endAt') ? moment(get(dn, 'endAt')).format(moment.defaultFormat).toString() : "-",
              proposedDeductionAmount: proposedDeductionAmount ? proposedDeductionAmount : "-",
              refundableAmount: refundableAmount && approveDisplayStatus ? refundableAmount : "-",
              startAt: get(dn, 'startAt') ? moment(get(dn, 'startAt')).format(moment.defaultFormat).toString() : "-",
              typeOfNonCompliance: typeOfNonCompliance ? typeOfNonCompliance : "-"
            }
        }),
        totalProposedDeductionAmount: tempTotalProposedDeductionAmount ? tempTotalProposedDeductionAmount : 0
      }
      console.log("res", res)
      return res
    case '/templates/DN_template_old.docx':
      let tempTotalProposedDeductionAmountOld = 0
      const draftStageOld = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_DRAFT])?.includes(formStatusId) })
      const issuedStageOld = findLast(get(data, 'parentForm.baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DNA_IOW_REVIEW])?.includes(formStatusId) })
      const reviewStageOld = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_REVIEW])?.includes(formStatusId) })
      const reviewIssuedStageOld = findLast(get(data, 'parentForm.baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DNA_ECTO_REVIEW])?.includes(formStatusId) });
      // Draft might be empty if there is no Draft stage, added special handling below
      const draftOld = draftStageOld ? draftStageOld : issuedStageOld;
      const reviewOld = reviewStageOld ? reviewStageOld : reviewIssuedStageOld;
      // Extract data from issued if draft stage does not exist
      const draftBy_draftOld = get(find(globalState.userMetaFullList, { 'userId': get(draftOld, 'actionBy')}) , "userName",  "")
      const draftBy_issuedOld = get(find(globalState.userMetaFullList, { 'userId': get(draftOld, 'createdBy')}) , "userName",  "")
      const draft_post_draftOld = get(find(globalState.userMetaFullList, { 'userId': get(draftOld, 'actionBy')}), "position", "")
      const draft_post_issuedOld = get(find(globalState.userMetaFullList, { 'userId': get(draftOld, 'createdBy')}), "position", "")
      const draft_datetime_draftOld = get(draftOld, 'actionAt') ? moment(get(draftOld, 'actionAt')).format(moment.defaultFormat).toString() ?? "" : ""
      const draft_datetime_issuedOld = get(draftOld, 'createdAt') ? moment(get(draftOld, 'createdAt')).format(moment.defaultFormat).toString() ?? "" : ""
      // Ends
      const appealedOld = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_APPEALED])?.includes(formStatusId) })
      const rectiticationOld = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_NF_RECTIFICATION])?.includes(formStatusId) })       
      const resOld =  {
        checking_datetime: get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', '')) ? moment(get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', ''))).format(moment.defaultFormat).toString() ?? "" : "",
        contract_no: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractNo', ''),
        contract_title: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractTitle', ''),
        contractor: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractor', ''),
        deduction_amount: get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': get( data, 'nonComplianceId', '')}), 'amountOfDeduction'),
        draftBy: draftBy_draftOld ? draftBy_draftOld : draftBy_issuedOld,
        draft_datetime: draft_datetime_draftOld ? draft_datetime_draftOld : draft_datetime_issuedOld,
        draft_post: draft_post_draftOld ? draft_post_draftOld : draft_post_issuedOld,
        lastDnDraftDatetime: get(draftOld, 'updatedAt') ? moment(get(draftOld, 'updatedAt')).format(moment.defaultFormat).toString() ?? "" : "",
        NF_no: get(data, 'parentNf.nfNo', ''),
        nc_startAt: get(data, 'startAt') ? moment(get(appealedOld, 'startAt')).format(moment.defaultFormat).toString() ?? "" : "",
        rectification_datetime: get(rectiticationOld, 'actionAt') ? moment(get(rectiticationOld, 'actionAt')).format(moment.defaultFormat).toString() ?? "" : "",
        reference: '',
        review_datetime: get(reviewOld, 'actionAt') ? moment(get(reviewOld, 'actionAt')).format(moment.defaultFormat).toString() ?? "" : "",
        review_name: get(reviewOld, 'actionByUserName', ''),
        review_post: get(find(globalState.userMetaFullList, { 'userId': get(reviewOld, 'actionBy')}), "position", ""),
        review_signature: get(reviewOld, 'actionSignatureBase64', ''),
        road: get(data, 'parentNf.roadName', get(data, 'parentDna.roadName', '')),
        sor_item: get(data, 'sorItem', ''),
        works_order_no: get(data, 'baseForm.workOrderNo', ''),
        formDn: filter(get(data, 'parentForm.formDn'), (dn) => {
          return map(get(dn, 'baseForm.formStatusHistories'), 'formStatusId').includes(first(GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_ISSUED])))}).map(dn => {
            const amountOfDeduction = get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'amountOfDeduction') ? get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'amountOfDeduction') : 0;
            let status = get(dn, 'baseForm.formStatus')
            let appealStatusResult = "";
            // Checking for display contractor's appeal or not
            let appealDisplayStatus = true;
            if (status === FormStatusEnum.FORM_DN_DRAFT || status === FormStatusEnum.FORM_DN_REVIEW || status === FormStatusEnum.FORM_DN_ISSUED || status === FormStatusEnum.FORM_DN_ISSUE_REJECTED) {
              appealDisplayStatus = false;
            }
            // ends
            // Checking for display Results of appeal or not
            let approveDisplayStatus = false;
            if (status === FormStatusEnum.FORM_DN_APPEAL_SUCCESS || status === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED) {
              approveDisplayStatus = true;
            }
            // ends
            if (status === FormStatusEnum.FORM_DN_APPEAL_SUCCESS) {
              appealStatusResult =  "Success"
            } else if (status === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED) {
              appealStatusResult =  "Rejected"
            } else {
              appealStatusResult = "-"
            }
            const approved = findLast(get(dn, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_APPEAL_SUCCESS])?.includes(formStatusId)})
            const refundableAmount = get(dn, 'baseForm.formStatus') === FormStatusEnum.FORM_DN_APPEAL_SUCCESS ? get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'refundAmount') : 0;
            const typeOfNonCompliance = get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'typeOfNonCompliance')
            const proposedDeductionAmount = amountOfDeduction - refundableAmount
            tempTotalProposedDeductionAmountOld += proposedDeductionAmount;
            return {
              amountOfDeduction: amountOfDeduction ? amountOfDeduction : "-",
              appealedBy: get(dn, 'baseForm.createdByUserName') && appealDisplayStatus ? get(dn, 'baseForm.createdByUserName') : "-",
              appealed_datetime: get(dn, 'baseForm.createdAt') && appealDisplayStatus ? moment(get(dn, 'baseForm.createdAt')).format(moment.defaultFormat).toString() : "-",
              appealed_post: get(dn, 'baseForm.createdBy') && appealDisplayStatus ? get(find(globalState.userMetaFullList, { 'userId': get(dn, 'baseForm.createdBy')}), "position", "") : "-",
              appealStatus: appealStatusResult,
              approvedBy: get(approved, 'createdByUserName') && approveDisplayStatus ? get(approved, 'createdByUserName') : "-",
              approved_post: findLast(get(approved, 'createdByUserRole')) && approveDisplayStatus ? get(find(globalState.userMetaFullList, { 'userId': get(approved, 'createdBy')}), 'position', "-") : "-",
              dnNo: get(dn, 'dnNo'),
              endAt: get(dn, 'endAt') ? moment(get(dn, 'endAt')).format(moment.defaultFormat).toString() : "-",
              proposedDeductionAmount: proposedDeductionAmount ? proposedDeductionAmount : "-",
              refundableAmount: refundableAmount && approveDisplayStatus ? refundableAmount : "-",
              startAt: get(dn, 'startAt') ? moment(get(dn, 'startAt')).format(moment.defaultFormat).toString() : "-",
              typeOfNonCompliance: typeOfNonCompliance ? typeOfNonCompliance : "-"
            }
        }),
        totalProposedDeductionAmount: tempTotalProposedDeductionAmountOld ? tempTotalProposedDeductionAmountOld : 0
      }
      console.log("resOld", resOld)
      return resOld
    case '/templates/SD_template.docx':
      const { tradeList = [], idleCodeList = []} = sdGeneralOptions

      const sdDraft = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SD_DRAFT])?.includes(formStatusId) })       
      const sdReview = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SD_REVIEW])?.includes(formStatusId) })       
      const sdApproved = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SD_APPROVED])?.includes(formStatusId) })       
      const sdContractorReview = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SD_CONTRACTOR_REVIEW])?.includes(formStatusId) })       

      return {
        contractor: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractor ?? "",
        contractTitle: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractTitle ?? "",
        contract_no: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractNo ?? "",
        works_order_no: data['baseForm']['workOrderNo'] ?? '' ?? "",
        sd_no: get(data, 'sdNo', ''),
        dayNightWorks: get(data, 'formSdCrw.dayNightWorks', ''),
        siteProgressForLabour: get(data, 'siteProgressForLabour', []).map(({ tradeId, resourceTypeId, ...rest}) => ({
          ...rest,
          trade: get(find(tradeList, {id: tradeId}), 'trade', ''),
          resourceType: get(find(resourceType, { 'key': resourceTypeId}), 'value', '')
        })),
        siteProgressForPlant: get(data, 'siteProgressForPlant', []).map(({ idleCodeId, noOfPlant, noOfIdlePlant, resourceTypeId, ...rest}) => ({
          ...rest,
          idleCode: get(find(idleCodeList, {id: idleCodeId}), 'name', ''),
          noOfTotalPlant: noOfIdlePlant + noOfPlant,
          noOfPlant: noOfPlant,
          noOfIdlePlant: noOfIdlePlant,
          resourceType: get(find(constructionPlant, { 'key': resourceTypeId }), 'value', ''),
        })),
        formDate: data['formDate'] === undefined ? "" : moment(data['formDate']).format(moment.defaultFormat).toString() ?? "",   
        remarks: get(data, 'remarks', ''),
        instructionToContractor: get(data, 'instructionToContractor', ''),
        contactorRepesentativeComment: get(data, 'contactorRepesentativeComment', ''),
        actualActivities: get(find(wipType, {'key': get(data, 'wipTypeSubOptionId', 0)}), 'value', ''),
        workCompletion: get(find(workSCompletionList, {'key': get(data, 'workCompletionId')}), 'value', ''),
        essentialOperation: get(find(essentialOperationList, {'key': get(data, 'essentialOperation')}), 'value', ''),
        inSituTest: get(find(inSituTestList, {'key': get(data, 'inSituTest')}), 'value', ''),
        inSituSample: get(find(inSituSampleList, {'key': get(data, 'inSituSample')}), 'value', ''),
        hiddenWorkCheck: get(data, 'hiddenWorkCheck', '') ? 'Yes' : "No",
        hiddenWorkRefs: get(data, 'hiddenWorkRefs', []),
        sdPhotos: get(data, 'sdPhotos', []).map(({ photoCategoryId, ...rest }) => ({
          ...rest,
          photoCategory:  get(find(sitePhotoCategory, {'key': photoCategoryId}), 'value', ''),
        })),
        draftBy: globalState.userMetaFullList?.find((x) => x.userId === sdDraft?.actionBy)?.userName ?? "",
        draftByPost: globalState.userMetaFullList?.find((x) => x.userId === sdDraft?.actionBy)?.position ?? "",
        reviewBy: globalState.userMetaFullList?.find((x) => x.userId === sdReview?.actionBy)?.userName ?? "",
        reviewByPost: globalState.userMetaFullList?.find((x) => x.userId === sdReview?.actionBy)?.position ?? "",
        approvedBy: globalState.userMetaFullList?.find((x) => x.userId === sdApproved?.actionBy)?.userName ?? "",
        approvedByPost: globalState.userMetaFullList?.find((x) => x.userId === sdApproved?.actionBy)?.position ?? "",
        contractorReviewBy: globalState.userMetaFullList?.find((x) => x.userId === sdContractorReview?.actionBy)?.userName ?? "",
        contractorReviewByPost: globalState.userMetaFullList?.find((x) => x.userId === sdContractorReview?.actionBy)?.position ?? "",
        summaryMapBase64: get(data, 'summaryMapBase64', '')
      }

    case '/templates/NEA_template.docx':
      const { auditingOfficer = [], checkingOfficer = []} = neaGeneralOptions
      const mmValue = get(data, 'typeOfMmWorksValue', '')

      let templateType = ''
      Object.keys(templateMapping).forEach((type) => {
        if(templateMapping[type].includes(mmValue)){
          templateType = type
        }
      })

      const neas = get(data, 'neaSampleResult', []).map(({ formEa = [], ...rest}) =>  {
        let remarks = ''
        switch(templateType){
          case "structuralMaintainence":
            remarks = `Road Name: 	${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            6-M.I./G.I./P.I. Report No:		${get(formEa, '0.neaAuditCode', '')}
            Structure number:	${get(formEa, '0.neaStructureNo', '')}
            Plan area of the structure: ${get(formEa, '0.planAreaOfStructure', '')} Plan Area of the structure m2
            `
            break
          case "tunnel":
            remarks = `Tunnel Name: ${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
              Sample selected:		${get(formEa, '0.neaAuditCode', '')}
              Total Tunnel Portions length Sampled:	${get(formEa, '0.neaLength', '')}m2
            `
            break
          case "detailedInspection":
            remarks = `Road Name: 	${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Road Type:	${get(find(roadType, { key: get(formEa, '0.neaRoadTypeId')}), 'value', '')}
            `
            break
          case "slope":
            remarks = `Road Name: 	${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            Slope No:		${get(formEa, '0.neaGeoRegistrationNo', '')}, ${get(formEa, '0.neaHydRegistrationNo', '')}
            RMI Report:	${get(formEa, '0.neaAuditCode', '')}
            `
            break
          case "stepping":
            remarks = `Road Name: ${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Total area checked:	${get(formEa, '0.neaShapeLength', '')}
            `
            break
          case "landscape":
            remarks = `Road Name: ${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Slope No:	${get(formEa, '0.neaGeoRegistrationNo', '')}	Vegetation ID:		${get(formEa, '0.neaVegetationId', '')}
            `
            break
          case "roadMarking":
          case "roadDrainage":
            remarks = `Road Name: ${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Road Type:	${get(find(roadType, { key: get(formEa, '0.neaRoadTypeId')}), 'value', '')} 
            `
            break
          case "routineInspection":
            remarks = `Road Name: ${get(data, 'baseForm.districtId', []).map(( district ) => {
              return get(find(globalState.districtList, { 'key': district }), 'value')
            }).join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Road Type:	${get(find(roadType, { key: get(formEa, '0.neaRoadTypeId')}), 'value', '')} 
            `
            break;
        }

        return {
          ...rest,
          locationReference: get(formEa, '0.neaAuditCode', ''),
          reportNo: get(formEa, '0.reportNo', ''),
          noOfDefect: get(formEa, '0.formDeaList', []).length,
          defective: get(formEa, '0.defective', false) ? 1 : 0,
          viableToConductInspection: get(formEa, '0.viableToConductInspection', undefined) === undefined ? '' : get(formEa, '0.viableToConductInspection', true) ? "Available" : 'Unavailable' ,
          remarks: remarks
        }
      })
  
      const ress =  {
        contractTitle: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractTitle', ''),
        contract_no: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractNo', ''),
        typeOfMmWorks:  get(find(eaCategoryList, { 'key': get(data, 'baseForm.typeOfMmWorks')}), 'value', ''),
        typeOfMmWorksValue: get(data, 'typeOfMmWorksValue', ''),
        neaNotificationNo: get(data, 'neaNotiticationNo', ''),
        workOrderNo: get(data, 'baseForm.workOrderNo', ''),
        monthYearOfPerformanceStandard: get(data, 'monthYearOfPerformanceStandard') ? moment(data?.monthYearOfPerformanceStandard).format('YYYY MMMM').toString() ?? "" : "",
        dateOfAudit: get(data, 'dateOfAudit') ? moment(get(data, 'dateOfAudit')).format('D MMMM YYYY') ?? '': '',
        auditingOfficer: get(find(auditingOfficer, {'id': get(data, 'auditingOfficer', '')}), 'name', ''),
        checkingOfficer: get(find(checkingOfficer, {'id': get(data, 'checkingOfficer', '')}), 'name', ''),
        neaSampleResult: neas,
        noOfSelectedItem: size(filter(neas, { 'viableToConductInspection': "Available"})),
        noOfDefectiveSection: reduce(neas, (result, n) => {
          return get(n, 'defective', 0) + result
        }, 0),
      }
      console.log("ress", ress)
      return ress
    case '/templates/testing.docx':
      return {
        name: 'name' ,
        surname: 'surname',
        checkbox: data['eiNo'] === "N/A" ? '☑': '☐',
        items: data['images']
      }
  }
}


const additionalJsContext = {
  urlToImage: async (url: string, w?: number, h?: number) => {
    try {
      let svg_data = await GetBase64FromUrl(url)
      if (svg_data !== null || svg_data !== undefined) {
        svg_data = typeof svg_data === 'string' ? svg_data?.split(',')[1] : svg_data
      }
      let widthHeight = await GetImageDimensions(url)
      let width = 15
      let height = (15 / widthHeight.width) * widthHeight.height
      
      if ( w ){
        width = w
        height = (w / widthHeight.width) * widthHeight.height
      } else if (h) {
        height = h
        width = (h / widthHeight.height) * widthHeight.width                  
      }
      
      return {
        width: width ?? 6,
        height: height ?? 6,
        data: svg_data,
        extension: '.jpeg',
      }
    } catch (err) {
      console.error('Error in exporting image in pdf.')
    }
  },
  base64ToImage: async (base64: string, w?: number, h?: number) => {
    try {
      let widthHeight = await GetImageDimensions(base64)
      let width = 15
      let height = (15 / widthHeight.width) * widthHeight.height

      if ( w ){
        width = w
        height = (w / widthHeight.width) * widthHeight.height
      } else if (h) {
        height = h
        width = (h / widthHeight.height) * widthHeight.width                  
      }

      return {
        width: width ?? 6,
        height: height ?? 6,
        data: base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        extension: '.jpeg',
      }
    } catch (err) {
      console.error('Error in exporting image in pdf.')
    }
  },
  signature: async (signatureBase64: string) => {
    try {
      return {
        width: 3,
        height: 0.7,
        data: signatureBase64,
        extension: '.jpeg',
      }
    } catch (e: any) {
      console.error('[SignatureBase64 Error]', signatureBase64, e)
    }
  },
}

const EaExporter = async (data: any, optionList?: any) => {
  try {
    const template = await getTemplate('/templates/EA template.docx')
    const { globalState, eaCategoryList = [], roadType = []} = optionList

    const mmValue = get(data, 'formNea.typeOfMmWorksValue', '')
    let templateType: string[] = []

    Object.keys(templateMapping).forEach((type) => {
      if(templateMapping[type].includes(mmValue)){
        templateType.push(type)
      }
    })

    console.info("[EA template export] - type used: ", templateType)
    console.info("globalState ", globalState)
    console.info("roadType ", roadType)
    for(let i = 0; i < templateType.length ; i++){
      const mmType = templateType[i]

      const reviewPrelimResult = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_EA_REVIEW_PRELIM_RESULT])?.includes(formStatusId) })       
      const endorsePrelimResult = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_EA_ENDORSE_PRELIM_RESULT])?.includes(formStatusId) })       
      const reviewAuditAppeal = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_EA_REVIEW_AUDIT_APPEAL])?.includes(formStatusId) })       
      const endorseAuditAppeal = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_EA_ENDORSE_AUDIT_APPEAL])?.includes(formStatusId) })       
      const auditResultIssued = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED])?.includes(formStatusId) })       
      
      const auditingOffier = globalState.userMetaFullList?.find((x) => x.userId === get(data, 'formNea.auditingOfficer'))
      const checkingOfficer = globalState.userMetaFullList?.find((x) => x.userId === get(data, 'formNea.checkingOfficer'))

      const res =  {
        ...data,
        mmType: mmType,
        mmTypeTitle: upperCase(mmValue),
        contractor: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractor', '') ,
        contract_title: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractTitle', '').replace('Highways Department Term Contract', ''),
        contract_no: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractNo', ''),
        works_order_no: get(data, 'baseForm.workOrderNo', ''),
        monthYearOfPerformanceStandard: get(data, 'formNea.monthYearOfPerformanceStandard') ? moment(get(data, 'formNea.monthYearOfPerformanceStandard')).format('YYYY MMMM').toString() ?? "" : "",
        auditingOfficer: `${auditingOffier?.userName ?? ""} (${auditingOffier?.position ?? ""})` ,
        checkingOfficer: `${checkingOfficer?.userName ?? ""} (${checkingOfficer?.position ?? ""})`,
        actualCheckingDate: get(data, 'actualCheckingDate') ? moment(get(data, 'actualCheckingDate')).format(moment.defaultFormat).toString() ?? ""  : "",
        defectiveNo: size(get(data, 'formDeaList', [])),
        defective: get(data, 'defective', false) ? 1 : 0,
        definition: eaCategoryList?.find((x) => x.typeOfMmWorks === get(data, 'formNea.typeOfMmWorks'))?.defectDefinition ?? '',
        otherInfo: get(data, 'otherInfo', ''),
        district:  get(data, 'baseForm.districtId', []).map(( district ) => {
          return get(find(globalState.districtList, { 'key': district }), 'value')
        }).join(','),
        neaStructureNo: get(data, 'neaStructureNo', ''),
        neaShapeLength: ['detailedInspection'].includes(mmType) ?  get(data, 'neaShapeArea', '') : get(data, 'neaShapeLength', ''),
        neaLocation: get(data, 'neaLocation', ''),
        neaAuditCode: get(data, 'neaAuditCode', ''),
        neaGeoRegistrationNo: get(data, 'neaGeoRegistrationNo', ''),
        neaHydRegistrationNo: get(data, 'neaHydRegistrationNo', ''),
        neaVegetationId: get(data, 'neaVegetationId', ''),
        neaSampleNo: get(data, 'neaSampleNo', ''),
        planAreaOfStructure: get(data, 'planAreaOfStructure', ''),
        roadType: get(find(roadType, { key: get(data, 'neaRoadTypeId')}), 'value'),
        viableToConductInspection: get(data, 'viableToConductInspection', '') ? 'Available': '-',

        reviewPrelimResultBy: get(find(globalState.userMetaFullList, { 'userId': get(reviewPrelimResult, 'actionBy')}) , "userName",  ""),
        reviewPrelimResultDatetime: get(reviewPrelimResult, 'actionAt') ? moment(get(reviewPrelimResult, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        reviewPrelimResultPost: get( find(globalState.userMetaFullList, {'userId': get(reviewPrelimResult, 'actionBy')}), "position", ""),
        
        endorsePrelimResultBy: get(find(globalState.userMetaFullList, { 'userId': get(endorsePrelimResult, 'actionBy')}) , "userName",  ""),
        endorsePrelimResultDatetime: get(endorsePrelimResult, 'actionAt') ? moment(get(endorsePrelimResult, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        endorsePrelimResultPost: get( find(globalState.userMetaFullList, {'userId': get(endorsePrelimResult, 'actionBy')}), "position", ""),
        
        reviewAuditAppealBy: get(find(globalState.userMetaFullList, { 'userId': get(reviewAuditAppeal, 'actionBy')}) , "userName",  ""),
        reviewAuditAppealDatetime: get(reviewAuditAppeal, 'actionAt') ? moment(get(reviewAuditAppeal, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",

        
        endorseAuditAppealBy: get(find(globalState.userMetaFullList, { 'userId': get(endorseAuditAppeal, 'actionBy')}) , "userName",  ""),
        endorseAuditAppealDatetime: get(endorseAuditAppeal, 'actionAt') ? moment(get(endorseAuditAppeal, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        
        auditResultIssuedBy: get(find(globalState.userMetaFullList, { 'userId': get(auditResultIssued, 'actionBy')}) , "userName",  ""),
        auditResultIssuedDatetime: get(auditResultIssued, 'actionAt') ? moment(get(auditResultIssued, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        auditResultIssuedPost: get( find(globalState.userMetaFullList, {'userId': get(auditResultIssued, 'actionBy')}), "position", ""),

        eaImages: chunk(get(data, 'eaImages', []), 2)?.map((image, index) => {
          return {
            index: index * 2 + 1,
            file: image,
          }
        }),

        formDeaList: get(data, 'formDeaList', []).map(({ images = [], defectsIdentified = 0, ...res }) => {
          const defect = find(globalState.generalOptionGroupList?.find((x) => x.name === 'Form EA - Category')?.generalOptions?.find((x) => x.id === get(data, 'formNea.typeOfMmWorks'))?.defectOptions, { 'id': defectsIdentified})
          return {
            ...res,
            defectsIdentified: `${get(defect, 'defectCode', '')} - ${get(defect, 'description', '')}`,
            images: images === null ? [] : images
          }
        }),

        auditLocationPlanImages: get(data, 'auditLocationPlanImages', []),
        eaAppealImages: get(data, 'eaAppealImages', []),
        appealInfo: get(data, 'appealInfo', ''),
        summaryMapBase64: get(data, 'summaryMapBase64', '')
      }
  

      const report = await createReport({
        // @ts-ignore
        template, 
        data: res,
        additionalJsContext: additionalJsContext
      });
  
      await saveDataToFile(
        report,
        `EA_${mmType}_${get(data, 'reportNo', 'EA')}.pdf`,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );
    }
    
  } catch (err) {
    console.error(err)
    return err
  }
}

const DocumentExporter = async (templatePath: string, reportName: String, data: any, data2: any, optionList?: any) => {
  const template = await getTemplate(templatePath); 
  try {
    const report = await createReport({
      // @ts-ignore
      template, 
      data: postTemplateData(templatePath, data,  data2, optionList),
      additionalJsContext: additionalJsContext
    });

    await saveDataToFile(
      report,
      reportName + '.pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  } catch (err) {
    console.error(err)
    return err
  }

  /* Below code is genreating pdf but in a broken format*/ 
  // let htmlFormat;
  // await mammoth.convertToHtml({ arrayBuffer: report.buffer })
  //   .then(result => htmlFormat = result.value)

  // htmlFormat = htmlFormat.replace("img", "img width='400px' height='auto' ")

  // let opt = {
  //   margin:       1,
  //   filename:     'myfile.pdf',
  //   image:        { type: 'jpeg', quality: 0.98 },
  //   html2canvas:  { scale: 2 },
  //   jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
  //   pagebreak:    { mode: ['avoid-all', 'css', 'legacy'], before: ['#page2el', 'img'] }
  // };
  // console.log(html2pdf().set(opt).from(htmlFormat).save())

};

async function getTemplate(dataPath): Promise<Blob> {
    const request = await fetch(dataPath);
    const defaultTemplate = await request.blob();
    return defaultTemplate;
  }

// Load the user-provided file into an ArrayBuffer
const readFileIntoArrayBuffer = (fd): (Promise<any>) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(fd);
  });

const saveDataToFile = async (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });

    const formData = new FormData();
    formData.append("file", blob);
    const pdfBlob = await ConvertorsService.ConvertFiles(formData)

    const url = window.URL.createObjectURL(pdfBlob);
    console.log(url)
    console.log(fileName)
    downloadURL(url, fileName, mimeType);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  };

const downloadURL = (data, fileName, mimeType) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

export { DocumentExporter, EaExporter }
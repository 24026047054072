import { useContext, useEffect, useState }                      from 'react'
import { useNavigate }                                          from 'react-router-dom'
import { useForm, useWatch }                                    from 'react-hook-form'
import { Bar }                                                  from 'react-chartjs-2'
import { ChartData, ChartDataset }                              from 'chart.js'
import { subDays }                                              from 'date-fns'
import { Box, Stack, styled, useTheme }                         from '@mui/system'
import { Button, Card, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import useAPIFetch                                              from '@hooks/useAPIFetch'
import { GlobalContext }                                        from '@providers/globalStore'
import FormController                                           from '@components/form/controller'
import FormMultipleSelect                                       from '@components/form/multipleSelect'
import FormDateTimePicker                                       from '@components/form/dateTimePicker'
import FormField                                                from '@components/form/field'
import LoadingPopup                                             from '@components/form/LoadingDialog'
import { formIconProps }                                        from '@components/form/overview/icon'
import FormIntroductionBox, { formBriefInfo }                   from '@components/form/overview/introductionBox'
import { RightsCategory }                                       from '@services/model/form/form.model'
import { HwrDashboardReq }                                      from '@services/model/form/form.HWR.model'
import FormHwrService                                           from '@services/formService/form.HWR.service'
import { getFormColor }                                         from '@utils/getFormColor'
import { NavigateTo }                                           from '@utils/navigate'
import HWRInfo                                                  from '../../../mockData/fetchData/formInfo/form.HWR.json'

const StyledCard = styled(Card)(({ theme }) => ({
    margin : theme.spacing(2),
    padding: theme.spacing(2)
}))

const Overview = ({ isDashboard }: { isDashboard?: boolean }) => {
    const theme                                                  = useTheme()
    const matchUpMd                                              = useMediaQuery(theme.breakpoints.up('md'))
    const { state: globalState, hasRightByCatAndCode, userInfo } = useContext(GlobalContext)

    const navigate                = useNavigate()
    const [formInfo, setFormInfo] = useState(HWRInfo)

    const { setRequest, isLoading } = useAPIFetch()

    const [firstSubmissionData, setFirstSubmissionData] = useState<ChartData<'bar', Array<number>, string>>()
    const [resubmissionData, setResubmissionData]       = useState<ChartData<'bar', Array<number>, string>>()

    const { control, getValues, setValue } = useForm<HwrDashboardReq>({
                                                                          defaultValues: {
                                                                              districtId: [],
                                                                              startDate : subDays(new Date(), 30),
                                                                              endDate   : new Date()
                                                                          }
                                                                      })

    const [districtId, startDate, endDate] = useWatch({
                                                          control: control,
                                                          name   : ['districtId', 'startDate', 'endDate']
                                                      })

    // form introduction
    const fIconProps: formIconProps = {
        shortName       : formInfo.shortName,
        shortNameBgColor: getFormColor(formInfo.shortName, formInfo.module),
        SvgSx           : { fontSize: '70px' }
    }

    const fInfo: formBriefInfo = {
        id                 : formInfo.id,
        workflowId         : formInfo.workflowId,
        name               : formInfo.name,
        description        : formInfo.description,
        workflowInvolvement: formInfo.workflowInvolvement,
        linkedLists        : formInfo.linkedLists
    }

    const fOnClickSideBtnHandler: Function = () => {
        NavigateTo(navigate, '/hidden-works-record')
    }

    const fetchChartData = () => {
        setRequest({
                       callback: async (cancelToken: any) => {
                           FormHwrService.GetHwrDashboard(getValues(), cancelToken)
                                         .then(async hwrDashboard => {
                                             const uniqueDatesFirstSubmission = [...new Set(hwrDashboard.firstSubmissionTime.map(item => item.month))]
                                             const firstSubmission            = [...new Set(hwrDashboard.firstSubmissionTime.map(item => item.submissionTime))]

                                             const colorArray = ['#0000FF', '#FFFF00', '#FF6633', '#FF0000']

                                                   const labelRender = (submissionTime: string) => {
                                                       switch (submissionTime) {
                                                           case '< 14':
                                                               return 'within 14 days after completion of works'
                                                           case '14 - 21':
                                                               return '14 - 21 days'
                                                           case '21 - 1m':
                                                               return '21 - 1 month'
                                                           case '> 1m':
                                                               return 'over 1 month'
                                                       }
                                                   }

                                                   const barDatasetsFirst = firstSubmission.map<ChartDataset<'bar', Array<number>>>(
                                                       (submissionTime, index) => ({
                                                           label          : labelRender(submissionTime),
                                                           data           : uniqueDatesFirstSubmission.map(month => {
                                                               let item = hwrDashboard.firstSubmissionTime.find(d => d.month === month && d.submissionTime === submissionTime)
                                                               return item ? item.total : 0 // Return total if the item exists, otherwise return 0
                                                           }),
                                                           backgroundColor: colorArray[index] // Get a color for each output
                                                       })
                                                   )
                                                   setFirstSubmissionData({
                                                                              labels  : uniqueDatesFirstSubmission,
                                                                              datasets: barDatasetsFirst
                                                                          })

                                                   const uniqueDatesResubmission = [...new Set(hwrDashboard.resubmissionTime.map(item => item.month))]
                                                   const resubmission            = [...new Set(hwrDashboard.resubmissionTime.map(item => item.submissionTime))]

                                                   const barDatasetsSecond = resubmission.map<ChartDataset<'bar', Array<number>>>(
                                                       (submissionTime, index) => ({
                                                           label          : labelRender(submissionTime),
                                                           data           : uniqueDatesResubmission.map(month => {
                                                               let item = hwrDashboard.resubmissionTime.find(d => d.month === month && d.submissionTime === submissionTime)
                                                               return item ? item.total : 0 // Return total if the item exists, otherwise return 0
                                                           }),
                                                           backgroundColor: colorArray[index] // Get a color for each output
                                                       })
                                                   )
                                                   setResubmissionData({
                                                                           labels  : uniqueDatesResubmission,
                                                                           datasets: barDatasetsSecond
                                                                       })
                                               }
                                         )
                       }
                   })
    }

    useEffect(() => {
        if (globalState.districtList)
            setValue('districtId',
                     userInfo.districts.filter(district =>
                                                   globalState.districtList?.map((district) => district.key)
                                                              .includes(district)))

        fetchChartData()
    }, [globalState.districtList])

    useEffect(() => {
        if (districtId.length > 0)
            fetchChartData()
    }, [districtId, startDate, endDate])

    return (
        <Box sx={ {
            padding      : matchUpMd ? '40px' : '10px',
            gap          : '12px',
            display      : 'flex',
            flexDirection: 'column'
        } }>
            <Grid container>
                {
                    !isDashboard &&
                    <Grid item md={ 12 } lg={ 4 }>
                        <StyledCard variant='outlined'>
                            <FormIntroductionBox formIconProps={ fIconProps }
                                                 formBriefInfo={ fInfo }
                                                 hasSideBtn={ true }
                                                 disableSideBtn={ !hasRightByCatAndCode(RightsCategory.FORM_HWR, ['C'], true) }
                                                 onClickSideBtnHandler={ fOnClickSideBtnHandler } />
                        </StyledCard>
                    </Grid>
                }

                <Grid item md={ 12 } lg={ isDashboard ? 12 : 8 }>
                    <Grid container sx={ { overflow: 'hidden', width: 'inherit' } }>
                        <FormField fieldName='Districts'
                                   additionalChildren={
                                       <Stack sx={ { flexDirection: matchUpMd ? 'row' : 'column' } }>
                                           <Button sx={ { minWidth: '10%' } }
                                                   onClick={ (event: any) => {
                                                       setValue('districtId',
                                                                globalState.districtList?.map(district => district.key) ?? [],
                                                                { shouldDirty: true }
                                                       )
                                                   } }>
                                               Select All
                                           </Button>
                                           <Button sx={ { minWidth: '10%' } }
                                                   onClick={ (event: any) => {
                                                       setValue('districtId',
                                                                [],
                                                                { shouldDirty: true })
                                                   } }>
                                               Unselect All
                                           </Button>
                                       </Stack>
                                   }>
                            <FormController controllerProps={ { control, name: 'districtId' } }>
                                <FormMultipleSelect options={
                                    globalState.districtList?.filter(({ key }) =>
                                                                         userInfo.districts.includes(key)) || []
                                } />
                            </FormController>
                        </FormField>

                        <Grid item>
                            <FormField fieldName='Start Date'>
                                <FormController controllerProps={ { control, name: 'startDate' } }>
                                    <FormDateTimePicker type='date' />
                                </FormController>
                            </FormField>
                            <FormField fieldName='End Date'>
                                <FormController controllerProps={ { control, name: 'endDate' } }>
                                    <FormDateTimePicker type='date' />
                                </FormController>
                            </FormField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <LoadingPopup open={ isLoading } />

            <Paper sx={ { padding: matchUpMd ? '24px' : '8px' } }>
                <Stack sx={ { flexDirection: matchUpMd ? 'row' : 'column' } }>
                    {
                        firstSubmissionData &&
                        <Box width={ matchUpMd ? '50%' : '100%' }>
                            <Typography align='center' variant='h6'>Time of First Submission of HWR</Typography>
                            <Bar data={ firstSubmissionData }
                                 options={ {
                                     scales: {
                                         x: { stacked: true },
                                         y: {
                                             stacked: true,
                                             min    : 0,
                                             ticks  : { stepSize: 1 }
                                         }
                                     }
                                 } }
                            />
                        </Box>
                    }

                    {
                        resubmissionData &&
                        <Box width={ matchUpMd ? '50%' : '100%' }>
                            <Typography align='center' variant='h6'>Time of Resubmission of HWR</Typography>
                            <Bar data={ resubmissionData }
                                 options={ {
                                     scales: {
                                         x: { stacked: true },
                                         y: {
                                             stacked: true,
                                             min    : 0,
                                             ticks  : { stepSize: 1 }
                                         }
                                     }
                                 } }
                            />
                        </Box>
                    }
                </Stack>
            </Paper>
        </Box>
    )
}

export default Overview
import {
  Box,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  useMediaQuery,
  IconButton,
  Button,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { formIconProps } from '@components/form/overview/icon'
import { getFormColor } from '@utils/getFormColor'
import FormIcon from '@components/FormIcon'
import { FormCountByUser } from '@services/model/form/form.model'
import { useTranslation } from 'react-i18next'
import { getFormDetails } from '@utils/getFormDetails'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import styles from '@styles/landing.module.scss'

const FormItemContainer = styled(Box)({
  borderRadius: '5px',
  marginTop: '15px',
})

const FormItemTitle = styled(Box)<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  minHeight: '29px',
  paddingLeft: '20px',
  paddingRight: '20px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: backgroundColor ?? '#fff',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  fontWeight: "bold"
}))

const FormItemContent = styled(Grid)({
  minHeight: '68px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
})


const LandingFormItem = ({
  formCode,
  totalCount
}: {
  formCode: string
  totalCount: number
}) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const navigate = useNavigate()

  const fIconProps: formIconProps = {
    shortName: formCode,
    shortNameBgColor: getFormColor(formCode, ''),
    SvgSx: {
      fontSize: '70px',
    },
  }

  const url =  getFormDetails(formCode, "urlPattern") + "/my-action"

  return (
    <Grid
      item
      xs={12} 
      sx={{ px: 1 }}
      >
      <FormItemContainer className={styles.formItem}>
        <FormItemTitle backgroundColor={fIconProps.shortNameBgColor} className={styles.formItemHeader}>
          <Typography noWrap={true} fontWeight={"600"}>
            {getFormDetails(formCode, "title")}
          </Typography>
        </FormItemTitle>
        <FormItemContent className={styles.formItemContent} sx={{'--data-code-bg-color-var': fIconProps.shortNameBgColor}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Box
              onClick={() => NavigateTo(navigate, url)}
              data-code={fIconProps.shortNameBgColor}
              sx={{
                display: 'flex',
                minHeight: '68px',
                padding: '10px 20px',
                alignItems: 'center',
                gap: matchDownMd ? '8px' : '16px',
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden'
              }}>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <FormIcon
                  acronym={fIconProps.shortName}
                  shortNameBgColor={fIconProps.shortNameBgColor}></FormIcon>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'baseline',
                  flex: 1,
                  width: '100%',
                }}>
                <Typography
                  sx={{
                    fontSize: '28px',
                    fontWeight: 'bold',
                    mr: '12px',
                    ...(!matchDownMd && { textAlign: 'end' }),
                  }}>
                  {totalCount}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    ...(!matchDownMd && { textAlign: 'end' }),
                  }}>
                  {t('Forms')}
                </Typography>
              </Box>

              <NavigateNextIcon fontSize="large"/>
            </Box>
          </Box>
        </FormItemContent>
      </FormItemContainer>
    </Grid>
  )
}

export default LandingFormItem

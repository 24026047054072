import { KeyValPair } from '@models/common'
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { Fragment, ReactNode, useState } from 'react'

interface MCKeyValPair extends KeyValPair {
  isOthers?: boolean
  isNa?: boolean
  disabled?: boolean
}

export default function FormMultipleChoices(props: {
  value?: number[]
  onChange?: (...event: any[]) => void
  options: MCKeyValPair[]
  additionalChangeAction?: (event: any) => void

  otherEndornment?: ReactNode

  disabled?: boolean
  uneditable?: boolean
}) {
  const checkList = props.options.map((option: MCKeyValPair) =>
    props.value ? !!props.value.find((n: number) => option.key === n) : false,
  )
  const isNaChecked =
    props.value?.find((n: number) => props.options?.find((y) => y.isNa)?.key === n) !== undefined

  const [choices, setChoices] = useState<boolean[]>(checkList)
  const [mcOptions, setMcOptions] = useState<MCKeyValPair[]>(
    props.options.map((option: MCKeyValPair, cIndex: number) => {
      return { ...option, disabled: !option.isNa && isNaChecked ? true : false }
    }),
  )
  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any, index: number, option: MCKeyValPair) => {
    const _choices = choices.map((choice: boolean, cIndex: number) => {
      if (option.isNa) {
        return cIndex === index ? !choice : false
      }
      return cIndex === index ? !choice : choice
    })
    if (option.isNa) {
      if (!choices[index]) {
        setMcOptions(
          mcOptions.map((option: MCKeyValPair, cIndex: number) => {
            return { ...option, disabled: cIndex === index ? false : true }
          }),
        )
      } else {
        setMcOptions(
          mcOptions.map((option: MCKeyValPair) => {
            return { ...option, disabled: false }
          }),
        )
      }
    }
    setChoices(_choices)
    const _value: any[] = []
    _choices.forEach((choice: boolean, cIndex: number) => {
      if (choice) {
        _value.push(props.options[cIndex].key)
      }
    })
    props.onChange!(_value)

    if (props.additionalChangeAction) {
      props.additionalChangeAction(event)
    }
  }

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>
          {(props.value &&
            props.value.reduce((prev: string, cur: number, currentIndex: number) => {
              const found = mcOptions.find((option: MCKeyValPair) => option.key === cur)
              if(currentIndex+1 === props.value?.length ){
                return found ? prev + found.value : prev
              }
              return found ? prev + found.value + ', ' : prev
            }, '')) ??
            ''}
        </Typography>
      ) : (
        <FormGroup
          sx={{
            width: '100%',
          }}>
          {mcOptions.map((option: MCKeyValPair, index: number) => (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}>
              <FormControlLabel
                key={`form-control-label-${index}`}
                control={
                  <Checkbox
                    key={`form-checkbox-${index}`}
                    checked={choices[index]}
                    onChange={(event: any) => {
                      handleChange(event, index, option)
                    }}
                    disabled={option.disabled || props.disabled || props.uneditable}
                  />
                }
                label={option.value}
              />
              {option.isOthers && choices[index] && props.otherEndornment}
            </Box>
          ))}
        </FormGroup>
      )}
    </Fragment>
  )
}

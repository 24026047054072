import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { FormSDModel } from '@services/model/form/form.SD.model'
import { KeyValPair } from '@models/common'
import { Control } from 'react-hook-form'
import FormRadio from '@components/form/radio'
import FormMultipleChoices from '@components/form/multipleChoices'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import FormFreeText from '@components/form/freeText'
import FormField from '@components/form/field'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

export default ({
  control,
  useWatch,
  readOnly,
  options,
}: {
  control: Control<FormSDModel>
  useWatch: any
  readOnly: boolean
  options: any
}) => {
  const UUID = uuid()
  let index = 0
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()

  const PartFessentialOperationOther = ({
    control,
    options,
  }: {
    control: Control<FormSDModel>
    options: KeyValPair[]
  }) => {
    const essentialOperation = useWatch({
      name: 'essentialOperation',
      control,
    })

    const founded = essentialOperation?.find(
      (x) => x === options.find((o) => o.value === 'Other')?.key,
    )
    const matched = founded !== undefined ? true : false

    if (matched) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: 'essentialOperationOther',
              control: control,
            }}>
            {/* <TextField fullWidth multiline rows={7} placeholder={t('Please fill in information')} /> */}
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                rows: 7,
              }}
                //uneditable={readOnly}
              disabled={readOnly}
            />
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const PartFinSituTestOther = ({
    control,
    options,
  }: {
    control: Control<FormSDModel>
    options: KeyValPair[]
  }) => {
    const inSituTest = useWatch({
      name: 'inSituTest',
      control,
    })

    const founded = inSituTest?.find((x) => x === options?.find((o) => o.value === 'Other')?.key)
    const matched = founded !== undefined ? true : false

    if (matched) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: 'inSituTestOther',
              control: control,
            }}>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                rows: 7,
              }}
                //uneditable={readOnly}
              disabled={readOnly}
            />
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const PartFinSituSampleOther = ({
    control,
    options,
  }: {
    control: Control<FormSDModel>
    options: KeyValPair[]
  }) => {
    const inSituSample = useWatch({
      name: 'inSituSample',
      control,
    })

    const founded = inSituSample?.find((x) => x === options?.find((o) => o.value === 'Other')?.key)
    const matched = founded !== undefined ? true : false

    if (matched) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: 'inSituSampleOther',
              control: control,
            }}>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                rows: 7,
              }}
                //uneditable={readOnly}
              disabled={readOnly}
            />
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  return (
    <FormContainer fkey={`${UUID}-sd-f`}>
      <FormField fieldName="Category">
        <Typography>{t('Estimated Percentage of Works Completion')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'workCompletionId',
            control: control,
          }}>
          <FormRadio
            labels={getOptionsByKey('Form SD - Estimated Percentage of Works Completion')}
            //uneditable={readOnly}
            disabled={readOnly}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Category">
        <Typography>{t('Essential Operations Involved')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'essentialOperation',
            control: control,
          }}>
          <FormMultipleChoices
            options={getOptionsByKey('Form SD - Essential Operations Involved')?.map((eo) => ({
              key: eo.key,
              value: eo.value,
              isOthers: eo.value === 'Other',
              isNa: eo.value === 'N/A',
            }))}
              //uneditable={readOnly}
            disabled={readOnly}
          />
        </FormController>
      </FormField>

      <PartFessentialOperationOther
        control={control}
        options={getOptionsByKey('Form SD - Essential Operations Involved')?.map((eo) => ({
          key: eo.key,
          value: eo.value,
        }))}
      />

      <FormField fieldName="Category">
        <Typography>{t('In-situ Test(s)/Sampling Witenessed on Site')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'inSituTest',
            control: control,
          }}>
          <FormMultipleChoices
            options={getOptionsByKey('Form SD - In-situ Test')?.map((ist) => ({
              key: ist.key,
              value: ist.value,
              isOthers: ist.value === 'Other',
              isNa: ist.value === 'N/A',
            }))}
              //uneditable={readOnly}
            disabled={readOnly}          />
        </FormController>
      </FormField>

      <PartFinSituTestOther
        control={control}
        options={getOptionsByKey('Form SD - In-situ Test')?.map((ist) => ({
          key: ist.key,
          value: ist.value,
        }))}
      />

      <FormField fieldName="Category">
        <Typography>{t('In-situ Sample(s) obtained')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'inSituSample',
            control: control,
          }}>
          <FormMultipleChoices
            options={getOptionsByKey('Form SD - In-situ Sample')?.map((iss) => ({
              key: iss.key,
              value: iss.value,
              isOthers: iss.value === 'Other',
              isNa: iss.value === 'N/A',
            }))}
              //uneditable={readOnly}
            disabled={readOnly}
          />
        </FormController>
      </FormField>

      <PartFinSituSampleOther
        control={control}
        options={getOptionsByKey('Form SD - In-situ Sample')?.map((iss) => ({
          key: iss.key,
          value: iss.value,
        }))}
      />
    </FormContainer>
  )
}

import {
  Box,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  useMediaQuery,
  IconButton,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import moment from 'moment'
import { styled, useTheme } from '@mui/material/styles'
import NotificationCommentIcon from '@images/notification-comment.svg'
import NotificationWarningIcon from '@images/notification-warning.svg'
import NotificationGearIcon from '@images/notification-gear.svg'
import NotificationFileIcon from '@images/notification-file.svg'
import NoificationIcon from '@images/notification.svg'
import { NotificationModel, NotificationTypeColorEnum } from '@services/model/notification.model'
import CheckIcon from '@mui/icons-material/Check'
import NotificationService from '@services/notification.service'
import ReactHtmlParser from 'react-html-parser'
import useAPIFetch from '@hooks/useAPIFetch'
import { getFormDetails } from '@utils/getFormDetails'
import { useNavigate } from 'react-router-dom'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import useLocalStorage from '@hooks/useLocalStorage'
import { UserInfo } from '@services/model/user.model'

const CategoryBoxContainer = styled(Paper)({
  borderRadius: '5px',
  marginTop: '15px',
  height: '580px',
})

const CategoryBoxTitle = styled(Box)<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  minHeight: '45px',
  paddingLeft: '14px',
  paddingRight: '14px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: backgroundColor ?? '#fff',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
}))

const CategoryBoxContent = styled(Grid)({
  maxHeight: '520px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  padding: '12px 4px',
  overflowY: 'scroll',
  gap: '8px'
})

function createMarkup(htmlRaw: string) {
  return { __html: htmlRaw }
}

const CategoryBox = ({
  type,
  msgList,
  reload,
}: {
  type: string
  msgList: NotificationModel[]
  reload: () => Promise<void>
}) => {
  const { setRequest, isLoading } = useAPIFetch()
  const theme = useTheme()
  const navigate = useNavigate()
  const [loginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)

  const getNotificationColor = (type: string) => {
    switch (type) {
      case 'Form Progression':
        return '#9BC2E6'
      case 'Form Comment':
        return '#E1C2FF'
      case 'Progress Due':
        return '#FFC400'
      case 'Reminder':
        return '#FF6060'
    }
  }

  const handleCategoryBoxClick = async (msg) => {
    setRequest({
      callback: async (cancelToken: any) => {
        await NotificationService.BatchMarkNotificationRead([msg.id])
        await reload()
      },
    })
  }

  const navigateToTargetForm = (model) => {
    if (model.title === 'Form Progression') {
      let routePath = getFormDetails(model.referenceType, 'urlPattern') + '/' + model.referenceId
      console.log('routePath', routePath)
      navigate(routePath)
    }
  }

  return (
    <CategoryBoxContainer>
      <CategoryBoxTitle backgroundColor={getNotificationColor(type)}>
        <Typography variant="h6" fontWeight={'500'}>
          {type}
        </Typography>
      </CategoryBoxTitle>
      <CategoryBoxContent>
        {msgList.map((msg) =>{ 
          const isRead = msg.notificationTo.find((notification) => notification.toUser === loginUser?.uuid)?.isRead ?? true 
          return(
          <Paper
            onClick={() => {
              navigateToTargetForm(msg)
            }}
            sx={{ padding: '8px'}}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography fontWeight={550}>{msg.title}</Typography>
              {!isRead && <IconButton onClick={(e) => {e.stopPropagation(); handleCategoryBoxClick(msg)}}>
                <DoneAllIcon />
              </IconButton>}
            </Box>
            <Typography variant="body2">
              {moment(msg.createdAt).format(moment.defaultFormat)}
            </Typography>
            <Typography variant="body2">
              <div
                style={{ padding: '8px' }}
                dangerouslySetInnerHTML={createMarkup(
                  ReactHtmlParser(msg.messageContent).toString(),
                )}
              />
            </Typography>
          </Paper>
        )})}
      </CategoryBoxContent>
    </CategoryBoxContainer>
  )
}

export default CategoryBox

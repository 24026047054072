import { DashboardChart }        from '@services/model/base.model'
import apiGateway, { APIConfig } from '@utils/axiosInterceptor'
import {
  EiDashboard,
  EiDashboardReq,
  EiFilterOptions,
  EiGeneralOptions,
  EiListResponse,
  FormEIModel
}                                from '../model/form/form.EI.model'
import { BaseFormResponse }      from '../model/form/form.model'

const GetGeneralOptions = async () => {
  return await apiGateway.get<EiGeneralOptions>(`/api/v1/forms/EI/options`).then((rep) => {
    return rep.data
  })
}

const GetEIList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<EiListResponse>(`api/v1/forms/EI/${myRecordOnly ? 'my-action' : 'list'}`, req, {cancelToken: cancelToken})
    .then((rep) => {
      return rep.data
    })
}

const GetEiFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/EI/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<EiFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const GetEIForm = async (formId: string) => {
  return await apiGateway
    .get<FormEIModel>(`api/v1/forms/EI/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetEiDashboard = async (req: EiDashboardReq, cancelToken: any): Promise<EiDashboard> =>
    await apiGateway
        .post<EiDashboard>('api/v1/forms/EI/dashboard', req, { cancelToken: cancelToken })
        .then(resp => resp.data)

const SaveEIForm = async (req: FormEIModel) => {
  const config: APIConfig = {
    compress: true,
  }
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/EI', req, config).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/EI/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveEIForm = async (req: FormEIModel) => {
  const config: APIConfig = {
    compress: true,
  }
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EI/${req.baseForm.formId ? req.baseForm.formId : '_'}/approve`,
      req,
      config
    )
    .then((rep) => {
      return rep.data
    })
}

const RejectEIForm = async (req: FormEIModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EI/${req.baseForm.formId ? req.baseForm.formId : '_'}/reject`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const DeleteEIForm = async (req: FormEIModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/EI/${req.baseForm.formId}`,
  )
}

const UpdateMap = async (req: FormEIModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EI/${req.baseForm.formId ? req.baseForm.formId : '_'}/map-image`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const GetEIDashboardContent = async(req: EiDashboardReq) => {
  return await apiGateway.post<{mmWoMeetMinEiRqmt: DashboardChart}>(`api/v1/forms/EI/dashboards`, req).then((rep) => {
    return rep.data
  })
}

const GetEIDashboardCatContent = async(req: EiDashboardReq) => {
  return await apiGateway.post<{mmWoMeetMinEiRqmt: DashboardChart}>(`api/v1/forms/EI/dashboards-cat`, req).then((rep) => {
    return rep.data
  })
}

const FormEIService = {
  GetEIForm,
  GetEIList,
  GetEiFilterOption,
  GetEiDashboard,
  SaveEIForm,
  ApproveEIForm,
  RejectEIForm,
  DeleteEIForm,
  GetGeneralOptions,
  UpdateMap,
  GetEIDashboardContent,
  GetEIDashboardCatContent
}

export default FormEIService
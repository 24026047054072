import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse, { BaseFilterOptions } from '../model/base.model'
import { FormTwoListModel, FormTWOModel, TwoGeneralOptions, TwoListResponse } from '../model/form/form.TWO.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetGeneralOptions = async () => {
  return await apiGateway.get<TwoGeneralOptions>(`/api/v1/forms/TWO/options`).then((rep) => {
    return rep.data
  })
}

const GetTWOForm = async (formId: string) => {
  return await apiGateway
    .get<FormTWOModel>(`api/v1/forms/TWO/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetTwoList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<TwoListResponse>(
      `api/v1/forms/TWO/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetTwoFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/TWO/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<BaseFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveTWOForm = async (req: FormTWOModel) => {
  if (req.baseForm.formId === '') {
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/TWO', req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway
      .put<BaseFormResponse>(
        `api/v1/forms/TWO/${req.baseForm.formId}`,
        req,
      )
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveTWOForm = async (req: FormTWOModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/TWO/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectTWOForm = async (req: FormTWOModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/TWO/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteTWOForm = async (req: FormTWOModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/TWO/${req.baseForm.formId}`,
  )
}

export default {
  GetTWOForm,
  GetTwoList,
  GetTwoFilterOption,
  SaveTWOForm,
  ApproveTWOForm,
  RejectTWOForm,
  DeleteTWOForm,
  GetGeneralOptions,
}

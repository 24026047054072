import apiGateway from '@utils/axiosInterceptor'
import { UserListModel, UserFormModel, UserFilterOptions, DutyListModel, DutyFormModel } from './model/admin.model'

import hardCodeData from './admin.data.json'

const GetUserList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: UserListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/users/list`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      // console.log('list', list)
      console.log('rep.data in GetUserList', rep.data)
      return rep.data
    })
}

const GetDutyList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: DutyListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/users/list-ds`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      let { list, lastModifyList, totalCount } = rep.data
      // console.log('list', list)
      console.log('rep.data in GetDutyList', rep.data)
      return rep.data
    })
}

const GetUserFilterOption = async () => {
  return await apiGateway.get<UserFilterOptions>(`api/v1/users/filter-options`).then((rep) => {
    return rep.data
  })
}

const GetUserForm = async (userId: string) => {
  return await apiGateway.get<UserFormModel>(`api/v1/users?uuid=${userId}`).then((rep) => {
    console.log('rep.data in getuserform', rep.data)
    return rep.data
  })
}

const GetDutyFormById = async (id: string) => {
  return await apiGateway.get<DutyFormModel>(`api/v1/users/ds?id=${id}`).then((rep) => {
    console.log('rep.data in getuserform', rep.data)
    return rep.data
  })
}

const GetDutyFormByUUId = async (uuid: string) => {
  return await apiGateway.get<DutyFormModel>(`api/v1/users/ds?uuid=${uuid}`).then((rep) => {
    return rep.data
  })
}

const GetOrganisationMeta = async () => {
  return await apiGateway.get(`api/v1/organizations/meta`).then((rep) => {
    return rep.data
  })
}
const CreateUser = async (req: UserFormModel) => {}

const SaveCreateUser = async (req: UserFormModel) => {
  if (req.uuid === undefined) {
    console.log('calling create form', req)
    return await apiGateway.post(`api/v1/users/`, req).then((rep) => {
      console.log('rep.data in SaveCreateUser - CreateUser', rep.data)
      return rep.data
    })
  } else {
    console.log('calling update form', req)
    return await apiGateway.put(`api/v1/users/${req.uuid}`, req).then((rep) => {
      console.log('rep.data in SaveCreateUser - SaveUser', rep.data)
      return rep.data
    })
  }
}

const SaveCreateDuty = async (req: UserFormModel, formId: string) => {
  if (formId === '') {
    console.log('calling create form', req)
    return await apiGateway.post(`api/v1/users/ds`, req).then((rep) => {
      console.log('rep.data in SaveCreateDuty - CreateDuty', rep.data)
      return rep.data
    })
  } else {
    console.log('calling update form', req)
    return await apiGateway.put(`api/v1/users/ds?id=${formId}`, req).then((rep) => {
      console.log('rep.data in SaveCreateDuty - SaveDuty', rep.data)
      return rep.data
    })
  }
}

const resetUserPassword = async (email: string) => {
  return await apiGateway.get(`api/v1/users/reset_password?email=${email}`).then((rep) => {
    return rep.data
  })
}

const AdminService = {
  GetUserList,
  GetDutyList,
  GetDutyFormById,
  GetDutyFormByUUId,
  GetUserFilterOption,
  GetUserForm,
  CreateUser,
  SaveCreateUser,
  SaveCreateDuty,
  GetOrganisationMeta,
  resetUserPassword,
}

export default AdminService

import BasicBarChart, { basicBarChartConfig } from '@components/chart/bar/BasicBarChart'
import { basicChartConfig, basicChartOption, basicDataMap } from '@components/chart/BasicChart'
import BasicLineChart, { basicLineChartConfig } from '@components/chart/line/BasicLineChart'
import BasicPieChart, { basicPieChartConfig } from '@components/chart/pie/BasicPieChart'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Color } from 'chart.js'
import styles from '@styles/generalChart.module.scss'
import { Box, SxProps } from '@mui/system'

export interface singleDatasetChartProps {
  eleBefore?: any
  chartWrapperSX?: SxProps
  type: 'bar' | 'line' | 'pie' | 'doughnut'
  subType?: any
  hasSummaryTable?: Boolean
  options?: any //basicChartOption | basicBarChartConfig | basicLineChartConfig | basicPieChartConfig
  data: basicDataMap //[label]data
  dataConfig?: basicChartConfig
  eleAfter?: any
}

function generateSummaryTable(
  labels: string[],
  datas: (number | null)[],
  textColor?: Color | Color[],
) {
  return (
    <TableContainer component={Box}>
      <Table aria-label="submmary table" className={styles.summaryTable}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Sum</TableCell>
            {labels.map((label, index) => (
              <TableCell align="center" key={`summary-tabel-cell-${index}`}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              align="center"
              sx={{
                fontWeight: 700,
              }}>
              {datas.reduce((accumulator, data) => {
                let d = data === null ? 0 : data
                if (accumulator == null) {
                  return data
                }
                return accumulator + d
              }, 0)}
            </TableCell>
            {datas.map((data, i) => (
              <TableCell
                align="center"
                key={`summary-tabel-cell-${i}`}
                sx={{
                  fontWeight: 700,
                  color: textColor
                    ? Array.isArray(textColor) + '!important'
                      ? textColor[i] + '!important'
                      : textColor + '!important'
                    : null,
                }}>
                {data === null ? 0 : data}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const SingleDatasetChart = (props: singleDatasetChartProps) => {
  const labels = Object.keys(props.data)
  const data = Object.values(props.data)

  const summaryTable = props.hasSummaryTable
    ? generateSummaryTable(labels, data, props.dataConfig?.borderColor)
    : null

  let chart: any

  switch (props.type) {
    case 'bar':
      chart = (
        <BasicBarChart
          labels={labels}
          datasets={[
            {
              name: '',
              data: data,
              dataConfig: props.dataConfig,
            },
          ]}
          type={props.subType}
          options={props.options}></BasicBarChart>
      )
      break
    case 'line':
      chart = (
        <BasicLineChart
          labels={labels}
          datasets={[
            {
              name: '',
              data: data,
              dataConfig: props.dataConfig,
            },
          ]}
          options={props.options}></BasicLineChart>
      )
      break
    case 'pie':
      chart = (
        <BasicPieChart
          labels={labels}
          type={props.type}
          datasets={[
            {
              name: '',
              data: data,
              dataConfig: props.dataConfig,
            },
          ]}
          options={props.options}></BasicPieChart>
      )
      break
    case 'doughnut':
      chart = (
        <BasicPieChart
          labels={labels}
          type={props.type}
          datasets={[
            {
              name: '',
              data: data,
              dataConfig: props.dataConfig,
            },
          ]}
          options={props.options}></BasicPieChart>
      )
      break
    default:
      chart = <>Undefined Chart</>
      break
  }
  return (
    <>
      {props.eleBefore}
      {summaryTable}
      <Box sx={props.chartWrapperSX}>{chart}</Box>
      {props.eleAfter}
    </>
  )
}
export default SingleDatasetChart

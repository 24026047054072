import FormBinaryRadio from '@components/form/binaryRadio'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import FormBinarySelect from '@components/form/binarySelect'
import FormTypography from '@components/form/typography'
import { FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import { Typography, TextField } from '@mui/material'
import FormFreeText from '@components/form/freeText'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

export default ({
  editPartB,
  options,
  control,
  watch,
  readOnly,
  globalState
}: {
  editPartB: boolean
  options: any
  control: Control<FormSDModel>
  watch: any
  readOnly: boolean
  globalState: any
}) => {
  const UUID = uuid()
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()
  const [contractNoId, workOrderId] = watch(['baseForm.contractNoId', 'baseForm.workOrderId'])

  const matchedWorkOrder = globalState.worksOrderList?.find((x) => x.id === workOrderId)

  return (
    <FormContainer fkey={`${UUID}-sd-b`}>
      <FormField fieldName="Contractor's Daily Report Ref." >
        <Typography>{editPartB ? t('No') : t('Yes')}</Typography>
      </FormField>
      <FormField fieldName="Type of Works" >
        <FormTypography value={matchedWorkOrder?.typeOfWorks ? 'M&M Works' : 'Non-M&M Works'} />
      </FormField>
      <FormField fieldName="Works Order No." >
        <FormTypography value={matchedWorkOrder?.workOrderNo} />
      </FormField>
      <FormField fieldName="Road Name & Location Details" >
        <FormController
          controllerProps={{
            name: 'formSdCrw.locationDetails',
            control: control,
          }}>
          {editPartB ? (
            // <TextField fullWidth multiline rows={4} placeholder={t('Please fill in information')} />
            <FormFreeText
            textfieldProps={{
              fullWidth: true,
              multiline: true,
              rows: 4
            }}
            uneditable = {readOnly}
          />
          ) : (
            <FormTypography />
          )}
        </FormController>
      </FormField>
      <FormField fieldName="Proposed Activities" >
        <FormController
          controllerProps={{
            name: 'formSdCrw.proposedActivities',
            control: control,
          }}>
          {editPartB ? (
            // <TextField fullWidth multiline rows={4} placeholder={t('Please fill in information')} />
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 4
              }}
              uneditable = {readOnly}
            />
          ) : (
            <FormTypography />
          )}
        </FormController>
      </FormField>
      <FormField fieldName="Day/Night Works" >
        <FormController
          controllerProps={{
            name: 'formSdCrw.dayNightWorks',
            control: control,
          }}>
          {editPartB ? (
            <FormSelect fullWidth options={getOptionsByKey('Form SD - Day Night Works')} uneditable ={readOnly}/>
          ) : (
            <FormTypography type='select' options={getOptionsByKey('Form SD - Day Night Works')}  />
          )}
        </FormController>
      </FormField>
      <FormField fieldName="No. of Workers" >
        <FormController
          controllerProps={{
            name: 'formSdCrw.noOfWorker',
            control: control,
          }}>
          {editPartB ? (
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                rows: 10,
                type: 'number',
                inputProps: {
                  min: '0',
                },
              }}
              uneditable = {readOnly}
            />
          ) : (
            <FormTypography />
          )}
        </FormController>
      </FormField>
      <FormField fieldName="LSG on site" >
        <FormController
          controllerProps={{
            name: 'formSdCrw.lsgOnSite',
            control: control,
          }}>
          {editPartB ? <FormBinarySelect fullWidth uneditable = {readOnly}/> : <FormTypography type="binary" />}
        </FormController>
      </FormField>
      {matchedWorkOrder?.typeOfWorks ? (
        <></>
      ) : (
        <>
          <FormField fieldName="Essential Operation" >
            <FormController
              controllerProps={{
                name: 'formSdCrw.essentialOperation',
                control: control,
              }}>
              {editPartB ? <FormBinarySelect fullWidth uneditable = {readOnly}/> : <FormTypography type="binary" />}
            </FormController>
          </FormField>
          <FormField fieldName="Hidden Works other than LSG" >
            <FormController
              controllerProps={{
                name: 'formSdCrw.hiddenWorkOtherThanLsg',
                control: control,
              }}>
              {editPartB ? <FormBinarySelect fullWidth uneditable = {readOnly}/> : <FormTypography type="binary" />}
            </FormController>
          </FormField>
          <FormField fieldName="In-situ Test" >
            <FormController
              controllerProps={{
                name: 'formSdCrw.inSituTest',
                control: control,
              }}>
              {editPartB ? <FormBinarySelect fullWidth uneditable = {readOnly}/> : <FormTypography type="binary" />}
            </FormController>
          </FormField>
        </>
      )}

      <FormField fieldName="IOW/WS Remarks" >
        <FormController
          controllerProps={{
            name: 'formSdCrw.iosWsRemarks',
            control: control,
          }}>
          {editPartB ? (
            // <TextField fullWidth multiline rows={4} placeholder={t('Please fill in information')}/>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 4
              }}
              uneditable = {readOnly}
            />
          ) : (
            <FormTypography />
          )}
        </FormController>
      </FormField>

      {/* <FormField fieldName="Site Idling" >
          <FormController
            controllerProps={{
              name: 'siteIdling',
              control: control,
            }}>
            {editPartB ? <FormBinaryRadio /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>
        <FormField fieldName="Works Commenced" >
          <FormController
            controllerProps={{
              name: 'worksCommenced',
              control: control,
            }}>
            {editPartB ? <FormBinaryRadio /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>
        <FormField fieldName="Works Completed" >
          <FormController
            controllerProps={{
              name: 'worksCompleted',
              control: control,
            }}>
            {editPartB ? <FormBinaryRadio /> : <FormTypography type="binary" />}
          </FormController>
        </FormField>
        <FormField fieldName="XP/SSW/EO No." >
          <FormController
            controllerProps={{
              name: 'xpSswEoNo',
              control: control,
            }}>
            {editPartB ? (
              <TextField fullWidth placeholder={t('Please fill in information')} />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
        <FormField fieldName="Location" >
          <FormController
            controllerProps={{
              name: 'location',
              control: control,
            }}>
            {editPartB ? (
              <TextField fullWidth placeholder={t('Please fill in information')} />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField> */}
    </FormContainer>
  )
}
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
  Box,
} from '@mui/material'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { minHeight } from '@mui/system'
export interface FormDialogProps {
  open: boolean
  onClose: (event: any) => void
  toolbarStyle?: SxProps<Theme>
  title?: JSX.Element
  buttons?: JSX.Element
  children?: JSX.Element
}

const FormDialog = (props: FormDialogProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const handleOnClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false
    }

    if (reason === 'escapeKeyDown') {
      return false
    }

    if (props.onClose) {
      props.onClose(event)
    }
  }

  return (
    <Dialog disableEnforceFocus open={props.open} onClose={handleOnClose} fullWidth maxWidth="md" PaperProps={{ style: {borderRadius: '12px', border: '0'} }}>
      <Box
        sx={{
          width: '100%',
        }}>
        {props.title && (
          <Toolbar sx={props.toolbarStyle} variant="dense">
            <Typography sx={{ flex: 1, paddingTop: matchDownMd ? '16px' : '8px'}} component="div" fontWeight='bold'>
              {props.title}
            </Typography>
          </Toolbar>
        )}
      </Box>

      <DialogContent sx={{ minHeight: 200, ...(matchDownMd && { padding: '20px 8px' }) }}>
        {props.children}
      </DialogContent>
      {matchDownMd ? (
        <Box sx={{ padding: 2 }}>{props.buttons}</Box>
      ) : (
        <Box sx={{ boxShadow: 1, padding: 0}}>
          <DialogActions>{props.buttons}</DialogActions>
        </Box>
      )}
    </Dialog>
  )
}

export default FormDialog

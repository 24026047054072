import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import moment from 'moment'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SearchPanel from '@components/searchPanel'
import { Grid, Tooltip, IconButton } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import FormStatusLabel from '@components/FormStatusLabel'
import ExportIcon from '@components/icon/export'
import { EaFilterOptions, FormEaListModel } from '@services/model/form/form.EA.model'
import FormEAService from '@services/formService/form.EA.service'
import { RightsCategory } from '@services/model/form/form.model'
import useTableView from '@hooks/useTableView'
import { GetWorksOrderOptions } from '@utils/worksOrderOptions'
import GetValueWithKey from '@utils/getValueWithKey'
import { NavigateTo } from '@utils/navigate'
import { exportToCSV } from '@utils/exportExcel'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<EaFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
    workOrderIdList: [],
    workOrderId: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormEAService.GetEaFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const getTableFullList = async () => {
    let fullList = await FormEAService.GetEaList(
      {
        pagination: { skipPagination: true },
      },
      '',
      props.showMyRecord || false,
    )
    let list: any[] = fullList.list.map((x) => {
      return {
        'General Status': x.formStatusShortName,
        'Submission Status': x.formStatus,
        'NEA Form': x.parentReportNo,
        'Audit Sequence': x.auditSequence,
        'Form ID': x.reportNo,
        'Type of M&M Works': GetValueWithKey(x.typeOfMmWorks, typeOfMmWorksOrderList),
        Location: x.location,
        'Inspection Date': x.inspectionDate
          ? moment(x.inspectionDate).format(moment.defaultFormat)
          : 'N/A',
        'Submitted By': GetValueWithKey(x.submittedBy, globalState.userMetaList ?? []),
        'Approved By': GetValueWithKey(x.approvedBy, globalState.userMetaList ?? []),
        'Last update': x.updatedAt ? moment(x.updatedAt).format(moment.defaultFormat) : 'N/A',
      }
    })
    return list ?? []
  }

  const typeOfMmWorksOrderList =
    globalState.generalOptionGroupList
      ?.find((x) => x.name.toLowerCase() === 'Form EA - Category'.toLowerCase())
      ?.generalOptions.map((x) => {
        return { key: x.id, value: x.excelMenu?.shortTerm ?? '', status: x.status }
      }) || []

  const [TableView, reload] = useTableView<FormEaListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formNeaNo',
        desc: t('NEA Form', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => <Fragment>{record.parentReportNo}</Fragment>,
      },
      {
        key: 'auditSequence',
        desc: t('Audit Sequence', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => <Fragment>{record.auditSequence}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'typeOfMmWorks',
        desc: t('Type of M&M Works', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <Fragment>{GetValueWithKey(record.typeOfMmWorks, typeOfMmWorksOrderList)}</Fragment>
        ),
      },
      {
        key: 'defective',
        desc: t('Defective', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => <>{record.defective ? 'Yes' : 'No'}</>,
      },
      {
        key: 'monthYearOfPerformanceStandard',
        desc: t('Month/Year of Performance Standard', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <>
            {record.monthYearOfPerformanceStandard
              ? moment(record.monthYearOfPerformanceStandard).format('MMM YYYY')
              : '—'}
          </>
        ),
      },
      {
        key: 'workOrderId',
        desc: t('Work Order', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <>
            {globalState?.worksOrderList?.find((x) => x.id === record.workOrderId)?.workOrderNo ??
              '—'}
          </>
        ),
      },
      {
        key: 'structureNo',
        desc: t('Structure No', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <>{record.structureNo === '' ? '—' : record.structureNo}</>
        ),
      },
      {
        key: 'slopeNo',
        desc: t('Slope No', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <>{record.slopeNo === '' ? '—' : record.slopeNo}</>
        ),
      },
      {
        key: 'location',
        desc: t('Location', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => <Fragment>{record.location}</Fragment>,
      },
      {
        key: 'inspectionDate',
        desc: t('Inspection Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <Fragment>
            {record.inspectionDate
              ? moment(record.inspectionDate).format(moment.defaultFormat)
              : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormEaListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],

    onRowClick: function (navigate: NavigateFunction, record: FormEaListModel) {
      NavigateTo(navigate, '/engineer-audit/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('EA', JSON.stringify(state.filterResult))
        let resp = await FormEAService.GetEaList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_EA))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.formStatus?.value
                  : [],
            },
            {
              key: 'formNeaNo',
              desc: 'Nea Form',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.parentFormIdList,
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.formNeaNo?.value
                  : [],
            },
            {
              key: 'auditSequence',
              desc: 'Audit Sequence',
              type: 'NumberFilter',
              defaultOperator:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.auditSequence?.operator
                  : 'in',
              defaultMin:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.auditSequence?.value?.min
                  : '',
              defaultMax:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.auditSequence?.value?.max
                  : '',
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.formId?.value
                  : [],
            },
            {
              key: 'typeOfMmWorks',
              desc: 'Type of Works',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: typeOfMmWorksOrderList ?? [],
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.typeOfMmWorks?.value
                  : [],
            },
            {
              key: 'defective',
              desc: 'Defective',
              type: 'BooleanSelectionFilter',
              valueList: [
                { key: true, value: 'Yes' },
                { key: false, value: 'No' },
              ],
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.defective?.value
                  : null,
            },
            {
              key: 'monthYearOfPerformanceStandard',
              desc: 'Month / Year of Performance Standard',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.monthYearOfPerformanceStandard?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.monthYearOfPerformanceStandard?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.monthYearOfPerformanceStandard?.value?.max
                  : '',
            },
            {
              key: 'workOrderId',
              desc: 'Work Order',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: GetWorksOrderOptions(
                globalState.worksOrderList ?? [],
                userInfo.teams,
                userInfo.districts,
              ),
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.workOrderId?.value
                  : [],
            },
            {
              key: 'structureNo',
              desc: 'Structure No',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.structureNo?.value
                  : '',
            },
            {
              key: 'slopeNo',
              desc: 'Slope No',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.slopeNo?.value
                  : '',
            },
            {
              key: 'location',
              desc: 'Location',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.location?.value
                  : '',
            },
            {
              key: 'inspectionDate',
              desc: 'Inspection Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.inspectionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.inspectionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.inspectionDate?.value?.max
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.EA !== 'undefined' && sessionStorage?.EA !== undefined
                  ? JSON.parse(sessionStorage?.EA)?.updatedAt?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <Tooltip title="Export">
              <IconButton
                sx={{
                  backgroundColor: globalState.headerColor,
                  borderRadius: '10%',
                  width: '40px',
                  height: '40px',
                  '&:hover': { background: globalState.headerColor },
                }}
                onClick={async () => await exportToCSV(await getTableFullList(), 'EA-all-record')}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord

import { FormModel, InitFormModel, FormStatusEnum, InitFormStatusHistory } from './form.model'
import { FormNFModel } from './form.NF.model'
import { FormEIModel } from './form.EI.model'
import { ImageModel } from '../image.model'
import { LocationModel } from '../location.model'
import { ContractNo } from '../contract.model'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { KeyValPair } from '@models/common'

export interface FormEIAModel {
  // a
  baseForm: FormModel //
  from?: string // TODO remove later
  client?: string
  contractor?: string
  eiaNo?: string
  emergencySerialNo?: string
  remark?: string
  assignedTo?: string
  assignedToUserName?: string
  assignedToUserRole?: number[]
  eiAssignments?: FormEIModel[]
  assignedToWithEffectiveDuty?: string  //frontent only

  //b
  signatureRequired: boolean
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string
}

export interface eiAssignmentsModel {
  id: number
  eiId?: string
  eiNo?: string
  eiStatus?: boolean
  caseSourceId?: number
  iccNo?: string
  workOrderId?: number
  workOrderNo?: string
  locationRemark?: string
}

export const InitEIAForm: FormEIAModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.FORM_EIA_DRAFT,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  signatureRequired: true,
}

export interface FormEIAListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string

  iccNo?: string[]
  reportNo: string
  contractNoId: number
  teamId: number[]
  districtId: number[]
  assignedTo?: string
  assignedToUserName?: string
  roadName: string
  inspectionDate: Date
  archiveReason?: string

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export type EIAGeneralOptions = {
  caseSource: {
    id: number
    name: string
  }[]
  assignedTo: {
    id: number
    name: string
  }[]
}

export type EIAContractOptions = {
  caseSource: {
    id: number
    name: string
  }[]
}

export interface EIAListResponse extends BaseListResponse {
  list: FormEIAListModel[]
}

export interface EIAFilterOptions extends BaseFilterOptions {
  workOrderIdList?: number[]
  iccNoList?: string[]
  assignedToList?: string[]
  assignedToNameList?: KeyValPair[]
}

export interface EIADashboardReq {
  workOrderId?: { operator?: string; value?: (number | undefined)[] }
  districtId?: { operator?: string; value?: (number | undefined)[] }
  startDate?: Date
  dateInterval?: number
  columnNo?: number
}

import { Box, IconButton, Paper, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ImageModel } from '@services/model/image.model'
import { Fragment, useEffect, useRef, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import {
  FileToImageModel,
  ImageModelToUrl,
  Base64StringToImageBase64,
  GetImageFromUrl,
} from '@utils/image'
import { useTranslation } from 'react-i18next'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { useSnackbar } from 'notistack'
import useImageModal from '@hooks/useImageModal'
import { reverseGeoCoding } from './mediumMap'
import DownloadIcon from '@mui/icons-material/Download'
import LoadingPopup from './LoadingDialog'

const FormImageUplaodContainer = styled(Paper)<{
  state: {
    height?: string | number
    width?: string | number
    numImages: number
  }
}>(({ theme, state }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(1),
  height: state.height ?? 300,
  [theme.breakpoints.up('md')]: {
    width: state.width ?? '100%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  ...(state.numImages > 0 && {
    height: 350,
  }),
}))

const LargeImageGroupContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
})

const ThumbnailImageGroupContainer = styled(Box)<{
  state: { numImages: number; thumbnailMaxImages: number }
}>(({ theme, state }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: state.numImages > state.thumbnailMaxImages ? 'left' : 'center',
  overflowX: 'auto',
  position: 'absolute',
}))

const ButtonGroupBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const LargeImageHolder = styled(Box)<{
  state: { height?: string | number; width?: string | number }
}>(({ state, theme }) => ({
  border: '0.1px solid rgb(0 0 0 / 20%)',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  objectFit: 'contain',
  position: 'relative',
  height: state.height ?? 180,
  [theme.breakpoints.up('md')]: {
    width: state.width ?? '80%',
  },
  [theme.breakpoints.down('md')]: {
    width: '85%',
  },
}))

const LargeUploadImageBox = styled(Box)<{ state: { disabled?: boolean; uneditable?: boolean } }>(
  ({ state }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    ...(!(state.disabled || state.uneditable) && {
      cursor: 'pointer',
    }),
    ...(!state.disabled &&
      !state.uneditable && {
        ':hover': {
          border: 'dashed 2px #c5c5c5',
          '#large-image-upload-icon': {
            transition: 'transform 200ms ease-in-out',
            transform: 'translateY(-15px)',
          },
          '#large-image-upload-text': {
            visibility: 'visible',
            transition: 'transform 0ms, visibility 200ms, ease-in-out',
            transitionDelay: '200ms',
            transform: 'translateY(15px)',
          },
        },
      }),
  }),
)

const ThumbnailImageHolder = styled(Box)<{ state: { current: boolean } }>(({ state }) => ({
  border: '0.1px solid rgb(0 0 0 / 20%)',
  minWidth: 50,
  minHeight: 30,
  width: 50,
  height: 30,
  marginLeft: 6,
  marginRight: 6,
  objectFit: 'contain',
  ...(state.current && {
    // border: '0.1px solid red',
    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
  }),
  position: 'relative',
}))

const ThumbnailImageRemoveButton = styled(IconButton)({
  position: 'absolute',
  top: -10,
  right: -10,
  zIndex: 5,
  fontSize: '0.5rem',
  backgroundColor: 'red',
  '#thumbnail-image-remove-icon': {
    fontSize: 'inherit',
    color: '#ffffff',
    fontWeight: 'bolder',
  },
  ':hover': {
    backgroundColor: 'pink',
  },
  '.MuiTouchRipple-child': {
    backgroundColor: 'red',
  },
})

const ThumbnailImageAddButtonHolder = styled(Box)({
  border: '0.1px solid rgb(0 0 0 / 20%)',
  width: 50,
  height: 30,
  margin: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  transform: 'translateY(-4px)',
})

const LargeImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const ThumbnailImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  ':hover': {
    cursor: 'pointer',
  },
})

const ThumbnailAddImageButton = styled(IconButton)({
  width: 50,
  height: 30,
  padding: 'auto',
})

const ImageDownloadButton = styled(IconButton)({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: '50',
  height: '50',
  zIndex: '10',
  ':hover': {
    cursor: 'pointer',
    color: '#5A5A5A',
    backgroundColor: 'transparent',
    transition: 'transform 500ms ease-in-out',
  },
})

interface formImageUploadProps {
  onChange?: (...event: any[]) => void
  value?: ImageModel[]
  error?: any
  onBlur?: () => void
  height?: string | number
  width?: string | number
  largeImageHeight?: string | number
  largeImageWidth?: string | number
  disableUpload?: boolean
  disableRemove?: boolean
  thumbnailMaxImages?: number

  multiple?: boolean

  disabled?: boolean
  uneditable?: boolean
  disableDownload?: boolean
  additionalChangeAction?: (image: ImageModel[]) => void
}

const FormImageUpload = (props: formImageUploadProps) => {
  const [images, setImages] = useState<ImageModel[]>([])
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [numImages, setNumImages] = useState<number>(0)
  const uploadRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // mounting
  const mounted = useRef<boolean>(false)
  useEffect(() => {
    if (!mounted.current && props.value) {
      setImages(props.value)
      mounted.current = true
    }
  }, [props.value])

  // disable navigate images
  const [disablePrev, setDisablePrev] = useState<boolean>(true)
  const [disableNext, setDisableNext] = useState<boolean>(true)
  useEffect(() => {
    let prevReducer = false
    let nextReducer = false

    // prev images
    for (let i = imageIndex - 1; i >= 0; i--) {
      if (images[i].status) {
        prevReducer = true
        break
      }
    }

    // next images
    for (let i = imageIndex + 1; i < images.length; i++) {
      if (images[i].status) {
        nextReducer = true
        break
      }
    }

    setDisablePrev(!prevReducer)
    setDisableNext(!nextReducer)
    setNumImages(images.filter((image: ImageModel) => image.status).length)
  }, [images, imageIndex])

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handlePrevImage = () => {
    for (let i = imageIndex - 1; i >= 0; i--) {
      if (images[i].status) {
        setImageIndex(i)
        break
      }
    }
  }

  const handleNextImage = () => {
    for (let i = imageIndex + 1; i < images.length; i++) {
      if (images[i].status) {
        setImageIndex(i)
        break
      }
    }
  }

  const handleImageDescriptionChange = (event: any) => {
    const _images = [...images]
    _images[imageIndex].description = event.target.value
    setImages(_images)
  }

  const handleImageDescriptionBlur = (event: any) => {
    const _images = [...images]
    _images[imageIndex].description = event.target.value
    props.onChange!(_images)
  }

  const handleUploadImage = async (event: any) => {
    // check uploaded file
    if (event.target.files && event.target.files.length > 0) {
      setIsLoading(true)
      let imgModels: ImageModel[] = []
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i]
        await FileToImageModel(file)
          .then(async (image) => {
            if (image) {
              if (image.location) {
                try {
                  let addr = await reverseGeoCoding(image.location)
                  if (addr && image.location) image.location.locationAddress = addr
                } catch {
                  console.log('Failed to get location address')
                }
              }
              imgModels.push(image)
            } else {
              enqueueSnackbar(t('Cannot convert image'), {
                variant: 'warning',
              })
            }
          })
          .catch((e: Error) => {
            enqueueSnackbar(e.message, { variant: 'error' })
            setIsLoading(false)
          })
      }
      setImageIndex(images.length + imgModels.length - 1)
      setImages([...images, ...imgModels])
      props.onChange!([...images, ...imgModels])

      if (props.additionalChangeAction) {
        props.additionalChangeAction(imgModels)
      }
      event.target.value = ''
      setIsLoading(false)
    }
  }

  const handleRemoveImage = (index: number) => {
    if (props.disableRemove || props.disabled || props.uneditable) {
      return
    }

    const _images = [...images]
    //     fileId => set status = false
    // otherize => remove record completely
    if (_images[index].fileId) {
      _images[index].status = false
    } else {
      _images.splice(index, 1)
    }
    props.onChange!([..._images])
    setImages([..._images])

    const foundIndex = [..._images].findIndex((i) => i.status)
    if (foundIndex !== -1) {
      setImageIndex(foundIndex)
    } else {
      setImageIndex(0)
    }
  }

  const onAddImageClick = () => {
    if (
      uploadRef &&
      uploadRef.current &&
      !props.disableUpload &&
      !props.disabled &&
      !props.uneditable
    ) {
      uploadRef.current.click()
    }
  }

  const handleDownloadImage = (imageIndex: number) => {
    const download = document.createElement('a')
    if (!images[imageIndex].bytesBase64) {
      download.href = images[imageIndex].fileUrl
      download.click()
    } else {
      enqueueSnackbar(t('Image download is available after form saved or sumitted.'), {
        variant: 'warning',
      })
    }
  }

  const [modalImage, setModalImage] = useState<string | undefined>()
  const [ImageModal, openImageModal] = useImageModal()
  const handleImageModalOpen = (base64Url: string) => {
    setModalImage(base64Url)
    openImageModal()
  }

  return (
    <FormImageUplaodContainer
      state={{ height: props.height, width: props.width, numImages }}
      sx={{
        border: props.error !== undefined ? 'red 1px solid' : 'unset',
      }}>
      <LoadingPopup open={isLoading}></LoadingPopup>
      <input
        type="file"
        accept="image/*"
        ref={uploadRef}
        style={{ display: 'none' }}
        onChange={handleUploadImage}
        onBlur={props.onBlur}
        multiple={props.multiple}
      />
      <LargeImageGroupContainer>
        <ButtonGroupBox>
          <IconButton disabled={disablePrev} onClick={handlePrevImage}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </ButtonGroupBox>
        <Box sx={{ flex: 1 }}>
          {images[imageIndex] && images[imageIndex].status ? (
            <Fragment>
              <LargeImageHolder
                state={{
                  height: props.largeImageHeight,
                  width: props.largeImageWidth,
                }}
                onClick={() => {
                  var base64regex =
                    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
                  if (images[imageIndex].location) {
                    handleImageModalOpen(
                      Base64StringToImageBase64(
                        ImageModelToUrl(images[imageIndex], 'original'),
                        images[imageIndex].ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                      ),
                    )
                  } else if (base64regex.test(ImageModelToUrl(images[imageIndex], 'original'))) {
                    handleImageModalOpen(
                      Base64StringToImageBase64(
                        ImageModelToUrl(images[imageIndex], 'original'),
                        images[imageIndex].ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                      ),
                    )
                  } else {
                    handleImageModalOpen(ImageModelToUrl(images[imageIndex], 'original'))
                  }
                }}>
                <ImageDownloadButton
                  onClick={(e) => {
                    handleDownloadImage(imageIndex)
                    e.stopPropagation()
                  }}
                  disabled={props.disableDownload}>
                  <DownloadIcon />
                </ImageDownloadButton>
                <LargeImage
                  src={ImageModelToUrl(images[imageIndex], 'regular')}
                  alt={`current-img-${images[imageIndex].fileName}`}
                />
              </LargeImageHolder>
              <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                <TextField
                  label={t('Description')}
                  value={images[imageIndex].description ?? ''}
                  onChange={handleImageDescriptionChange}
                  onBlur={handleImageDescriptionBlur}
                  disabled={props.uneditable || props.disabled}
                />
              </Box>
            </Fragment>
          ) : (
            <LargeImageHolder
              state={{
                height: props.largeImageHeight,
                width: props.largeImageWidth,
              }}>
              <LargeUploadImageBox
                state={{
                  disabled: props.disableUpload || props.disabled,
                  uneditable: props.uneditable,
                }}
                style={{ position: 'relative' }}
                onClick={onAddImageClick}>
                <CameraAltIcon
                  id="large-image-upload-icon"
                  sx={{
                    position: 'absolute',
                    textAlign: 'center',
                    width: '100%',
                    height: 40,
                    ...((props.disableUpload || props.disabled || props.uneditable) && {
                      color: 'grey.500',
                    }),
                  }}
                />
                <Typography
                  id="large-image-upload-text"
                  sx={{
                    visibility: 'hidden',
                    textAlign: 'center',
                  }}>
                  {t('Click to upload image')}
                </Typography>
              </LargeUploadImageBox>
            </LargeImageHolder>
          )}
        </Box>
        <ButtonGroupBox>
          <IconButton disabled={disableNext} onClick={handleNextImage}>
            <ArrowForwardIosIcon />
          </IconButton>
        </ButtonGroupBox>
      </LargeImageGroupContainer>
      <Box sx={{ position: 'relative' }}>
        <ThumbnailImageGroupContainer
          state={{ numImages, thumbnailMaxImages: props.thumbnailMaxImages ?? 7 }}>
          {images.map((image: ImageModel, index: number) =>
            image.status ? (
              <ThumbnailImageHolder
                state={{ current: index === imageIndex }}
                key={`thumbnail-${index}`}>
                {!props.uneditable && !props.disabled && (
                  <ThumbnailImageRemoveButton
                    size="small"
                    onClick={() => {
                      handleRemoveImage(index)
                    }}>
                    <CloseIcon id="thumbnail-image-remove-icon" />
                  </ThumbnailImageRemoveButton>
                )}
                <ThumbnailImage
                  src={ImageModelToUrl(image, 'thumbnail')}
                  alt={`img-thumbnail-${image.fileName}`}
                  onClick={() => {
                    setImageIndex(index)
                  }}
                />
              </ThumbnailImageHolder>
            ) : (
              <Fragment key={`thumbnail-${index}`} />
            ),
          )}
          {!props.uneditable && !props.disabled && !props.disableUpload && (
            <ThumbnailImageAddButtonHolder>
              <ThumbnailAddImageButton disableFocusRipple disableRipple onClick={onAddImageClick}>
                <AddIcon />
              </ThumbnailAddImageButton>
            </ThumbnailImageAddButtonHolder>
          )}
        </ThumbnailImageGroupContainer>
      </Box>
      <ImageModal image={modalImage} />
    </FormImageUplaodContainer>
  )
}

export default FormImageUpload

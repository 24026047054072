import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormHWRModel, InitHWRForm } from '@services/model/form/form.HWR.model'
import FormFileUpload from '@components/form/fileUpload'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormFreeText from '@components/form/freeText'
import FormMultipleChoices from '@components/form/multipleChoices'
import {FormSDModel} from "@services/model/form/form.SD.model";
import {KeyValPair} from "@models/common";

export default ({
    control,
    useWatch,
    readOnlyWs,
    readOnlyIow,
    options,

}:{
    control: Control<FormHWRModel>,
    useWatch: any
    readOnlyWs: boolean
    readOnlyIow: boolean
    options: any

}) => {
    const UUID = uuid()
    let index = 0


    const PartCcheckedSupportingEvidenceOther = ({
                                      control,
                                      options,
                                  }: {
        control: Control<FormHWRModel>
        options: KeyValPair[]
    }) => {
        const checkedSupportingEvidenceId = useWatch({
            name: 'checkedSupportingEvidenceId',
            control,
        })

        const founded = checkedSupportingEvidenceId?.find((x) => x === options?.find((o) => o.value === 'Others')?.key)
        const matched = founded !== undefined ? true : false

        if (matched) {
            return (
                <FormField fieldName="Other">
                    <FormController
                        controllerProps={{
                            name: 'evidenceOthers',
                            control: control,
                        }}>
                        <FormFreeText
                            textfieldProps={{
                                fullWidth: true,
                                rows: 7,
                                type: 'string',
                            }}
                            disabled={readOnlyIow}
                        />
                    </FormController>
                </FormField>
            )
        }
        return <></>
    }

    return (
    <FormContainer fkey={`${UUID}-hwr-c`}>
       <FormField fieldName="WS Checked on Site">
          <FormController
            controllerProps={{
              name: 'wsCheckedOnSite',
              control: control,
            }}>
            <FormBinaryRadio  disabled={readOnlyIow}/>
          </FormController>
        </FormField>

        <FormField fieldName="Checked Supporting Evidence" >
            <FormController
                controllerProps={{
                    name: 'checkedSupportingEvidenceId',
                    control: control,
                }}>
                <FormMultipleChoices
                    options={
                        options?.checkedSupportingEvidenceList?.map((x) => {
                            return {
                                key: x.id,
                                value: x.name,
                                isOthers: x.name === 'Others',
                            }
                        }) || []
                    }
                    disabled={readOnlyIow}
                />
            </FormController>
        </FormField>
        <PartCcheckedSupportingEvidenceOther
            control={control}
            options={
                options?.checkedSupportingEvidenceList?.map((x) => {
                    return {
                        key: x.id,
                        value: x.name,
                    }
                }) || []
            }
        />

        <FormField fieldName="Remarks">
          <FormController
            controllerProps={{
              name: `reviewRemarks`,
              control,
            }}>
            <FormFreeText
              textfieldProps={{
                multiline: true,
                rows: 4,
              }}
              disabled={readOnlyIow}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Checked at least 25% of HWR">
          <FormController
            controllerProps={{
              name: 'iowChecked',
              control: control,
            }}>
            <FormBinaryRadio disabled={readOnlyWs} />
          </FormController>
        </FormField>
        <FormField fieldName="SIOW/IOW/AIOW Checked on Site">
          <FormController
            controllerProps={{
              name: 'iowCheckedOnSite',
              control: control,
            }}>
            <FormBinaryRadio disabled={readOnlyWs} />
          </FormController>
        </FormField>
        <FormField fieldName="Remarks">
          <FormController
            controllerProps={{
              name: `iowRemarks`,
              control,
            }}>
            <FormFreeText
              textfieldProps={{
                multiline: true,
                rows: 4,
              }}
              disabled={readOnlyWs}
            />
          </FormController>
        </FormField>
    </FormContainer>
    )
  }
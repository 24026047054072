import { Box, Paper, Typography, Divider, useMediaQuery } from '@mui/material'
import { LocationModel } from '@services/model/location.model'
import { LeafletEvent, Icon } from 'leaflet'
import { useMapEvents, Marker, Popup } from 'react-leaflet'
import FormMapCore from '@components/form/mapCore'
import { forwardRef, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import axios from 'axios'
import MapPinIcon from '@components/icon/mapPin'
import RoadIcon from '@components/icon/road'
import InfoIcon from '@components/icon/info'
import RefreshIcon from '@components/icon/refresh'
import MoveMarkerIcon from '@components/icon/moveMarker'
import uuid from 'react-uuid'
import { styled, useTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import RouteIcon from '@components/icon/route'
import * as locator from '@arcgis/core/rest/locator.js'
import Point from '@arcgis/core/geometry/Point.js'
import proj4 from 'proj4'

const GEOCODE_URL =
  'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location='

export const reverseGeoCoding = async (coordinates: LocationModel): Promise<string | undefined> => {
  try {
    const res = await axios.get(
      `${GEOCODE_URL}${coordinates.locationLongitude},${coordinates.locationLatitude}`,
    )
    return res.data.address.LongLabel as string
  } catch (err) {
    console.error(`error getting geo-location: ${err}`)
  }
}

const MediumMapContainer = styled(Box)<{
  state: {
    height?: string | number
    width?: string | number
    maxWidth?: string | number
    movable?: boolean
  }
}>(({ theme, state }) => ({
  margin: theme.spacing(1),
  maxWidth: state.maxWidth ?? '100%',
  display: 'flex',
  flexDirection: 'row',
  ...(state.movable && {
    border: '2px solid #1b88cc',
    borderRadius: 5,
  }),
  position: 'relative',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    height: state.height ?? 300,
    width: state.width ?? '100%',
  },
  [theme.breakpoints.down('md')]: {
    height: 600,
    width: '100%',
    flexDirection: 'row',
  },
}))

const MapDetailContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f1f1f1',
  overflow: 'hidden',
  position: 'absolute',
  zIndex: 50,
  [theme.breakpoints.up('md')]: {
    width: '41%',
    height: '100%',
    left: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '41%',
    bottom: 0,
  },
}))

const MapDetailInformationContainer = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '50%',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: '60%',
  },
}))

const MapDetailInformationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'inherit',
  gap: theme.spacing(1),
  alignItems: 'center',
  overflow: 'hidden',
}))

const MapDetailCaptureContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  gap: theme.spacing(1),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

const MapDetailButton = styled(Paper)<{
  state: {
    disabled?: boolean
    uneditable?: boolean
    clicked?: boolean
    svgId?: string
  }
}>(({ state, theme }) => ({
  padding: theme.spacing(1),
  ...(!state.disabled &&
    !state.uneditable && {
      '@media (hover: hover)': {
        ':hover': {
          backgroundColor: '#1b88cc',
          cursor: 'pointer',
          ...(state.svgId && {
            ['#' + state.svgId]: {
              filter: 'brightness(0) invert(1)',
            },
          }),
        },
      },
    }),
  ...(state.clicked && {
    backgroundColor: '#1b88cc',
    ...(state.svgId && {
      ['#' + state.svgId]: {
        filter: 'brightness(0) invert(1)',
      },
    }),
  }),
}))

interface formMediumMapProps {
  value?: LocationModel
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  height?: number | string
  width?: number | string
  maxWidth?: number | string
  popUpText?: string
  isMounted?: boolean

  additionalChangeAction?: (address: string, lat: number, lng: number) => void

  disabled?: boolean
  uneditable?: boolean
  preventBrowserLocation?: boolean
}

const FormMediumMap = forwardRef((props: formMediumMapProps, ref) => {
  const { t } = useTranslation()
  const [center, setCenter] = useState<LocationModel | undefined>(props.value)
  const [movable, setMovable] = useState<boolean>(false)
  const [getNearestRoad, setGetNearestRoad] = useState<boolean>(false)
  const [fkey, setFkey] = useState<string>(uuid())
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const browserLocation = navigator.geolocation
  const mapLocationRef = useRef<{
    locationAddress: string
    locationLatitude: number
    locationLongitude: number
  }>()
  const { enqueueSnackbar } = useSnackbar()

  const MapControl = () => {
    useMapEvents({
      move: (e: LeafletEvent) => {
        if (movable && !props.disabled && !props.uneditable && e.target.getCenter) {
          const _center = e.target.getCenter()
          setCenter({
            locationAddress: center ? center.locationAddress : '',
            locationLatitude: parseFloat(_center.lat),
            locationLongitude: parseFloat(_center.lng),
          })
        }
      },
      moveend: async () => {
        if (center && movable && !props.disabled && !props.uneditable) {
          const address = await reverseGeoCoding(center)
          setCenter({
            locationAddress: address ?? t('Unknown Address'),
            locationLatitude: center.locationLatitude,
            locationLongitude: center.locationLongitude,
          })
          props.onChange!({
            locationAddress: address ?? t('Unknown Address'),
            locationLatitude: center.locationLatitude,
            locationLongitude: center.locationLongitude,
          })
          if (props.additionalChangeAction) {
            props.additionalChangeAction(
              address ?? t('Unknown Address'),
              center.locationLatitude,
              center.locationLongitude,
            )
          }
        }
      },
      click: async (e: LeafletEvent) => {
        if (center && getNearestRoad && !props.disabled && !props.uneditable) {
          const _center = e.target.getCenter()
          await locator
            .locationToAddress(
              `https://api.hkmapservice.gov.hk/ags/gc/ib1000/transportation/streetcentrelines?key=${process.env.REACT_APP_LAND_KEY}`,
              {
                location: new Point({
                  latitude: _center.lat,
                  longitude: _center.lng,
                }),
                locationType: 'street',
              },
            )
            .then(({ location, address: locationAddress }) => {
              /*
              * The coordainate system format is generated by this link:
              * https://mygeodata.cloud/cs2cs/
              */
              const [locationLongitude, locationLatitude] = proj4(
                '+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.243649,-1.158827,-1.094246 +units=m +no_defs',
                '+proj=longlat +datum=WGS84 +no_defs',
                [location.x, location.y, location.z, location.m],
              )
              setCenter({
                locationAddress,
                locationLatitude,
                locationLongitude
              })
              props.onChange!({
                locationAddress,
                locationLatitude,
                locationLongitude
              })
              if (props.additionalChangeAction) {
                props.additionalChangeAction(
                  locationAddress ?? t('Unknown Address'),
                  locationLatitude,
                  locationLongitude,
                )
              }
              setGetNearestRoad(false)
            })
            .catch((e: Error) => {
              enqueueSnackbar("Cannot find nearest road in selected road. Please click another point.", { variant: 'error' })
            });
        }
      },
    })
    return null
  }

  const [count, setCounter] = useState(0)
  useEffect(() => {
    if (!browserLocation) {
      alert('Geolocation is not supported by your browser')
    } else if (!props.value && !props.uneditable && !props.preventBrowserLocation) {
      //&& props.preventBrowserLocation
      browserLocation.getCurrentPosition((position) => {
        const _center = {
          locationAddress: '',
          locationLatitude: position.coords.latitude,
          locationLongitude: position.coords.longitude,
        }

        reverseGeoCoding({
          locationAddress: '',
          locationLatitude: position.coords.latitude,
          locationLongitude: position.coords.longitude,
        })
          .then((x) => {
            _center.locationAddress = x || ''
            mapLocationRef.current = _center
            setCounter((p) => p + 1)
          })
          .catch((x) => console.log('reverseGeoCoding err', x))
      })
    }
  }, [browserLocation, props.isMounted])

  useEffect(() => {
    if (mapLocationRef.current) {
      setFkey(uuid())
      setCenter(mapLocationRef.current)

      if (props.onChange && props.isMounted) {
        props.onChange(mapLocationRef.current)

        if (props.additionalChangeAction) {
          props.additionalChangeAction(
            mapLocationRef.current.locationAddress,
            mapLocationRef.current.locationLatitude,
            mapLocationRef.current.locationLongitude,
          )
        }
      }
    }
  }, [count])

  useEffect(() => {
    if (props.isMounted === undefined || props.isMounted === true) {
      setCenter(props.value)
    }
  }, [props.value, props.isMounted])

  const mounted = useRef<boolean>(false)
  useEffect(() => {
    if (!mounted.current && center) {
      setFkey(uuid())
      mounted.current = true
      props.onChange!(center)
    }

    if (mounted.current && center) {
    }
  }, [center])

  useEffect(() => {
    if (mounted.current && props.value != center && !movable) {
      setFkey(uuid())
    }
  }, [mounted, props.value])

  useEffect(() => {
    setFkey(uuid())
  }, [downMd])

  const handleMovableChange = async () => {
    if (!props.disabled && !props.uneditable) {
      setGetNearestRoad(false)
      setMovable(!movable)
      setCenter(
        props.value ?? {
          locationAddress:
            (await reverseGeoCoding({
              locationAddress: '',
              locationLatitude: 22.3814682,
              locationLongitude: 114.187941,
            })) ?? '',
          locationLatitude: 22.3814682,
          locationLongitude: 114.187941,
        },
      )
    }
  }

  const handleGetNearestRoad = async () => {
    if (!props.disabled && !props.uneditable) {
      setMovable(false)
      setGetNearestRoad(!getNearestRoad)
    }
  }

  return (
    <MediumMapContainer
      ref={ref}
      state={{
        height: props.height,
        width: props.width,
        maxWidth: props.maxWidth,
        movable,
      }}
      sx={{
        border: props.error !== undefined ? 'red 1px solid' : 'unset',
      }}>
      <MapDetailContainer>
        <MapDetailInformationContainer>
          <MapDetailInformationBox>
            <RoadIcon
              sx={{
                height: 21,
                width: 21,
              }}
            />
            <Box
              sx={{
                height: '100%',
                alignSelf: 'flex-start',
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
              }}>
              <Typography
                sx={{
                  height: 'inherit',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  fontSize: 16,
                }}>
                {center ? center.locationAddress : '-'}
              </Typography>
            </Box>
          </MapDetailInformationBox>
          <Divider />
          <MapDetailInformationBox>
            <MapPinIcon
              sx={{
                height: 21,
                width: 21,
              }}
            />
            <Box
              sx={{
                height: '100%',
                alignSelf: 'flex-start',
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
              }}>
              <Typography
                sx={{
                  height: 'inherit',
                  fontSize: 16,
                }}>
                {center
                  ? `${center.locationLatitude.toFixed(8)}, ${center.locationLongitude.toFixed(8)}`
                  : '-'}
              </Typography>
            </Box>
          </MapDetailInformationBox>
        </MapDetailInformationContainer>

        <Box
          sx={{
            position: 'relative',
            height: 48,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 8px',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
            }}>
            {/* <Box>
              <MapDetailButton
                state={{
                  disabled: props.disabled,
                  uneditable: props.uneditable,
                  clicked: getNearestRoad,
                  svgId: 'route-icon',
                }}
                onClick={handleGetNearestRoad}>
                <RouteIcon id="route-icon" />
              </MapDetailButton>
            </Box> */}
            <Box>
              <MapDetailButton
                state={{
                  disabled: props.disabled,
                  uneditable: props.uneditable,
                  clicked: movable,
                  svgId: 'move-marker-icon',
                }}
                onClick={handleMovableChange}>
                <MoveMarkerIcon id="move-marker-icon" />
              </MapDetailButton>
            </Box>
            <Box>
              <MapDetailButton
                state={{
                  disabled: props.disabled,
                  uneditable: props.uneditable,
                  svgId: 'refresh-icon',
                }}
                onClick={() => {
                  if (browserLocation) {
                    browserLocation.getCurrentPosition(
                      async (position) => {
                        const _center = {
                          locationAddress:
                            (await reverseGeoCoding({
                              locationAddress: '',
                              locationLatitude: position.coords.latitude,
                              locationLongitude: position.coords.longitude,
                            })) ?? '',
                          locationLatitude: position.coords.latitude,
                          locationLongitude: position.coords.longitude,
                        }
                        setMovable(false)
                        setCenter(_center)
                        setFkey(uuid())
                        mounted.current = false
                      },
                      () => {
                        // alert('Unable to retrieve your location')
                      },
                    )
                  }
                }}>
                <RefreshIcon id="refresh-icon" />
              </MapDetailButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 1, position: 'relative' }}></Box>
        <Paper
          sx={{
            height: '15%',
            padding: 1,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}>
          <MapDetailCaptureContainer>
            <InfoIcon
              sx={{
                height: 21,
                width: 21,
              }}
            />
            <Typography
              sx={{
                whiteSpace: 'break-spaces',
                fontSize: 14,
              }}>
              {center ? t('Location capture success') : t('Please move to capture location')}
            </Typography>
          </MapDetailCaptureContainer>
        </Paper>
      </MapDetailContainer>
      <FormMapCore
        key={fkey}
        sx={{
          borderRadius: 5,
          zIndex: 1,
          width: downMd ? '100%' : '60%',
          height: downMd ? '60%' : '100%',
          position: 'absolute',
          ...(downMd && {
            top: 0,
          }),
        }}
        enableSearch
        esriEventHandlers={{
          results: async (e) => {
            if (e.latlng) {
              const _center = e.latlng
              const address = e.text
              setCenter({
                locationAddress: address ?? t('Unknown Address'),
                locationLatitude: _center.lat,
                locationLongitude: _center.lng,
              })
              props.onChange!({
                locationAddress: address ?? t('Unknown Address'),
                locationLatitude: _center.lat,
                locationLongitude: _center.lng,
              })
              if (props.additionalChangeAction) {
                props.additionalChangeAction(
                  address ?? t('Unknown Address'),
                  _center.lat,
                  _center.lng,
                )
              }
            }
          },
        }}
        center={
          center
            ? {
                lat: center.locationLatitude,
                lng: center.locationLongitude,
              }
            : undefined
        }>
        <MapControl />
        {center && !getNearestRoad && (
          <Marker
            icon={
              new Icon.Default({
                iconRetinaUrl: iconRetinaUrl,
                iconUrl: '/img/location-on.svg',
                shadowUrl: shadowUrl,
              })
            }
            position={{
              lat: center.locationLatitude,
              lng: center.locationLongitude,
            }}>
            {props.popUpText && <Popup>{t(props.popUpText)}</Popup>}
          </Marker>
        )}
      </FormMapCore>
    </MediumMapContainer>
  )
})

export default FormMediumMap

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { FormPartComponents, KeyValPair } from '@models/common'
import { Control, UseFormSetValue } from 'react-hook-form'
import FormField from '@components/form/field'
import FormRadio from '@components/form/radio'
import FormMultipleChoices from '@components/form/multipleChoices'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import { Typography, TextField } from '@mui/material'
import { FormScrModel } from '@services/model/form/form.SCR.model'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import FormBinaryRadio from '@components/form/binaryRadio'
import { Fragment, useState } from 'react'
import FormFreeText from '@components/form/freeText'
import { FormStatusEnum } from '@services/model/form/form.model'
import FormTypography from '@components/form/typography'

export default ({
  control,
  useWatch,
  formStatus,
}: {
  control: Control<FormScrModel>
  useWatch: any
  formStatus: string
}) => {
  const UUID = uuid()
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

  const PartFessentialOperationOther = ({
    control,
    options,
  }: {
    control: Control<FormScrModel>
    options: KeyValPair[]
  }) => {
    const essentialOperation = useWatch({
      name: 'essentialOperation',
      control,
    })

    const founded = essentialOperation?.find(
      (x) => x === options?.find((o) => o.value === 'Other')?.key,
    )
    const matched = founded !== undefined ? true : false

    if (matched) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: 'essentialOperationOther',
              control: control,
            }}>
            {editable ? (
              <TextField
                fullWidth
                multiline
                rows={7}
                placeholder={t('Please fill in information')}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const PartFinSituTestOther = ({
    control,
    options,
  }: {
    control: Control<FormScrModel>
    options: KeyValPair[]
  }) => {
    const inSituTest = useWatch({
      name: 'inSituTest',
      control,
    })

    const founded = inSituTest?.find((x) => x === options?.find((o) => o.value === 'Other')?.key)
    const matched = founded !== undefined ? true : false

    if (matched) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: 'inSituTestOther',
              control: control,
            }}>
            {editable ? (
              <TextField
                fullWidth
                multiline
                rows={7}
                placeholder={t('Please fill in information')}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const PartFinSituSampleOther = ({
    control,
    options,
  }: {
    control: Control<FormScrModel>
    options: KeyValPair[]
  }) => {
    const inSituSample = useWatch({
      name: 'inSituSample',
      control,
    })

    const founded = inSituSample?.find((x) => x === options?.find((o) => o.value === 'Other')?.key)
    const matched = founded !== undefined ? true : false

    if (matched) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: 'inSituSampleOther',
              control: control,
            }}>
            {editable ? (
              <TextField
                fullWidth
                multiline
                rows={7}
                placeholder={t('Please fill in information')}
              />
            ) : (
              <FormTypography />
            )}
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const LsgStatisfactoryField = ({
    control,
    options,
  }: {
    control: Control<FormScrModel>
    options: KeyValPair[]
  }) => {
    const lsgSatisfactionId = useWatch({
      name: 'lsgSatisfactionId',
      control,
    })

    const founded = options?.find((o) => o.key === lsgSatisfactionId && o.value === 'Unsatisfactory')
    const matched = founded !== undefined ? true : false

    return (
      <Fragment>
        <FormField fieldName="Category">
          <Typography>
            {t(
              'Lighting, Signing and Guarding (Incl. publicity board and traffic and pedestrain diversion)',
            )}
          </Typography>
        </FormField>

        <FormField fieldName={''} removeColon>
          <Fragment>
            <FormController
              controllerProps={{
                name: 'lsgSatisfactionId',
                control: control,
              }}>
              <FormRadio
                labels={options}
                  // uneditable={!editable}
                disabled={!editable}
              />
            </FormController>

            {matched ? (
              <FormController
                controllerProps={{
                  name: 'lsgReason',
                  control: control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  sx={{ marginTop: '45px' }}
                    // uneditable={!editable}
                  disabled={!editable}
                />
              </FormController>
            ): (<></>)}
          </Fragment>
        </FormField>
      </Fragment>
    )
  }

  const QosStatisfactoryField = ({
    control,
    options,
  }: {
    control: Control<FormScrModel>
    options: KeyValPair[]
  }) => {
    const qowSatisfactionId = useWatch({
      name: 'qowSatisfactionId',
      control,
    })

    const founded = options?.find((o) => o.key === qowSatisfactionId && o.value === 'Unsatisfactory')
    const matched = founded !== undefined ? true : false

    return (
      <Fragment>
        <FormField fieldName="Category">
          <Typography>{t('Quality of Works')}</Typography>
        </FormField>

        <FormField fieldName={''} removeColon>
          <Fragment>
            <FormController
              controllerProps={{
                name: 'qowSatisfactionId',
                control: control,
              }}>
              <FormRadio
                labels={options}
                  // uneditable={!editable}
                disabled={!editable}
              />
            </FormController>

            {matched ? (
              <FormController
                controllerProps={{
                  name: 'qowReason',
                  control: control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  sx={{ marginTop: '45px' }}
                    // uneditable={!editable}
                  disabled={!editable}
                />
              </FormController>
            ) : ( <></> )}
          </Fragment>
        </FormField>
      </Fragment>
    )
  }

  const EmStatisfactoryField = ({
    control,
    options,
  }: {
    control: Control<FormScrModel>
    options: KeyValPair[]
  }) => {
    const emSatisfactionId = useWatch({
      name: 'emSatisfactionId',
      control,
    })

    const founded = options?.find((o) => o.key === emSatisfactionId && o.value === 'Unsatisfactory')
    const matched = founded !== undefined ? true : false

    return (
      <Fragment>
        <FormField fieldName="Category">
          <Typography>{t('Environmental Monitoring')}</Typography>
        </FormField>

        <FormField fieldName={''} removeColon>
          <Fragment>
            <FormController
              controllerProps={{
                name: 'emSatisfactionId',
                control: control,
              }}>
              <FormRadio
                labels={options}
                  // uneditable={!editable}
                disabled={!editable}
              />
            </FormController>

            {matched ? (
              <FormController
                controllerProps={{
                  name: 'emReason',
                  control: control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  sx={{ marginTop: '45px' }}
                    // uneditable={!editable}
                  disabled={!editable}
                />
              </FormController>
            ) : ( <></> )}
          </Fragment>
        </FormField>
      </Fragment>
    )
  }

  return (
    <FormContainer fkey={`${UUID}-scr-f`}>
      <FormField fieldName="Category">
        <Typography>{t('Estimated Percentage of Works Completion')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'workCompletionId',
            control: control,
          }}>
          <FormRadio
            labels={getOptionsByKey('Form SD - Estimated Percentage of Works Completion')}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Category">
        <Typography>{t('Site Tidiness - Mosquito breeding prevented?')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'mosquitoBpId',
            control: control,
          }}>
          <FormRadio
            labels={getOptionsByKey('Default Option - Yes/No')}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Category">
        <Typography>
          {t('Site Tidiness - Litter/over-accumulated wastes absent on site?')}
        </Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'loaWastesAbsentId',
            control: control,
          }}>
          <FormRadio
            labels={getOptionsByKey('Default Option - Yes/No')}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Category">
        <Typography>{t('Site Tidiness - Materials properly stockpilled?')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'mpStockpiledId',
            control: control,
          }}>
          <FormRadio
            labels={getOptionsByKey('Default Option - Yes/No')}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <LsgStatisfactoryField control={control} options={getOptionsByKey('Default Option - Satisfactory/Unsatisfactory')}/>
      <QosStatisfactoryField control={control} options={getOptionsByKey('Default Option - Satisfactory/Unsatisfactory')}/>
      <EmStatisfactoryField control={control} options={getOptionsByKey('Default Option - Satisfactory/Unsatisfactory')}/>

      <FormField fieldName="Category">
        <Typography>{t('Essential Operations Involved')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'essentialOperation',
            control: control,
          }}>
          <FormMultipleChoices
            options={getOptionsByKey('Form SD - Essential Operations Involved')?.map((eo) => ({
              key: eo.key,
              value: eo.value,
              isOthers: eo.value === 'Others',
              isNa: eo.value === 'N/A',
            }))}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <PartFessentialOperationOther
        control={control}
        options={getOptionsByKey('Form SD - Essential Operations Involved')}
      />

      <FormField fieldName="Category">
        <Typography>{t('In-situ Test(s)/Sampling Witenessed on Site')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'inSituTest',
            control: control,
          }}>
          <FormMultipleChoices
            options={getOptionsByKey('Form SD - In-situ Test')?.map((ist) => ({
              key: ist.key,
              value: ist.value,
              isOthers: ist.value === 'Others',
              isNa: ist.value === 'N/A',
            }))}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <PartFinSituTestOther control={control} options={getOptionsByKey('Form SD - In-situ Test')} />

      <FormField fieldName="Category">
        <Typography>{t('In-situ Sample(s) obtained')}</Typography>
      </FormField>

      <FormField fieldName="" removeColon>
        <FormController
          controllerProps={{
            name: 'inSituSample',
            control: control,
          }}>
          <FormMultipleChoices
            options={getOptionsByKey('Form SD - In-situ Sample')?.map((iss) => ({
              key: iss.key,
              value: iss.value,
              isOthers: iss.value === 'Others',
              isNa: iss.value === 'N/A',
            }))}
              // uneditable={!editable}
            disabled={!editable}
          />
        </FormController>
      </FormField>

      <PartFinSituSampleOther
        control={control}
        options={getOptionsByKey('Form SD - In-situ Sample')}
      />
    </FormContainer>
  )
}

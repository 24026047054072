import svgUrl from '@images/road.svg'
import { Box } from '@mui/material'
import { CSSProperties } from 'react'

interface iconProps {
    sx?: CSSProperties
}

const RoadIcon = (props: iconProps) => {
    return (
        <Box style={{ height: 20, width: 20, ...props.sx }}>
            <img src={svgUrl} alt="road-icon" style={{ height: 'inherit', width: 'inherit' }} />
        </Box>
    )
}

export default RoadIcon

import { ChildFriendlySharp } from '@mui/icons-material'
import { Box, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  // padding: theme.spacing(1),
}))

const FormSubFieldContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '14px 60px',
  },
  minHeight: 67,
  whiteSpace: 'initial',
  wordBreak: 'break-word',
}))

const FormFieldNameBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 200,
    width: 200,
    // padding: theme.spacing(1),
  },
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
}))

const FormFieldContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    // padding: theme.spacing(1),
  },
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
}))

interface formCompactFieldProps {
  hidden?: boolean
  childrens: {
    component: JSX.Element
    fieldName: string
    subfieldName?: string
    hidden?: boolean
    fieldTooltip?: string
    fieldTooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
    removeColon?: boolean
  }[]
}

/**
 * form field wrapping a child jsx element
 * @param props formFieldProps
 * @example
 * ```
 * <FormField fieldName="Weather" index={8}>
 *      <FormController
 *          controllerProps={{
 *              name: 'weatherId',
 *              control: control,
 *          }}>
 *          <FormSelect fullWidth options={masterData.weather} />
 *      </FormController>
 *  </FormField>
 * ```
 */
const FormCompactField = (props: formCompactFieldProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const nonHiddenChildrens = props.childrens.filter((child) => !child.hidden)

  return (
    <Grid
      container
      {...(!downMd && { item: true, xs: 12, className: 'grid-item' })}
      {...(props.hidden && { display: 'hidden' })}>
      {nonHiddenChildrens.map((child, childIndex) => (
        <FormSubFieldContainer
          key={`form-compact-field-${childIndex}`}
          item
          {...(!downMd && { xs: Math.floor(12 / nonHiddenChildrens.length) })}
          {...(downMd && { xs: 12, className: 'grid-item' })}>
          <FormFieldNameBox>
            <Tooltip
              title={child.fieldTooltip ? t(child.fieldTooltip) : ''}
              placement={child.fieldTooltipPlacement ?? 'right'}>
              <Box>
                <StyledTypography>
                  {t(child.fieldName) + (!child.removeColon && !child.subfieldName ? ':' : '')}
                </StyledTypography>
                {child.subfieldName && (
                  <StyledTypography>{t(child.subfieldName) + ':'}</StyledTypography>
                )}
              </Box>
            </Tooltip>
          </FormFieldNameBox>
          <FormFieldContentBox>{child.component}</FormFieldContentBox>
        </FormSubFieldContainer>
      ))}
    </Grid>
  )
}
export default FormCompactField

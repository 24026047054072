import { FileModel } from '@services/model/file.model'
import axios from 'axios'

/**
 * remove image base64 header
 * @param base64 base64 image string
 * @returns base64 string
 * @example
 * ```
 * const base64 = RemoveBase64Header(imageBase64)
 * ```
 */
const RemoveBase64Header = (base64: string): string => {
  return base64.split(',')[1] ? base64.split(',')[1] : base64.split(',')[0]
}

const ConvertBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(RemoveBase64Header(reader.result as string))
    reader.onerror = (err) => reject(err)
  })

const ReadFile = (file: File): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = (err) => reject(err)
  })

const GetFileFromUrl = async (file: FileModel): Promise<Blob | undefined> => {
  if (file.fileUrl && file.fileUrl !== '') {
    const res = await axios.get(file.fileUrl, {
      responseType: 'blob',
      headers: {
        'Content-type': 'application/octet-stream',
      },
    })
    return res.data as Blob
  }
}

const GetPdfPageNum = (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      //@ts-ignore
      resolve(parseInt(reader?.result?.match(/\/Type[\s]*\/Page[^s]/g)?.length) )
    }
    reader.onerror = () => reject
  })

const FileToFileModel = async (file: File): Promise<FileModel | undefined> => {
    const fileName = file.name.split('.')

    const _file: FileModel = {
      fileId: '',
      status: true,
      fileName: fileName[0],
      ext: fileName[fileName.length - 1],
      fileUrl: '',
      size: file.size,
      bytes: await ReadFile(file),
      bytesBase64: await ConvertBase64(file),
    }

    if(_file.size >	60000000){
      throw new Error("File size exceeded")
    }
    
    if (fileName[fileName.length - 1] === 'pdf') {
      _file.pdfPageNum = await GetPdfPageNum(file)
    }
    return _file
  
}

export { ConvertBase64, FileToFileModel, GetFileFromUrl, RemoveBase64Header, ReadFile }

import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { find, get } from 'lodash'
import { Grid, IconButton, Tooltip } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import useTableView from '@hooks/useTableView'
import { RightsCategory } from '@services/model/form/form.model'
import { FormNFListModel, NfFilterOptions } from '@services/model/form/form.NF.model'
import FormNFService from '@services/formService/form.NF.service'
import SearchPanel from '@components/searchPanel'
import FormStatusLabel from '@components/FormStatusLabel'
import ExportIcon from '@components/icon/export'
import GetValueWithKey from '@utils/getValueWithKey'
import { NavigateTo } from '@utils/navigate'
import { exportToCSV } from '@utils/exportExcel'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [extraListInfo, setExtraListInfo] = useState<NfFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    parentFormIdList: [],
    workOrderIdList: [],
    defectTypes: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormNFService.GetNFFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const getTableFullList = async () => {
    let fullList = await FormNFService.GetNFList(
      {
        findInBatches: true,
        pagination: { skipPagination: true },
      },
      '',
      props.showMyRecord || false,
    )
    let list: any[] = fullList.list.map((x) => {
      return {
        'General Status': x.formStatusShortName,
        'Submission Status': x.formStatus,
        'EI Form': x.parentReportNo,
        'Form ID': x.reportNo,
        'DN Type': x.nonComplianceId
          ?.map((x) =>
            GetValueWithKey(
              x,
              globalState.generalOptionDNGroupList?.map((x) => {
                return { key: x.id, value: x.dnRefNo }
              }) ?? [],
            ),
          )
          .join(','),
        'Road Name': x.roadName,
        'Defect Type': x.defectType,
        'Case Source': x.caseSourceID,
        'ICC number': x.iccNo,
        'Location Remarks': x.locationRemark,
        'Inspection Date': x.inspectionDate
          ? moment(x.inspectionDate).format(moment.defaultFormat)
          : 'N/A',
        'Due Date': x.dueDate ? moment(x.dueDate).format(moment.defaultFormat) : 'N/A',
        'Submission Date': x.submissionDate
          ? moment(x.submissionDate).format(moment.defaultFormat)
          : 'N/A',
        'Issue Date': x.issueDate ? moment(x.issueDate).format(moment.defaultFormat) : 'N/A',
        'Completion Date': x.completionDate
          ? moment(x.completionDate).format(moment.defaultFormat)
          : 'N/A',
        'Submitted By': GetValueWithKey(x.submittedBy, globalState.userMetaList ?? []),
        'Approved By': GetValueWithKey(x.approvedBy, globalState.userMetaList ?? []),
        'Last update': x.updatedAt ? moment(x.updatedAt).format(moment.defaultFormat) : 'N/A',
      }
    })

    return list ?? []
  }

  const { t } = useTranslation()
  const [TableView, reload] = useTableView<FormNFListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formEiNo',
        desc: t('EI Form', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <Fragment>{record.parentReportNo}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'dnType',
        desc: t('DN Type', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <Fragment>
            {record.nonComplianceId?.map((x) =>
              GetValueWithKey(
                x,
                globalState.generalOptionDNGroupList?.map((x) => {
                  return { key: x.id, value: x.dnRefNo }
                }) ?? [],
              ),
            )}
          </Fragment>
        ),
      },
      {
        key: 'roadName',
        desc: t('Road Name', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <Fragment>{record.roadName}</Fragment>,
        width: '250px',
      },
      {
        key: 'defectType',
        desc: t('Defect Type', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <>{record.defectType ?? '—'}</>,
      },
      {
        key: 'caseSourceID',
        desc: t('Case Source', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <>
            {find(
              get(
                globalState.formOptionsList?.find(({ key }) => key === 'NF'),
                'value.caseSource',
                [],
              ),
              { id: record.caseSourceID },
            )?.name ?? '—'}
          </>
        ),
      },
      {
        key: 'iccNo',
        desc: t('ICC Number', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <>{record.iccNo ?? '—'}</>,
      },
      {
        key: 'locationRemark',
        desc: t('Location Remarks', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => <>{record.locationRemark ?? '—'}</>,
      },
      {
        key: 'inspectionDate',
        desc: t('Inspection Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <>
            {record.inspectionDate
              ? moment(record.inspectionDate).format(moment.defaultFormat)
              : '—'}
          </>
        ),
      },
      {
        key: 'dueDate',
        desc: t('Due Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <>{record.dueDate ? moment(record.dueDate).format(moment.defaultFormat) : '—'}</>
        ),
      },
      {
        key: 'submissionDate',
        desc: t('Submission Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <>
            {record.submissionDate
              ? moment(record.submissionDate).format(moment.defaultFormat)
              : '—'}
          </>
        ),
      },
      {
        key: 'issueDate',
        desc: t('Issue Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <>{record.issueDate ? moment(record.issueDate).format(moment.defaultFormat) : '—'}</>
        ),
      },
      {
        key: 'completionDate',
        desc: t('Completion Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) => (
          <>
            {record.completionDate
              ? moment(record.completionDate).format(moment.defaultFormat)
              : '—'}
          </>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) =>
          GetValueWithKey(record.submittedBy, globalState.userMetaList ?? []) ?? '—',
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) =>
          GetValueWithKey(record.approvedBy, globalState.userMetaList ?? []) ?? '—',
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormNFListModel) =>
          record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : '—',
        mobileHidden: true,
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: FormNFListModel) {
      NavigateTo(navigate, '/notification-form-of-defect/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('NF', JSON.stringify(state.filterResult))
        let resp = await FormNFService.GetNFList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_NF))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.formStatus?.value
                  : [],
            },
            {
              key: 'formEiNo',
              desc: 'EI Form',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.parentFormIdList,
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.formEiNo?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.formId?.value
                  : [],
            },
            {
              key: 'roadName',
              desc: 'Road Name',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.roadName?.value
                  : '',
            },
            {
              key: 'defectTypes',
              desc: 'Defect Types',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: extraListInfo.defectTypes ?? [],
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.defectTypes?.value
                  : [],
            },
            {
              key: 'caseSourceID',
              desc: 'Case Source',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList:
                get(
                  globalState.formOptionsList?.find(({ key }) => key === 'NF'),
                  'value.caseSource',
                  [],
                ).map((x) => {
                  return {
                    key: String(x.id),
                    value: x.name,
                  }
                }) || [],
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.caseSourceID?.value
                  : [],
            },
            {
              key: 'iccNo',
              desc: 'ICC Number',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.iccNo?.value
                  : '',
            },
            {
              key: 'locationRemark',
              desc: 'Location Remarks',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.locationRemark?.value
                  : '',
            },
            {
              key: 'inspectionDate',
              desc: 'Inspection Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.inspectionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.inspectionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.inspectionDate?.value?.max
                  : '',
            },
            {
              key: 'dueDate',
              desc: 'Due Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.dueDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.dueDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.dueDate?.value?.max
                  : '',
            },
            {
              key: 'submissionDate',
              desc: 'Submission Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.submissionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.submissionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.submissionDate?.value?.max
                  : '',
            },
            {
              key: 'issueDate',
              desc: 'Issue Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.issueDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.issueDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.issueDate?.value?.max
                  : '',
            },
            {
              key: 'completionDate',
              desc: 'Completion Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.completionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.completionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.completionDate?.value?.max
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NF !== 'undefined' && sessionStorage?.NF !== undefined
                  ? JSON.parse(sessionStorage?.NF)?.updatedAt?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <Tooltip title="Export">
              <IconButton
                sx={{
                  backgroundColor: globalState.headerColor,
                  borderRadius: '10%',
                  width: '40px',
                  height: '40px',
                  '&:hover': { background: globalState.headerColor },
                }}
                onClick={async () => await exportToCSV(await getTableFullList(), 'NF-all-record')}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord

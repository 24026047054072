import { KeyValPair } from '@models/common'

const GetValueWithKey = (
  key: number | string | undefined,
  pairs: KeyValPair[],
): any | undefined => {
  if (key === undefined) return undefined
  for (let i = 0; i < pairs.length; i++) {
    if (pairs[i].key === key) {
      return pairs[i].value
    }
  }
  return undefined
}

export default GetValueWithKey

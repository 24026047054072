import { ImageModel } from '../image.model'
import { LocationModel } from '../location.model'

export interface FormSdpModel {
  uuid: string
  status: boolean
  sdNo?: string
  sdPhotoNo?: string
  photoCategoryId?: number
  images?: ImageModel[]
  location?: LocationModel

  locationMapBase64?: string
  
}

export const InitSDPForm: FormSdpModel = {
  uuid: '',
  status: true,
}

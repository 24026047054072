import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse, { BaseFilterOptions } from '../model/base.model'
import { FormWouModel, WouFilterOptions, WouGeneralOptions, WouListResponse } from '../model/form/form.WOU.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'
import { FormWoiModel } from '@services/model/form/form.WOI.model'

const GetGeneralOptions = async () => {
  return await apiGateway.get<WouGeneralOptions>(`/api/v1/forms/WOU/options`).then((rep) => {
    return rep.data
  })
}

const GetWOUForm = async (formId: string) => {
  return await apiGateway.get<FormWouModel>(`api/v1/forms/WOU/${formId}`).then((rep) => {
    return rep.data
  })
}

const GetWouList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<WouListResponse>(`api/v1/forms/WOU/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetWouFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/WOU/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<WouFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveWOUForm = async (req: FormWouModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/WOU', req).then((rep) => {
      return rep.data
    })
  else
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/WOU/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
}

const ApproveWOUForm = async (req: FormWouModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/WOU/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectWOUForm = async (req: FormWouModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/WOU/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteWOUForm = async (req: FormWouModel) => {
  return await apiGateway.delete<BaseFormResponse>(`api/v1/forms/WOU/${req.baseForm.formId}`)
}

const GetWOIData = async (workOrderId: number) => {
  return await apiGateway
    .post<FormWoiModel>(`api/v1/forms/WOU/data`, { workOrderId: workOrderId })
    .then((rep) => {
      return rep.data
    })
}

export default {
  GetWOUForm,
  GetWouList,
  GetWouFilterOption,
  SaveWOUForm,
  ApproveWOUForm,
  RejectWOUForm,
  DeleteWOUForm,
  GetGeneralOptions,
  GetWOIData,
}

import apiGateway from '@utils/axiosInterceptor'
import { FormSDModel, FormSdListModel, SDGeneralOptions, CdrOptions, SdListResponse, SdFilterOptions } from '../model/form/form.SD.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetSdForm = async (formId: string) => {
  return await apiGateway
    .get<FormSDModel>(`api/v1/forms/SD/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetCdrOptions = async (req: {
  contractNoId: number
  formDate: Date
  WorkOrderId: number
}) => {
  return await apiGateway.post<CdrOptions>('api/v1/forms/SD/cdr-options', req).then((rep) => {
    return rep.data
  })
}

// const GetSdCategory = async () => {
//   return await apiGateway.get<SdCategory[]>(`api/v1/forms/SD/categories`).then((rep) => {
//     return rep.data
//   })
// }
const GetGeneralOptions = async () => {
  return await apiGateway.get<SDGeneralOptions>(`/api/v1/forms/SD/options`).then((rep) => {
    return rep.data
  })
}

const GetSdList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<SdListResponse>(
      `api/v1/forms/SD/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetExistingSD = async (formId: string, reportDate: string) => {
  return await apiGateway
      .get<FormSDModel>(`/api/v1/forms/clone?category=SD&formId=${formId}&reportDate=${reportDate}`)
      .then(resp => resp.data)
}

const GetSdFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/SD/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<SdFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveSdForm = async (req: FormSDModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/SD', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/SD/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveSdForm = async (req: FormSDModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/SD/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectSdForm = async (req: FormSDModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/SD/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteSdForm = async (req: FormSDModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/SD/${req.baseForm.formId}`,
  )
}

const FormSdService = {
  GetSdForm,
  GetSdList,
  GetExistingSD,
  GetSdFilterOption,
  SaveSdForm,
  ApproveSdForm,
  RejectSdForm,
  DeleteSdForm,
  GetGeneralOptions,
  GetCdrOptions
}

export default FormSdService

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn } from 'react-hook-form'
import FormField from '@components/form/field'
import uuid from 'react-uuid'
import FormFileUpload from '@components/form/fileUpload'
import FormTable from '@components/form/table'
import FormBinaryRadio from "@components/form/binaryRadio";

export default ({
    control,
    getValues,
    readOnly,
    useWatch
}:{
    control: Control<FormSDModel>
    getValues: any
    readOnly: boolean
    useWatch: any
}) => {
    const UUID = uuid()

    const TempHiddenWorkRef = () => {
        const [hiddenWorkCheck] = useWatch({
            name: ['hiddenWorkCheck'],
            control: control,
        })

        return (
            <FormField fieldName="Photo / Attachment Upload">
                <FormController
                    controllerProps={{
                        name: 'hiddenWorkRefs',
                        control: control,
                    }}>
                    <FormFileUpload disabled={readOnly||!hiddenWorkCheck} multipleUpload />
                </FormController>
            </FormField>
        )
    }


    return (
        <FormContainer fkey={`${UUID}-sd-h`}>
            <FormField fieldName="Document Availability" index={0}>
                <FormController
                    controllerProps={{
                        name: 'hiddenWorkCheck',
                        control: control,
                    }}>
                    <FormBinaryRadio
                        binaryOptionNames={{
                            yes: 'Yes',
                            no: 'No',
                        }}
                        uneditable={readOnly}
                    />
                </FormController>
            </FormField>
        {getValues('hasHiddenWorks') ? (
          <FormTable
            columns={[
              {
                field: 'recordNo',
                displayName: 'Hidden Works Record No',
              },
              {
                field: 'contractNo',
                displayName: 'Contract No.',
              },
              {
                field: 'category',
                displayName: 'Category',
              },
              {
                field: 'woNo',
                displayName: 'WO No.',
              },
            ]}
            hiddenItemNo
            disableAddButton
            height={50}
            fieldArrayProps={{
              control: control,
              name: 'hiddenWorkRefs',
            }}
          />
        ) : (

            <TempHiddenWorkRef />
          // <FormField fieldName="Photo/ Attachment Upload" index={0}>
          //   <FormController
          //     controllerProps={{
          //       name: 'hiddenWorkRefs',
          //       control: control,
          //     }}>
          //     <FormFileUpload  uneditable = {readOnly}/>
          //   </FormController>
          // </FormField>
        )}
      </FormContainer>
    )
  }
import { KeyValPair } from '@models/common'
import { BaseListResponse } from '../base.model'
import { FileModel } from '../file.model'
import { FormModel, InitFormModel, InitFormStatusHistory } from './form.model'
import { FormTWOModel, InitTWOForm } from './form.TWO.model'

export interface FormWoiModel {
  baseForm: FormModel

  id?: number // <- system generated

  // a
  mainsWorkOrderNo?: string
  workLocation?: string
  workDesc?: string
  voteType?: number
  // <- dynamic fields
  origEstCost?: number | string
  revEstCost?: number | string
  issueDate?: Date
  daysForCompletion?: number
  originalCompletionDate?: Date
  extendedCompletionDate?: Date
  responsibileEctoEmail?: string
  responsibileInspectorateEmail?: string
  typeOfWorks?: boolean
  coveringWorkOrder?: boolean
  urgencyOfWorks?: number
  justificationOnUrgency?: string
  emergencySerialNo?: string
  workDescRemark?: string

  // dynamic fields
  // fundingMVoteItem?: string <= removed
  bVoteItemNumber?: string
  allocationWarrantItemNumber?: string
  othersVoteItemNumber?: string

  // b
  // contractId
  // twoId?: number
  // justificationOnCoveringWorkOrder?: string
  // dateTimeOfVerbalAgreementFromECto?: Date
  // dateTimeOfVerbalInstructionToContractor?: Date
  typeOfMmWorks?: number
  // districtId
  // teamId
  sorItemNo?: number

  // c
  aggregatedInterimPayments?: number
  dateOfSecondLastIp?: Date
  ceilingOfAggregatedInterimPayment?: number
  applicationOfSbv?: boolean
  dateOfApplicationOfSbv?: Date

  // d
  letterOfSlowProgressIssued?: boolean
  attachmentForLetterOfSlowProgress?: FileModel[]
  slowProgressNumber?: string
  letterOfOverdueProgressIssued?: boolean
  attachmentForLetterOfOverdueProgress?: FileModel[]
  completionStatuses?: WOISiteSupervisionCompletionStatus[] // TODO: backend add this table
  certifiedCompletionDate?: Date
  outstandingWorks?: boolean
  // commencementDateOfOutstandingWorks?: Date
  completionDateOfOutstandingWorks?: Date

  // e
  delayOfWorks?: boolean
  letterOfConfirmation?: boolean
  attachmentForLetterOfNoEot?: FileModel[]
  extensionOfTimes?: WOIExtensionOfTimeAssessment[]

  // f
  liquidatedDamage?: number
  minimumLiquidatedDamage?: number
  issueLetterForLdImposation?: boolean

  // g
  hiddenWorkRecords?: WOIHiddenWorksRecord[]

  // h
  starRates?: WOIStarRateValuation[]

  // i
  siteInstructionValuations?: WOISiteInstructionValuation[]

  // j
  urgencyLevelRequirementComplied?: boolean
  actualUrgencyLevel?: number
  sbv?: number
  lastInterimPayment?: boolean
  relevantInformation?: string
  relevantAttachments?: FileModel[]

  // k
  dateOfFirstContractorSubmission?: Date
  numberOfRevisionsReceived?: number
  dateOfDimensionBookAcceptance?: Date
  paymentRecovery?: boolean
  applicationForExemptions?: WOIDimensionBookByContractor[]

  // l - signature
  signatureRequired: boolean
  signatureBase64?: string
  submissionComment?: string

  // linked two
  linkedTempWorkOrder?: {
    tempWorkOrder: FormTWOModel
  }
}

export interface WoiListResponse extends BaseListResponse {
  list: FormWoiListModel[]
}

export interface WOISiteSupervisionCompletionStatus {
  id: number
  status: boolean
  completionReportSubmissionDate?: Date
  proposedCompletionDate?: Date
  submissionStatus?: string
}

export interface WOIExtensionOfTimeAssessment {
  id: number
  status: boolean
  dateOfContractorNotification?: Date
  dateOfContractorParticularsSubmission?: Date
  completionOrWithdrawal?: number
  dateOfCompletionOrWithdrawal?: Date
}

export interface WOIHiddenWorksRecord {
  id: number
  dateOfContractorSubmission?: Date
  dateOfEngineerReturn?: Date
  dateOfContractorResubmission?: Date
  dateOfEngineerAgreement?: Date
  numberOfPage?: number
}

export interface WOIStarRateValuation {
  id: number
  starRateNo?: number
  dateOfContractorParticularsSubmission?: Date
  dateOfCompletionOfEngineerValuation?: Date
}

export interface WOISiteInstructionValuation {
  id: number
  status: boolean
  dateOfContractorParticularsSubmission?: Date
  dateOfCompletionOfEngineerValuation?: Date
  attachment?: FileModel[]
}

export interface WOIDimensionBookByContractor {
  id: number
  status: boolean
  dateOfFirstContractorApplicationForExemption?: Date
  ectoApproval?: boolean
  dateOfEctoApproval?: Date
}

export const InitWOIForm: FormWoiModel = {
  signatureRequired: true,
  baseForm: {
    ...InitFormModel,
    formStatus: 'FORM_WOI_DRAFT',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  linkedTempWorkOrder: {
    tempWorkOrder: InitTWOForm,
  },
}

export interface WoiGeneralOptions {
  typeOfMmWorks: {
    id: number
    name: string
  }[]
  fundingSource: {
    id: number
    name: string
  }[]
  urgencyLevel: {
    id: number
    name: string
  }[]
  sbv: {
    id: number
    name: string
  }[]
  completionOrWithdraw: {
    id: number
    name: string
  }[]
}

export interface FormWoiListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string

  reportNo: string
  districtId: number[]
  teamId: number[]
  issueDate: Date
  typeOfWorks: boolean
  mainWoStatus: number

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

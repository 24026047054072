import { menuItems } from '@components/sidebar/menuList'

export function getFormColor(formShortName: string, module?: string) {
    let formColorMap = {}
    menuItems.map((module) => {
        let formColorCode = {}
        module.children.map((form) => {
            let shortName = form.acronym ? form.acronym : ''
            formColorCode[shortName] = form.colorCode
            return form
        })
        formColorMap[module.id] = formColorCode
        return module
    })
    let m = module ? module : 'm_1'
    if (formColorMap[m] !== undefined && formColorMap[m][formShortName] !== undefined) {
        return formColorMap[m][formShortName]
    }
    for(const module in formColorMap){
        for(const form in formColorMap[module]){
            if(form === formShortName){
                return formColorMap[module][formShortName]
            }
        }
    }
    return 'white'
}

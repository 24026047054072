import { FormStateProps } from '@providers/formStateProvider'

export type FormStateActionType =
  | {
      type: 'pop'
      targetFormType: string
    }
  | {
      type: 'push'
      form: any
      formType: string
    }
  | {
      type: 'get'
      targetFormType: string
    }
  | {
      type: 'clear'
    }

function FormStateReducer(state: FormStateProps, action: FormStateActionType): FormStateProps {
  switch (action.type) {
    case 'pop': {
      const f = state.formStack.pop()
      if (f && f.formType === action.targetFormType) {
        return {
          ...state,
          form: f.form,
          formType: f.formType,
        }
      } else {
        return {
          ...state,
          isDirty: true,
          formStack: [],
          form: undefined,
          formType: undefined,
        }
      }
    }
    case 'push': {
      state.formStack.push({ form: action.form, formType: action.formType })
      return {
        ...state,
        form: action.form,
        formType: action.formType,
      }
    }
    case 'get': {
      const f = state.formStack[state.formStack.length - 1]
      if (f && f.formType === action.targetFormType) {
        return {
          ...state,
          form: f.form,
          formType: f.formType,
        }
      } else {
        return {
          ...state,
          isDirty: true,
          formStack: [],
          form: undefined,
          formType: undefined,
        }
      }
    }
    case 'clear': {
      return {
        ...state,
        isDirty: false,
        formStack: [],
        form: undefined,
        formType: undefined,
      }
    }
    default: {
      console.error('wrong form state action type')
      return state
    }
  }
}

export default FormStateReducer

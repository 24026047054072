import SearchPanel from '@components/searchPanel'
import { Grid, IconButton, Tooltip } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'

import { CrFilterOptions, FormCrListModel } from '@services/model/form/form.CR.model'
import FormCRService from '@services/formService/form.CR.service'
import { NavigateTo } from '@utils/navigate'
import { KeyValPair } from '@models/common'
import { RightsCategory } from '@services/model/form/form.model'
import ExportIcon from '@components/icon/export'
import { exportToCSV } from '@utils/exportExcel'

export default function AllRecord(props: { showMyRecord?: boolean }) {
  const refMounted = useRef(false)
  const { state: globalState } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<CrFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    workOrderIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormCRService.GetCrFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const getTableFullList = async () => {
    let fullList = await FormCRService.GetCrList(
      {
        pagination: { skipPagination: true },
      },
      '',
      props.showMyRecord || false,
    )
    
    let list: any[] = fullList.list.map((x) => {
      return {
        'General Status': x.formStatusShortName,
        'Submission Status': x.formStatus,
        'Form ID': x.reportNo,
        'Work Order No': x.workOrderNo,
        'Completion Date': x.completionDate,
        'Submitted By': GetValueWithKey(x.submittedBy, globalState.userMetaList ?? []),
        'Approved By': GetValueWithKey(x.approvedBy, globalState.userMetaList ?? []),
        'Last update': x.updatedAt ? moment(x.updatedAt).format(moment.defaultFormat) : 'N/A',
      }
    })
    console.log('list', list)
    return list ?? []
  }

  const [TableView, reload] = useTableView<FormCrListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'workOrderId',
        desc: t('Works Order No', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => <Fragment>{record.workOrderNo}</Fragment>,
      },
      {
        key: 'completionDate',
        desc: t('Completion Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => (
          <Fragment>
            {record.completionDate
              ? moment(record.completionDate).format(moment.defaultFormat)
              : ''}
          </Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormCrListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormCrListModel) {
      NavigateTo(navigate, '/completion-report/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('CR', JSON.stringify(state.filterResult))
        let resp = await FormCRService.GetCrList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_CR))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.formStatus?.value
                  : [],
            },
            {
              key: 'workOrderId',
              desc: 'Works Order No',
              type: 'StringSelectionFilter',
              multiple: true,
               valueList: globalState.worksOrderList?.filter((x) =>
                extraListInfo.workOrderIdList?.includes(x.id),
              )?.map(({ id, workOrderNo}) => ({ key: id, value: workOrderNo})),
              defaultValue:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.formStatus?.value
                  : [],
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.formStatus?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.formStatus?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.updatedAt?.value?.max
                  : '',
            },
            {
              key: 'completionDate',
              desc: 'Completion Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.completionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.completionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.CR !== 'undefined' && sessionStorage?.CR !== undefined
                  ? JSON.parse(sessionStorage?.CR)?.completionDate?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <Tooltip title="Export">
              <IconButton
                sx={{
                  backgroundColor: globalState.headerColor,
                  borderRadius: '10%',
                  width: '40px',
                  height: '40px',
                  '&:hover': { background: globalState.headerColor },
                }}
                onClick={async () => await exportToCSV(await getTableFullList(), 'CR-all-record')}
                >
                <ExportIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

import { useContext, useReducer, useRef, useState }    from 'react'
import { useTranslation }                              from 'react-i18next'
import { NavigateFunction }                            from 'react-router-dom'
import { Grid }                                        from '@mui/material'
import moment                                          from 'moment'
import * as common                                     from '@common/common'
import FormStatusLabel                                 from '@components/FormStatusLabel'
import SearchPanel                                     from '@components/searchPanel'
import { GlobalContext }                               from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import useTableView                                    from '@hooks/useTableView'
import SearchPanelReducer, { SearchPanelState }        from '@reducers/searchPanelReducer'
import FormDNAService                                  from '@services/formService/form.DNA.service'
import { DNAFilterOptions, FormDNAListModel }          from '@services/model/form/form.DNA.model'
import { RightsCategory }                              from '@services/model/form/form.model'
import GetValueWithKey                                 from '@utils/getValueWithKey'
import { NavigateTo }                                  from '@utils/navigate'

export default function AllRecord(props: { showMyRecord?: boolean }) {
  const refMounted             = useRef(false)
  const { state: globalState } = useContext(GlobalContext)
  const [state, dispatch]      = useReducer<React.Reducer<SearchPanelState, any>>(SearchPanelReducer, InitialSearchPanelState)

  const { t }                             = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<DNAFilterOptions>({
                                                                         workOrderIdList : [],
                                                                         parentFormIdList: [],
                                                                         submittedByList : [],
                                                                         approvedByList  : [],
                                                                         formIdList      : []
                                                                       })

  const getExtraListInfo = async () => {
    let extraInfo = await FormDNAService.GetDNAFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<FormDNAListModel>({
                                                               headers       : [
                                                                 {
                                                                   key       : 'formStatusShortName',
                                                                   desc      : t('General Status', { ns: common.i18nFormNS }),
                                                                   renderCell: (record: FormDNAListModel) =>
                                                                       <FormStatusLabel label={ record.formStatusShortName }
                                                                                        color={ record.formStatusColor || '' } />
                                                                 },
                                                                 {
                                                                   key       : 'formStatus',
                                                                   desc      : t('Submission Status', { ns: common.i18nFormNS }),
                                                                   renderCell: (record: FormDNAListModel) => <>{ record.formStatus }</>
                                                                 },
                                                                 {
                                                                   key       : 'formId',
                                                                   desc      : t('Form ID', { ns: common.i18nFormNS }),
                                                                   renderCell: (record: FormDNAListModel) => <>{ record.reportNo }</>
                                                                 },
                                                                 {
                                                                   key       : 'submittedBy',
                                                                   desc      : t('Submitted By', { ns: common.i18nFormNS }),
                                                                   renderCell: (record: FormDNAListModel) =>
                                                                       <>
                                                                         {
                                                                           GetValueWithKey(record.submittedBy,
                                                                                           globalState.userMetaList ?? [])
                                                                         }
                                                                       </>
                                                                 },
                                                                 {
                                                                   key       : 'approvedBy',
                                                                   desc      : t('Approved By', { ns: common.i18nFormNS }),
                                                                   renderCell: (record: FormDNAListModel) =>
                                                                       <>
                                                                         {
                                                                           GetValueWithKey(record.approvedBy,
                                                                                           globalState.userMetaList ?? [])
                                                                         }
                                                                       </>
                                                                 },
                                                                 {
                                                                   key       : 'updatedAt',
                                                                   desc      : t('Last Update', { ns: common.i18nFormNS }),
                                                                   renderCell: (record: FormDNAListModel) =>
                                                                       <>
                                                                         {
                                                                           record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''
                                                                         }
                                                                       </>
                                                                 }
                                                               ],
                                                               onRowClick    : (navigate: NavigateFunction, record: FormDNAListModel) => {
                                                                 NavigateTo(navigate,
                                                                            '/application-of-default-notice-for-non-compliance/:id',
                                                                            { id: record.formId })
                                                               },
                                                               mountedRef    : refMounted,
                                                               reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
                                                                 if (refMounted.current) {
                                                                   sessionStorage.setItem('DNA', JSON.stringify(state.filterResult))
                                                                   return await FormDNAService.GetDNAList(
                                                                       {
                                                                         ...state.filterResult,
                                                                         sorting   : sorting,
                                                                         pagination: pagination
                                                                       },
                                                                       cancelToken,
                                                                       props.showMyRecord || false
                                                                   )
                                                                 }
                                                               }
                                                             })

  return (
      <Grid component='main' container padding={ 1 }>
        <SearchPanelContext.Provider value={ { state, dispatch } }>
          <SearchPanel dispatch={ dispatch }
                       addUrl='/booking/new'
                       onSearch={ reload }
                       onInitReload={ reload }
                       onToggleFilterDrawer={ getExtraListInfo }
                       mountedRef={ refMounted }
                       excludeStatus={ true }
                       criteria={ [
                         {
                           key         : 'formStatus',
                           desc        : 'Submission Status',
                           type        : 'NumberSelectionFilter',
                           multiple    : true,
                           valueList   :
                               globalState.formStatusList
                                          ?.filter(x => x.actionCode.includes(RightsCategory.FORM_DNA))
                                          .map(x => ({
                                            key  : x.id,
                                            value: x.actionName
                                          })) || [],
                           defaultValue:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.formStatus?.value
                               : []
                         },
                         {
                           key         : 'formId',
                           desc        : 'Form ID',
                           type        : 'StringSelectionFilter',
                           multiple    : true,
                           valueList   : extraListInfo.formIdList,
                           defaultValue:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.formId?.value
                               : []
                         },
                         {
                           key         : 'workOrderId',
                           desc        : 'Works Order No',
                           type        : 'StringSelectionFilter',
                           multiple    : true,
                           valueList   : globalState.worksOrderList
                                                    ?.filter(x => extraListInfo.workOrderIdList?.includes(x.id))
                                                    ?.map(({ id, workOrderNo }) => ({ key: id, value: workOrderNo })),
                           defaultValue:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.workOrderId?.value
                               : []
                         },
                         {
                           key         : 'submittedBy',
                           desc        : 'Submitted By',
                           type        : 'StringSelectionFilter',
                           multiple    : true,
                           valueList   : globalState.userMetaList?.filter(x => extraListInfo.submittedByList?.includes(x.key)),
                           defaultValue:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.submittedBy?.value
                               : []
                         },
                         {
                           key         : 'approvedBy',
                           desc        : 'Approved By',
                           type        : 'StringSelectionFilter',
                           multiple    : true,
                           valueList   : globalState.userMetaList?.filter(x => extraListInfo.approvedByList?.includes(x.key)),
                           defaultValue:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.approvedBy?.value
                               : []
                         },
                         {
                           key            : 'updatedAt',
                           desc           : 'Last Update',
                           type           : 'DateFilter',
                           defaultOperator:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.updatedAt?.operator
                               : 'in',
                           defaultFrom    :
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.updatedAt?.value?.min
                               : '',
                           defaultTo      :
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.updatedAt?.value?.max
                               : ''
                         },
                         {
                           key            : 'completionDate',
                           desc           : 'Completion Date',
                           type           : 'DateFilter',
                           defaultOperator:
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.completionDate?.operator
                               : 'in',
                           defaultFrom    :
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.completionDate?.value?.min
                               : '',
                           defaultTo      :
                               sessionStorage?.DNA !== 'undefined' && sessionStorage?.DNA !== undefined
                               ? JSON.parse(sessionStorage?.DNA)?.completionDate?.value?.max
                               : ''
                         }
                       ] } />
          <TableView />
        </SearchPanelContext.Provider>
      </Grid>
  )
}
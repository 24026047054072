import { Color } from 'chart.js'
import { basicChartConfig, basicChartOption } from '../BasicChart'
import VerticalOrHorizontalBarChart from './VerticalOrHorizontalBarChart'

export interface basicBarChartConfig extends basicChartConfig {
    borderAlign?: string | string[]
    fill?: boolean | boolean[]
}

interface basicBarChartDataset {
    name?: string
    data: (number | null)[]
    dataConfig?: basicBarChartConfig
}

export interface basicBarChartProps extends barChartProps {
    type: string
}

export interface barChartProps {
    options?: any //basicChartOption
    labels: string[]
    datasets: basicBarChartDataset[]
}

const BasicBarChart = (props: basicBarChartProps) => {
    let options = props.options

    switch (props.type) {
        case 'vertical':
        case 'horizontal':
            if (options !== undefined) {
                if (props.type === 'horizontal') {
                    options.indexAxis = 'y'
                } else {
                    options.indexAxis = 'x'
                }
            } else {
                if (props.type === 'horizontal') {
                    options = {
                        indexAxis: 'y',
                    }
                } else {
                    options = {
                        indexAxis: 'x',
                    }
                }
            }
            return (
                <VerticalOrHorizontalBarChart
                    labels={props.labels}
                    datasets={props.datasets}
                    options={options}
                ></VerticalOrHorizontalBarChart>
            )
        default:
            return <>Undefined bar chart</>
    }
}
export default BasicBarChart

import { Box } from '@mui/material'

export interface customIconProps {
    className?: string
    image: string
}

const CustomIcon = (props: customIconProps) => {
    return (
        <Box
            component={'div'}
            className={props.className ? props.className : ''}
            style={{
                backgroundImage: props.image,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
            }}
            sx={{
                width: '100%',
                height: '100%',
            }}
        />
    )
}
export default CustomIcon

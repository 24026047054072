import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse, { BaseFilterOptions } from '../model/base.model'
import { FormQsListModel, FormQSModel, QSGeneralOptions, QSFirstPageData, QSFirstPageReturnData, QsListResponse } from '../model/form/form.QS.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetGeneralOptions = async () => {
  return await apiGateway.get<QSGeneralOptions>(`/api/v1/forms/QS/options`).then((rep) => {
    return rep.data
  })
}

const GetQSForm = async (formId: string) => {
  return await apiGateway
    .get<FormQSModel>(`api/v1/forms/QS/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetQsList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<QsListResponse>(
      `api/v1/forms/QS/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetQsFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/QS/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<BaseFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveQSForm = async (req: FormQSModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/QS', req).then((rep) => {
      return rep.data
    })
  else{
    return await apiGateway
      .put<BaseFormResponse>(
        `api/v1/forms/QS/${req.baseForm.formId}`,
        req,
      )
      .then((rep) => {
        return rep.data
      })
    }
}

const ApproveQSForm = async (req: FormQSModel) => {
  return await apiGateway.put<BaseSaveResponse>(`api/v1/forms/QS/${req.baseForm.formId}/approve`, req)
}

const RejectQSForm = async (req: FormQSModel) => {
  return await apiGateway.put<BaseSaveResponse>(`api/v1/forms/QS/${req.baseForm.formId}/reject`, req)
}

const DeleteQSForm = async (req: FormQSModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/QS/${req.baseForm.formId}`,
  )
}

const GetQSData = async (req: QSFirstPageData) => {
  return await apiGateway.post<QSFirstPageReturnData>(
    `api/v1/forms/QS/data`, req
  ).then((rep) => {
    return rep.data
  })
}

export default {
  GetQSForm,
  GetQsList,
  GetQsFilterOption,
  SaveQSForm,
  ApproveQSForm,
  RejectQSForm,
  DeleteQSForm,
  GetGeneralOptions,
  GetQSData
}

import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form'
import { Typography }                                                         from '@mui/material'
import { GlobalStateProps }                                                   from '@providers/globalStore'
import { WorkOrderList }                                                      from '@services/model/contract.model'
import { FormHWRModel, HWRGeneralOptions }                                    from '@services/model/form/form.HWR.model'
import { BaseFormPermission, FormStatusEnum }                                 from '@services/model/form/form.model'
import FormHWRService                                                         from '@services/formService/form.HWR.service'
import { KeyValPair }                                                         from '@models/common'
import { UserInfo }                                                           from '@services/model/user.model'
import FormContainer                                                          from '@components/form/container'
import FormController                                                         from '@components/form/controller'
import FormField                                                              from '@components/form/field'
import FormSelect                                                             from '@components/form/select'
import FormFileUpload                                                         from '@components/form/fileUpload'
import FormMultipleSelect                                                     from '@components/form/multipleSelect'
import FormTypography                                                         from '@components/form/typography'
import { GetTeamOptions }                                                     from '@utils/teamOptions'
import { GetDistrictOptions }                                                 from '@utils/districtOptions'
import { GetWorksOrderListOptions }                                           from '@utils/worksOrderOptions'

export default ({
                    globalState,
                    control,
                    userInfo,
                    watch,
                    workOrderList,
                    setValue,
                    getOptionsByKey,
                    getValues,
                    formStatus,
                    formPermission,
                    readOnly,
                    options
                }: {
    globalState: GlobalStateProps
    control: Control<FormHWRModel>
    userInfo: UserInfo
    watch: UseFormWatch<FormHWRModel>
    workOrderList?: Array<WorkOrderList>
    setValue: UseFormSetValue<FormHWRModel>
    getOptionsByKey: any
    getValues: UseFormGetValues<FormHWRModel>
    formStatus: string
    formPermission: BaseFormPermission
    readOnly: boolean
    options?: HWRGeneralOptions
}) => {
  const uneditable = !(
    formStatus === FormStatusEnum.FORM_HWR_DRAFT ||
    formStatus === FormStatusEnum.FORM_HWR_REVIEW ||
    formStatus === FormStatusEnum.FORM_HWR_REJECTED ||
    formStatus === FormStatusEnum.FORM_HWR_ENDORSEMENT ||
    formStatus === FormStatusEnum.FORM_HWR_NOT_ENDORSED ||
    formStatus === FormStatusEnum.FORM_HWR_APPROVED ||
    formStatus === FormStatusEnum.FORM_HWR_COUNTERSIGNED
  )
  const [contractNoId] = watch(['baseForm.contractNoId'])
  const matchedContract = globalState.contractList?.find(
    (contractNo) => contractNo.id === contractNoId,
  )

  let index = 0
    const WorkOrderField = () => {
        const [teamId, districtId] = useWatch({
                                                  name   : ['baseForm.teamId', 'baseForm.districtId'],
                                                  control: control
                                              })

        return (
            <FormField fieldName='Works Order No'>
                {
                    readOnly ? (
                        <FormTypography value={ getValues('baseForm.workOrderNo') } />
                    ) : (
                        <FormController controllerProps={ {
                            name   : 'baseForm.workOrderId',
                            control: control
                        } }>
                            <FormSelect fullWidth
                                        options={ GetWorksOrderListOptions(
                                            workOrderList || [],
                                            teamId,
                                            districtId) }
                                        uneditable={ readOnly }
                                        additionalChangeAction={ (event: any, newValue: KeyValPair | null) => {
                                            let workOrderId = newValue?.key
                                            if (
                                                workOrderId &&
                                                (formStatus === FormStatusEnum.FORM_HWR_DRAFT ||
                                                 formStatus === FormStatusEnum.FORM_HWR_REJECTED)
                                            ) {
                                                FormHWRService.GetHwRefList(workOrderId).then((resp) => {
                                                    setValue('referenceOfHwr', resp?.referenceOfHwr || [])
                                                })
                                            }
                                        } }
                            />
                        </FormController>
                    )
                }
            </FormField>
        )
    }

  const [referenceOfHwr] = useWatch({
    name: ['referenceOfHwr'],
    control: control,
  })

  const HiddenWorksRef = () => {
    return (
      <FormField fieldName="Temporary Hidden Works Record">
        <FormController
          controllerProps={{
            name: 'referenceOfHwr',
            control: control,
          }}>
          <FormFileUpload disabled multipleUpload hideUpload hideRemove />
        </FormController>
      </FormField>
    )
  }

  return (
    <FormContainer>
      <FormField fieldName="Contract No." index={index++}>
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
          }}>
          <FormSelect
            fullWidth
            options={
              globalState.contractList?.map((x) => {
                return {
                  key: x.id,
                  value: x.contractNo,
                }
              }) || []
            }
            uneditable={true}
          />
        </FormController>
      </FormField>
      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect
            options={GetTeamOptions(userInfo.teams)}
            uneditable={readOnly}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={GetDistrictOptions(userInfo.districts)}
            uneditable={readOnly}
          />
        </FormController>
      </FormField>

      <WorkOrderField />

      <FormField fieldName="HWR No." index={index++}>
        <Typography>{getValues('hwrNo')}</Typography>
      </FormField>

      <HiddenWorksRef />
    </FormContainer>
  )
}

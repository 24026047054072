import { basicChartConfig, basicChartOption } from '../BasicChart'
import LineChart from './LineChart'

export interface basicLineChartConfig extends basicChartConfig {
    xAxisID?: string
    yAxisID?: string
    spanGaps?: boolean | number //default false
    showLine?: boolean //default true
}

interface basicLineChartDataset {
    name?: string
    data: (number | null)[]
    dataConfig?: basicLineChartConfig
}

export interface basicLineChartProps extends lineChartProps {}

export interface lineChartProps {
    options?: any //basicChartOption
    labels: string[]
    datasets: basicLineChartDataset[]
}

const BasicLineChart = (props: basicLineChartProps) => {
    return (
        <LineChart
            labels={props.labels}
            datasets={props.datasets}
            options={props.options}
        ></LineChart>
    )
}
export default BasicLineChart

import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import styles from '@styles/var.module.scss'
import '@styles/common.module.scss'
import { loadSpace } from '@usersnap/browser'


// import i18n (needs to be bundled ;))
// import './utils/i18n'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { CssBaseline } from '@mui/material'
import '@styles/globalStyles.scss'
import SnackbarCloseButton from '@components/SnackbarCloseButton'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const theme = createTheme({
  palette: {
    primary: {
      main: styles.main,
      light: styles.light,
      dark: styles.dark,
      contrastText: styles.contrasttext,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    // MuiDivider: {
    //     styleOverrides: {
    //         root: {
    //             marginLeft: 0,
    //             marginRight: 0,

    //             '::before': {
    //                 width: '0%',
    //             },
    //         },
    //     },
    // },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          border: '1px solid #c9c9c9',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px 14px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            '& .MuiOutlinedInput-input': {
              padding: '8px 14px',
            },
          },
        },
        multiline: {
          padding: '8px 14px !important',
        },
        inputMultiline: {
          padding: '0px !important',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px 14px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&[data-shrink="false"]': {
            transform: 'translate(14px, 8px) scale(1)',
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0px !important',
          '& .MuiAutocomplete-input': {
            padding: '7.5px 14px',
          },
        },
        root: {
          '& .MuiAutocomplete-tag': {
            margin: '0 3px 0 0 !important',
          },
        },
        endAdornment: {
          top: 'auto',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'transparent',
          '&.Mui-selected': {
            // <-- mixing the two classes
            backgroundColor: '#add3eb !important',
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '8px !important',
          width: 'calc(100% - 8px) !important',
        },
      },
    },
  },
})

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)

loadSpace(process.env.REACT_APP_USERSNAP_API_KEY as string).then((api) => {
  api.init()
  api.on('open', function (event) {
    event.api.setValue('visitor', localStorage.getItem('email')?.replace(/"/g, "") ?? "" )
    event.api.setValue('custom', {
      appVersion: '1.10.16k',
      environment: 'UAT',
      site: 'HyD RMMS',
    })
  })
})

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider
      maxSnack={5}
      action={(key) => {
        return <SnackbarCloseButton id={key} />
      }}>
      <App />
    </SnackbarProvider>
  </ThemeProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

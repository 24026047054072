import React, { createContext, useReducer } from 'react'
import SearchPanelReducer, { ActionType, SearchPanelState } from '@reducers/searchPanelReducer'

export const InitialSearchPanelState: SearchPanelState = {
  isInitialized: false,
  canReload: false,
}

export const SearchPanelContext = createContext<{
  state: SearchPanelState
  dispatch: React.Dispatch<ActionType>
}>({
  state: InitialSearchPanelState,
  dispatch: () => undefined,
})

type SearchPanelProviderProps = {}

export const SearchPanelProvider = (props: SearchPanelProviderProps, { children }) => {
  const [state, dispatch] = useReducer(SearchPanelReducer, InitialSearchPanelState)

  return (
    <SearchPanelContext.Provider value={{ state, dispatch }}>
      {' '}
      {children}
    </SearchPanelContext.Provider>
  )
}

import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormLinkTable from '@components/form/linkTable'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { FormPartComponents } from '@models/common'
import { FormContext } from '@providers/formStateProvider'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormScrModel } from '@services/model/form/form.SCR.model'
import { NavigateTo } from '@utils/navigate'
import { t } from 'i18next'
import { useContext } from 'react'
import { Control } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export default({
  control,
  handleLinkClick,
  handleAddPhotoClick,
  formStatus,
  LargeMap
}: {
  control: Control<FormScrModel>
  handleLinkClick: any
  handleAddPhotoClick: any
  formStatus:string
  LargeMap: () => JSX.Element
}) => {
  const navigate = useNavigate()
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

  const { getOptionsByKey } = useGeneralOptions()

  return  (
      <FormContainer fkey={`$scr-e`}>
        <LargeMap />
        <FormLinkTable
          columns={[
            { field: 'images', name: 'Images', type: 'images' },
            {
              field: 'photoCategoryId',
              name: 'Category',
              type: 'selected',
              options: getOptionsByKey('Form SD - Site Photo Category'),
            },
            { field: 'location.locationAddress', name: 'Address', type: 'string' },
          ]}
          fieldArrayProps={{
            control: control,
            name: 'scrPhotos',
          }}
          title={' '}
          addButtonText={'Add a SCR Photo'}
          onLinkClick={handleLinkClick}
          appendField={handleAddPhotoClick}
          uneditable={!editable}
        />
      </FormContainer>
    )
}

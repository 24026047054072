// material-ui
import { Divider, List, Typography } from '@mui/material'

// project imports
import NavItem from './navItem'
import NavCollapse from './navCollapse'

import { SideMenu } from '../menuList'

import styles from '@styles/common.module.scss'
import { Fragment } from 'react'

const NavGroup = (props: { item: SideMenu; leftDrawerOpened: boolean }) => {
  const item = props.item
  const leftDrawerOpened = props.leftDrawerOpened

  // menu list collapse & items
  const items = item.children
    .filter((x) => x.hidden !== true)
    .map((menu) => {
      switch (menu.type) {
        case 'collapse':
          return <NavCollapse key={menu.id} {...menu} />
        case 'item':
          return <NavItem key={menu.id} {...menu} />
        default:
          return <>Unmatched menu type</>
      }
    })

  if (items.length > 0 && (item.hidden === undefined || item.hidden !== true)) {
    return (
      <>
        <List
          subheader={
            <Fragment>
              {item.title && (
                <Typography
                  variant="body1"
                  className={styles.menuCaption}
                  display="block"
                  gutterBottom>
                  {item.title}
                </Typography>
              )}
              {item.caption && (
                <Typography
                  variant="body1"
                  className={styles.subMenuCaption}
                  display="block"
                  gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Fragment>
          }>
          {items}
        </List>

        {/* group divider */}
        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
      </>
    )
  } else return <></>
}

export default NavGroup

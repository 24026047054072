import FormContainer from '@components/form/container'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { BaseFormPermission, FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import { FormEIAModel } from '@services/model/form/form.EIA.model'
import FormSignatureHistories from '@components/form/signatureHistories'
import { GlobalStateProps } from '@providers/globalStore'

export default ({
  control,
  getValues,
  globalState,
  formPermission,
  handleOnComplete,
}: {
  control: Control<FormEIAModel>
  getValues: any
  globalState: GlobalStateProps
  formPermission: BaseFormPermission
  handleOnComplete: any
}) => {
  const UUID = uuid()

  return (
    <FormContainer fkey={`${UUID}-eia-b`}>
      <FormSignatureHistories
        formStatusList={globalState.formStatusList}
        histories={getValues('baseForm.formStatusHistories')}
        control={control}
        handleOnComplete={() => handleOnComplete()}
        endOfFlow={formPermission?.endOfFlow}
        userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
        disabled={!formPermission?.canUpdate}
      />
    </FormContainer>
  )
}

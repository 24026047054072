import { KeyValPair } from '@models/common'
import {
  Typography,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material'
import GetValuesWithKey from '@utils/getValuesWithKey'
import { Fragment } from 'react'

interface multipleSelect {
  value?: number[]
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  additionalChangeAction?: (event: any) => void
  options: KeyValPair[]
  disabled?: boolean
  uneditable?: boolean
  defaultValue?: any
  limit?: number
}

const FormMultipleSelect = (props: multipleSelect) => {
  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any, newValue: KeyValPair[]) => {
    const _value = newValue.map((item) => {
      return item.key
    })
    props.onChange!(_value)
    if (props.additionalChangeAction) {
      props.additionalChangeAction(event)
    }
  }

  const list =
    props.options?.filter((option) => {
      return props.value?.includes(option.key)
    }) || []

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{props.value !== null ? GetValuesWithKey(props.value, props.options) : ""}</Typography>
      ) : (
        <Autocomplete
          readOnly={props.uneditable}
          defaultValue={
            props.limit !== undefined && (list?.length || 0) >= props.limit
              ? list.slice(0, props.limit)
              : list
          }
          value={
            props.limit !== undefined && (list?.length || 0) >= props.limit
              ? list.slice(0, props.limit)
              : list
          }
          fullWidth
          disablePortal
          multiple
          getOptionLabel={(option: KeyValPair) => {
            return option.value ?? ''
          }}
          getOptionDisabled={(option: KeyValPair) => {
            return props.limit !== undefined ? (props.value?.length || 0) >= props.limit : false
          }}
          options={props.options ?? []}
          onChange={handleChange}
          onBlur={props.onBlur}
          renderInput={(params) => <TextField {...params} error={!!props.error} />}
        />
      )}
    </Fragment>
  )
}

export default FormMultipleSelect

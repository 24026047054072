import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWouModel, WouGeneralOptions } from '@services/model/form/form.WOU.model'
import { useTranslation } from 'react-i18next'
import FormBinarySelect from '@components/form/binarySelect'
import FormFileUpload from '@components/form/fileUpload'
import FormFreeText from '@components/form/freeText'
import FormSelect from '@components/form/select'

export default ({
  control,
  options,
  useWatch,
  setValue,
  uneditable
}: {
  control: Control<FormWouModel>
  options?: WouGeneralOptions
  useWatch: any
  setValue: any
  uneditable: boolean
}) => {
  const { t } = useTranslation()

  const PartFActualUrgencyLevel = () => {
    const urgencyLevelRequirementComplied = useWatch({
      name: 'urgencyLevelRequirementComplied',
      control,
    })

    if (urgencyLevelRequirementComplied === false) {
      return (
        <FormField fieldName="Actual Urgency Level">
          <FormController
            controllerProps={{
              name: 'actualUrgencyLevel',
              control,
            }}>
            <FormSelect
              options={
                options?.urgencyLevel?.map((x) => ({
                  key: x.id,
                  value: x.name,
                })) ?? []
              }
              additionalChangeAction={(_, selected) => {
                if (selected && (selected.value === 'Normal' || selected.value === 'Urgency')) {
                  setValue(
                    'sbv',
                    options?.sbv
                      ?.map((x) => ({
                        key: x.id,
                        value: x.name,
                      }))
                      ?.find((o) => o.value === 'Nil')?.key,
                  )
                } else {
                  setValue('sbv', undefined)
                }
              }}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
      )
    }

    return <></>
  }

  const PartFSbv = () => {
    const actualUrgencyLevel = useWatch({
      name: 'actualUrgencyLevel',
      control,
    })

    const actualUrgencyLevelNormalKey =
      options?.urgencyLevel
        ?.map((x) => ({
          key: x.id,
          value: x.name,
        }))
        ?.find((o) => o.value === 'Normal')?.key ?? undefined
    const actualUrgencyLevelUrgencyKey =
      options?.urgencyLevel
        ?.map((x) => ({
          key: x.id,
          value: x.name,
        }))
        ?.find((o) => o.value === 'Urgency')?.key ?? undefined

    return (
      <FormField fieldName="SBV">
        <FormController
          controllerProps={{
            name: 'sbv',
            control,
          }}>
          <FormSelect
            options={
              options?.sbv?.map((x) => ({
                key: x.id,
                value: x.name,
              })) ?? []
            }
            uneditable={
              !!(
                actualUrgencyLevel &&
                (actualUrgencyLevel === actualUrgencyLevelNormalKey ||
                  actualUrgencyLevel === actualUrgencyLevelUrgencyKey)
              )
            }
          />
        </FormController>
      </FormField>
    )
  }

  return (
    <FormContainer fkey={`woi-f`}>
      <FormField
        fieldName="Urgency Level Requirement Complied"
        fieldTooltip="Works of Normal/Urgency/High Urgency/Extreme Urgency Requirment Complied">
        <FormController
          controllerProps={{
            name: 'urgencyLevelRequirementComplied',
            control,
          }}>
          <FormBinarySelect uneditable={uneditable}/>
        </FormController>
      </FormField>

      <PartFActualUrgencyLevel />
      <PartFSbv />
      <FormField fieldName="Last Interim Payment">
        <FormController
          controllerProps={{
            name: 'lastInterimPayment',
            control,
          }}>
          <FormBinarySelect uneditable={uneditable}/>
        </FormController>
      </FormField>
      <FormField
        fieldName="Other Relevant Information"
        fieldTooltip="Information Relevant to Checking of Contractor's Dimension Book other than Above">
        <FormController
          controllerProps={{
            name: 'relevantInformation',
            control,
          }}>
          <FormFreeText
            textfieldProps={{
              multiline: true,
              rows: 4,
            }}
            uneditable={uneditable}
          />
        </FormController>
      </FormField>
      <FormField fieldName="Relevant Attachment">
        <FormController
          controllerProps={{
            name: 'relevantAttachments',
            control,
          }}>
          <FormFileUpload height="125"  
            acceptFiles={[
              'application/pdf',
            ]}
            uneditable={uneditable}
          />
        </FormController>
      </FormField>
    </FormContainer>
  )
}

import FormContainer from '@components/form/container'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import FormRectifications from '@components/form/rectifications'
import { FormNFModel, NFGeneralOptions } from '@services/model/form/form.NF.model'

export default ({
  control,
  formStatus,
  getValues,
}: {
  control: Control<FormNFModel>
  formStatus: string
  getValues: any
}) => {
  const UUID = uuid()

  return (
    <FormContainer fkey={`${UUID}-nf-b`}>
      <FormRectifications
        rectifications={getValues('rectifications')}
        control={control}
        uneditable={
          formStatus === FormStatusEnum.FORM_NF_REVIEW_RECTIFICATION ||
          formStatus === FormStatusEnum.FORM_NF_APPROVED
        }
      />
    </FormContainer>
  )
}

import FormContainer from '@components/form/container'
import { FormStatusEnum } from '@services/model/form/form.model'
import { EaCategory, FormEaModel } from '@services/model/form/form.EA.model'
import { Control, Path } from 'react-hook-form'
import uuid from 'react-uuid'
import FormField from '@components/form/field'
import { Checkbox, MenuItem, Select, Typography } from '@mui/material'
import FormImageUpload from '@components/form/imageUpload'
import FormController from '@components/form/controller'
import FormFreeText from '@components/form/freeText'
import { useTranslation } from 'react-i18next'
import FormTypography from '@components/form/typography'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import FormTable from '@components/form/table'

const UUID = uuid()

export default ({
  control,
  formStatus,
  getValues,
  globalState,
  setValue,
  useWatch,
}: {
  control: Control<FormEaModel>
  formStatus: string
  getValues: any
  globalState?: any
  setValue: any
  useWatch: any
}) => {
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()

  const mmWorksValue =
    getOptionsByKey('Form EA - Category')?.find((x) => x.key === getValues('formNea.typeOfMmWorks'))
      ?.value ?? ''

  const appealDisabled =
    formStatus !== FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED &&
    formStatus !== FormStatusEnum.FORM_EA_AUDIT_RESULT_UPDATED

  const defectIdentifiedList =
    globalState.generalOptionGroupList
      ?.find((x) => x.name === 'Form EA - Category')
      ?.generalOptions?.find((x) => x.id === getValues('formNea.typeOfMmWorks'))
      ?.defectOptions?.map((x) => {
        return {
          key: x.id,
          value: `${x.defectCode} - ${x.description}`,
        }
      }) || []

  const FormWarningMessage = (props: { control: Control<FormEaModel> }) => {
    const [formDeaList, defective] = useWatch({
      name: ['formDeaList', 'defective'],
      control: props.control,
    })

    return (
      <FormTypography
        value={
          formDeaList.find((dea) => typeof dea.markApproval === 'boolean' && dea.markApproval) &&
          typeof defective !== 'boolean'
            ? 'Please navigate to session D to select defective option'
            : ''
        }
        sx={{
          color: 'red',
        }}
      />
    )
  }

  return (
    <>
      <FormContainer fkey={`${UUID}-ea-e`}>
        <FormField fieldName="Image">
          <FormController
            controllerProps={{
              name: 'eaAppealImages',
              control,
            }}>
            <FormImageUpload disabled={appealDisabled} multiple />
          </FormController>
        </FormField>
        <FormField fieldName="Justification for Appeal">
          <FormController
            controllerProps={{
              name: 'appealInfo',
              control,
            }}>
            <FormFreeText
              uneditable={formStatus === 'Audit Result Appeal Success'}
              textfieldProps={{
                fullWidth: false,
                multiline: true,
                rows: 10,
              }}
              sx={{
                width: '70%',
              }}
              disabled={appealDisabled}
            />
          </FormController>
        </FormField>
        <Typography
          variant="h5"
          sx={{
            padding: 4,
            fontWeight: 'bold',
          }}>
          {t('Select Defect to Appeal')}
        </Typography>
        <FormTable
          columns={[
            {
              field: 'markAppeal',
              displayName: 'Appeal',
              type: 'controlledCustom',
              controlledRender: (_row, field) => {
                return (
                  <Checkbox
                    key={`${_row._id}`}
                    checked={!!field.value}
                    onChange={(event: any) => {
                      field.onChange(event.target.checked)
                    }}
                    disabled={
                      formStatus !== FormStatusEnum.FORM_EA_AUDIT_RESULT_UPDATED &&
                      formStatus !== FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED
                    }
                  />
                )
              },
            },
            {
              field: 'reportNo',
              displayName: 'EA Defect No.',
            },
            {
              field: 'defectsIdentified',
              displayName: 'Defect Identified',
              type: 'select',
              uneditable: true,
              options: defectIdentifiedList,
            },
            {
              field: 'images',
              displayName: 'Images',
              type: 'displayImages',
            },
            {
              field: 'markApproval',
              displayName: 'Approval',
              type: 'controlledCustom',
              controlledRender: (row, field) => (
                <Select
                  fullWidth
                  sx={{
                    fontSize: 12,
                    backgroundColor: '#ffffff',
                  }}
                  disabled={
                    !(
                      (formStatus == FormStatusEnum.FORM_EA_REVIEW_AUDIT_APPEAL ||
                        formStatus == FormStatusEnum.FORM_EA_AUDIT_APPEAL_NOT_ENDORSED) &&
                      row['markAppeal']
                    )
                  }
                  value={typeof field.value !== 'boolean' ? '' : field.value ? 'Yes' : 'No'}
                  onChange={(event: any) => {
                    switch (event.target.value) {
                      case 'Yes': {
                        setValue('defective', null)
                        field.onChange(true)
                        return
                      }
                      case 'No': {
                        field.onChange(false)
                        return
                      }
                      default: {
                        field.onChange(undefined)
                        return
                      }
                    }
                  }}>
                  <MenuItem
                    sx={{
                      fontSize: 12,
                    }}
                    value="Yes">
                    {t('Accept Appeal')}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: 12,
                    }}
                    value="No">
                    {t('Reject Appeal')}
                  </MenuItem>
                  <MenuItem
                    value=""
                    style={{
                      display: 'none',
                    }}
                  />
                </Select>
              ),
              hidden:
                formStatus === FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED ||
                formStatus === FormStatusEnum.FORM_EA_AUDIT_RESULT_UPDATED,
            },
            {
              field: 'remarks',
              displayName: 'Remarks',
              type: 'controlledCustom',
              controlledRender: (row, field) => (
                <FormFreeText
                  onChange={field.onChange}
                  value={field.value as string | undefined}
                  sx={{
                    backgroundColor: '#ffffff',
                  }}
                  textfieldProps={{
                    inputProps: {
                      style: {
                        fontSize: 12,
                      },
                    },
                    multiline: true,
                    rows: 5,
                  }}
                  disabled={
                    !(
                      (formStatus == FormStatusEnum.FORM_EA_REVIEW_AUDIT_APPEAL ||
                        formStatus == FormStatusEnum.FORM_EA_AUDIT_APPEAL_NOT_ENDORSED) &&
                      row['markAppeal']
                    )
                  }
                />
              ),
              hidden:
                formStatus === FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED ||
                formStatus === FormStatusEnum.FORM_EA_AUDIT_RESULT_UPDATED,
            },
          ]}
          hiddenItemNo
          removeAddButton
          fieldArrayProps={{
            name: 'formDeaList',
            control,
          }}
          rowFilter={(row) =>
            row['baseForm'] &&
            row['baseForm']['formStatus'] !== FormStatusEnum.FORM_DEA_APPEAL_SUCCESS
          }
        />
        <FormWarningMessage control={control} />
      </FormContainer>
    </>
  )
}

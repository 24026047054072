import {
  Typography,
  Collapse,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import { useState, Fragment } from 'react'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormImageUpload from '@components/form/imageUpload'
import FormTypography from '@components/form/typography'
import { GlobalStateProps } from '@providers/globalStore'
import { FormCRModel, OutstandingWorks } from '@services/model/form/form.CR.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn, useWatch } from 'react-hook-form'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { FormStatusEnum } from '@services/model/form/form.model'

type OutstandingWorksHistoryProps = {
  submission?: OutstandingWorks[]
  handleOnComplete?: () => void
  control?: any
  errors?: any
  endOfFlow?: boolean
  // formStatusList?: FormStatus[]
  // userMetaList?: KeyValPair[]

  disabled?: boolean
}

export default ({
  globalState,
  control,
  formStatus
}: {
  globalState: GlobalStateProps
  control: Control<FormCRModel>
  formStatus: string
}) => {

  const [submission] = useWatch({
    name: ['crOutstandingWorks'],
    control,
  })

  const [open, setOpen] = useState<boolean[]>(
    submission?.map((_: OutstandingWorks, index: number) =>
      index === submission.length - 1 ? true : false,
    ) || [],
  )
  const handleOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }

  const OutstandingWorksList = (props: OutstandingWorksHistoryProps) => {
    console.log('submission', submission)
    console.log('formStatus', formStatus)
    return (
    <FormContainer fkey="cr-c">
      <Grid xs={12}>
        <List>   
          {submission === undefined || submission === null ? (
            <>
            <Box
                sx={{
                  color: 'inherit',
                  display: 'flex',
                  width: '100%',
                  padding: '8px 16px',
                  border: '1px solid #c9c9c9',
                  alignItems: 'center',
                }}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    1
                  }
                />
            </Box>
            <Fragment>
              <FormField fieldName="Submission">
                <FormTypography
                  value={1}
                />
              </FormField>
              <FormField fieldName="Date of Outstanding Works Completion">
                <FormController
                  controllerProps={{
                    name: `crOutstandingWorks.${0}.dateOfOutstandingWorksCompleteion`,
                    control,
                  }}>
                  <FormDateTimePicker type="date" uneditable={formStatus !== FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS}/>
                </FormController>
              </FormField>
              <FormField fieldName="Image">
                <FormController
                  controllerProps={{
                    name: `crOutstandingWorks.${0}.crOutstandingWorkImages`,
                    control,
                  }}>
                  <FormImageUpload 
                    uneditable={formStatus !== FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS} 
                    multiple
                  />
                </FormController>
              </FormField>
              <FormField fieldName="IOW Remarks">
                <FormController
                  controllerProps={{
                    name: `crOutstandingWorks.${0}.iowRemarks`,
                    control,
                  }}>
                  <FormFreeText
                    textfieldProps={{
                      multiline: true,
                      rows: 4,
                    }}
                    disabled={formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS}
                  />
                </FormController>
              </FormField>
            </Fragment>
            </>) : (submission?.map((history: OutstandingWorks, index: number) => (
            <List key={`submission-${index}-${history.submissionNo}`}>
              {index === submission.length - 1 ? (<>
                <Box
                  sx={{
                    color: 'inherit',
                    display: 'flex',
                    width: '100%',
                    padding: '8px 16px',
                    border: '1px solid #c9c9c9',
                    alignItems: 'center',
                  }}>
                  <ListItemIcon>
                    <BookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      history.submissionNo || index + 1
                    }
                  />
                </Box>
                <Fragment>
                <FormField fieldName="Submission">
                    <FormTypography
                      value={history.submissionNo || index + 1}
                    />
                  </FormField>
                  <FormField fieldName="Date of Outstanding Works Completion">
                    <FormController
                      controllerProps={{
                        name: `crOutstandingWorks.${index}.dateOfOutstandingWorksCompleteion`,
                        control,
                      }}>
                      <FormDateTimePicker type="date" uneditable={formStatus !== FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS &&  formStatus !== FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_INCOMPLETE}/>
                    </FormController>
                  </FormField>
                  <FormField fieldName="Image">
                    <FormController
                      controllerProps={{
                        name: `crOutstandingWorks.${index}.crOutstandingWorkImages`,
                        control,
                      }}>
                      <FormImageUpload 
                        uneditable={formStatus !== FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS && formStatus !== FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_INCOMPLETE} 
                        multiple 
                      />
                    </FormController>
                  </FormField>
                  <FormField fieldName="IOW Remarks">
                    <FormController
                      controllerProps={{
                        name: `crOutstandingWorks.${index}.iowRemarks`,
                        control,
                      }}>
                      <FormFreeText
                        textfieldProps={{
                          multiline: true,
                          rows: 4,
                        }}
                        disabled={formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS || formStatus === FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_INCOMPLETE}
                        uneditable={formStatus != FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_REVIEW}
                      />
                    </FormController>
                  </FormField>
              </Fragment></>
              ) : (
                <Fragment>
                  <ListItemButton
                    onClick={(_: any) => {
                      handleOpen(index)
                    }}>
                    <ListItemIcon>
                      <BookIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        history.submissionNo
                      }
                    />
                    {open[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open[index]}>
                    {index === submission.length - 1 ? (
                      <Fragment>
                        <FormField fieldName="Submission">
                            <FormTypography
                              value={history.submissionNo || index + 1}
                            />
                          </FormField>
                          <FormField fieldName="Date of Outstanding Works Completion">
                            <FormController
                              controllerProps={{
                                name: `crOutstandingWorks.${index}.dateOfOutstandingWorksCompleteion`,
                                control,
                              }}>
                              <FormDateTimePicker type="date" />
                            </FormController>
                          </FormField>
                          <FormField fieldName="Image">
                            <FormController
                              controllerProps={{
                                name: `crOutstandingWorks.${index}.crOutstandingWorkImages`,
                                control,
                              }}>
                              <FormImageUpload multiple />
                            </FormController>
                          </FormField>
                          <FormField fieldName="IOW Remarks">
                            <FormController
                              controllerProps={{
                                name: `crOutstandingWorks.${index}.iowRemarks`,
                                control,
                              }}>
                              <FormFreeText
                                textfieldProps={{
                                  multiline: true,
                                  rows: 4,
                                }}
                                disabled={formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS}
                              />
                            </FormController>
                          </FormField>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {/* display value only */}
                        <FormField fieldName="Submission">
                          <FormTypography
                            value={history.submissionNo}
                          />
                        </FormField>
                        <FormField fieldName="Date of Outstanding Works Completion">
                          <FormController
                            controllerProps={{
                              name: `crOutstandingWorks.${index}.dateOfOutstandingWorksCompleteion`,
                              control,
                            }}>
                            <FormDateTimePicker type="date" uneditable={true}/>
                          </FormController>
                        </FormField>
                        <FormField fieldName="Image">
                          <FormController
                            controllerProps={{
                              name: `crOutstandingWorks.${index}.crOutstandingWorkImages`,
                              control,
                            }}>
                            <FormImageUpload uneditable={true} multiple />
                          </FormController>
                        </FormField>
                        <FormField fieldName="IOW Remarks">
                          <FormController
                            controllerProps={{
                              name: `crOutstandingWorks.${index}.iowRemarks`,
                              control,
                            }}>
                            <FormFreeText
                              textfieldProps={{
                                multiline: true,
                                rows: 4,
                              }}
                              uneditable={true} 
                            />
                          </FormController>
                        </FormField>
                      </Fragment>
                    )}
                  </Collapse>
                </Fragment>
              )}
            </List>
          )))}
        </List>
      </Grid>
    </FormContainer>)
  }

  return (
    <>
      <OutstandingWorksList submission={submission}/>
      {console.log('submission', submission)}
    </>
  )
}

import { Select, MenuItem, Stack, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { GlobalContext } from '@providers/globalStore'
import useLocalStorage from '@hooks/useLocalStorage'
import { menuItems } from './menuList'
import { flatMap, map } from 'lodash'
import useAPIFetch from '@hooks/useAPIFetch'
import { UserInfo } from '@services/model/user.model'

export const DrawerWidth = 300

const SelectContract = ({ uneditable = false }: { uneditable?: boolean }) => {
  const { state: globalState, dispatch, userInfo } = useContext(GlobalContext)
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)

  const [contractNoId, setContractNoId] = useLocalStorage<string | undefined>('contract', undefined)
  const [workGroupId, setWorkGroupId] = useLocalStorage<string | number | undefined>(
    'workgroup',
    undefined,
  )

  const pathList = window.location.pathname.split('/')

  const notInForm = ['my-action', 'dashboard', 'overview', 'all-record']
  const formPath = map(flatMap(menuItems, 'children'), 'id')
  const isInForm =
    pathList.some((item) => formPath.includes(item)) &&
    (!pathList.some((item) => notInForm.includes(item)) || pathList.length === 2)

  return (
    <Stack sx={{ padding: '8px', ml: '1', gap: '6px' }}>
      <Typography>Select Contract</Typography>
      <Select
        onChange={(event: any) => {
          dispatch({
            type: 'selectContract',
            contractNoId: event.target.value,
          })
          setContractNoId(event.target.value)
          const targetWorkGroup = globalState.contractList?.find(
            ({ id }) => id === event.target.value,
          )?.workGroups?.[0]?.id

          setWorkGroupId(targetWorkGroup)
          dispatch({
            type: 'selectWorkGroup',
            workGroupId: targetWorkGroup || 0,
          })
        }}
        disabled={isInForm || uneditable}
        value={contractNoId || 0}
        sx={{
          fontSize: 12,
          backgroundColor: '#FFFFFF',
        }}>
        {(globalState.contractList || []).map(({ id, contractNo }, idx) => {
          return (
            <MenuItem
              sx={{
                fontSize: 12,
              }}
              value={id}
              key={`form-body-select-yes-option-${idx}`}>
              {` ${contractNo}`}
            </MenuItem>
          )
        })}
      </Select>
      <Typography>Select Workgroup</Typography>
      <Select
        onChange={(event: any) => {
          dispatch({
            type: 'selectWorkGroup',
            workGroupId: event.target.value,
          })
          setWorkGroupId(event.target.value)
        }}
        disabled={isInForm || uneditable}
        value={workGroupId || 0}
        sx={{
          fontSize: 12,
          backgroundColor: '#FFFFFF',
        }}>
        {globalState.contractList
          ?.find(({ id = '' }) => id === contractNoId)
          ?.workGroups?.map(({ id, workGroupName }, idx) => {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                value={id}
                key={`form-body-select-yes-option-${idx}`}>
                {` ${workGroupName}`}
              </MenuItem>
            )
          })}
      </Select>
    </Stack>
  )
}
export default SelectContract

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { lineChartProps } from './BasicLineChart'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const LineChart = (props: lineChartProps) => {
    let datasets = props.datasets.map((dataset) => {
        return {
            label: dataset.name,
            data: dataset.data,
            ...dataset.dataConfig,
        }
    })
    return (
        <Line
            options={props.options}
            data={{
                labels: props.labels,
                datasets: [...datasets],
            }}
        />
    )
}
export default LineChart

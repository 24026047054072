import { KeyValPair } from '@models/common'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  Grid,
  Checkbox,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { styled, useTheme } from '@mui/material/styles'
import {
  ArrayPath,
  Controller,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  Path,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayProps,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import GetValueWithKey from '@utils/getValueWithKey'
import { Base64StringToImageBase64, ImageModelToUrl } from '@utils/image'
import { ImageModel } from '@services/model/image.model'
import VisibilityIcon from '@mui/icons-material/Visibility'
import useImageModal from '@hooks/useImageModal'
import FormTypography from './typography'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import DoneIcon from '@mui/icons-material/Done'

const FormTableBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '16px 30px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '16px 0px',
  },
  // overflowX: 'auto',
}))

const FormTableFooterButton = styled(Button)(() => ({
  height: 60,
  width: '100%',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  backgroundColor: '#ffffff',
  ':hover': {
    backgroundColor: '#00ffff',
    '#form-table-add-icon': {
      color: '#ffffff',
    },
  },
  ':disabled': {
    backgroundColor: '#d9d9d9',
    '#form-table-add-icon': {
      color: '#ffffff',
    },
  },
}))

const FormTableAddIcon = styled(AddIcon)(() => ({
  color: '#000000',
}))

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 'bold',
  padding: theme.spacing(1),
}))

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  padding: theme.spacing(1),
}))

const LinkTableImageHolder = styled(Box)(({ theme }) => ({
  margin: 'auto',
  border: '0.1px solid rgb(0 0 0 / 20%)',
  objectFit: 'contain',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 70,
  },
  [theme.breakpoints.up('md')]: {
    width: 300,
    height: 200,
  },
}))

const LinkTableImage = styled('img')(() => ({
  width: 'inherit',
  height: 'inherit',
  objectFit: 'contain',
}))

const LinkTableAddButton = styled(Box)<{
  state: {
    disableAddButton?: boolean
    uneditable?: boolean
    color?: string
  }
}>(({ state }) => ({
  width: 60,
  height: 60,
  display: state.uneditable ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  position: 'relative',
  zIndex: 50,
  backgroundColor: state.disableAddButton ? '#909090' : state.color ?? '#23e9c0',
  ...(!state.disableAddButton && {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00ffff',
      '#add-button-hover-text': {
        opacity: '100% !important',
        transition: 'opacity 500ms !important',
      },
    },
  }),
}))

function FormLinkTable<F extends FieldValues>(props: {
  columns: {
    field: string
    name: string
    type?: 'image' | 'string' | 'selected' | 'images' | 'custom' | 'checkbox'
    render?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex: number) => JSX.Element
    options?: KeyValPair[]
    hidden?: boolean
    mobileHidden?: boolean
    width?: number | string,
    disabled?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex: number) => boolean
  }[]
  fieldArrayProps: UseFieldArrayProps<F>
  title?: string
  addButtonText?: string
  addWithPopupConponment?: (isOpen: boolean, handleClose: () => void, append: any) => JSX.Element
  color?: string

  align?: 'left' | 'right' | 'center'
  hiddenItemNo?: boolean
  height?: number | string
  removeAddButton?: boolean
  disableAddButton?: boolean
  removeRemoveButton?: boolean
  onLinkClick?: (index: number) => void
  appendField?: (event: any) => void
  additionalAppendAction?: (event: any) => void
  additionalRemoveAction?: (event: any, index: number) => void
  rowFilter?: (row: FieldArrayWithId<F, ArrayPath<F>, '_id'>, rowIndex?: number) => boolean
  onHeaderAddButtonClick?: (append: UseFieldArrayAppend<F, ArrayPath<F>>) => void
  enableSelectionButton?: boolean
  showSelectionBox?: boolean
  setShowSelectionBox?: any
  handleOnApproval?: any
  additionalFields?: JSX.Element

  disabled?: boolean
  uneditable?: boolean
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const { fields, append, remove, update } = useFieldArray({
    keyName: '_id',
    ...props.fieldArrayProps,
  })
  let itemNo = 0

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen)
  }

  const [modalImage, setModalImage] = useState<string | undefined>()
  const [ImageModal, openImageModal] = useImageModal()
  const handleImageModalOpen = (base64Url: string) => {
    setModalImage(base64Url)
    openImageModal()
  }

  const appendField = (event: any) => {
    if (props.appendField && !props.disableAddButton && !props.disabled && !props.uneditable) {
      props.appendField(event)
    } else if (
      props.addWithPopupConponment &&
      !props.disableAddButton &&
      !props.disabled &&
      !props.uneditable
    ) {
      togglePopup()
    } else {
      append({ status: true } as FieldArray<F, ArrayPath<F>>)
    }
    if (props.additionalAppendAction) {
      props.additionalAppendAction(event)
    }
  }

  const headerAddButtonClick = (event: any) => {
    if (props.onHeaderAddButtonClick) {
      props.onHeaderAddButtonClick(append)
    } else {
      appendField(event)
    }
  }

  const removeField = (event: any, index: number) => {
    // uuid / formId / id -> set status = false
    //           otherize -> remove record
    if (fields[index]['uuid'] || fields[index]['id'] || fields[index]['fileId']) {
      const _field = fields[index]
      _field['status'] = false
      update(index, _field)
      console.log('set to false')
    } else if (fields[index]['baseForm'] && fields[index]['baseForm']['formId']) {
      const _field = fields[index]
      _field['baseForm']['status'] = false
      update(index, _field)
      console.log('set to false')
    } else {
      remove(index)
    }
    if (props.additionalRemoveAction) {
      props.additionalRemoveAction(event, index)
    }
  }

  return (
    <Grid xs={12}>
      <Box
        sx={{
          display: 'flex',
          padding: 2,
          alignItems: 'center',
        }}>
        <Box
          sx={{
            maxWidth: 250,
          }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              ...(downMd && {
                fontSize: 18,
              }),
            }}>
            {props.title ? t(props.title) : props.fieldArrayProps.name}
          </Typography>
        </Box>
        <Box flex={1} />
        {props.addWithPopupConponment && props.addWithPopupConponment(isPopupOpen, togglePopup, append)}

        <LinkTableAddButton
          state={{
            disableAddButton: props.disableAddButton,
            uneditable: props.uneditable,
            color: props.color,
          }}
          onClick={headerAddButtonClick}>
          <AddIcon
            id="link-table-add-icon"
            sx={{
              height: '100%',
              width: '100%',
              color: '#ffffff',
            }}
          />
          <Box
            id="add-button-hover-text"
            style={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              padding: 10,
              paddingRight: 70,
              opacity: 0,
            }}>
            <Typography>
              {props.addButtonText ? t(props.addButtonText) : t('Add a ') + props.title}
            </Typography>
          </Box>
        </LinkTableAddButton>
        {props.enableSelectionButton &&
          (props.showSelectionBox ? (
            <>
              <LinkTableAddButton
                state={{
                  disableAddButton: props.disableAddButton,
                  uneditable: props.uneditable,
                  color: props.color,
                }}
                sx={{ marginLeft: '24px' }}
                onClick={props.setShowSelectionBox}
                // onClick={approveSelectedCheckbox}
              >
                <LibraryAddIcon
                  sx={{
                    height: '100%',
                    width: '100%',
                    color: '#ffffff',
                  }}
                />
              </LinkTableAddButton>
            </>
          ) : (
            <>
              <LinkTableAddButton
                state={{
                  disableAddButton: props.disableAddButton,
                  uneditable: props.uneditable,
                  color: props.color,
                }}
                sx={{ marginLeft: '24px' }}
                onClick={() => props.handleOnApproval()}>
                <DoneIcon
                  sx={{
                    height: '100%',
                    width: '100%',
                    color: '#ffffff',
                  }}
                />
              </LinkTableAddButton>
            </>
          ))}
      </Box>
      <Box sx={{ overflow: 'auto' }}>
        {props.additionalFields && <>{props.additionalFields}</>}
        <FormTableBox>
          <Table
            sx={{
              boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
            }}>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#c5c5c5',
                }}>
                <HeaderTableCell
                  align={props.align ?? 'center'}
                  style={{
                    width: 5,
                    display: props.hiddenItemNo ? 'none' : 'table-cell',
                  }}>
                  {downMd ? '#' : t('Item No.')}
                </HeaderTableCell>
                {props.columns.map((column, index: number) => (
                  <HeaderTableCell
                    style={{
                      display:
                        column.hidden || (downMd && column.mobileHidden) ? 'none' : 'table-cell',
                      // flex: 1,
                      minWidth: column.width ?? '100px',
                    }}
                    key={`form-table-header-${index}`}
                    align={props.align ?? 'center'}>
                    {t(column.name)}
                  </HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((datum, datumIndex: number) => {
                if (props.rowFilter && !props.rowFilter(datum, datumIndex)) {
                  return <Fragment key={`table-body-row-${datumIndex}`}></Fragment>
                } else if (
                  !props.rowFilter &&
                  !(datum['status'] || (datum['baseForm'] && datum['baseForm']['status']))
                ) {
                  return <Fragment key={`table-body-row-${datumIndex}`}></Fragment>
                }

                return (
                  <TableRow
                    key={`form-table-row-${datumIndex}`}
                    sx={{
                      height: props.height ?? 200,
                      backgroundColor: '#edeef0',
                      ...(!props.removeRemoveButton && {
                        ':hover': {
                          '#table-row-actions': {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        },
                      }),
                    }}
                    {...(props.onLinkClick &&
                      downMd && {
                        onClick: () => props.onLinkClick!(datumIndex),
                      })}>
                    <BodyTableCell
                      style={{
                        flex: 1,
                        position: 'relative',
                        display: props.hiddenItemNo ? 'none' : 'table-cell',
                      }}
                      key={`form-table-cell-${datumIndex}-item-no`}>
                      <Box
                        id="table-row-actions"
                        sx={{
                          position: 'absolute',
                          transition: 'transform',
                          transform: 'translate(-100%, -40%)',
                          display: 'none',
                          ':hover': {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        }}>
                        <IconButton
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                              transition: 'transform 1000ms ease-in-out',
                              transform: 'rotate(360deg)',
                            },
                            display:
                              props.uneditable || props.enableSelectionButton ? 'none' : 'inherit',
                          }}
                          onClick={(event: any) => {
                            removeField(event, datumIndex)
                          }}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                              transition: 'transform 500ms ease-in-out',
                              transform: 'rotate(15deg)',
                            },
                            display: props.onLinkClick ? 'inherit' : 'none',
                          }}
                          onClick={() => {
                            props.onLinkClick!(datumIndex)
                          }}>
                          {props.uneditable ? <VisibilityIcon /> : <EditIcon />}
                        </IconButton>
                      </Box>
                      <Typography textAlign={props.align ?? 'center'} fontSize={12}>
                        {++itemNo}
                      </Typography>
                    </BodyTableCell>
                    {props.columns.map((column, columnIndex: number) => {
                      let children: JSX.Element = <Fragment></Fragment>
                      if (column.hidden || (downMd && column.mobileHidden))
                        return children

                      const fieldList = column.field.split('.')
                      let renderedDatum: any | undefined = undefined
                      if (fieldList.length > 1) {
                        if (datum[fieldList[0]] && datum[fieldList[0]][fieldList[1]]) {
                          if (datum[fieldList[0]][fieldList[1]][fieldList[2]]) {
                            renderedDatum = datum[fieldList[0]][fieldList[1]][fieldList[2]]
                          } else {
                            renderedDatum = datum[fieldList[0]][fieldList[1]]
                          }
                        }
                      } else {
                        renderedDatum = datum[column.field];
                      }

                      if (column.type) {
                        switch (column.type) {
                          case 'image': {
                            // display an image model image
                            children = (
                              <Fragment>
                                {renderedDatum ? (
                                  <LinkTableImageHolder>
                                    <LinkTableImage
                                      src={ImageModelToUrl(renderedDatum, 'regular')}
                                      alt={`link-table-img-${datumIndex + 1}`}
                                    />
                                  </LinkTableImageHolder>
                                ) : (
                                  <Typography fontSize={12}>{t('No image')}</Typography>
                                )}
                              </Fragment>
                            )
                            break
                          }
                          case 'images': {
                            // display first image of the image model array with status = true
                            const _renderedDatum = (
                              renderedDatum as ImageModel[] | undefined
                            )?.find((image) => image.status)
                            children = (
                              <Fragment>
                                {_renderedDatum ? (
                                  <LinkTableImageHolder
                                    onClick={() => {
                                      var base64regex =
                                        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
                                      if (
                                        base64regex.test(
                                          ImageModelToUrl(_renderedDatum, 'original'),
                                        )
                                      ) {
                                        handleImageModalOpen(
                                          Base64StringToImageBase64(
                                            ImageModelToUrl(_renderedDatum, 'original'),
                                            _renderedDatum.ext as 'png' | 'jpeg' | 'jpg' | 'webp',
                                          ),
                                        )
                                      } else {
                                        handleImageModalOpen(
                                          ImageModelToUrl(_renderedDatum, 'original'),
                                        )
                                      }
                                    }}>
                                    <LinkTableImage
                                      src={ImageModelToUrl(_renderedDatum, 'regular')}
                                      alt={`link-table-img-${datumIndex + 1}`}
                                    />
                                  </LinkTableImageHolder>
                                ) : (
                                  <Typography fontSize={12}>{t('No image')}</Typography>
                                )}
                              </Fragment>
                            )
                            break
                          }
                          case 'selected': {
                            if (column.options) {
                              children = (
                                <Typography textAlign="center" fontSize={12}>
                                  {GetValueWithKey(renderedDatum, column.options)}
                                </Typography>
                              )
                            }
                            break
                          }
                          case 'string': {
                            children = (
                              <Typography textAlign="center" fontSize={12}>
                                {renderedDatum ?? ''}
                              </Typography>
                            )
                            break
                          }
                          case 'custom': {
                            if (column.render) {
                              children = column.render(datum, datumIndex)
                            }
                            break
                          }
                          case 'checkbox': {
                            children = (
                              <Controller
                                render={({ field }) => (
                                  <Fragment>
                                    {props.uneditable ? (
                                      <FormTypography
                                        type="binary"
                                        value={field.value}
                                        sx={{ fontSize: 12 }}
                                      />
                                    ) : (
                                      <Checkbox
                                        checked={!!field.value}
                                        onChange={(event: any) => {
                                          field.onChange(event.target.checked)
                                        }}
                                        disabled={column.disabled && column.disabled(datum, datumIndex)}
                                      />
                                    )}
                                  </Fragment>
                                )}
                                name={
                                  `${props.fieldArrayProps.name}.${datumIndex}.${column.field}` as Path<F>
                                }
                                control={props.fieldArrayProps.control}
                                defaultValue={datum[column.field]}
                              />
                            )
                          }
                        }
                      } else {
                        children = (
                          <Typography textAlign="center" fontSize={12}>
                            {renderedDatum ?? ''}
                          </Typography>
                        )
                      }

                      return (
                        <BodyTableCell
                          sx={{
                            minWidth: column.width ?? '100px',
                            position: 'relative',
                          }}
                          key={`form-table-cell-${columnIndex}-${datumIndex}`}>
                          <Box textAlign={props.align ?? 'center'}>{children}</Box>
                        </BodyTableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
            <tfoot
              style={{
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,

                border: '1px solid rgba(224, 224, 224, 1)',
                ...((props.removeAddButton || props.uneditable) && {
                  display: 'none',
                }),
              }}>
              <tr>
                <td colSpan={1000}>
                  <FormTableFooterButton
                    disabled={props.disableAddButton}
                    startIcon={
                      <FormTableAddIcon
                        id="form-table-add-icon"
                        sx={{
                          height: 40,
                          width: 40,
                        }}
                      />
                    }
                    onClick={(event: any) => {
                      appendField(event)
                    }}
                  />
                  {/* </Box> */}
                </td>
              </tr>
            </tfoot>
          </Table>
        </FormTableBox>
      </Box>
      <ImageModal image={modalImage} />
    </Grid>
  )
}

export default FormLinkTable

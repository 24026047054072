import { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Typography, Divider, Button, Grid, Stack, useMediaQuery } from '@mui/material'
import { GetOrganizationOptions } from '@utils/organizationOptions'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogButton from '@components/button/DialogButton'
import FormDialog from '@components/form/dialog_v2'
import ValidationToast from '@components/form/ValidationToast'
import FormTable from '@components/form/table'
import { ContractNo, InitContractNo } from '@services/model/contract.model'
import ContractService from '@services/contract.service'
import FormTypography from '@components/form/typography'
import LoadingPopup from '@components/form/LoadingDialog'

const UUID = uuid()

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(1),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))

const StyledDivider = styled(Divider)(() => ({
  '&::before, &::after': {
    borderColor: 'black',
  },
}))

const ButtonGroupBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  pt: 2,
}))

const SwitcherButton = styled(Button)(() => ({
  color: '#000000',
  '&:hover': {
    backgroundColor: '#e6e6e6',
  },
}))

export default function UserForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  const { id: contractUUID } = useParams<string>()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const validationSchema = yup
    .object()
    .shape({
      // email: yup.string().required(t('Email is required')),
      // firstName: yup.string().required(t('Full name is required')),
      // position: yup.string().required(t('Position is required')),
      // organizationId: yup.number().required(t('Organization is required')),
      // teams: yup.array().nullable().min(1).required(t('Teams is required')),
      // districts: yup.array().nullable().min(1).required(t('Districts is required')),
      // roles: yup.array().nullable().min(1).required(t('Roles is required')),
    })
    .required()

  const { control, getValues, reset, trigger } = useForm<ContractNo>({
    defaultValues: { ...InitContractNo },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()

  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (contractUUID) {
          await ContractService.GetContractDetails(contractUUID)
            .then(async (f) => {
              f.workOrderList = f.workOrderList?.map((x) => ({ ...x, status: true }))
              f.workGroups = f.workGroups?.map((x) => ({ ...x, status: true }))
              if (f) reset(f)
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            })
        }
        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    reload()
  }, [contractUUID])

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/settings/contracts/list')
  }

  const handleSubmitDialog = async () => {
    if (!contractUUID) {
      setDialogContext({
        isOpen: true,
        title: <Typography variant="body1">Create Contract</Typography>,
        children: <Typography>You are going to create contract.</Typography>,
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            <DialogButton
              disabled={isLoading}
              onClick={async (event) => {
                if (!(await trigger())) {
                  handleDialogClose()
                  return
                }
                var ff = getValues()
                console.log('ff', ff)

                // setRequest({
                //   callback: async () => {
                //     await AdminService.SaveCreateUser(ff).then((resp) => {
                //       enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                //       NavigateTo(navigate, '/admin')
                //     })
                //   },
                // })
                handleDialogClose()
              }}>
              {t('Confirm')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
          </Stack>
        ),
      })
    } else {
      if (!(await trigger())) {
        console.log('in trigger')
        handleDialogClose()
        return
      }
      var ff = getValues()
      // setRequest({
      //   callback: async () => {
      //     await AdminService.SaveCreateUser(ff).then((resp) => {
      //       enqueueSnackbar(t('Record Saved'), { variant: 'success' })
      //       NavigateTo(navigate, '/admin')
      //     })
      //   },
      // })
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <LoadingPopup open={isLoading} />
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}>
              {t('Contract')}
            </Typography>
          </Box>
          <FormContainer fkey={`${UUID}-contracts-a`}>
            <FormField fieldName="Contract No">
              <FormController
                controllerProps={{
                  name: 'contractNo',
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>

            <FormField fieldName="Client">
              <FormController
                controllerProps={{
                  name: 'client',
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>

            <FormField fieldName="Contractor">
              <FormController
                controllerProps={{
                  name: 'contractor',
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>

            {/* TODO:  */}
            {/* <FormField fieldName="Client">
              <FormController
                controllerProps={{
                  name: 'clientOrganizationId',
                  control,
                }}>
                <FormTypography type="select" options={GetOrganizationOptions() || []} />
              </FormController>
            </FormField>

            <FormField fieldName="Contractor">
              <FormController
                controllerProps={{
                  name: 'contractorOrganizationId',
                  control,
                }}>
                <FormTypography type="select" options={GetOrganizationOptions() || []} />
              </FormController>
            </FormField> */}

            <FormField fieldName="Contract Title">
              <FormController
                controllerProps={{
                  name: 'contractTitle',
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>

            <FormField fieldName="High Speed Road Project?">
              <FormController
                controllerProps={{
                  name: 'highSpeedRoad',
                  control,
                }}>
                <FormTypography type="binary" binaryOptions={{ yes: t('Yes'), no: t('No') }} />
              </FormController>
            </FormField>

            <FormField fieldName="Works Order">
            <FormController
                controllerProps={{
                  name: 'workOrderList',
                  control,
                }}>
              <FormTypography
                  type="multipleString"
                  displayKey="workOrderNo"
              />
              </FormController>
            </FormField>

            <FormTable
              columns={[
                {
                  field: 'workGroupCode',
                  displayName: 'Work Group Code',
                  type: 'string',
                },
                {
                  field: 'workGroupName',
                  displayName: 'Work Group Name',
                  type: 'string',
                },
              ]}
              height="80"
              fieldArrayProps={{
                name: 'workGroups',
                control,
              }}
              uneditable
              header="Work Group"
            />
          </FormContainer>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!contractUUID ? t('Back') : t('Back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              {/* <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleSubmitDialog}
                disabled={false}>
                {!contractUUID ? t('Submit') : t('Save')}
              </SwitcherButton> */}
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
      <ValidationToast control={control} />
    </Fragment>
  )
}

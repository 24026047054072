import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import FormFreeText from '@components/form/freeText'
import { FormEIModel, InitEIForm, EiGeneralOptions } from '@services/model/form/form.EI.model'
import FormImageUpload from '@components/form/imageUpload'
import FormField from '@components/form/field'

export default ({
  control,
  formStatus,
}: {
  control: Control<FormEIModel>
  formStatus: string
}) => {
  const UUID = uuid()
  const uneditable = !(
    formStatus === FormStatusEnum.FORM_EI_DRAFT || formStatus === FormStatusEnum.FORM_EI_REJECTED
  )

  return (
    <FormContainer fkey={`${UUID}-ei-c`}>
    {/* Site Condition */}
    <FormField fieldName="Site Condition">
      <FormController
        controllerProps={{
          name: 'siteImages',
          control,
        }}>
        <FormImageUpload uneditable={uneditable} multiple />
      </FormController>
    </FormField>
    {/* Other Information */}
    <FormField fieldName="Other Information">
      <FormController
        controllerProps={{
          name: 'otherInfo',
          control,
        }}>
        <FormFreeText
          textfieldProps={{
            fullWidth: true,
            multiline: true,
            rows: 10,
          }}
          sx={{
            width: '90%',
          }}
          uneditable={uneditable}
        />
      </FormController>
    </FormField>
  </FormContainer>
  )
}

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import '@firebase/messaging'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { IconButton, Tooltip } from '@mui/material'

const vapidKey = process.env.REACT_APP_VAPID_KEY
console.log('vapidKey', vapidKey)
export const initializeFirebase = () => {
  return initializeApp({
    apiKey: "AIzaSyCpaj4Tg8Frv3mulCo1z3RQg5X77TFaeRk",
    authDomain: "hyd-rmms.firebaseapp.com",
    projectId: "hyd-rmms",
    storageBucket: "hyd-rmms.appspot.com",
    messagingSenderId: "200401251421",
    appId: "1:200401251421:web:ec0d9de2e0c96ebf1371b4",
  })
}

export const firebaseGetToken = async (app: any) => {
  const messaging = getMessaging(app)
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('currentToken', currentToken)
        return currentToken
      } else {
        // Show permission request UI
        if (Notification.permission !== 'granted') {
          Notification.requestPermission().then((x) => {
            if (x === 'granted')
              getToken(messaging, {
                vapidKey: vapidKey,
              })
          })
        } else {
          return undefined
        }
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err)

      return undefined
    })
}

export const registerMessageListener = (app: any, enqueueSnackbar: any, navigate: any) => {
  const messaging = getMessaging(app)
  onMessage(messaging, (payload) => {
    if (payload && payload.notification) {
      enqueueSnackbar(`(${payload.notification.title}) ` + payload.notification.body, {
        action: (
          <Tooltip title="Navigate to notification page">
            <IconButton
              aria-label="Navigate to notification page"
              color="inherit"
              onClick={() => navigate('/notifications')}>
              <NavigateNextIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        ),
        variant: 'success',
      })
    }
  })
}

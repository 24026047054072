import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { barChartProps } from './BasicBarChart'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const VerticalOrHorizontalBarChart = (props: barChartProps) => {
    let datasets = props.datasets.map((dataset) => {
        return {
            label: dataset.name,
            data: dataset.data,
            ...dataset.dataConfig,
        }
    })
    return (
        <Bar
            options={props.options}
            data={{
                labels: props.labels,
                datasets: [...datasets],
            }}
        />
    )
}
export default VerticalOrHorizontalBarChart

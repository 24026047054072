import { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import { useNavigate, useParams } from 'react-router-dom'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Typography, Divider, Button, Grid, Stack, useMediaQuery } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogButton from '@components/button/DialogButton'
import FormDialog from '@components/form/dialog_v2'
import ValidationToast from '@components/form/ValidationToast'
import { WorkGroup } from '@services/model/workGroup.model'
import FormTypography from '@components/form/typography'
import ContractService from '@services/contract.service'
import LoadingPopup from '@components/form/LoadingDialog'

const UUID = uuid()

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(1),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))

const StyledDivider = styled(Divider)(() => ({
  '&::before, &::after': {
    borderColor: 'black',
  },
}))

const ButtonGroupBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  pt: 2,
}))

const SwitcherButton = styled(Button)(() => ({
  color: '#000000',
  '&:hover': {
    backgroundColor: '#e6e6e6',
  },
}))


export default function WorkGroupsForm() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { id: workGroupId } = useParams<string>()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { state: globalState, dispatch, userInfo } = useContext(GlobalContext)

  const validationSchema = yup
    .object()
    .shape({
      // email: yup.string().required(t('Email is required')),
      // firstName: yup.string().required(t('Full name is required')),
      // position: yup.string().required(t('Position is required')),
      // organizationId: yup.number().required(t('Organization is required')),
      // teams: yup.array().nullable().min(1).required(t('Teams is required')),
      // districts: yup.array().nullable().min(1).required(t('Districts is required')),
      // roles: yup.array().nullable().min(1).required(t('Roles is required')),
    })
    .required()

  const { control, getValues, reset, trigger } = useForm<WorkGroup>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()

  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (workGroupId) {
          await ContractService.GetContractWorkGroupDetails(workGroupId)
            .then(async (f) => {
              if (f) reset(f)
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            })
        }
        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    reload()
  }, [workGroupId])

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/settings/workgroups/list')
  }

  const handleSubmitDialog = async () => {
    if (!workGroupId) {
      setDialogContext({
        isOpen: true,
        title: <Typography variant="body1">Create Workgroup</Typography>,
        children: <Typography>You are going to create a workgroup.</Typography>,
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            {/* <DialogButton
              disabled={isLoading}
              onClick={async (event) => {
                if (!(await trigger())) {
                  handleDialogClose()
                  return
                }
                var ff = getValues()
                console.log('ff', ff)
                if (ff.status === undefined) {
                  ff.status = true
                }
                setRequest({
                  callback: async () => {
                    await AdminService.SaveCreateUser(ff).then((resp) => {
                      enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                      NavigateTo(navigate, '/admin')
                    })
                  },
                })
                handleDialogClose()
              }}>
              {t('Confirm')}
            </DialogButton> */}
            <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
          </Stack>
        ),
      })
    } else {
      // if (!(await trigger())) {
      //   console.log('in trigger')
      //   handleDialogClose()
      //   return
      // }
      // var ff = getValues()
      // console.log('ff', ff)
      // if (ff.status === undefined) {
      //   ff.status = true
      // }
      // setRequest({
      //   callback: async () => {
      //     await AdminService.SaveCreateUser(ff).then((resp) => {
      //       enqueueSnackbar(t('Record Saved'), { variant: 'success' })
      //       NavigateTo(navigate, '/admin')
      //     })
      //   },
      // })
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <LoadingPopup open={isLoading} />
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}>
              {t('Workgroup')}
            </Typography>
          </Box>
          <FormContainer fkey={`${UUID}-contracts-a`}>
          <FormField fieldName="Name">
              <FormController
                controllerProps={{
                  name: 'workGroupName',
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>
        
            <FormField fieldName="Code">
              <FormController
                controllerProps={{
                  name: 'workGroupCode',
                  control,
                }}>
                <FormTypography/>
              </FormController>
            </FormField>
        
            <FormField fieldName="Description">
              <FormController
                controllerProps={{
                  name: 'workGroupDesc',
                  control,
                }}>
                <FormTypography/>
              </FormController>
            </FormField>

            <FormField fieldName="Contract No">
              <FormController
                controllerProps={{
                  name: 'contractNoId',
                  control,
                }}>
                <FormSelect options={globalState.contractList?.map(({ id, contractNo }) => ({key: id, value: contractNo})) || []} uneditable={true} />
              </FormController>
            </FormField>

          </FormContainer>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!workGroupId ? t('Back') : t('Back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              {/* <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleSubmitDialog}
                disabled={false}>
                {!workGroupId ? t('Submit') : t('Save')}
              </SwitcherButton> */}
            </ButtonGroupBox>
          </Grid> 
        </StyledBox>
      </Box>
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
      <ValidationToast control={control} />
    </Fragment>
  )
}

import apiGateway                                             from '@utils/axiosInterceptor'
import { ContractNo, IowWorkOrder, WorkOrder, WorkOrderList } from './model/contract.model'
import { WorkGroup }                                          from './model/workGroup.model'

const GetContractList = async () => {
  return await apiGateway.get<ContractNo[]>('/api/v1/contracts').then((rep) => {
    return rep.data
  })
}

const GetContractDetails = async (id: string) => {
  return await apiGateway.get<ContractNo>(`/api/v1/contracts/${id}`).then((rep) => {
    return rep.data
  })
}
const GetContractWorkGroupList = async () => {
  return await apiGateway.get<WorkGroup[]>('/api/v1/contracts/workgroups/list').then((rep) => {
    return rep.data
  })
}

const GetContractWorkGroupDetails = async (id: string) => {
  return await apiGateway.get<WorkGroup>(`/api/v1/contracts/workgroups/${id}`).then((rep) => {
    return rep.data
  })
}

const GetWorkOrderListForCDR = async (req: {
  // formId?: string
  contractNoId: number
  formDate: Date
  teamId: number
  districtId: number
  typeOfWorks?: boolean
}) => {
  return await apiGateway
    .post<
      {
        id: number
        workOrderNo: string
        districtId: number
        emergencySerialNo: string
      }[]
    >('/api/v1/contracts/work-orders/list-for-cdr', req)
    .then((rep) => {
      return rep.data
    })
}

const GetWorkOrderListForWOU = async (contractNoId: string, typeOfWorks?: boolean) => {
  const query = {
    contract_no_id: contractNoId,
    ...(typeOfWorks !== undefined ? { type_of_works: typeOfWorks ? 'true' : 'false' } : {})
  }

  return await apiGateway
      .get<WorkOrderList[]>(`/api/v1/contracts/work-orders/list-for-wou?${new URLSearchParams(query).toString()}`)
      .then(resp => resp.data)
}

const GetWorkOrderListForSCR = async () => {
  return await apiGateway
      .get<WorkOrderList[]>('/api/v1/contracts/work-orders/list-for-scr')
      .then(rep => rep.data)
}

const GetWorkOrderListForHWR = async () => {
  return await apiGateway
      .get<WorkOrderList[]>('/api/v1/contracts/work-orders/list-for-hwr')
      .then(rep => rep.data)
}

const GetWorkOrderListForCR = async () => {
  return await apiGateway
      .get<WorkOrderList[]>('/api/v1/contracts/work-orders/list-for-cr')
      .then(rep => rep.data)
}

const GetWorkOrderListForDN = async () => {
  return await apiGateway
      .post<WorkOrder[]>('/api/v1/contracts/work-orders/list-for-dn')
      .then(resp => resp.data)
}

const GetWorkOrderMetaList = async (contractNoId: number /* 0 means always use X-Contract-Id in the request header */) =>
    await apiGateway.post<WorkOrder[]>('/api/v1/contracts/work-orders/meta/list', { contractNoId })
                    .then(resp => resp.data)

const GetContractAndWorkOrderList = async () => {
  return await apiGateway.get<ContractNo[]>('/api/v1/contracts/work-order').then((rep) => {
    return rep.data
  })
}

const GetIowWorkOrderList = async (req: number) => {
  return await apiGateway
    .post<{ list: IowWorkOrder[]; totalCount: number }>(
      `api/v1/contracts/work-orders/list-for-iow`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const ContractService = {
  GetContractList,
  GetContractDetails,
  GetContractWorkGroupList,
  GetContractWorkGroupDetails,
  GetWorkOrderListForCDR,
  GetWorkOrderListForWOU,
  GetWorkOrderListForSCR,
  GetWorkOrderListForHWR,
  GetWorkOrderListForCR,
  GetWorkOrderListForDN,
  GetWorkOrderMetaList,
  GetContractAndWorkOrderList,
  GetIowWorkOrderList
}

export default ContractService
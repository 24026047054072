import { ImageModel } from '../image.model'
import { LocationModel } from '../location.model'
import { FormModel, FormStatusEnum, InitFormModel } from './form.model'

export interface FormDeaModel {
  baseForm: FormModel
  eaId: number
  markAppeal?: boolean
  markApproval?: boolean
  mmWorksId?: number
  defectsIdentified?: number
  location?: LocationModel
  locationRemarks?: string
  checkingDate?: Date
  road?: string
  inspectionSection?: number
  totalInspectionSection?: number
  reportNo: string
  images?: ImageModel[]
  remarks?: string
  locationMapBase64?: string
}

export const InitDeaForm: FormDeaModel = {
  baseForm: { ...InitFormModel, formStatus: FormStatusEnum.FORM_DEA_DRAFT },
  reportNo: 'N/A',
  eaId: 0,
}

// TODO: confirm dea list model
export interface FormDEAListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string
  submissionStatus: string

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

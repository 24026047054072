import { Dispatch, SetStateAction, useEffect } from 'react'
import { Control, UseFormSetValue, useWatch }  from 'react-hook-form'
import { GlobalStateProps }                   from '@providers/globalStore'
import { FormWouModel, WouGeneralOptions }    from '@services/model/form/form.WOU.model'
import { WorkOrderList }                      from '@services/model/contract.model'
import { FormStatusEnum }                     from '@services/model/form/form.model'
import FormContainer                          from '@components/form/container'
import FormController                         from '@components/form/controller'
import FormField                              from '@components/form/field'
import FormTypography                         from '@components/form/typography'
import FormBinaryRadio                        from '@components/form/binaryRadio'
import FormSelect                             from '@components/form/select'
import FormMultipleSelect                     from '@components/form/multipleSelect'
import { GetWorksOrderListOptions }           from '@utils/worksOrderOptions'

export default ({
	                control,
	                globalState,
	                workOrderList,
	                setValue,
	                watch,
	                setDisableNav,
	                uneditable,
	                formStatus,
	                options
                }: {
	control: Control<FormWouModel>
	globalState: GlobalStateProps
	workOrderList?: WorkOrderList[]
	setValue: UseFormSetValue<FormWouModel>
	watch: any
	setDisableNav: Dispatch<SetStateAction<boolean>>
	uneditable: boolean
	formStatus: string
	options: WouGeneralOptions
}) => {
	const typeOfWorks = useWatch({ control, name: 'formWoi.typeOfWorks' })

	useEffect(() => {
		const subscription = watch(data => {
			if (data.baseForm?.contractNoId &&
			    data.baseForm?.workOrderId &&
			    data.baseForm?.teamId?.length > 0 &&
			    data.baseForm?.districtId?.length > 0 &&
			    data.provisional !== undefined)
				setDisableNav(false)
		})

		return () => subscription.unsubscribe()
	}, [watch])

	const contractNoList = globalState.contractList?.map(x => ({
		key  : x.id,
		value: x.contractNo
	})) || []

	const WorksOrderField = () => {
		if (formStatus === FormStatusEnum.FORM_WOU_DRAFT) {
			const [teamIds, districtIds] = useWatch({ control, name: ['baseForm.teamId', 'baseForm.districtId'] })

			return (
				<FormField fieldName='Work Order No'>
					<FormController controllerProps={ { control, name: 'baseForm.workOrderId' } }>
						<FormSelect fullWidth
						            options={ GetWorksOrderListOptions(workOrderList ?? [], teamIds, districtIds) }
						            additionalChangeAction={ (_, selected) => {
							            setValue('firstPageData', true)
							            if (selected) {
								            let selectedWorksOrder = workOrderList?.find(wo => wo.id === selected.key)
								            setValue('formWoi.typeOfWorks', selectedWorksOrder?.typeOfWorks)
							            }
						            } }
						            uneditable={ uneditable } />
					</FormController>
				</FormField>
			)
		} else {
			return (
				<FormField fieldName='Work Order No'>
					<FormController controllerProps={ { control, name: 'baseForm.workOrderNo' } }>
						<FormTypography />
					</FormController>
				</FormField>
			)
		}
	}

	return (
		<FormContainer fkey='wou-a'>
			{
				formStatus !== FormStatusEnum.FORM_WOU_DRAFT &&
                <FormField fieldName='WOU No'>
                  <FormController controllerProps={ { control, name: 'wouNo' } }>
                    <FormTypography />
                  </FormController>
                </FormField>
			}

			<FormField fieldName='Contract No.'>
				<FormController controllerProps={ { control, name: 'baseForm.contractNoId' } }>
					<FormSelect fullWidth
					            options={ contractNoList }
					            additionalChangeAction={ event => setValue('firstPageData', true) }
					            uneditable={ true } />
				</FormController>
			</FormField>

			<FormField fieldName='Team'>
				<FormController
					controllerProps={ { control, name: 'baseForm.teamId' } }>
					<FormMultipleSelect options={ globalState.teamList || [] } uneditable={ uneditable } />
				</FormController>
			</FormField>

			<FormField fieldName='District'>
				<FormController controllerProps={ { control, name: 'baseForm.districtId' } }>
					<FormMultipleSelect options={ globalState.districtList || [] } uneditable={ uneditable } />
				</FormController>
			</FormField>

			<WorksOrderField />

			<FormField fieldName='Temporary Work Order No'>
				<FormController controllerProps={ { control, name: 'baseForm.workOrderId' } }>
					<FormSelect fullWidth options={ [] } uneditable={ true } />
				</FormController>
			</FormField>

			<FormField fieldName='Provisional'>
				<FormController controllerProps={ { control, name: 'provisional' } }>
					<FormBinaryRadio uneditable={ uneditable } />
				</FormController>
			</FormField>

			{
				typeOfWorks &&
                <FormField fieldName='Category of Works'>
                  <FormController controllerProps={ { control, name: 'workDescRemark' } }>
                    <FormSelect options={ options?.typeOfMmWorks?.map(option => ({ key: option.id, value: option.name })) ?? [] }
                                uneditable={ uneditable } />
                  </FormController>
                </FormField>
			}
		</FormContainer>
	)
}

import { Fragment, useEffect, useRef, CSSProperties } from 'react'
import SignaturePad from 'react-signature-canvas'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FieldError } from 'react-hook-form'
import { RemoveBase64Header } from '@utils/file'
import { useTranslation } from 'react-i18next'

interface formSignaturePadProps {
  onChange?: (...event: any[]) => void
  value?: string
  error?: FieldError
  sx?: CSSProperties

  disabled?: boolean
  uneditable?: boolean
}

const FormSignaturePad = (props: formSignaturePadProps) => {
  const signContainer = useRef<any>()
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (signContainer.current && props.value) {
      signContainer.current.fromDataURL(props.value, {
        width: matchDownMd ? 235 : 300,
        height: matchDownMd ? 100 : 150,
      })
    }
  }, [props.value])

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const formatSignature = () => {
    if (signContainer.current) {
      const dataURL = signContainer.current.getTrimmedCanvas().toDataURL('image/png')
      return RemoveBase64Header(dataURL)
    }
  }

  const removeSignature = () => {
    if (signContainer.current) {
      signContainer.current.clear()
      props.onChange!(undefined)
    }
  }

  return (
    <Grid>
      {props.disabled || props.uneditable ? (
        <Fragment>
          {props.value ? (
            <img
              src={props.value}
              alt="signature"
              style={{
                width: matchDownMd ? 235 : 300,
                height: matchDownMd ? 100 : 150,
                borderBottom: '1px solid rgb(50, 50, 50)',
                ...props.sx,
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: matchDownMd ? 235 : 300,
                height: matchDownMd ? 100 : 150,
                borderBottom: '1px solid rgb(50, 50, 50)',
                ...props.sx,
              }}>
              <Typography>{t('No Signature')}</Typography>
            </Box>
          )}
        </Fragment>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <SignaturePad
            ref={signContainer}
            onEnd={() => props.onChange!(formatSignature())}
            penColor="#000000"
            canvasProps={{
              width: matchDownMd ? 300 : props.sx?.width ? props.sx?.width : 270 , 
              height: props.sx?.height ?? matchDownMd ? 110 : 150,
              style: props.error
                ? { border: '1px solid red', borderRadius: '25px' }
                : { borderBottom: '1px solid rgb(50, 50, 50)' },
            }}
          />
          <Box sx={{ alignSelf: 'center' }}>
            <IconButton onClick={removeSignature}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default FormSignaturePad

import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import { FormEIModel } from '@services/model/form/form.EI.model'
import FormService from '@services/form.service'
import { FormNFModel, InitNFForm, NFGeneralOptions } from '@services/model/form/form.NF.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { useSnackbar } from 'notistack'
import FormEIService from '@services/formService/form.EI.service'
import FormNFService from '@services/formService/form.NF.service'
import FormDNService from '@services/formService/form.DN.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { DevTool } from '@hookform/devtools'
import { DNGeneralOptions, FormDNModel } from '@services/model/form/form.DN.model'
import {
  BaseFormPermission,
  FormStatusEnum,
  FormStatusHistory,
} from '@services/model/form/form.model'
import { PartA } from './part-a'
import PartB from './part-b'
import useFormApprove from '@hooks/useFormApprove'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import getFormValidationSchema from '@utils/formValidationSchema'
import { DocumentExporter } from '@utils/documentExporter'
import { GetBase64FromUrl, GetImageDimensions } from '@utils/image'
import useLocalStorage from '@hooks/useLocalStorage'
import { canvasExport } from '@utils/canvasExport'
import { find, get } from 'lodash'

const UUID = uuid()

const NFPartC = (props: {
  control: any
  formStatusHistories: FormStatusHistory[]
  handleOnComplete: () => void
  hasParentForm: boolean
  formStatus: string
  formPermission: BaseFormPermission
  parentForm: FormEIModel | undefined
}): FormPartComponents => {
  const { state: globalState } = useContext(GlobalContext)
  return {
    partTitle: 'Submission Confirmation',
    component: (
      <FormContainer fkey={`${UUID}-nf-c`}>
        <FormSignatureHistories
          formStatusList={globalState.formStatusList}
          histories={props.formStatusHistories}
          control={props.control}
          handleOnComplete={() => props.handleOnComplete()}
          endOfFlow={
            (props.hasParentForm &&
              props.formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION &&
              props.formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION_REJECTED &&
              props.formStatus !== FormStatusEnum.FORM_NF_REVIEW_RECTIFICATION) ||
            props.formPermission?.endOfFlow
          }
          userMetaList={globalState.userMetaList}
          roleMetaList={globalState.roleMetaList}
          disabled={
            (props.hasParentForm &&
              props.formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION &&
              props.formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION_REJECTED &&
              props.formStatus !== FormStatusEnum.FORM_NF_REVIEW_RECTIFICATION) ||
            !props.formPermission?.canUpdate
          }
        />
      </FormContainer>
    ),
    disabled:
      props.parentForm && props.parentForm.baseForm.formStatus !== FormStatusEnum.FORM_EI_APPROVED,
  }
}

// export default function NFForm() {}
export default function NFForm() {
  const { state: globalState, dispatch: globalAction, userInfo } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const num = QueryStepParser(query.get('num'))

  //default value, set current date as check default value
  InitNFForm.dateOfCheck = new Date()

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_NF_DRAFT, FormStatusEnum.FORM_NF_ISSUE_REJECTED],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(t('Contract No. is required')),
          teamId: yup.array().nullable().required(t('Team is required')).min(1),
          districtId: yup.array().nullable().required(t('District is required')).min(1),
        }),
        location: yup.object().required(t('Location is required')),
        roadName: yup.string().required(t('Roadname is required')),
        scopeOfWorksId: yup.number().required(t('Work Order No. is required')),
        //TODO: check when can used in nested object getting outside value
        // "baseForm.workOrderId": yup.number().when("scopeOfWorksId", {
        //   is: 37,
        //   then: yup.number().required(),
        //   otherwise: yup.number().nullable()
        // }),
        defectCategoryId: yup.number().when('scopeOfWorksId', {
          is: 37,
          then: yup.number().required(t('Defect Category is required')),
          otherwise: yup.number().nullable(),
        }),
        defectOptionId: yup.number().when('scopeOfWorksId', {
          is: 37,
          then: yup.number().required(t('Defect Identified is required')),
          otherwise: yup.number().nullable(),
        }),
        //TODO: when defect identified include "non-compliance" -> not required
        // timeLimitId: yup.number().when()
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_NF_RECTIFICATION,
        FormStatusEnum.FORM_NF_RECTIFICATION_REJECTED,
      ],
      validationSchema: yup.object().shape({
        rectifications: yup.array().of(
          yup.object().shape({
            rectificationImages: yup
              .array()
              .nullable()
              .min(1)
              .required(t('Rectification images is required')),
          }),
        ),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_NF_OUTSIDE_M_M],
      validationSchema: yup.object().shape({
        coveringWorkOrder: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .moreThan(0, t('Issued Works Order is required'))
          .required(t('Issued Works Order is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_NF_NOT_UNDER_HYD_PURVIEW],
      validationSchema: yup.object().shape({
        followUpAction: yup.boolean().required(t('Follow-up Action is required')),
        followUpActionTo: yup.string().required(t('Follow-up By is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_NF_REVIEW, FormStatusEnum.FORM_NF_REVIEW_RECTIFICATION],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormNFModel>({
    defaultValues: { ...InitNFForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  // watching form status, signature change
  const formPermission = getValues('baseForm.formPermission')
  const formStatus = watch('baseForm.formStatus')

  const [nfGeneralOptions, setNfGeneralOptions] = useState<NFGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'NF')?.value ?? {},
  )
  const [dnOptions, setDnOptions] = useState<DNGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'DN')?.value ?? {},
  )
  const isMounted = useRef(false)

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })
  }, [formStatus])

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  let formEI
  let formEIId
  const reloadForm = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormNFService.GetNFForm(formId)
            .then(async (f) => {
              if (f) reset(f)
              // //store map if no map inside
                setTimeout(() => {
                  saveTargetMap().then(async () => await FormNFService.UpdateMap(getValues()))
                }, 20000)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/notification-form-of-defect/all-record')
            })
        }
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'NF') {
        formStateAction({ type: 'pop', targetFormType: 'NF' })

        if (formState.formStack[0].formType === 'EI') {
          setParentForm({ ...formState.formStack[0].form })
        }

        reset({ ...formState.form })
      } else if (formState.form && formState.formType === 'EI') {
        let formNF: FormNFModel = { ...InitNFForm }
        const _parentForm = formState.form as FormEIModel

        console.log(' _parentForm', _parentForm)
        if (num !== undefined) {
          if (_parentForm.formNf && _parentForm.formNf[num]) {
            formNF = { ..._parentForm.formNf[num] }
          }
        } else {
          formNF.baseForm.contractNoId = _parentForm.baseForm.contractNoId
          formNF.baseForm.workOrderId = _parentForm.baseForm.workOrderId
          formNF.baseForm.teamId = _parentForm.baseForm.teamId
          formNF.baseForm.districtId = _parentForm.baseForm.districtId
          formNF.location = _parentForm.location
          formNF.roadName = _parentForm.roadName
          formNF.caseSourceId = _parentForm.caseSourceId
          formNF.iccNo = _parentForm.iccNo
          formNF.caseSource = _parentForm.caseSource
        }

        formNF.eiNo = _parentForm.eiNo

        reset({ ...formNF })

        setParentForm({ ...formState.form })
      } else if (formId) {
        reloadForm()
      } else {
        // new form
        reset({ ...InitNFForm })

        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setValue('baseForm.teamId', userInfo.teams)
        setValue('baseForm.districtId', userInfo.districts)
      }

      isMounted.current = true
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      }) // nav bar remove status
    }
  }, [formId, formStateAction, globalAction])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return

    setValue('signatureBase64', signatureBase64)
    if (userInfo?.districts !== null) {
      setValue('district', userInfo?.districts[0])
    }
    if (userInfo?.teams !== null) {
      setValue('team', userInfo?.teams[0])
    }
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormNFService.RejectNFForm(ff).then(() => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/notification-form-of-defect/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormNFService.ApproveNFForm(ff).then(() => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/notification-form-of-defect/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormNFService.SaveNFForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/notification-form-of-defect/:id', {
            id: resp.formId,
          })

          formId = resp.formId
          reloadForm()

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormNFService.DeleteNFForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/notification-form-of-defect/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          })
      },
    })
  }

  const handleExportDocument = async () => {
    if (getValues('locationMapBase64') === undefined) {
      try {
        await saveTargetMap().then(async () => await FormNFService.UpdateMap(getValues()))
      } catch (err) {
        enqueueSnackbar(t('Please click the export button again after a few second.'), {
          variant: 'warning',
        })
        return
      }
    }

    formEIId = getValues('baseForm.parentFormUuid')
    if (formEIId !== '' && formEIId !== undefined) {
      formEI = await FormEIService.GetEIForm(formEIId)
    }
    let templateName = '/templates/NF_ver2.docx'

    setRequest({
      callback: async () => {
        await DocumentExporter(
          templateName,
          getValues('nfNo') ?? 'NF',
          getValues(),
          formEI,
          {
            nfGeneralOptions: nfGeneralOptions,
            globalState: globalState,
            dnGeneralOptions: dnOptions,
          }
        )
      },
    })
  }

  const mapRef = useRef<HTMLUListElement>(null)
  const saveTargetMap = async () => {
    let targetBase64 = await canvasExport(mapRef)
    setValue('locationMapBase64', targetBase64)
  }
  const [parentForm, setParentForm] = useState<FormEIModel | undefined>()
  const hasParentForm =
    parentForm !== undefined ||
    (getValues('baseForm.parentFormId') !== '' && getValues('baseForm.parentFormId') !== undefined)
  console.log('globalState', globalState)

  const NFPartA = (): FormPartComponents => {
    return {
      partTitle: t('General information'),
      component: (
        <PartA
          control={control}
          formStatus={formStatus}
          userInfo={userInfo}
          useWatch={useWatch}
          nfGeneralOptions={nfGeneralOptions}
          setValue={setValue}
          isMounted={isMounted}
          watch={watch}
          hasParentForm={hasParentForm}
          getValues={getValues}
          dnOptions={dnOptions}
          formPermission={formPermission}
          formStateAction={formStateAction}
          formId={formId}
          handleOnApproval={() => handleApproveDN()}
          ref={mapRef}
        />
      ),
      onNext: () => saveTargetMap(),
    }
  }

  const NFPartB = (): FormPartComponents => {
    return {
      partTitle: t('Rectification'),
      component: <PartB control={control} formStatus={formStatus} getValues={getValues} />,

      disabled:
        formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION &&
        formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION_REJECTED &&
        formStatus !== FormStatusEnum.FORM_NF_REVIEW_RECTIFICATION &&
        formStatus !== FormStatusEnum.FORM_NF_APPROVED,
    }
  }

  const onLoadNotifyList = async (isRejcet: boolean) => {
    var defailtNotifyList = await FormService.GetNotifyList(
      'NF',
      getValues('baseForm.formId'),
      isRejcet,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defailtNotifyList
  }

  const onLoadDNNotifyList = async (isRejcet: boolean) => {
    var dnFormId = getValues('formDn')?.find((x) => x.approveState === true)?.baseForm.formId || ''
    var defailtNotifyList = await FormService.GetNotifyList(
      'DN',
      dnFormId,
      isRejcet,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defailtNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const onLoadDnFormPermission = () => {
    if (
      getValues('formDn')?.find((x) => x.approveState === true)?.baseForm.formPermission
        .workflowRequired !== undefined
    ) {
      return getValues('formDn')?.find((x) => x.approveState === true)?.baseForm.formPermission
        .workflowRequired
    }
    return false
  }

  const handleOnApproval = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    approvalList?: { index: number; status: string }[],
  ) => {
    setValue('signatureBase64', signatureBase64)
    //get the checkbox value
    let sameStatus = true
    var req: { signatureBase64: string; formDn: FormDNModel[] } = {
      signatureBase64: '',
      formDn: [],
    }
    if (signatureBase64 !== undefined) req.signatureBase64 = signatureBase64
    if (getValues('formDn')) {
      req.formDn = getValues('formDn')
        .filter((x) => x.approveState === true)
        ?.map((x) => x, getValues('baseForm'))
    }
    let statusArray = req.formDn.map((x) => x.baseForm.formStatusName)
    if (statusArray.length > 0) {
      sameStatus = statusArray.every((element) => {
        if (element === statusArray[0]) {
          return true
        }
      })
    }
    console.log('handleOnApproval', req)

    if (!sameStatus) {
      enqueueSnackbar(t('You can only approve DN with same status at once'), { variant: 'error' })
    } else if (statusArray.length === 0) {
      enqueueSnackbar(t("You haven't select any DN."), { variant: 'error' })
    } else if (statusArray[0] === 'Issued') {
      enqueueSnackbar(
        t('These DN have already issued. You cannot do any additional action to these DN.'),
        { variant: 'error' },
      )
    } else if (isReject) {
      setRequest({
        callback: async () => {
          await FormNFService.RejectBatchDN(req).then(() => {
            enqueueSnackbar(t('Status updated'), { variant: 'success' })
            NavigateTo(navigate, '/notification-form-of-defect/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormNFService.ApproveBatchDN(req).then(() => {
            enqueueSnackbar(t('Status updated'), { variant: 'success' })
            NavigateTo(navigate, '/notification-form-of-defect/all-record')
          })
        },
      })
    }
  }

  const [ApproveDN, handleApproveDN] = useFormApprove({
    formOnSubmit: handleOnApproval,
    formOnLoadNotifyList: onLoadDNNotifyList,
    approveRequired: onLoadDnFormPermission,
  })

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('NOTIFICATION FORM OF DEFECT'),
    components: [
      NFPartA(),
      NFPartB(),
      NFPartC({
        control: control,
        formStatusHistories: getValues('baseForm.formStatusHistories'),
        handleOnComplete: () => {
          handleOnComplete()
        },
        hasParentForm: hasParentForm,
        formStatus: formStatus,
        formPermission: formPermission,
        parentForm: parentForm,
      }),
    ],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    onExportDocument: handleExportDocument,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    disableExport: formStatus === FormStatusEnum.FORM_NF_DRAFT,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
    navigateBack: async () => {
      if (parentForm) {
        let _parentForm: FormEIModel = { ...parentForm }
        formStateAction({ type: 'push', form: _parentForm, formType: 'EI' })
        NavigateTo(navigate, '/engineer-inspection/:formId', { formId }, { step: 1 })
      } else {
        formStateAction({ type: 'clear' })
        NavigateTo(navigate, '/notification-form-of-defect/all-record')
      }
    },
    ...(parentForm && {
      onSubFormComplete: async () => {
        if (parentForm) {
          if (!(await trigger())) return
          let _parentForm: FormEIModel = { ...parentForm }
          // if(!getValues("scopeOfWorksId")){
          //   return enqueueSnackbar("Scope of Works is required", { variant: 'error', autoHideDuration: null })
          // }
          // if(!getValues("location")){
          //   return enqueueSnackbar("Location is required", { variant: 'error', autoHideDuration: null })
          // }
          if (num !== undefined) {
            // old form
            _parentForm = {
              ...parentForm,
              formNf: parentForm.formNf?.map((photo: FormNFModel, index: number) =>
                index === num ? getValues() : photo,
              ),
            }
          } else {
            // new form
            _parentForm = {
              ...parentForm,
              formNf: [...(parentForm.formNf ?? []), getValues()],
            }
          }
          // insert this form to parent from
          formStateAction({ type: 'push', form: _parentForm, formType: 'EI' })
        } else {
          alert('something went wrong')
          formStateAction({ type: 'clear' })
        }

        NavigateTo(navigate, '/engineer-inspection/:formId', { formId }, { step: 1 })
      },
    }),
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ApproveDN />
      <ValidationToast control={control} />
      <DevTool control={control} />
    </Fragment>
  )
}

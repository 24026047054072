import Resizer                     from 'react-image-file-resizer'
import axios                       from 'axios'
import exifr                       from 'exifr'
import { ImageModel }              from '@services/model/image.model'
import { LocationModel }           from '@services/model/location.model'
import { ConvertBase64, ReadFile } from './file'

const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5 MB in bytes

const getImageDimension = async (file: File): Promise<[number, number]> =>
    new Promise((resolve, reject) => {
      const objectUrl = URL.createObjectURL(file)

      const img   = new Image()
      img.onerror = (error) => reject(error)
      img.onload  = async () => {
        URL.revokeObjectURL(objectUrl)
        resolve([img.width, img.height])
      }
      img.src     = objectUrl
    })

const ResizeImage = async (file: File, fileType: string, sizes?: [number, number]): Promise<File> => {
  let [width, height] = sizes ?? await getImageDimension(file)

  if (file.size <= MAX_IMAGE_SIZE)
    return new Promise((resolve, _) => {
      Resizer.imageFileResizer(file,
                               width,
                               height,
                               fileType,
                               100,
                               0,
                               (uri) => resolve(uri as File),
                               'file')
    })
  else {
    const MIN_QUALITY = 25 // Minimum quality to prevent excessive degradation

    let quality        = 80
    let dimensionRatio = (file.size - MAX_IMAGE_SIZE) / 10_000_000

    while (true) {
      file = await new Promise((resolve, _) => {
        Resizer.imageFileResizer(file,
                                 width * dimensionRatio,
                                 height * dimensionRatio,
                                 fileType,
                                 quality,
                                 0,
                                 (uri) => resolve(uri as File),
                                 'file')
      })

      // Check the size of the compressed image
      if (file.size <= MAX_IMAGE_SIZE || quality <= MIN_QUALITY || dimensionRatio <= 0.1) {
        break // Desired size achieved or minimum quality reached
      }

      // Adjust quality and dimensions
      quality -= 10
      dimensionRatio -= 0.1

      if (quality < MIN_QUALITY)
        quality = MIN_QUALITY
    }

    return file
  }
}

const createImageModel = async (file: File,
                                fileName: Array<string>,
                                fileType: string,
                                sizes?: ImageSizes,
                                location?: LocationModel): Promise<ImageModel | undefined> => {
  const [compressedFile, regularFile, thumbnailFile] = await Promise.all([ResizeImage(file, fileType),
                                                                          ResizeImage(file, fileType, sizes && sizes.medium ? sizes.medium : [300, 180]),
                                                                          ResizeImage(file, fileType, sizes && sizes.thumbnail ? sizes.thumbnail : [50, 30])])

  return {
    fileId      : '',
    status      : true,
    fileName    : fileName[0],
    ext         : fileName[fileName.length - 1],
    fileUrl     : await ConvertBase64(compressedFile),
    regularUrl  : await GetBase64FromFile(regularFile),
    thumbnailUrl: await GetBase64FromFile(thumbnailFile),
    size        : compressedFile.size,
    bytes       : await ReadFile(compressedFile),
    bytesBase64 : await ConvertBase64(compressedFile),
    location    : location
  }
}

/**
 * image sizes for resizing
 *
 * medium?: [width, height]
 *
 * thumbnail?: [width, height]
 */
export interface ImageSizes {
  medium?: [number, number]
  thumbnail?: [number, number]
}

const FileToImageModel = async (file: File, sizes?: ImageSizes): Promise<ImageModel | undefined> => {
  try {
    let fileType = 'JPEG'
    switch (file.type) {
      case 'image/png':
        fileType = 'PNG'
        break
      case 'image/webp':
        fileType = 'WEBP'
        break
      case 'image/jpeg':
      case 'image/jpg':
        break
      default:
        return undefined
    }

    let location: LocationModel | undefined
    try {
      let exifData = await exifr.parse(file)
      if (exifData.latitude && exifData.longitude) {
        location = {
          locationLatitude : exifData.latitude,
          locationLongitude: exifData.longitude,
          locationAddress  : ''
        }
      }
    }
    catch (e) {
      console.error('No GPS Meta Data')
    }

    return createImageModel(file,
                            file.name.split('.'),
                            fileType,
                            sizes,
                            location)
  }
  catch (err) {
    console.error('Error converting image to image model')
  }
}

const ImageModelToUrl = (image: ImageModel, size: 'regular' | 'thumbnail' | 'original'): string => {
  switch (size) {
    case 'regular':
      return image.regularUrl
    case 'thumbnail':
      return image.thumbnailUrl
    case 'original':
      return image.fileUrl
  }
}

/**
 * add header to base64 string to display as image in html `img` tag
 * @param base64 base64 string
 * @param format format of the base64 string, 'png' | 'jpeg' | 'jpg' | 'webp'
 * @returns base64 string with header
 * @example
 * ```
 * const imageBase64 = Base64StringToImageBase64(base64, 'png')
 * ```
 */
const Base64StringToImageBase64 = (
  base64: string | undefined,
  format: 'png' | 'jpeg' | 'jpg' | 'webp',
): string => {
  return base64 !== undefined ? `data:image/${format};base64,${base64}` : ''
}

const GetImageFromUrl = async (image: ImageModel): Promise<Blob | undefined> => {
  if (image.fileUrl && image.fileUrl !== '') {
    const res = await axios.get(image.fileUrl, {
      responseType: 'blob',
      headers: {
        'Content-type': 'image/jpg',
      },
    })
    return res.data as Blob
  }
}

const GetBase64FromUrl = async (url):  Promise<string | ArrayBuffer | null | undefined> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }
  });
}

const GetBase64FromFile = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
  })
}

const GetImageDimensions = (url: string,): Promise<{width: number, height: number}> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({
      width: img.width,
      height: img.height,
    });
    img.onerror = (error) => reject(error);
    img.src = url;
  });
};

export { FileToImageModel, ImageModelToUrl, Base64StringToImageBase64, GetImageFromUrl, GetBase64FromUrl, GetBase64FromFile, GetImageDimensions }
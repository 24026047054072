import { basicChartConfig, basicDataMap } from '@components/chart/BasicChart'
import {
    Box,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import styles from '@styles/generalChart.module.scss'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Color,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
)

export interface multiTypeDatasetChartProps {
    eleBefore?: any
    chartWrapperSX?: SxProps
    options?: any //basicChartOption | basicBarChartConfig | basicLineChartConfig | basicPieChartConfig
    datasets: generalChartDatasetProps[]
    eleAfter?: any
}

export interface generalChartDatasetProps extends basicChartConfig {
    type: 'bar' | 'line' | 'pie' | 'doughnut'
    label: string
    data: basicDataMap //[label]data
}

const MultiTypeDatasetChart = (props: multiTypeDatasetChartProps) => {
    let labels: string[] = []
    let datasets = props.datasets.map((dataset, i) => {
        if (i === 0) {
            labels = Object.keys(dataset.data)
        }
        return dataset
    })

    return (
        <>
            {props.eleBefore}
            <Box sx={props.chartWrapperSX}>
                <Chart
                    type="bar"
                    data={{
                        labels: labels,
                        datasets: [...datasets],
                    }}
                    options={props.options}
                />
            </Box>
            {props.eleAfter}
        </>
    )
}
export default MultiTypeDatasetChart

import { BaseListResponse }                                from '../base.model'
import { FormModel, InitFormModel, InitFormStatusHistory } from './form.model'

export interface FormQSModel {
  baseForm: FormModel

  // a
  // contract no
  // contractTitle
  // workOrderId
  // districtId
  typeOfMmWorks?: number
  startDateOfPeriod?: Date
  endDateOfPeriod?: Date
  totalPerformanceStandard?: number
  entitlementToMonthlySumId?: number
  scheduleOfRate?: number
  scheduleAmount?: number

  // b
  // workOrderId
  // ppStartDate
  // ppEndDate
  qsPerformanceStandard?: PerformanceStandard[]
  // totalPs?: number // <= frontend: sum(ps) / num(ps)
  entitlementMonthlySum?: number // <= db

  // c
  // workOrderId
  // ppStartDate
  // ppEndDate
  qsDefaultNotice?: DefaultNotice[]
  qsDefaultNoticeSummary?: DefaultNoticeSummary[]
  // TODO: toalPaymentDeductionsAmount?: {} <= db
  quarterlyPaymentDeductions?: number // <= db

  entitlementToMonthlySum?: number
  // d - signature
  signatureRequired: boolean
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string
}

export interface QsListResponse extends BaseListResponse {
  list: FormQsListModel[]
}

export interface PerformanceStandard {
  id: number
  status: boolean
  monthYearOfPerformanceStandard: Date,
  dateOfAudit: Date
  neaId: number
  neaNo: string
  noOfDefectiveLaneSection: number
  noOfLaneSectionAudited: number
  performanceStandard: number // in %
  entitlementToMonthlySum?: number // in %
  accumulatedIpPayable: number
}

export interface DefaultNotice {
  id: number
  status: boolean
  dnRefNo?: number
  dnType?: string
  //newly added
  formQsId?: number
  neaId?: number
  dnMonth: string
  //below are ok
  totalNoOfDn?: number
  totalDnAmount?: number
  totalRefundedAmount?: number
  totalPayment: number
  remarks?: string
}

export interface DefaultNoticeSummary {
  dnMonth: string,
  monthlyDeductionAmount: number,
  maxDeductionAmount: number, // 2 * scheduleOfRate
  actualDeductionAmount: number, // monthlyDeductionAmount or maxDeductionAmount whichever is higher
  accumulatedIpPayable: number,
  netIpToBePaidThisMonth: number // accumulatedIpPayable - actualDeductionAmount
}

export const InitQSForm: FormQSModel = {
  signatureRequired: true,
  baseForm: {
    ...InitFormModel,
    formStatus: 'FORM_QS_DRAFT',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
}

export interface FormQsListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string
  submissionStatus: string
  workOrderNo: string
  district: string
  districtId: number[]
  submittedBy: string
  approvedBy: string
  updatedAt: Date
  reportNo: string
}

export interface QSGeneralOptions {
  typeOfMmWorks: TypeOfMmWorks[]
  woiSorList: woiSor[]
}

export interface woiSor {
  mainsWorkOrderNo: string
  sorItemNo: number
  woId: number
}

export interface TypeOfMmWorks {
  id: number
  name: string
}

export interface QSFirstPageData {
  contractNoId: number
  workOrderId?: number
  typeOfMmWorks?: number
  startDateOfPeriod?: Date
  endDateOfPeriod?: Date
}

export interface QSFirstPageReturnData {
  totalPerformanceStandard: number
  entitlementToMonthlySumId: number
  entitlementToMonthlySum: number
  qsPerformanceStandard?: PerformanceStandard[]
  qsDefaultNotice?: DefaultNotice[]
  qsDefaultNoticeSummary?: Array<DefaultNoticeSummary>
}
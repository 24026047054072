import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Control, useWatch, UseFormSetValue } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents, KeyValPair } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { FormWoiModel, InitWOIForm, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import { DevTool } from '@hookform/devtools'
import formWOIService from '@services/formService/form.WOI.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import FormService from '@services/form.service'
import PartA from './part-a'
import PartB from './part-b'
import PartC from './part-c'
import PartD from './part-d'
import PartE from './part-e'
import PartF from './part-f'
import PartG from './part-g'
import PartH from './part-h'
import PartI from './part-i'
import PartJ from './part-j'
import PartK from './part-k'
import { find, get } from 'lodash'

export default function WOIForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { t } = useTranslation()

  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const { setRequest, isLoading } = useAPIFetch()
  const { enqueueSnackbar } = useSnackbar()
  const [woiGeneralOptions, setWoiGeneralOptions] = useState<WoiGeneralOptions>(globalState.formOptionsList?.find(({key}) => key === 'WOI')?.value ?? {})
  const isMounted = useRef<boolean>(false)

  const userMetaEmailList = globalState.userMetaEmailList
  console.log('userMetaList', userMetaEmailList)

  const { control, watch, setValue, getValues, handleSubmit, reset } = useForm<FormWoiModel>({
    defaultValues: { ...InitWOIForm },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const [typeOfWorks, formPermission] = getValues(['typeOfWorks', 'baseForm.formPermission'])
  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await formWOIService
            .GetWOIForm(formId)
            .then(async (f) => {
              if (f) {
                const ocd = f.originalCompletionDate
                const ecd = f.extendedCompletionDate
                const ccd = f.certifiedCompletionDate
                const delayOfWorks = f.delayOfWorks
                const oec = f.origEstCost
                const rec = f.revEstCost

                if (ocd && ecd && ccd) {
                  if (
                    !delayOfWorks &&
                    new Date(ccd).getTime() >
                      Math.max(new Date(ocd).getTime(), new Date(ecd).getTime())
                  ) {
                    f = { ...f, delayOfWorks: true }
                  }
                } else if (ocd && ecd && !!ccd) {
                  if (
                    delayOfWorks &&
                    Math.max(new Date(ocd).getTime(), new Date(ecd).getTime()) <
                      new Date().getTime()
                  ) {
                    f = { ...f, delayOfWorks: false }
                  }
                }

                if (oec === 0) {
                  f.origEstCost = ''
                }
                if (rec === 0) {
                  f.revEstCost = ''
                }

                if(f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId){
                  globalAction({
                    type: 'selectContract',
                    contractNoId: f.baseForm.contractNoId,
                  })
  
                  globalAction({
                    type: 'selectWorkGroup',
                    workGroupId: get(find(globalState.contractList, { id:  f.baseForm.contractNoId }), 'workGroups.0.id' , 0),
                  })
                }

                reset(f)
              }

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })

              console.log('changeWorkOrder', f.mainsWorkOrderNo)
              globalAction({
                type: 'changeWorkOrder',
                workOrder: f.mainsWorkOrderNo ?? undefined,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/works-order-information/all-record')
            })
        }
        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    if (formId) {
      // existed form
      reload()
    } else {
      // new form -> route to all record
      NavigateTo(navigate, '/works-order-information/all-record')
    }

    // reloadOthers()

    // clear form stack just in case it is not empty
    return () => {
      formStateAction({ type: 'clear' })
      globalAction({ type: 'changeWorkOrder', workOrder: undefined })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      }) // nav bar remove status
    }
  }, [])

  //#region form parts

  const WOIPartA = (): FormPartComponents => {
    return {
      partTitle: t('Information from MAINS'),
      component: (
        <PartA
          control={control}
          getValues={getValues}
          options={woiGeneralOptions}
          globalState={globalState}
        />
      ),
    }
  }

  const WOIPartB = (): FormPartComponents => {
    return {
      partTitle: t('General Information'),
      component: (
        <PartB
          control={control}
          getValues={getValues}
          options={woiGeneralOptions}
          globalState={globalState}
          userInfo={userInfo}
        />
      ),
    }
  }

  const WOIPartC = (): FormPartComponents => {
    return {
      partTitle: t('Expenditure'),
      component: <PartC control={control} />,
    }
  }

  const WOIPartD = (): FormPartComponents => {
    return {
      partTitle: t('Site Supervision'),
      component: <PartD control={control} />,
    }
  }

  const WOIPartE = (): FormPartComponents => {
    return {
      partTitle: t('Extension of Time Assessment'),
      component: <PartE control={control} options={woiGeneralOptions} />,
    }
  }

  const WOIPartF = (): FormPartComponents => {
    return {
      partTitle: t('Liquidated Damage'),
      component: <PartF control={control} />,
    }
  }

  const WOIPartG = (): FormPartComponents => {
    return {
      partTitle: t('Hidden Works Records'),
      component: <PartG control={control} />,
    }
  }

  const WOIPartH = (): FormPartComponents => {
    return {
      partTitle: t('Star Rate Valuation'),
      component: <PartH control={control} />,
    }
  }

  const WOIPartI = (): FormPartComponents => {
    return {
      partTitle: t('Site Instruction'),
      component: <PartI control={control} />,
    }
  }

  const WOIPartJ = (): FormPartComponents => {
    return {
      partTitle: t('Request for Payment Batching'),
      component: <PartJ control={control} options={woiGeneralOptions} setValue={setValue} />,
    }
  }

  const WOIPartK = (): FormPartComponents => {
    return {
      partTitle: t('Submission of Dimension Book by the Contractor '),
      component: <PartK control={control} />,
    }
  }

  //#endregion

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await formWOIService.SaveWOIForm(getValues()).then((resp) => {
          // setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          // NavigateTo(navigate, '/works-order-information/:id', {
          //   id: resp.formId,
          // })

          // formId = resp.formId
          reload()

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    // TODO: delete
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('WORKS ORDER INFORMATION'),
    components: [
      WOIPartA(),
      WOIPartB(),
      WOIPartC(),
      WOIPartD(),
      WOIPartE(),
      WOIPartF(),
      WOIPartG(),
      WOIPartH(),
      WOIPartI(),
      WOIPartJ(),
      WOIPartK(),
    ],
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    formOnLoadFormHistoryList: onLoadHistoryList,
    startStep: step,
    isLoading: isLoading,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
    // isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <DevTool control={control} />
    </Fragment>
  )
}

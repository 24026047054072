import SearchPanel from '@components/searchPanel'
import { Grid } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'

import { FormWoiListModel } from '@services/model/form/form.WOI.model'
import FormWOIService from '@services/formService/form.WOI.service'
import { NavigateTo } from '@utils/navigate'
import { RightsCategory } from '@services/model/form/form.model'
import { KeyValPair } from '@models/common'
import { GetDistrictOptions } from '@utils/districtOptions'
import TableBox from '@components/table/HoverTableBox'
import { BaseFilterOptions } from '@services/model/base.model'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

export default function AllRecord(props: { showMyRecord?: boolean }) {
  const refMounted = useRef(false)
  const { getOptionsByKey } = useGeneralOptions()
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<BaseFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormWOIService.GetWoiFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<FormWoiListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => <Fragment>{GetValueWithKey(record.mainWoStatus, getOptionsByKey("FORM WOI - Main WO Status") ?? [])}</Fragment>
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'typeOfWorks',
        disableSort: true,  // TODO: add key of typeOfWorks to sort in BE
        desc: t('Type Of Work', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => (
          <Fragment>{record.typeOfWorks ? 'M&M Works' : 'Non-M&M Works'}</Fragment>
        ),
      },
      {
        key: 'districtId',
        desc: t('District', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => (
          <Fragment>
            <TableBox columnValue={record.districtId} list={globalState.fullDistrictList} />
          </Fragment>
        ),
      },
      {
        key: 'teamId',
        desc: t('Team', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => (
          <Fragment>
            <TableBox columnValue={record.teamId} list={globalState.fullTeamList} />
          </Fragment>
        ),
      },
      {
        key: 'issueDate',
        desc: t('Issue Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => (
          <Fragment>{record.issueDate ? moment(record.issueDate).format() : ''}</Fragment>
        ),
      },
      // {
      //   key: 'submittedBy',
      //   desc: t('Submitted By', { ns: common.i18nFormNS }),
      //   renderCell: (record: FormWoiListModel) => (
      //     <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
      //   ),
      // },
      // {
      //   key: 'approvedBy',
      //   desc: t('Approved By', { ns: common.i18nFormNS }),
      //   renderCell: (record: FormWoiListModel) => (
      //     <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
      //   ),
      // },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormWoiListModel) => (
          <Fragment>{record.updatedAt ? moment(record.updatedAt).format() : ''}</Fragment>
        ),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormWoiListModel) {
      NavigateTo(navigate, '/works-order-information/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('WOI', JSON.stringify(state.filterResult))
        let resp = await FormWOIService.GetWoiList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:  getOptionsByKey("FORM WOI - Main WO Status") ?? [],
              defaultValue:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.formId?.value
                  : [],
            },
            // {
            //   key: 'typeOfWorks',
            //   desc: 'Type of Works',
            //   type: 'NumberSelectionFilter',
            //   multiple: true,
            //   valueList: [
            //     {
            //       key: 'true',
            //       value: 'M&M Works',
            //     },
            //     {
            //       key: 'false',
            //       value: 'Non-M&M Works',
            //     },
            //   ],
            //   defaultValue:
            //     sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
            //       ? JSON.parse(sessionStorage?.WOI)?.typeOfWorks?.value
            //       : [],
            // },
            {
              key: 'districtId',
              desc: 'District',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: GetDistrictOptions(userInfo.districts),
              defaultValue:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.districtId?.value
                  : [],
            },
            {
              key: 'issueDate',
              desc: 'Issue Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.issueDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.issueDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.issueDate?.value?.max
                  : '',
            },
            // {
            //   key: 'submittedBy',
            //   desc: 'Submitted By',
            //   type: 'StringSelectionFilter',
            //   multiple: true,
            //   valueList: globalState.userMetaList?.filter((x) =>
            //     extraListInfo.SubmittedByList?.includes(x.key),
            //   ),
            // },
            // {
            //   key: 'approvedBy',
            //   desc: 'Approved By',
            //   type: 'StringSelectionFilter',
            //   multiple: true,
            //   valueList: globalState.userMetaList?.filter((x) =>
            //     extraListInfo.ApprovedList?.includes(x.key),
            //   ),
            // },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.WOI !== 'undefined' && sessionStorage?.WOI !== undefined
                  ? JSON.parse(sessionStorage?.WOI)?.updatedAt?.value?.max
                  : '',
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

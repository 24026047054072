import SearchPanel from '@components/searchPanel'
import { Box, Grid, Stack } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import { EIAFilterOptions, FormEIAListModel } from '@services/model/form/form.EIA.model'
import FormEIAService from '@services/formService/form.EIA.service'
import { NavigateTo } from '@utils/navigate'
import { RightsCategory } from '@services/model/form/form.model'
import TableBox from '@components/table/HoverTableBox'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const [extraListInfo, setExtraListInfo] = useState<EIAFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    workOrderIdList: [],
    iccNoList: [],
    assignedToList: [],
    assignedToNameList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormEIAService.GetEIAFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }
  const { t } = useTranslation()

  const [TableView, reload] = useTableView<FormEIAListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'iccNo',
        desc: t('ICC No.', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => (
          <Fragment>
            <TableBox
              columnValue={record.iccNo ?? []}
              list={record.iccNo?.map((x) => {
                return { key: x, value: x }
              })}
            />
          </Fragment>
        ),
      },
      {
        key: 'archiveReason',
        desc: t('Remarks', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => <Fragment>{record?.archiveReason?.substring(0, 100) ?? ''}</Fragment>,
      },
      {
        key: 'assignedTo',
        desc: t('Assigned To', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => (
          <Fragment>{GetValueWithKey(record.assignedTo, globalState.userMetaList ?? [])}</Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      // {
      //   key: 'approvedBy',
      //   desc: t('Approved By', { ns: common.i18nFormNS }),
      //   renderCell: (record: FormEIAListModel) => (
      //     <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
      //   ),
      //   mobileHidden: true,
      // },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIAListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: FormEIAListModel) {
      NavigateTo(navigate, '/engineer-inspection-assignment/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('EIA', JSON.stringify(state.filterResult))
        let resp = await FormEIAService.GetEIAList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_EIA))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo?.formIdList,
              defaultValue:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.formId?.value
                  : [],
            },
            {
              key: 'iccNo',
              desc: 'ICC No.',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo?.iccNoList?.map((x) => {
                return {
                  key: x,
                  value: x,
                }
              }),
              defaultValue:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.iccNo?.value
                  : [],
            },
            {
              key: 'assignedTo',
              desc: 'Assigned To',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.assignedToList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.iccNo?.value
                  : [],
            },
            // {
            //   key: 'roadName',
            //   desc: 'Road Name',
            //   type: 'StringFilter',
            //   defaultValue:
            //     sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
            //       ? JSON.parse(sessionStorage?.EIA)?.roadName?.value
            //       : '',
            // },
            // {
            //   key: 'locationRemark',
            //   desc: 'Location Remarks',
            //   type: 'StringFilter',
            //   defaultValue:
            //     sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
            //       ? JSON.parse(sessionStorage?.EIA)?.locationRemark?.value
            //       : '',
            // },
            // {
            //   key: 'inspectionDate',
            //   desc: 'Inspection Date',
            //   type: 'DateFilter',
            //   defaultOperator:
            //     sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
            //       ? JSON.parse(sessionStorage?.EIA)?.inspectionDate?.operator
            //       : 'in',
            //   defaultFrom:
            //     sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
            //       ? JSON.parse(sessionStorage?.EIA)?.inspectionDate?.value?.min
            //       : '',
            //   defaultTo:
            //     sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
            //       ? JSON.parse(sessionStorage?.EIA)?.inspectionDate?.value?.max
            //       : '',
            // },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo?.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.submittedBy?.value
                  : [],
            },
            // {
            //   key: 'approvedBy',
            //   desc: 'Approved By',
            //   type: 'StringSelectionFilter',
            //   multiple: true,
            //   valueList: globalState.userMetaList?.filter((x) =>
            //     extraListInfo?.approvedByList?.includes(x.key),
            //   ),
            //   defaultValue:
            //     sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
            //       ? JSON.parse(sessionStorage?.EIA)?.approvedBy?.value
            //       : [],
            // },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.EIA !== 'undefined' && sessionStorage?.EIA !== undefined
                  ? JSON.parse(sessionStorage?.EIA)?.updatedAt?.value?.max
                  : '',
            },
          ]}
        />

        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord

import { Typography, Box } from '@mui/material'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

export default function FileIcon(props: { text: string }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
      <Typography
        sx={{
          position: 'absolute',
          top: '50%',
          left: '24%',
          fontSize: 9,
          color: '#ffffff',
          width: 23,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {props.text}
      </Typography>
      <InsertDriveFileIcon
        sx={{
          width: 40,
          height: 40,
        }}
      />
    </Box>
  )
}

import { KeyValPair } from '@models/common'
import { GlobalContext } from '@providers/globalStore'
import { GeneralOptionGroup } from '@services/model/general_option.model'
import { useContext } from 'react'

/**
 * get master data general options (KeyValPair[])
 * @param masterDataList master data list
 * @param name name of general options
 * @returns key value pair list
 */
const GetDistrictOptions = (userDistrictList: number[]): KeyValPair[] => {
    const { state } = useContext(GlobalContext)

    if(state.districtList ){
        if(userDistrictList === null){
            return state.districtList
        }else{
            return (
                state.districtList?.filter((district) => userDistrictList?.includes(district.key))
            )
        }
    }
    return []
}

export { GetDistrictOptions }

import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse from '../model/base.model'
import { FormDEAListModel, FormDeaModel } from '../model/form/form.DEA.model'
import { BaseFormResponse } from '../model/form/form.model'

const GetDEAForm = async (formStatus: string, id: string) => {
  // TODO should find anoyher workaround
  var formMeta = await apiGateway
    .get<{
      formId: string
      formStatus: string
    }>(`api/v1/forms/form-status?formId=${id}`)
    .then((rep) => {
      return rep.data
    })

  return await apiGateway
    .get<FormDeaModel>(`api/v1/forms/DEA/${formMeta.formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetDEAList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<{ list: FormDEAListModel[]; lastModifyList: number[]; totalCount: number }>(
      `api/v1/forms/DEA/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const SaveDEAForm = async (req: FormDeaModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/DEA', req).then((rep) => {
      return rep.data
    })
  else
    return await apiGateway
      .put<BaseFormResponse>(
        `api/v1/forms/EA/${req.baseForm.formId}`,
        req,
      )
      .then((rep) => {
        return rep.data
      })
}

const ApproveDEAForm = async (bookingId: number, inactiveReason: string) => {
  return await apiGateway.put<BaseSaveResponse>(`api/v1/forms/${bookingId}/reject`, {
    inactiveReason: inactiveReason,
  })
}

const RejectDEAForm = async (bookingId: number, inactiveReason: string) => {
  return await apiGateway.put<BaseSaveResponse>(`api/v1/forms/${bookingId}/reject`, {
    inactiveReason: inactiveReason,
  })
}

const DeleteDEAForm = async (req: FormDeaModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/EA/${req.baseForm.formId}`,
  )
}

export default {
  GetDEAForm,
  GetDEAList,
  SaveDEAForm,
  ApproveDEAForm,
  RejectDEAForm,
  DeleteDEAForm,
}

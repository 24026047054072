import { KeyValPair } from '@models/common'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'

/**
 * get master data general options (KeyValPair[])
 * @param masterDataList master data list
 * @param name name of general options
 * @returns key value pair list
 */
const GetTeamOptions = (userTeamList: number[]): KeyValPair[] => {
  const { state } = useContext(GlobalContext)
  if (state.teamList) {
    if (userTeamList === null) {
      return state.teamList
    } else {
      return state.teamList?.filter((team) => userTeamList?.includes(team.key))
    }
  }

  return []
}

export { GetTeamOptions }

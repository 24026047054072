import SearchPanel from '@components/searchPanel'
import { Box, Grid, Stack } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import { EiFilterOptions, FormEIListModel } from '@services/model/form/form.EI.model'
import FormEIService from '@services/formService/form.EI.service'
import { NavigateTo } from '@utils/navigate'
import { RightsCategory } from '@services/model/form/form.model'
import { isNil } from 'lodash'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const [extraListInfo, setExtraListInfo] = useState<EiFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    workOrderIdList: [],
    parentFormIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormEIService.GetEiFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }
  const { t } = useTranslation()

  const [TableView, reload] = useTableView<FormEIListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => (
          <Fragment>
            <Stack>
              <Box>{record.reportNo}</Box>
              {isNil(record.parentReportNo) || record.parentReportNo === "N/A" ? <></> : <Box>(Ref: {record.parentReportNo})</Box>}
            </Stack>
          </Fragment>
        ),
      },
      {
        key: 'refNo',
        desc: t('Ref No.', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => <Fragment>{record.refNo}</Fragment>,
      },
      {
        key: 'roadName',
        desc: t('Road Name', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => <Fragment>{record.roadName}</Fragment>,
        width: '250px',
      },
      {
        key: 'inspectionDate',
        desc: t('Inspection Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => (
          <Fragment>
            {record.inspectionDate
              ? moment(record.inspectionDate).format('D MMM YYYY HH:mm')
              : 'N/A'}
          </Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormEIListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowClick: function (navigate: NavigateFunction, record: FormEIListModel) {
      NavigateTo(navigate, '/engineer-inspection/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('EI', JSON.stringify(state.filterResult))
        let resp = await FormEIService.GetEIList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_EI))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.formId?.value
                  : [],
            },
            {
              key: 'formEiaNo',
              desc: 'EIA No.',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.parentFormIdList,
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.formEiaNo?.value
                  : [],
            },
            {
              key: 'workOrderId',
              desc: 'Works Order No',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.worksOrderList
                ?.filter((x) => extraListInfo.workOrderIdList?.includes(x.id))
                ?.map(({ id, workOrderNo }) => ({ key: id, value: workOrderNo })),
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.workOrderId?.value
                  : [],
            },
            {
              key: 'refNo',
              desc: 'Road Name',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.refNo?.value
                  : '',
            },
            {
              key: 'roadName',
              desc: 'Road Name',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.roadName?.value
                  : '',
            },
            {
              key: 'locationRemark',
              desc: 'Location Remarks',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.locationRemark?.value
                  : '',
            },
            {
              key: 'inspectionDate',
              desc: 'Inspection Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.inspectionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.inspectionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.inspectionDate?.value?.max
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.EI !== 'undefined' && sessionStorage?.EI !== undefined
                  ? JSON.parse(sessionStorage?.EI)?.updatedAt?.value?.max
                  : '',
            },
          ]}
        />

        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord

import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse, { BaseFilterOptions } from '../model/base.model'
import { FormWoiModel, WoiGeneralOptions, WoiListResponse } from '../model/form/form.WOI.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetGeneralOptions = async () => {
  return await apiGateway.get<WoiGeneralOptions>(`/api/v1/forms/WOI/options`).then((rep) => {
    return rep.data
  })
}

const GetWOIForm = async (formId: string) => {
  return await apiGateway
    .get<FormWoiModel>(`api/v1/forms/WOI/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetWoiList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<WoiListResponse>(`api/v1/forms/WOI/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetWoiFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/WOI/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<BaseFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveWOIForm = async (req: FormWoiModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/WOI', req).then((rep) => {
      return rep.data
    })
  else
    return await apiGateway
      .put<BaseFormResponse>(
        `api/v1/forms/WOI/${req.baseForm.formId}`,
        req,
      )
      .then((rep) => {
        return rep.data
      })
}

const ApproveWOIForm = async (bookingId: number, inactiveReason: string) => {
  return await apiGateway.put<BaseSaveResponse>(`api/v1/forms/${bookingId}/reject`, {
    inactiveReason: inactiveReason,
  })
}

const RejectWOIForm = async (bookingId: number, inactiveReason: string) => {
  return await apiGateway.put<BaseSaveResponse>(`api/v1/forms/${bookingId}/reject`, {
    inactiveReason: inactiveReason,
  })
}

const DeleteWOIForm = async (req: FormWoiModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/WOI/${req.baseForm.formId}`,
  )
}

export default {
  GetWOIForm,
  GetWoiList,
  GetWoiFilterOption,
  SaveWOIForm,
  ApproveWOIForm,
  RejectWOIForm,
  DeleteWOIForm,
  GetGeneralOptions,
}

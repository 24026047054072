import AddIcon from '@mui/icons-material/Add'

import {
  Box,
  Typography,
  Button,
  Grid,
  TableCell,
} from '@mui/material'
import * as common from '@common/common'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { UserFilterOptions, DutyListModel } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { styled, SxProps, Theme } from '@mui/material/styles'

const DutyList = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [extraListInfo, setExtraListInfo] = useState<UserFilterOptions>({
    organizationIdList: [],
  })

  const [TableView, reload] = useTableView<DutyListModel>({
    headers: [
      // {
      //   key: 'status',
      //   desc: t('Status', { ns: common.i18nFormNS }),
      //   renderCell: (record: DutyListModel) => (
      //     <Fragment>{record.status === true ? 'Enabled' : 'Disabled'}</Fragment>
      //   ),
      // },
      {
        key: 'email',
        desc: t('Email', { ns: common.i18nFormNS }),
        renderCell: (record: DutyListModel) => <Fragment>{record.email}</Fragment>,
      },
      {
        key: 'predecessor',
        desc: t('Name of Predecessor', { ns: common.i18nFormNS }),
        renderCell: (record: DutyListModel) => <Fragment>{record.predecessor}</Fragment>,
      },
      {
        key: 'position',
        desc: t('Position', { ns: common.i18nFormNS }),
        renderCell: (record: DutyListModel) => <Fragment>{record.position}</Fragment>,
      },

      {
        key: 'effectiveDateFrom',
        desc: t('Effective Date From', { ns: common.i18nFormNS }),
        renderCell: (record: DutyListModel) => (
          <Fragment>
            {record.effectiveStartTime
              ? moment(record.effectiveStartTime).format(moment.defaultFormat)
              : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },

      {
        key: 'effectiveDateTo',
        desc: t('Effective Date To', { ns: common.i18nFormNS }),
        renderCell: (record: DutyListModel) => (
          <Fragment>
            {record.effectiveEndTime
              ? moment(record.effectiveEndTime).format(moment.defaultFormat)
              : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },

      // {
      //   key: 'organizationName',
      //   desc: t('Organisation', { ns: common.i18nFormNS }),
      //   renderCell: (record: DutyListModel) => <Fragment>{record.organizationName}</Fragment>,
      // },
      // {
      //   key: 'action',
      //   desc: t('Action', { ns: common.i18nFormNS }),
      //   renderCell: (record: DutyListModel) => (
      //     <Fragment>
      //       <EditIcon />
      //       <EditIcon />
      //     </Fragment>
      //   ),
      // },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowGen: function (navigate: NavigateFunction, record: DutyListModel): JSX.Element {
      return (
        <Fragment>
          <TableCell>{record.status}</TableCell>
          {/* <TableCell>{record.displayName}</TableCell> */}
          <TableCell>{record.position}</TableCell>
          {/* <TableCell>{record.organizationName}</TableCell> */}
          {/* <TableCell>
            <EditIcon /> <EditIcon />
          </TableCell> */}
        </Fragment>
      )
    },
    onRowClick: function (navigate: NavigateFunction, record: DutyListModel) {
      NavigateTo(navigate, '/admin/duty/:id', { id: record.id })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      var list = await AdminService.GetDutyList(
        {
          ...state.filterResult,
          sorting: sorting,
          pagination: pagination,
        },
        cancelToken,
        props.showMyRecord || false,
      )

      return list
    },
  })

  useEffect(() => {
    document.title = t('Duty Effective Time', { ns: common.i18nFormNS })

    reload()
  }, [])

  const onClickSideBtnHandler: Function = () => {
    NavigateTo(navigate, '/admin/user')
  }

  const handleClickUser = () => {
    NavigateTo(navigate, '/admin')
  }
  const LinkTableAddButton = styled(Box)<{
    state: {
      disableAddButton?: boolean
      uneditable?: boolean
      color?: string
      hoverBackgroundColor?: string
      backgroundColor?: string
    }
  }>(({ state }) => ({
    width: 40,
    height: 40,
    display: state.uneditable ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    zIndex: 1000,
    backgroundColor: state.disableAddButton ? '#eeeeee' : state.color ?? '#23e9c0',
    ...(!state.disableAddButton && {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: state.hoverBackgroundColor ? state.hoverBackgroundColor : 'grey',
        '#add-button-hover-text': {
          opacity: '100% !important',
          width: '200px',
          transition: 'opacity 500ms !important',
        },
      },
    }),
  }))
  const handleAddDuty = (event) => {
    console.log('event', event.target.value)
    NavigateTo(navigate, '/admin/duty')
  }
  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            width: '100%',
          }}>
               <Box justifyContent={'center'}>
          <Button
            variant="outlined"
            sx={{
              fontWeight: 'bold',
              // margin: theme.spacing(6)
            }}
            onClick={handleClickUser}>
            {t('Back to Users')}
          </Button></Box>
          <Box sx={{ padding: '5px 10px ' }}>
            <LinkTableAddButton
              state={{
                disableAddButton: false, // add logic
                uneditable: false, // add logic
                color: '#cccccc',
                hoverBackgroundColor: '#000000',
              }}
              onClick={handleAddDuty}>
              <AddIcon
                id="link-table-add-icon"
                sx={{
                  height: '100%',
                  width: '100%',
                  color: '#ffffff',
                }}
              />
              <Box
                id="add-button-hover-text"
                style={{
                  position: 'absolute',
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  padding: 10,
                  paddingRight: 70,
                  opacity: 0,
                }}>
                <Typography>{t('Add a Duty Effective TIme')}</Typography>
              </Box>
            </LinkTableAddButton>
          </Box>
          {/* <Box>
            <SearchPanel
              dispatch={dispatch}
              addUrl="/booking/new"
              onSearch={reload}
              onInitReload={reload}
              onToggleFilterDrawer={getExtraListInfo}
              mountedRef={refMounted}
              excludeStatus={true}
              criteria={[
                {
                  key: 'status',
                  desc: 'Status',
                  type: 'BooleanSelectionFilter',
                  multiple: true,
                  valueList: [
                    {
                      key: true,
                      value: 'Enabled',
                    },
                    {
                      key: false,
                      value: 'Disabled',
                    },
                  ],
                  defaultValue:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.status?.value
                      : [],
                },
                {
                  key: 'displayName',
                  desc: 'Display Name',
                  type: 'StringFilter',
                  defaultValue:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.displayName?.value
                      : '',
                },
                {
                  key: 'position',
                  desc: 'Position',
                  type: 'StringFilter',
                  defaultValue:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.position?.value
                      : '',
                },
                {
                  key: 'organizationName',
                  desc: 'Organization',
                  type: 'NumberSelectionFilter',
                  multiple: true,
                  valueList: globalState.organizationMetaList?.filter((x) =>
                    extraListInfo.organizationIdList?.includes(x.key),
                  ),
                  defaultValue:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.organizationId?.value
                      : [],
                },
                {
                  key: 'updatedAt',
                  desc: 'Last Update',
                  type: 'DateFilter',
                  defaultOperator:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.updatedAt?.operator
                      : 'in',
                  defaultFrom:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.updatedAt?.value?.min
                      : '',
                  defaultTo:
                    sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                      ? JSON.parse(sessionStorage?.User)?.updatedAt?.value?.max
                      : '',
                },
              ]}
            />
          </Box> */}
        </Box>
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default DutyList

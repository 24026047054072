import { KeyValPair }               from '@models/common'
import { WorkOrder, WorkOrderList } from '@services/model/contract.model'

/**
 * get master data general options (KeyValPair[])
 * @returns key value pair list
 * @param worksOrderNoList
 * @param selectedTeam
 * @param selectedDistrict
 */
const GetWorksOrderOptions = (worksOrderNoList: WorkOrder[], selectedTeam: number[], selectedDistrict: number[]): KeyValPair[] => {
	if (selectedDistrict !== null && selectedTeam !== null) {
		return (
			worksOrderNoList?.filter(wo => {
				                const teamIncluded     = selectedTeam.some(t => wo.teamId !== undefined && wo.teamId.includes(t))
				                const districtIncluded = selectedDistrict.some(d => wo.districtId !== undefined && wo.districtId.includes(d))

				                return teamIncluded && districtIncluded
			                })
			                .map(wo => ({
				                key  : wo.id,
				                value: wo.workOrderNo
			                })) || []
		)
	}
	return []
}

const GetWorksOrderListOptions = (worksOrderNoList: WorkOrderList[], selectedTeam: number[], selectedDistrict: number[]): KeyValPair[] => {
	if (selectedDistrict !== null && selectedTeam !== null) {
		return (
			worksOrderNoList?.filter(wo => {
				                const teamIncluded     = selectedTeam.some((r) => wo.teamId !== undefined && wo.teamId.includes(r))
				                const districtIncluded = selectedDistrict.some((r) => wo.districtId !== undefined && wo.districtId.includes(r))

				                return teamIncluded && districtIncluded
			                })
			                .map(wo => ({
				                key  : wo.id,
				                value: wo.workOrderNo
			                })) || []
		)
	}
	return []
}

export { GetWorksOrderOptions, GetWorksOrderListOptions }

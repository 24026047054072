import apiGateway           from '@utils/axiosInterceptor'
import {
  EaCategory,
  EaDashboard,
  EaDashboardReq,
  EaFilterOptions,
  EaListResponse,
  FormEaModel
}                           from '@services/model/form/form.EA.model'
import { BaseFormResponse } from '@services/model/form/form.model'

const GetEaForm = async (formId: string) => {
  return await apiGateway.get<FormEaModel>(`api/v1/forms/EA/${formId}`).then((rep) => {
    return rep.data
  })
}

const GetEaCategory = async () => {
  return await apiGateway.get<EaCategory[]>(`api/v1/forms/EA/categories`).then((rep) => {
    return rep.data
  })
}

const GetEaList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<EaListResponse>(`api/v1/forms/EA/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetEaFilterOption = async (showMyRecord?: boolean) => {
  let api = `api/v1/forms/EA/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<EaFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const GetEaDashboard = async (req: EaDashboardReq, cancelToken: any): Promise<EaDashboard> =>
    await apiGateway
        .post<EaDashboard>('api/v1/forms/EA/dashboard', req, { cancelToken: cancelToken })
        .then(resp => resp.data)

const SaveEaForm = async (req: FormEaModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/EA', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/EA/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveEaForm = async (req: FormEaModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/EA/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const AppealEaForm = async (req: FormEaModel) => {
  return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/EA/${req.baseForm.formId}/appeal`, req)
      .then(resp => resp.data)
}

const RejectEaForm = async (req: FormEaModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/EA/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteEaForm = async (req: FormEaModel) => {
  return await apiGateway.delete<BaseFormResponse>(`api/v1/forms/EA/${req.baseForm.formId}`)
}

const FormEaService = {
  GetEaForm,
  GetEaCategory,
  GetEaList,
  GetEaFilterOption,
  GetEaDashboard,
  SaveEaForm,
  ApproveEaForm,
  AppealEaForm,
  RejectEaForm,
  DeleteEaForm,
}

export default FormEaService

import { groupBy } from 'lodash'

import { menuItems } from '@components/sidebar/menuList'
import { NotificationModel } from '@services/model/notification.model'

export interface NotificationModelByType {
    FORM_PROGRESSION?: NotificationModel[]
    FORM_COMMENT?: NotificationModel[]
    PROGRESS_DUE?: NotificationModel[]
    REMINDER?: NotificationModel[]
}

export const getNotificationByModule = (
  list: NotificationModel[],
): {
  module: string
  notifications: NotificationModelByType
}[] => {
  let groupByFormType: Record<string, NotificationModel[]> = groupBy(list, 'referenceType')

  let notificationByModule = menuItems.map(({ children, title }) => {
    const moduleForm = children.map((child) => child.acronym)

    return {
      module: title,
      notifications: groupBy(
        moduleForm
          .map((module) => {
            console.log('result', module, groupByFormType[module ?? ''])
            return groupByFormType[module ?? ''] ?? []
          })
          .flat(),
        'messageType',
      ),
    }
  })

  notificationByModule = [{ module: 'All', notifications: groupBy(list, 'messageType') }, ...notificationByModule]

  return notificationByModule 
}

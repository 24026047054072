import pako from 'pako'
import uuid from 'react-uuid'
import { size } from 'lodash'
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios'

import keycloak from '../keycloak'

const https = require('https-browserify')

const baseUrl = process.env.REACT_APP_API_BASE as string

export type APIConfig<T = AxiosRequestConfig> = T & {
  compress?: boolean
}

export interface APIAxiosInstance extends AxiosInstance {
  (url: string, config?: APIConfig): AxiosPromise
}

const defaultTransformers = (): AxiosRequestTransformer[] => {
  const { transformRequest } = axios.defaults
  if (!transformRequest) return []
  else if (transformRequest instanceof Array) return transformRequest
  return [transformRequest]
}

const gZipTransformers = (
  config: APIConfig<AxiosRequestConfig<any>>,
): AxiosRequestTransformer => (data: any, headers?: AxiosRequestHeaders) => {
  /**
   * NOTE
   * GZIP requests over a certain size:
   * gzipping small amounts of data will actually increase the overall size,
   * with 1024 bytes being a semi-arbitrary magic number.
   * Reference: https://medium.com/axiomzenteam/put-your-http-requests-on-a-diet-3e1e52333014
   */
  if (config.compress && typeof data === 'string' && size(data) > 1024) {
    if(headers) headers['Content-Encoding'] = 'gzip'

    try {
      const gzipped = pako.gzip(data, { level: 9 })
      console.debug(
        `[Axios] Compress to GZIP; original size: ${size(data)}; gzipped size: ${size(gzipped)}`,
        JSON.parse(data),
      )
      return gzipped
    } catch (e) {
      console.debug('[Axios] Compress to GZIP Error', e)
    }
  }

  return data
}

const apiGateway: APIAxiosInstance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

apiGateway.interceptors.request.use(
  function (config: APIConfig<AxiosRequestConfig<any>>) {
    // !TODO Only for dev, rewrite the base url to particular service endpoint
    if (process.env.REACT_APP_ENV === 'DEV' && config.url) {
      if (
        config.url.includes('/users') ||
        config.url.includes('/organizations') ||
        config.url.includes('/group')
      )
        config.baseURL = process.env.REACT_APP_API_USER_BASE as string
      else if (config.url.includes('/files'))
        config.baseURL = process.env.REACT_APP_API_FILE_BASE as string
      else if (config.url.includes('/notifications'))
        config.baseURL = process.env.REACT_APP_API_NOTIFICATION_BASE as string
      else if (config.url.includes('/convertors'))
        config.baseURL = process.env.REACT_APP_API_FILE_UPLOAD_BASE as string
      else config.baseURL = process.env.REACT_APP_API_FORM_BASE as string
    }

    // add jwt
    if (keycloak.token) {
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + keycloak.token,
      }
    }

    // TO BE REMOVED
    // if (process.env.REACT_APP_ENV === 'DEV') {
    const item = window.localStorage.getItem('usr')
    const contractNoId = window.localStorage.getItem('contract')
    const workGroupId = window.localStorage.getItem('workgroup')
    if (item) {
      var userInfo = JSON.parse(item)
      if (userInfo.uuid) {
        config.headers = {
          ...config.headers,
          'X-Caller-Id': userInfo.uuid,
          // TODO: add back here when backend support 
          'X-Contract-Id': contractNoId ?? '',
          'X-Workgroup-Id': workGroupId ?? ''
        }
      }
    }

    // transform request body to gzip
    if (config.compress){
      console.log("[API Compress Action]")
      config.transformRequest = [...defaultTransformers(), gZipTransformers(config)]
    }

    if (config.decompress){ 
      console.log("[API Decompress Action]")
      config.responseType = 'arraybuffer'
      config.headers = {
        ...config.headers,
        'Accept-Encoding': 'gzip',
        // 'Content-Encoding': 'gzip',
      }
    }

    // !TODO add trace-id, Check later about E2E Tracing
    config.headers = {
      ...config.headers,
      'X-Trace-Id': uuid(),
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

apiGateway.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    if (axios.isCancel(err)) return Promise.reject(err)

    const originalConfig = err.config
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          keycloak.updateToken(-10).then(() => {
            return apiGateway(originalConfig)
          })
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
      // else if (err.response.status === 403) {
      //     window.location.href = '/401'
      //     return;
      // }
    }

    return Promise.reject(err)
  },
)

export default apiGateway

import { useEffect } from 'react'
import { GlobalStateProps } from '@providers/globalStore'
import { FormCRModel } from '@services/model/form/form.CR.model'
import { Control, UseFormSetValue } from 'react-hook-form'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormFreeText from '@components/form/freeText'
import { FormStatusEnum } from '@services/model/form/form.model'

export default ({
  globalState,
  control,
  watch,
  setValue,
  formStatus
}: {
  globalState: GlobalStateProps
  control: Control<FormCRModel>
  watch: any
  setValue: UseFormSetValue<FormCRModel>
  formStatus: string
}) => {

  const iowAgreementOnCompletion = watch('iowAgreementOnCompletion')
  const outstandingWorks = watch('outstandingWorks')
  const uneditable = !(
    formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETION_REVIEW || formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETION_ENDORSEMENT || 
    formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETION_ENDORSEMENT_REJECTED 
    )

  const iowUneditable = (
      formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETION_ENDORSEMENT?
      false:true)
  const eUneditable= (
      formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETION_REVIEW ||
      formStatus === FormStatusEnum.FORM_CR_WORKS_COMPLETION_ENDORSEMENT_REJECTED?
          false:true)


  useEffect(() => {
    if(iowAgreementOnCompletion){
      setValue('reasonOfIncompletion', '')
    }
  }, [iowAgreementOnCompletion])

  
  useEffect(() => {
    if(!outstandingWorks){
      setValue('outstandingDetails', '')
    }
  }, [outstandingWorks])
  
  return (
  <FormContainer fkey="cr-a">
    <FormField fieldName="IOW Agreement on Completion">
      <FormController
        controllerProps={{
          name: 'iowAgreementOnCompletion',
          control: control,
        }}>
        <FormBinaryRadio 
          disabled={eUneditable} 
          binaryOptionNames={{ yes: 'Agree', no: 'Disagree' }} 
          additionalChangeAction={(event: any) => {
            let value = event.target.value
            if ( value === 'false') {
              setValue('outstandingWorks', undefined)
            } 
          }}
          />
      </FormController>
    </FormField>
    {iowAgreementOnCompletion === false ? <FormField fieldName="Reason of Incompletion">
      <FormController
        controllerProps={{
          name: 'reasonOfIncompletion',
          control,
        }}>
        <FormFreeText
          textfieldProps={{
            multiline: true,
            rows: 4,
          }}
          disabled={eUneditable}
        />
      </FormController>
    </FormField> : ""}
    <FormField fieldName="Outstanding Works">
      <FormController
        controllerProps={{
          name: 'outstandingWorks',
          control: control,
        }}>
        <FormBinaryRadio disabled={eUneditable || iowAgreementOnCompletion === false  }/>
      </FormController>
    </FormField>
    {outstandingWorks ? 
    <FormField fieldName="Outstanding Works Details">
      <FormController
        controllerProps={{
          name: 'outstandingDetails',
          control,
        }}>
        <FormFreeText
          textfieldProps={{
            multiline: true,
            rows: 4,
          }}
          disabled={eUneditable}
        />
      </FormController>
    </FormField>:""}
    <FormField fieldName="E/CTO Agreement on Completion">
      <FormController
        controllerProps={{
          name: 'ectoAgreementOnCompletion',
          control: control,
        }}>
        <FormBinaryRadio disabled={ iowUneditable }  binaryOptionNames={{ yes: 'Agree', no: 'Disagree' }} />
      </FormController>
    </FormField>
  </FormContainer>

  )
}

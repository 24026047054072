export interface UserInfo {
  uuid: string
  firstName: string
  lastName: string
  defaultRoleName?: string
  organizationName?: string
  position?: string
  allRights: boolean
  districts: number[]
  teams: number[]
  categoryRights?: {
    category: string
    rights: string[]
  }[]
  fcmToken
  lastUpdateDt: Date | undefined
}

export const InitUserInfo: UserInfo = {
  uuid: '',
  firstName: '',
  lastName: '',
  fcmToken: '',
  allRights: false,
  lastUpdateDt: undefined,
  districts: [],
  teams: [],
}

import { Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Base64StringToImageBase64 } from '@utils/image'
import { FieldError } from 'react-hook-form'

interface formSignatureHolderProps {
  value?: string // base64
  onChange?: () => void // unused
  onClick: () => void

  error?: FieldError

  disabled?: boolean
}

export default function FormSignatureHolder(props: formSignatureHolderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        height: 60,
        width: 300,
        borderBottom: '1px solid rgb(50, 50, 50)',
        ...(!props.disabled && {
          cursor: 'pointer',
        }),
      }}
      onClick={props.onClick}>
      {props.value ? (
        <img
          src={Base64StringToImageBase64(props.value, 'png')}
          alt="signature"
          style={{
            height: 60,
            width: 300,
            objectFit: 'contain',
          }}
        />
      ) : (
        <Box>
          <EditIcon sx={{ color: props.disabled ? 'gray' : 'red' }} />
        </Box>
      )}
    </Box>
  )
}

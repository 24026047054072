import { KeyValPair } from '@models/common'
import { FormStatusEnum } from '@services/model/form/form.model'

export interface EAPartATemplateField {
  field: string
  displayName: string
  fieldType?: 'number' | 'select' | 'string' | 'displayNumber'
  options?: KeyValPair[]
}

export function EAPartATemplates(
  excelType: string,
  getOptionsByKey: (key: string) => KeyValPair[],
  isHighSpeedRoadContract: boolean,
  formStatus: string,
): EAPartATemplateField[] {
  const auditSequence: EAPartATemplateField = {
    field: 'auditSequence',
    displayName: 'Audit Sequence',
  }
  const neaSampleNo: EAPartATemplateField = { field: 'neaSampleNo', displayName: 'Sampling No.' }
  const neaSampleSelected: EAPartATemplateField = {
    field: 'neaAuditCode',
    displayName: 'Sample Selected',
  }

  const neaLocationRoadName: EAPartATemplateField = {
    field: 'neaLocation',
    displayName: 'Road Name',
  }
  const neaLocationLocation: EAPartATemplateField = {
    field: 'neaLocation',
    displayName: 'Location',
  }
  const neaLocationBoundTunnelName: EAPartATemplateField = {
    field: 'neaLocation',
    displayName: 'Tunnel Name (Bound)',
  }

  const neaLaneLength: EAPartATemplateField = {
    field: 'neaShapeLength',
    displayName: 'Total Lane Length Sampled (m)',
    fieldType: 'displayNumber',
  }
  const neaTunnelLength: EAPartATemplateField = {
    field: 'neaLength',
    displayName: 'Total Tunnel Portions length Sampled (m)',
    fieldType: 'displayNumber',
  }

  const neaRoadTypeId: EAPartATemplateField = {
    field: 'neaRoadTypeId',
    displayName: 'Road Type',
    fieldType: 'select',
    options: getOptionsByKey('Form EA - Road Type'),
  }
  const neaShapeArea: EAPartATemplateField = {
    field: 'neaShapeArea',
    displayName: 'Total Road Area Sampled (m²)',
    fieldType: 'displayNumber',
  }
  const auditDate: EAPartATemplateField = { field: 'auditDate', displayName: 'Audit Date' }
  const neaStructureNo: EAPartATemplateField = {
    field: 'neaStructureNo',
    displayName: 'Structure No.',
  }
  const planAreaOfStructure: EAPartATemplateField = {
    field: 'planAreaOfStructure',
    displayName: 'Plan Area of the Structure (m²)',
    fieldType:
      formStatus === FormStatusEnum.FORM_EA_AGREE_PRELIM_RESULT ? 'displayNumber' : 'number',
  }
  const neaGeoRegistrationNo: EAPartATemplateField = {
    field: 'neaGeoRegistrationNo',
    displayName: 'Slope No.',
  }
  const neaHydRegistrationNo: EAPartATemplateField = {
    field: 'neaHydRegistrationNo',
    displayName: 'HyD Reference No.',
  }
  const neaSlopeNo: EAPartATemplateField = {
    field: 'neaSlopeNo',
    displayName: 'Slope No.',
  }
  const neaVegetationId: EAPartATemplateField = {
    field: 'neaVegetationId',
    displayName: 'Vegetation ID',
  }

  switch (excelType) {
    case 'Maintenance of Road Marking':
    case 'Maintain Road Markings and Road Studs':
    case 'Maintenance of Road Marking and Road Studs':
    case 'Maintenance of Road Markings and Road Studs':
    case 'Conduct Inspections for and Maintenance of Road Marking': {
      return [auditSequence, neaSampleNo, neaSampleSelected, neaLocationRoadName, neaLaneLength]
    }
    case 'Maintain Road Drainage System':
    case 'Maintenance of Road Drainage System': {
      return [
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationRoadName,
        neaRoadTypeId,
        neaShapeArea,
      ]
    }
    case 'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works':
    case 'Conducting Routine Inspections and Undertaking Associated General Road Maintenance Works':
    case 'Conduct Routine Inspections and Undertake Associated General Road Maintenance Works':
    case 'Routine Inspections and Associated General Road Maintenance Works': {
      const neaParts: Array<EAPartATemplateField> = [
        auditDate,
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationRoadName,
        neaRoadTypeId,
      ]

      return neaParts
    }
    case 'Conducting Detailed Inspections on Roads and Undertaking  Associated General Road Maintenance Works':
    case 'Conduct Detailed Inspections of Roads and Undertake Associated General Road Maintenance Works':
    case 'Detailed Inspections and Associated General Road Maintenance Works': {
      return [
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationRoadName,
        neaRoadTypeId,
        neaShapeArea,
      ]
    }
    case 'Conduct Inspection of Stepping ≧5mm and ≦10mm on Paver Footways':
    case 'Stepping Inspection on Paver Footways': {
      return [auditSequence, neaSampleNo, neaSampleSelected, neaLocationRoadName, neaShapeArea]
    }
    case 'Conducting Inspections of Highway Structures and Undertake Structural Maintenance':
    case 'Conducting Inspections of Highway Structures and Undertaking Structural Maintenance':
    case 'Conduct Inspections of Highway Structures and Undertake Structural Maintenance':
    case 'Conduct Inspections on Highway Structures and Undertake Structural Maintenance': {
      return [
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationLocation,
        neaStructureNo,
        planAreaOfStructure,
      ]
    }
    case 'Conduct Routine Maintenance Inspection For and Maintain Roadside Slopes':
    case 'Conducting Routine Maintenance Inspection for and Maintenance of Roadside Slopes':
    case 'Conduct Routine Maintenance Inspection for and Maintain Roadside Slopes': {
      return [
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationLocation,
        neaGeoRegistrationNo,
        neaHydRegistrationNo,
      ]
    }
    case 'Conducting Inspections of Tunnel Segments and Undertaking Structural Maintenance':
    case 'Conduct Inspections of Tunnel Segments and Undertake Structural Maintenance':
    case 'Conducting Inspection of Tunnel Segements and Undertaking Structural Maintenance': {
      return [
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationBoundTunnelName,
        neaTunnelLength,
      ]
    }
    case 'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas':
    case 'Vegetation Maintenance on Roadsides Slopes and Designated Planted Areas': {
      return [
        auditSequence,
        neaSampleNo,
        neaSampleSelected,
        neaLocationLocation,
        neaSlopeNo,
        neaVegetationId,
      ]
    }
    case 'Maintaining the Road Network in Hygienic Conditions': {
      return []
    }

    default: {
      return []
    }
  }
}

import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const Anchor = styled(Typography)<{ currentPage: boolean }>(({ theme, currentPage }) => ({
  fontSize: 16,
  ...(!currentPage && {
    ':hover': {
      cursor: 'pointer',
      borderBottom: '1px solid',
    },
  }),
  ...(currentPage && {
    borderBottom: '1px solid',
    color: '#cdcdcd',
  }),
}))

const GridContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
}))

const GridItem = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export default function BottomNavBar() {
  const navigate = useNavigate()

  const pathList = window.location.pathname.split('/')
  const curModule = pathList[1]
  const pathListLast = pathList[pathList.length - 1]

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        height: '75px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px -3px 5px 0px rgb(0 0 0 / 12%)',
      }}>
      <GridContainer container>
        <GridItem item xs={3}>
          <Anchor
            {...(pathListLast !== 'overview' && {
              onClick: () => navigate(`${curModule}/overview`),
            })}
            currentPage={pathListLast === 'overview'}>
            Overview
          </Anchor>
        </GridItem>
        <GridItem item xs={3}>
          <Anchor
            {...(pathListLast !== 'dashboard' && {
              onClick: () => navigate(`${curModule}/dashboard`),
            })}
            currentPage={pathListLast === 'dashboard'}>
            Dashboard
          </Anchor>
        </GridItem>
        <GridItem item xs={3}>
          <Anchor
            {...(pathListLast !== 'my-action' && {
              onClick: () => navigate(`${curModule}/my-action`),
            })}
            currentPage={pathListLast === 'my-action'}>
            My Action
          </Anchor>
        </GridItem>
        <GridItem item xs={3}>
          <Anchor
            {...(pathListLast !== 'all-record' && {
              onClick: () => navigate(`${curModule}/all-record`),
            })}
            currentPage={pathListLast === 'all-record'}>
            All Record
          </Anchor>
        </GridItem>
      </GridContainer>
    </Box>
  )
}

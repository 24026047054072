import { KeyValPair } from '@models/common'

const GetValuesWithKey = (keys: any[] | undefined, pairs: KeyValPair[]): any | undefined => {
  if (keys === undefined) return undefined

  let valueString = pairs.filter((x) => keys.includes(x.key)).map((x) => x.value)
  return valueString.join(', ')
}

export default GetValuesWithKey

import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction }                                   from 'react-router-dom'
import moment                                                 from 'moment'
import { useTranslation }                                     from 'react-i18next'
import { Grid, IconButton, Tooltip }                          from '@mui/material'
import * as common                                            from '@common/common'
import { GlobalContext }                                      from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext }        from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState }               from '@reducers/searchPanelReducer'
import ContractService                                        from '@services/contract.service'
import { WorkOrder }                                          from '@services/model/contract.model'
import useTableView                                           from '@hooks/useTableView'
import SearchPanel                                            from '@components/searchPanel'
import FormStatusLabel                                        from '@components/FormStatusLabel'
import ExportIcon                                             from '@components/icon/export'
import GetValueWithKey                                        from '@utils/getValueWithKey'
import FormDNService                                          from '@services/formService/form.DN.service'
import { NavigateTo }                                         from '@utils/navigate'
import { FormDnListModel, DnFilterOptions }                   from '@services/model/form/form.DN.model'
import { RightsCategory }                                     from '@services/model/form/form.model'
import { exportToCSV }                                        from '@utils/exportExcel'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted             = useRef(false)
  const { state: globalState } = useContext(GlobalContext)
  const [state, dispatch]      = useReducer<React.Reducer<SearchPanelState, any>>(SearchPanelReducer, InitialSearchPanelState)

  const { t }                               = useTranslation()
  const [workOrderForDn, setWorkOrderForDn] = useState<Array<WorkOrder>>([])
  const [extraListInfo, setExtraListInfo]   = useState<DnFilterOptions>({
                                                                          submittedByList : [],
                                                                          approvedByList  : [],
                                                                          formIdList      : [],
                                                                          parentFormIdList: [],
                                                                          workOrderIdList : []
                                                                        })

  const getExtraListInfo = async () => {
    let extraInfo = await FormDNService.GetDnFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const getTableFullList = async () => {
    let fullList = await FormDNService.GetDNList(
        {
          inBatches : true,
          pagination: { skipPagination: true }
        },
        '',
        props.showMyRecord || false
    )

    let list: any[] = fullList.list.map(record => {
      return {
        'General Status'   : record.formStatusShortName,
        'Submission Status': record.formStatus,
        'NF Form'          : record.parentReportNo,
        'Form ID'          : record.reportNo,
        'DN Type'          : GetValueWithKey(record.nonComplianceId,
                                             globalState.generalOptionDNGroupList?.map(x => ({ key: x.id, value: x.dnRefNo })) ?? []),
        'Inspection Date'  : record.inspectionDate
                             ? moment(record.inspectionDate).format(moment.defaultFormat)
                             : '—',
        'Issue Date'       : record.issuedAt
                             ? moment(record.issuedAt).format('DD MMM YYYY')
                             : '—',
        'Appeal Due Date'  : record.appealDueDate
                             ? moment(record.appealDueDate).format('DD MMM YYYY (dddd)')
                             : '—',
        'NC Period'        :
            (record.startAt ? moment(record.startAt).format() : 'N/A') +
            ' - ' +
            (record.endAt ? moment(record.endAt).format() : 'N/A'),
        'Work Order'       : workOrderForDn
                                 ?.find(x => x.id === record.workOrderId)
                                 ?.workOrderNo ?? '—',
        'Submitted By'     : GetValueWithKey(record.submittedBy, globalState.userMetaList ?? []),
        'Approved By'      : GetValueWithKey(record.approvedBy, globalState.userMetaList ?? []),
        'Last update'      : record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : 'N/A',
      }
    })

    return list ?? []
  }

  const [TableView, reload] = useTableView<FormDnListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'parentFormNo',
        desc: t('Parent Form', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => <Fragment>{record.parentReportNo}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'nonComplianceId',
        desc: t('DN Type', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {GetValueWithKey(
              record.nonComplianceId,
              globalState.generalOptionDNGroupList?.map((x) => {
                return {
                  key: x.id,
                  value: x.dnRefNo,
                }
              }) ?? [],
            )}
          </Fragment>
        ),
      },
      {
        key: 'inspectionDate',
        desc: t('Inspection Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {record.inspectionDate
              ? moment(record.inspectionDate).format(moment.defaultFormat)
              : 'N/A'}
          </Fragment>
        ),
      },
      {
        key       : 'issuedAt',
        desc      : t('Issue Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) =>
            <>
              {
                record.issuedAt
                ? moment(record.issuedAt).format('DD MMM YYYY')
                : '—'
              }
            </>
      },
      {
        key       : 'appealDueDate',
        desc      : t('Appeal Due Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) =>
            <>
              {
                record.appealDueDate
                ? moment(record.appealDueDate).format('DD MMM YYYY (dddd)')
                : '—'
              }
            </>
      },
      {
        key: 'ncPeriodStart',
        desc: t('NC Period', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {(record.startAt ? moment(record.startAt).format() : 'N/A') +
              ' - ' +
              (record.endAt ? moment(record.endAt).format() : 'N/A')}
          </Fragment>
        ),
      },
      {
        key       : 'workOrderId',
        desc      : t('Work Order', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) =>
            <>
              {
                  workOrderForDn
                      ?.find(x => x.id === record.workOrderId)
                      ?.workOrderNo ?? '—'
              }
            </>
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormDnListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : 'N/A'}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormDnListModel) {
      NavigateTo(navigate, '/default-notice-for-non-compliance/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('DN', JSON.stringify(state.filterResult))

        let [dns, wo] = await Promise.all([FormDNService.GetDNList({
                                                                     ...state.filterResult,
                                                                     sorting   : sorting,
                                                                     pagination: pagination
                                                                   },
                                                                   cancelToken,
                                                                   props.showMyRecord || false),
                                           ContractService.GetWorkOrderListForDN()])
        setWorkOrderForDn(wo)
        return dns
      }
    }
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_DN))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.formStatus?.value
                  : [],
            },
            {
              key: 'parentFormNo',
              desc: 'Parent Form',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.parentFormIdList,
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.parentFormNo?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.formId?.value
                  : [],
            },
            {
              key: 'nonComplianceId',
              desc: 'DN Type',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.generalOptionDNGroupList?.map((x) => {
                return {
                  key: x.id,
                  value: x.dnRefNo,
                }
              }),
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.nonComplianceId?.value
                  : [],
            },
            {
              key: 'inspectionDate',
              desc: 'Inspection Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.inspectionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.inspectionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.inspectionDate?.value?.max
                  : '',
            },
            {
              key: 'issuedAt',
              desc: 'Issue Date',
              type: 'DateFilter',
              defaultOperator:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.issuedAt?.operator
                  : 'in',
              defaultFrom:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.issuedAt?.value?.min
                  : '',
              defaultTo:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.issuedAt?.value?.max
                  : '',
            },
            {
              key: 'appealDueDate',
              desc: 'Appeal Due Date',
              type: 'DateFilter',
              defaultOperator:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.appealDueDate?.operator
                  : 'in',
              defaultFrom:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.appealDueDate?.value?.min
                  : '',
              defaultTo:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.appealDueDate?.value?.max
                  : '',
            },
            {
              key: 'ncPeriodStart',
              desc: 'NC Start',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodStart?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodStart?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodStart?.value?.max
                  : '',
            },
            {
              key: 'ncPeriodEnd',
              desc: 'NC End',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodEnd?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodEnd?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.ncPeriodEnd?.value?.max
                  : '',
            },
            {
              key         : 'workOrderId',
              desc        : 'Work Order',
              type        : 'NumberSelectionFilter',
              multiple    : true,
              valueList   : workOrderForDn.map(workOrder => ({ key: workOrder.id, value: workOrder.workOrderNo })),
              defaultValue:
                  sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.workOrderId?.value
                  : []
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.DN !== 'undefined' && sessionStorage?.DN !== undefined
                  ? JSON.parse(sessionStorage?.DN)?.updatedAt?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <Tooltip title="Export">
              <IconButton
                sx={{
                  backgroundColor: globalState.headerColor,
                  borderRadius: '10%',
                  width: '40px',
                  height: '40px',
                  '&:hover': { background: globalState.headerColor },
                }}
                onClick={async () => await exportToCSV(await getTableFullList(), 'DN-all-record')}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord
import {
  Button,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FormContext } from '@providers/formStateProvider'
import { GlobalContext } from '@providers/globalStore'
import { t } from 'i18next'
import { MutableRefObject, useCallback, useContext, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import useAPIFetch from './useAPIFetch'

type TableHeader = {
  key: string
  desc: string
  minWidthInPixels?: string
  hidden?: boolean // generic hidden column
  mobileHidden?: boolean // mobile hidden column
  renderCell?: (row: any) => JSX.Element
}

export type ExcelDataSetType = {
  filename: string
  sheetname: string
  dataset: any
}

export type useTableViewProps<T> = {
  headers: {
    key: string
    desc: string
    // minWidthInPixels?: string
    hidden?: boolean // generic hidden column
    mobileHidden?: boolean // mobile hidden column
    renderCell?: (
      record: T,
      navigate?: NavigateFunction,
      columnIndex?: number,
      rowIndex?: number,
    ) => JSX.Element
    width?: string | number
    disableSort?: boolean
  }[]
  skeletonRows?: number
  rowsPerPage?: number
  reloadCallback: (
    pagination: Pagination,
    sorting: Sorting | undefined,
    cancelToken: any,
  ) => Promise<{ list: T[]; lastModifyList?: number[]; totalCount: number } | undefined>
  onRowGen?: (navigate: NavigateFunction, record: T, index: number) => JSX.Element
  onRowClick?: (navigate: NavigateFunction, record: T) => void
  customRow?: boolean
  mountedRef: MutableRefObject<boolean>
  viewOnly?: boolean
}

type Pagination = {
  page: number
  pageSize: number
  skipPagination?: boolean
}

interface Sorting {
  sortBy: string
  orderBy: 'asc' | 'desc'
}

const useTableView = function <T>(
  props: useTableViewProps<T>,
): [TableView: () => JSX.Element, reload: () => void] {
  const { setRequest, isLoading } = useAPIFetch()
  const [list, setList] = useState<T[] | undefined>()

  const [totalCnt, setTotalCnt] = useState<number>(0)
  const [lastModifyList, setLastModifyList] = useState<number[]>([])
  const navigate = useNavigate()
  const { dispatch } = useContext(FormContext)

  const [sorting, setSorting] = useState<Sorting>()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 25)
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { contractNoId } = globalState

  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const [exportLoading, setExportLoading] = useState<boolean>(false)
  // const hasExportRights = props.onExcelExportPermission === undefined ? true : userInfo.allRights || userInfo.accessRights.includes(props.onExcelExportPermission)

  useEffect(() => {
    globalAction({ type: 'changeFormStatus' })
  }, [])

  useEffect(() => {
    reload(0, props.rowsPerPage || 25)
  }, [contractNoId])

  useEffect(() => {
    reload(page, rowsPerPage)
  }, [sorting])

  const reloadCallback = useCallback(async () => {
    reload(page, rowsPerPage, true)
  }, [props.reloadCallback])

  const reload = (currentPage: number, currentPageSize: number, resetPage?: boolean) => {
    if (!isLoading) {
      if (resetPage) {
        setPage(0)
        currentPage = 0
      }

      setRequest({
        callback: async (cancelToken: any) => {
          await props
            .reloadCallback(
              { page: currentPage + 1, pageSize: currentPageSize },
              sorting,
              cancelToken,
            )
            .then((x) => {
              if (props.mountedRef.current) {
                setList(x?.list)
                setLastModifyList(x?.lastModifyList || [])
                setTotalCnt(x?.totalCount || 0)
              }
            })
        },
      })
    }
  }

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (sorting) {
      if (sorting.sortBy === property) {
        switch (sorting.orderBy) {
          case 'asc':
            setSorting({
              sortBy: property,
              orderBy: 'desc',
            })
            break
          case 'desc':
            setSorting({
              sortBy: property,
              orderBy: 'asc',
            })
            break
        }

        // reload(page, rowsPerPage)
        return
      }
    }

    setSorting({
      sortBy: property,
      orderBy: 'asc',
    })

    // reload(page, rowsPerPage)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    reload(newPage, rowsPerPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    reload(0, +event.target.value)
  }

  const [dataSet, setDataSet] = useState<ExcelDataSetType>()
  useEffect(() => {
    if (dataSet) {
      document.getElementById('downloadExcelBtn')?.click()
    }
  }, [dataSet])

  const TableView = () => {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer
          sx={{
            border: 0,
            background: 'white',
            width: '100%',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            maxHeight: '700px',
            minHeight: '700px',
            height: 'auto !important',
          }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            {!isLoading && (!list || list.length === 0) && (
              <caption style={{ textAlignLast: 'center' }}>No Records</caption>
            )}
            <TableHead>
              <TableRow key={'header'} sx={{ height: '50px' }}>
                {props.headers
                  .filter((header) => (downMd ? !header.mobileHidden : true) && !header.hidden)
                  .map((column, columnIndex) => {
                    return (
                      <TableCell
                        key={`header_${columnIndex}`}
                        sx={{
                          backgroundColor: '#b7b7b7',
                          minWidth: column.width ?? '100px',
                        }}
                        align="left"
                        sortDirection={
                          sorting && sorting.sortBy === column.key ? sorting.orderBy : false
                        }>
                        <TableSortLabel
                          active={sorting && sorting.sortBy === column.key}
                          direction={
                            sorting && sorting.sortBy === column.key ? sorting.orderBy : undefined
                          }
                          onClick={createSortHandler(column.key)}
                          disabled={column.disableSort}>
                          {column.desc}
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            {isLoading || !props.mountedRef.current || (props.mountedRef.current && !list) ? (
              <TableBody>
                {[...Array(props.skeletonRows || 20)].map((e, i) => {
                  return (
                    <TableRow key={`skeleton_r_${i}`}>
                      {props.headers.map((r, index) => {
                        return (
                          <TableCell key={`skeleton_${index}`}>
                            <Skeleton />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : (
              <TableBody>
                {list?.map((record, rowIndex) => {
                  if (props.customRow)
                    return (
                      <TableRow
                        onClick={() => {
                          dispatch({ type: 'clear' })
                          if (props.onRowClick) props.onRowClick(navigate, record)
                        }}
                        key={rowIndex}
                        sx={{
                          '&:nth-of-type(odd)': {
                            backgroundColor: '#e6e6e6',
                          },
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}>
                        {props.onRowGen && props.onRowGen(navigate, record, rowIndex)}
                      </TableRow>
                    )
                  else
                    return (
                      <TableRow
                        onClick={() => {
                          if(!props.viewOnly){
                            dispatch({ type: 'clear' })
                            if (props.onRowClick) props.onRowClick(navigate, record)
                          }
                        }}
                        key={rowIndex}
                        sx={{
                          '&:nth-of-type(odd)': {
                            backgroundColor: '#e6e6e6',
                          },
                          ...(props.viewOnly && {
                            '&:hover': {
                              cursor: 'pointer',
                              backgroundColor: '#cfcfcf',
                            },
                          }),
                        }}>
                        {props.headers
                          .filter(
                            (header) => (downMd ? !header.mobileHidden : true) && !header.hidden,
                          )
                          .map((column, columnIndex) => (
                            <TableCell
                              key={`table-record-${rowIndex}-${columnIndex}`}
                              sx={{ minWidth: column.width ?? '100px' }}>
                              {column.renderCell
                                ? column.renderCell(record, navigate, columnIndex, rowIndex)
                                : '-'}
                            </TableCell>
                          ))}
                      </TableRow>
                    )
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Grid container spacing={1}>
          <Grid item md={11}>
            <TablePagination
              component="div"
              count={totalCnt}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return [TableView, reloadCallback]
}

export default useTableView

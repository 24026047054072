import FormContainer from '@components/form/container'
import FormSignatureHistories from '@components/form/signatureHistories'
import { FormPartComponents } from '@models/common'
import { GlobalContext } from '@providers/globalStore'
import { FormScrModel } from '@services/model/form/form.SCR.model'
import { t } from 'i18next'
import { useContext } from 'react'
import { Control } from 'react-hook-form'

export default ({
  control,
  getValues,
  handleOnComplete,
}: {
  control: Control<FormScrModel>
  getValues: any
  handleOnComplete: () => void
}) => {
  const { state: globalState } = useContext(GlobalContext)
  const formPermission = getValues('baseForm.formPermission')

  return  (
      <FormContainer fkey={`-scr-i`}>
        <FormSignatureHistories
          formStatusList={globalState.formStatusList}
          histories={getValues('baseForm.formStatusHistories')}
          control={control}
          handleOnComplete={() => handleOnComplete()}
          endOfFlow={formPermission?.endOfFlow}
          userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
          disabled={!formPermission?.canUpdate}
        />
      </FormContainer>
    )
  
}